import { Box, Button, Checkbox, FormControlLabel, FormGroup } from "@mui/material"
import Footer from "../common/MarxEntertainment/footer/Footer"
import Header from "../common/MarxEntertainment/header/Header"
import '../styles/socialMediaStyle.css'
import ReactFacebookLogin from "react-facebook-login"
import { InstagramLogin } from "@amraneze/react-instagram-login";
import { Link } from "react-router-dom"
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google"
import { jwtDecode } from "jwt-decode";


const SocialMediaAssets = () => {

    const fbOathButton = () => {
        const fbButton = document.getElementById('fb-btn_oath');
        fbButton.style.display = (fbButton.style.display === 'block') ? 'none' : 'block';
    }
    const instaOathButton = () => {
        const instaButton = document.getElementById('insta-btn_oath');
        instaButton.style.display = (instaButton.style.display === 'block') ? 'none' : 'block';
    }
    const googleOathButton = () => {
        const googleButton = document.getElementById('google-btn_oath');
        googleButton.style.display = (googleButton.style.display === 'block') ? 'none' : 'block';
    }
    const linkdOathButton = () => {
        const linkdinButton = document.getElementById('linkd-btn_oath');
        linkdinButton.style.display = (linkdinButton.style.display === 'block') ? 'none' : 'block';
    }
    const responseFacebook = (response) => {
        console.log('facebook :', response.accessToken);
    };
    const responseInstagram = (response) => {
        console.log("instagram :", response);
        console.log('instagram :', response.accessToken);
    };

    return (
        <>
            <Header />

            <Box className='social-m-box'>
                <div>
                    <div className="social-text">
                        <h1 className=" fw-bold">Influencer Sign Up</h1>
                        <p>Get recognized as an Influencer & pave your way To financial freedom</p>
                    </div>
                    <div className="social-text">
                        <h2 className=" fw-bold mb-5">Your Social Media Assets</h2>
                    </div>
                    <div className="select-area-div">
                        <div>
                            <FormGroup>
                                <FormControlLabel onClick={fbOathButton} control={<Checkbox />} label="Facebook" />
                                <div id="fb-btn_oath">
                                    <ReactFacebookLogin
                                        appId="908811783939973"
                                        autoLoad={false}
                                        fields="name,email,user_posts"
                                        callback={responseFacebook}
                                    />
                                </div>
                                <FormControlLabel onClick={instaOathButton} control={<Checkbox />} label="Instagram" />
                                <div id="insta-btn_oath">

                                    <InstagramLogin
                                        clientId={"769788481642243"}
                                        onSuccess={responseInstagram}
                                        onFailure={(error) => console.error("instagram error", error)}
                                        redirectUri={'https://marxgroupworldwide.com/'}
                                        scope="user_profile"
                                    >
                                        <span> Login with Instagram</span>
                                    </InstagramLogin>
                                </div>

                                <FormControlLabel onClick={googleOathButton} control={<Checkbox />} label="Google" />
                                <div id="google-btn_oath">
                                    <GoogleOAuthProvider clientId="459720570554-fa48vkc62ju5obdmj986bfmoh0g5gshs.apps.googleusercontent.com">
                                        <GoogleLogin
                                            onSuccess={(credentialResponse) => {
                                                const decoded = jwtDecode(credentialResponse?.credential);
                                                console.log('google', decoded);
                                                console.log(credentialResponse.credential)
                                            }}
                                            onError={() => {
                                                console.log('Login Failed');
                                            }}
                                        />
                                        
                                    </GoogleOAuthProvider>
                                </div>

                                <FormControlLabel onClick={linkdOathButton} control={<Checkbox />} label="LinkedIn" />
                                <div id="linkd-btn_oath">
                                   
                                </div>

                            </FormGroup>
                        </div>

                        <div className="social-btn-div">
                            <Link to={'/influencer-signup'}>
                                <Button type="button">back</Button>
                            </Link>
                            <Link to={'/influencer-signup/client-registration-form'}>
                            <Button type="button">Proceed to next step</Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </Box>

            <Footer />
        </>
    )
}

export default SocialMediaAssets
