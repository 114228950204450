
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import '../../styles/marxFoundationHomeStyle.css'
import MarxFoundationHeader from "../../common/MarxFoundation/HeaderMarxFoundation";
import MarxFoundationFooter from "../../common/MarxFoundation/FooterMarxFoundation";
import RunningSlide from "../../common/RunningSlideAllMarx/RunningSlide";
import { useEffect } from "react";
import TopSliderAllMax from "../../common/TopSliderAllMarx/TopSliderAllMarx";
import mrDevashish from '../../assest/images/mrDevashish.jpeg'
import mrAnand from '../../assest/images/mrAnand.jpeg'
import mrSanday from '../../assest/images/mrSanday.jpeg'
import AllDemoData from "../../data/AllDemoData";
import { Dropdown } from "react-bootstrap";



const MarxFoundationHome = () => {
    useEffect(() => {
        document.title = "Mar'x Foundation Charitable Trust";
    }, []);

    return (
        <>
            <MarxFoundationHeader />

            <TopSliderAllMax title={"Gallery"} images={AllDemoData} />

            <div className="">
                <div className="event-navbrlists">
                    <ul>
                        <li>
                            <Link to="/marx-foundation">Home</Link>
                        </li>
                        <li>
                            <Dropdown id="custom-dropdown">
                                <Dropdown.Toggle variant="" id="dropdown-basic">Get Involved</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item as={Link} to="/foundation-individual">Individual</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/foundation-corporate">Corporates</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/foundation-noncorporate">Non-Corporates</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/foundation-schoolspartner">Schools Partner</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/foundation-collegepartners">College Partners</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/foundation-universitypartners">University Partners</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/foundation-institutionpartners">Institution Partners</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/foundation-governmentinstitutions">Government Institutions</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                       
                        <li>
                            <Link to="/foundation-socialcause">Social Cause</Link>
                        </li>
                       
                        <li>
                            <Dropdown id="custom-dropdown">
                                <Dropdown.Toggle variant="" id="dropdown-basic">Registration Form</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item as={Link} to="/foundation-Donationform">Donation form</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/foundation-Volunteerform">Volunteer Form</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/foundation-Internshipform">Internship Form</Dropdown.Item>

                                </Dropdown.Menu>
                            </Dropdown>
                        </li>

                        <li>
                            <Link to="/foundation-showvideoreel">Shows Video Reel</Link>
                        </li>
                        <li>
                            <Link to="/foundation-charityfund">Charity Fund</Link>
                        </li>
                        <li>
                            <Link to="/foundation-pressmediacoverage">Press & Media Coverage</Link>
                        </li>
                        <li>
                            <Link to="/foundation-shows-gallery">Events / Shows Pics</Link>
                        </li>
                       
                        <li>
                            <Link to="/foundation-socialcampaigns">Social Campaigns</Link>
                        </li>
                        <li>
                            <Link to="/foundation-fundraiserevent">Fund Raiser Event</Link>
                        </li>
                        
                    </ul>
                </div>
            </div>

            <div className="main-foundationdiv">
                <Box className="mainfoundationdiv-box">
                    <div className="allfoundationtabs">
                        <h5 className=" text-center fw-bold mb-4"> INDEX </h5>
                        <p className='mange-foundationtab'>
                            <Link to={'/'} >MARS </Link>
                        </p>
                    </div>
                    <div className="foundationtabpanels">
                        <div>
                            <div className="about-marx-foundation">
                                <h1>About Us</h1>
                                <p></p>
                            </div>

                            <div className="about-marx-foundation">
                                <h1>What We Do</h1>
                                <div>
                                    <p></p>
                                </div>
                            </div>

                            <div className="about-marx-foundation">
                                <h1>Why to Choose Us</h1>
                                <div className="servcedivptag">
                                    <p></p>
                                </div>
                            </div>

                            <div className="about-marx-foundation" style={{ borderTop: '2px solid white' }}>
                                <h2>Trustee</h2>
                                <div className="">
                                    <div className="each-director">
                                        <div className="">
                                            <img src={mrDevashish} alt="img" />
                                            <p className=" fw-bold fs-4 text-center m-0">Mr. Devashish Bhandari</p>
                                            <p className="fs-5 text-center mt-0">(Trustee)</p>
                                        </div>
                                        <div className="">
                                            <img src={mrAnand} alt="img" />
                                            <p className=" fw-bold fs-4 text-center m-0">Mr. Anand Chaurdhary</p>
                                            <p className="fs-5 text-center mt-0">(Settler)</p>
                                        </div>
                                        <div className="">
                                            <img src={mrSanday} alt="img" />
                                            <p className=" fw-bold fs-4 text-center m-0">Mr. Sanjay Dubey</p>
                                            <p className="fs-5 text-center mt-0">(Appointer / Assest)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </div>

            <RunningSlide title="News & Story Gallery" images={AllDemoData} />
            {/* <RunningSlide title="Designing" images={OurServiceData}/> */}

            <MarxFoundationFooter />
        </>
    )
}

export default MarxFoundationHome
