import modelBankGallery1 from '../../assest/images/eventModelBankGallery/modelBankGallery1.jpg'
import modelBankGallery2 from '../../assest/images/eventModelBankGallery/modelBankGallery2.jpg'
import modelBankGallery3 from '../../assest/images/eventModelBankGallery/modelBankGallery3.jpg'
import modelBankGallery4 from '../../assest/images/eventModelBankGallery/modelBankGallery4.jpg'
import modelBankGallery5 from '../../assest/images/eventModelBankGallery/modelBankGallery5.jpg'
import modelBankGallery6 from '../../assest/images/eventModelBankGallery/modelBankGallery6.jpg'
import modelBankGallery7 from '../../assest/images/eventModelBankGallery/modelBankGallery7.jpg'
import modelBankGallery8 from '../../assest/images/eventModelBankGallery/modelBankGallery8.jpg'
import modelBankGallery9 from '../../assest/images/eventModelBankGallery/modelBankGallery9.jpeg'
import modelBankGallery10 from '../../assest/images/eventModelBankGallery/modelBankGallery10.jpeg'
import enttCelebs1 from '../../assest/images/entt.CelebsGallery/enttCelebs1.jpeg'
import enttCelebs2 from '../../assest/images/entt.CelebsGallery/enttCelebs2.jpeg'
import enttCelebs3 from '../../assest/images/entt.CelebsGallery/enttCelebs3.jpeg'
import enttCelebs4 from '../../assest/images/entt.CelebsGallery/enttCelebs4.jpeg'
import enttCelebs5 from '../../assest/images/entt.CelebsGallery/enttCelebs5.jpeg'
import enttCelebs6 from '../../assest/images/entt.CelebsGallery/enttCelebs6.jpeg'
import enttCelebs7 from '../../assest/images/entt.CelebsGallery/enttCelebs7.jpeg'
import enttCelebs8 from '../../assest/images/entt.CelebsGallery/enttCelebs8.jpeg'
import enttCelebs9 from '../../assest/images/entt.CelebsGallery/enttCelebs9.jpeg'
import enttCelebs10 from '../../assest/images/entt.CelebsGallery/enttCelebs10.jpeg'
import weddDecorPhotography1 from '../../assest/images/weddDecorGallery/weddDecorPhotography1.jpeg'
import weddDecorPhotography2 from '../../assest/images/weddDecorGallery/weddDecorPhotography2.jpeg'
import weddDecorPhotography3 from '../../assest/images/weddDecorGallery/weddDecorPhotography3.jpeg'
import weddDecorPhotography4 from '../../assest/images/weddDecorGallery/weddDecorPhotography4.jpeg'
import weddDecorPhotography5 from '../../assest/images/weddDecorGallery/weddDecorPhotography5.jpeg'
import weddDecorPhotography6 from '../../assest/images/weddDecorGallery/weddDecorPhotography6.jpeg'
import weddDecorPhotography7 from '../../assest/images/weddDecorGallery/weddDecorPhotography7.jpeg'
import weddDecorPhotography8 from '../../assest/images/weddDecorGallery/weddDecorPhotography8.jpeg'
import weddDecorPhotography9 from '../../assest/images/weddDecorGallery/weddDecorPhotography9.jpeg'
import weddDecorPhotography10 from '../../assest/images/weddDecorGallery/weddDecorPhotography10.jpeg'
import media1 from '../../assest/images/MediaProductBrandingPromotion/media1.jpeg'
import media2 from '../../assest/images/MediaProductBrandingPromotion/media2.jpeg'
import media3 from '../../assest/images/MediaProductBrandingPromotion/media3.jpeg'
import media4 from '../../assest/images/MediaProductBrandingPromotion/media4.jpeg'
import media5 from '../../assest/images/MediaProductBrandingPromotion/media5.jpeg'
import media6 from '../../assest/images/MediaProductBrandingPromotion/media6.jpeg'
import media7 from '../../assest/images/MediaProductBrandingPromotion/media7.jpeg'
import media8 from '../../assest/images/MediaProductBrandingPromotion/media8.jpeg'
import media9 from '../../assest/images/MediaProductBrandingPromotion/media9.jpeg'
import media10 from '../../assest/images/MediaProductBrandingPromotion/media10.jpeg'

const MarxGroupGalleryData = [
    {
        id: 1,
        imgSrc: modelBankGallery1,
    },
    {
        id: 2,
        imgSrc: modelBankGallery2,
    },
    {
        id: 3,
        imgSrc: modelBankGallery3,
    }, {
        id: 4,
        imgSrc: modelBankGallery4,
    }, {
        id: 5,
        imgSrc: modelBankGallery5,
    }, {
        id: 6,
        imgSrc: enttCelebs1,
    }, {
        id: 7,
        imgSrc: enttCelebs2,
    }, {
        id: 8,
        imgSrc: enttCelebs3,
    }, {
        id: 9,
        imgSrc: enttCelebs4,
    }, {
        id: 10,
        imgSrc: enttCelebs5,
    }, {
        id: 11,
        imgSrc: media1,
    }, {
        id: 12,
        imgSrc: media2,
    }, {
        id: 13,
        imgSrc: media3,
    }, {
        id: 14,
        imgSrc: media4,
    }, {
        id: 15,
        imgSrc: media5,
    }, {
        id: 16,
        imgSrc: weddDecorPhotography1,
    }, {
        id: 17,
        imgSrc: weddDecorPhotography2,
    }, {
        id: 18,
        imgSrc: weddDecorPhotography3,
    }, {
        id: 19,
        imgSrc: weddDecorPhotography4,
    }, {
        id: 20,
        imgSrc: weddDecorPhotography5,
    }, {
        id: 21,
        imgSrc: modelBankGallery6,
    },
    {
        id: 22,
        imgSrc: modelBankGallery7,
    },
    {
        id: 23,
        imgSrc: modelBankGallery8,
    }, {
        id: 24,
        imgSrc: modelBankGallery9,
    }, {
        id: 25,
        imgSrc: modelBankGallery10,
    }, {
        id: 26,
        imgSrc: enttCelebs6,
    }, {
        id: 27,
        imgSrc: enttCelebs7,
    }, {
        id: 28,
        imgSrc: enttCelebs8,
    }, {
        id: 29,
        imgSrc: enttCelebs9,
    }, {
        id: 30,
        imgSrc: enttCelebs10,
    }, {
        id: 31,
        imgSrc: media6,
    }, {
        id: 32,
        imgSrc: media7,
    }, {
        id: 33,
        imgSrc: media8,
    }, {
        id: 34,
        imgSrc: media9,
    }, {
        id: 35,
        imgSrc: media10,
    }, {
        id: 36,
        imgSrc: weddDecorPhotography6,
    }, {
        id: 37,
        imgSrc: weddDecorPhotography7,
    }, {
        id: 38,
        imgSrc: weddDecorPhotography8,
    }, {
        id: 39,
        imgSrc: weddDecorPhotography9,
    }, {
        id: 40,
        imgSrc: weddDecorPhotography10,
    }
];
export default MarxGroupGalleryData