import { Button } from "@mui/material";
import { useState } from "react";
import OTPInput from "react-otp-input";
import './otpStyle.css'
import { useNavigate } from "react-router-dom";

const Otp = () => {
    const [otp, setOtp] = useState('');
    const navigate = useNavigate()

    const restPassPage=()=>{
        navigate('/forgot-password/reset-password')
    }
    return (
        <>
            <div className="otpsend">
                <div className="card otpmargin">
                    <div>
                        <h1 className="ph-center">ENTER 6 DIGIT OTP</h1>
                    </div>
                    <hr></hr>

                    <form>
                        <div className="otpsend-everywhere">
                            <OTPInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                renderSeparator={<span style={{color:'grey'}}>-</span>}
                                renderInput={(props) => <input {...props} />}
                                inputStyle={{
                                    border: "1px solid lightgrey",
                                    borderRadius: "8px",
                                    width: "48px",
                                    height: "48px",
                                    fontSize: "18px",
                                    color: "#000",
                                    fontWeight: "400",
                                    caretColor: "blue",
                                  }}
                                  focusStyle={{
                                    border: "1px solid #CFD3DB",
                                    outline: "none"
                                  }}
                            />
                            <Button onClick={restPassPage} fullWidth type="button" className="btnbtn">
                                Submit
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Otp
