import { Link } from "react-router-dom"
import '../../styles/weddRegistrationFormStyle.css'
import MarxWeddingHeader from "../../common/marxWedding/HeaderMarxWedding"
import WeddingFooter from "../../common/marxWedding/FooterMarxWedding"

const WeddingRegistrationForm = () => {
    return (
        <>
            <MarxWeddingHeader />
            <div className="wedd-registform">
                <h1>Registration Forms</h1>
                <ul className="wedd-formss">
                    <li><Link>Internship Registration Form</Link></li>
                    <li><Link>Registration Form</Link></li>
                    <li><Link>Registration Form</Link></li>
                    <li><Link>Registration Form</Link></li>
                    <li><Link>Registration Form</Link></li>
                </ul>
            </div>
            <WeddingFooter />
        </>
    )
}

export default WeddingRegistrationForm
