
import MarxMediaHeader from '../../common/MarxMedia/MarxMediaHeader/MarxMediaHeader'
import MediaFooter from '../../common/MarxMedia/MarxMediaFooter/MarxMediaFooter'
import { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import '../../styles/mediaElectronicMediaStyle.css'

const MediaElectronic = () => {
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response = await axios.get(
                    `https://www.googleapis.com/youtube/v3/search?key=AIzaSyD-AZ-67w2WhyRWHrx_INx4YnVc8t4m4D0&channelId=UCxtU9Xa-Xb4qIHd--_TVLJQ&part=snippet,id&order=date&maxResults=20`
                );

                setVideos(response.data.items);
            } catch (error) {
                console.error('Error fetching videos: ', error);
            }
        };

        fetchVideos();
    }, []);
    return (
        <>
            <MarxMediaHeader />
            <div className='mediaelectronic-div'>
                <h1>Electronic Media</h1>
                <div className="mediaelectronic-videos">
                    {videos.map((video) => (
                        <div key={video.id.videoId} className="mediaelectronic-video">
                            <Link
                                to={`https://www.youtube.com/watch?v=${video.id.videoId}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img className='proimg' src={video.snippet.thumbnails.medium.url} alt={video.snippet.title} />
                                <h2>{video.snippet.title}</h2>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
            <MediaFooter />
        </>
    )
}

export default MediaElectronic
