import "./InfluencerSignup.css";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import img from "../../assest/images/influencer-boy.jpg";
import FreqAskQues from "../../components/FreqAskQues";
import Header from "../../common/MarxEntertainment/header/Header";
import { useEffect, useState } from "react";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import Footer from "../../common/MarxEntertainment/footer/Footer";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function InfluencerSignup() {
  const [industry, setImdustry] = useState([])
  const [category, setCategoryType] = useState([])
  const [finalCategoryTypeD, setFinalCategoryTypeD] = useState()
  const [finalIndustryTypeD, setFinalIndustryTypeD] = useState()
  const [brand, setBrand] = useState()
  const [finalbrandTypeD, setFinalbrandTypeD] = useState()
  const [readdivisionData, setReadDivisionData] = useState([])
  const { t } = useTranslation()
  const navigate = useNavigate();


  const [SignUpData, SetSignUpData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    mobile: '',
    password: '',
    confirmpassword:'',
    gender: '',
    industry: [],
    category: [],
    zone:'',
    brand: []
  })

  const [errorFirstname, setErrorFirstname] = useState("");
  const [errorLastname, setErrorLastname] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorMobile, setErrorMobile] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorConfrimPassword, setErrorConfirmPassword] = useState("");

  const [firstNameColor, setfirstNameColor] = useState("");
  const [lastNameColor, setlastNameColor] = useState("");
  const [emailColor, setEmailColor] = useState("");
  const [passwordColor, setPasswordColor] = useState("");
  const [confirmPasswordColor, setConfirmPasswordColor] = useState("");



  useEffect(() => {
    const getCategoryTypeData = async () => {
      const getCategoryName = []
      const reqCatData = await axios.get("https://onlineparttimejobs.in/api/category/public", {
        withCredentials: true
      });
      console.log(reqCatData.data)
      for (let i = 0; i < reqCatData.data.length; i++) {
        getCategoryName.push({ name: reqCatData.data[i]?.name, uid: reqCatData.data[i]?.uid })
      };
      if (getCategoryName.length) {
        setCategoryType(getCategoryName);
      }
    }
    getCategoryTypeData();
  }, []);


  useEffect(() => {
    const getIndustryData = async () => {
      const getIndustryName = []
      const reqData = await axios.get("https://onlineparttimejobs.in/api/industry/public", {
        withCredentials: true
      });
      console.log(reqData.data)
      for (let i = 0; i < reqData.data.length; i++) {
        getIndustryName.push({ name: reqData.data[i]?.name, uid: reqData.data[i]?.uid })
      };
      if (getIndustryName.length) {
        setImdustry(getIndustryName);
      }
    }
    getIndustryData();
  }, []);

  const handleChange = (e) => {
    const inpName = e.target.name;
    const inpVar = e.target.value;
    const cloned = { ...SignUpData }
    cloned[inpName] = inpVar;
    SetSignUpData(cloned);
  }

  const submitData = async (e) => {
    try {
      const add_Infulencer = await axios.post('https://onlineparttimejobs.in/api/sellerList/add_Sellers', { ...SignUpData, category: finalCategoryTypeD, industry: finalIndustryTypeD, brand: finalbrandTypeD });
      SetSignUpData(add_Infulencer);
      console.log(add_Infulencer.data);
      e.preventDefault();

      if (SignUpData.firstname.length > 2 ) {
        setErrorFirstname("");
        setfirstNameColor("green");
      } else {
        setErrorFirstname("enter first name");
        setfirstNameColor("red");
      }

      if (SignUpData.lastname.length > 1) {
        setErrorLastname("");
        setlastNameColor("green");
      } else {
        setErrorLastname("enter last name");
        setlastNameColor("red");
      }
  

      if (SignUpData.email.includes("@gmail")) {
        setErrorEmail("");
        setEmailColor("green");
      } else {
        setEmailColor("red");
        setErrorEmail("enter valid email");
      }

      if (SignUpData.password.length > 8) {
        setErrorPassword("");
        setPasswordColor("green");
      } else {
        setErrorPassword("Password should be 6 digit long ");
        setPasswordColor("red");
      }
  
      if (SignUpData.password !== "" && SignUpData.password === SignUpData.confirmpassword) {
        setErrorConfirmPassword("");
        setConfirmPasswordColor("green");
      } else {
        setErrorConfirmPassword("Passwords didn't matched.");
        setConfirmPasswordColor("red");
      }
  
      console.log('Form data submitted successfully!');
      navigate('/influencer-signup/social-media-assets')
    } catch (error) {
      console.log('Error submitting form:', error)
    }
  }

  useEffect(() => {
    const getBrandData = async () => {
      const getBrandName = []
      const reqBrandData = await axios.get("https://onlineparttimejobs.in/api/brand/public", {
        withCredentials: true
      });
      console.log(reqBrandData.data)
      for (let i = 0; i < reqBrandData.data.length; i++) {
        getBrandName.push({ name: reqBrandData.data[i]?.name, uid: reqBrandData.data[i]?.uid })
      };
      if (getBrandName.length) {
        setBrand(getBrandName);
      }
    }
    getBrandData();
  }, []);

  const GetDivsionData = async () => {
    try {
      const redDivisionData = await axios.get('https://onlineparttimejobs.in/api/division/public', { withCredentials: true })
      console.log('bbb', redDivisionData.data);
      setReadDivisionData(redDivisionData.data);
      // console.log(redDivisionData.data);
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    GetDivsionData();
  }, []);

  return (
    <>
      <Header />
      <section className="mainSignup_holder">
        <div className="container mainWrapper">
          <div className="signupForm">
            <h2>{t('inflencer_signup_h')}</h2>
            <div className="innerForm">
              <Form>
                <div className="col-md-12 ">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="First Name*"
                    className="mb-3"
                  >
                    <Form.Control
                      name='firstname'
                      id="firstname"
                      value={SignUpData.firstname}
                      onChange={handleChange}
                      style={{ borderColor: firstNameColor }}
                      required
                      type="text" placeholder="first name"
                    />
                  </FloatingLabel>
                  <p className="error">{errorFirstname}</p>
                </div>
                <div className="col-md-12 ">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Last Name*"
                    className="mb-3"
                  >
                    <Form.Control
                      name='lastname'
                      onChange={handleChange}
                      value={SignUpData.lastname}
                      style={{ borderColor: lastNameColor }}
                      required
                      type="text" placeholder="Last name" />
                  </FloatingLabel>
                  <p className="error">{errorLastname}</p>
                </div>
                <div className="col-md-12 ">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Email*"
                    className="mb-3"
                  >
                    <Form.Control
                      name='email'
                      style={{ borderColor: emailColor }}
                      onChange={handleChange}
                      value={SignUpData.email}
                      required
                      type="email" placeholder="Email" />
                  </FloatingLabel>
                  <p className="error">{errorEmail}</p>
                </div>
                <div className="col-md-12 ">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="password"
                    className="mb-3"
                  >
                    <Form.Control
                      name='password'
                      onChange={handleChange}
                      value={SignUpData.password}
                      style={{ borderColor: passwordColor }}
                      required
                      type="password" placeholder="Enter Password" />
                  </FloatingLabel>
                  <p className="error">{errorPassword}</p>
                </div>
                <div className="col-md-12 ">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Confirm password"
                    className="mb-3"
                  >
                    <Form.Control
                      name='confirmpassword'
                      onChange={handleChange}
                      value={SignUpData.confirmpassword}
                      style={{ borderColor: confirmPasswordColor }}
                      required
                      type="password" placeholder="Enter Password" />
                  </FloatingLabel>
                  <p className="error">{errorConfrimPassword}</p>
                </div>
                <div className="col-md-12 ">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Enter 10 digit mobile number*"
                    className="mb-3"
                  >
                    <Form.Control
                      name='mobile'
                      onChange={handleChange}
                      value={SignUpData.mobile}
                      required
                      type="number" placeholder="phone no" />
                    <small style={{ display: "flex",fontSize:'10px',marginLeft:'10px' }}>
                      <strong>Note:</strong> Mobile number should not include
                      +91
                    </small>
                  </FloatingLabel>
                </div>
               
               
                <div className="col-md-12" style={{ marginBottom: "13px", textAlign: 'start' }}>
                  <label className="m-2" htmlFor="flexRadioDefault1">Service Type <span style={{ color: 'red' }}>*</span></label>
                  <Multiselect
                    name='industry'
                    onChange={handleChange}
                    value={SignUpData.industry}
                    required
                    isObject={true}
                    displayValue="name"
                    options={industry}
                    showCheckbox
                    selectedValues={[]}
                    onRemove={(selectedCat) => {
                      const selectedIds = selectedCat.map((cat) => {
                        return cat.uid
                      })
                      setFinalIndustryTypeD(selectedIds)
                    }}
                    onSelect={(selectedCat) => {
                      const selectedIds = selectedCat.map((cat) => {
                        return cat.uid
                      })
                      setFinalIndustryTypeD(selectedIds)
                    }}
                  />
                </div>
                <div className="col-md-12" style={{ marginBottom: "13px", textAlign: 'start' }}>
                  <label className="m-2" htmlFor="flexRadioDefault1">Category Type <span style={{ color: 'red' }}>*</span></label>
                  <Multiselect
                    name='category'
                    onChange={handleChange}
                    value={SignUpData.category}
                    required
                    className="multiSelect"
                    isObject={true}
                    displayValue="name"
                    options={category}
                    showCheckbox
                    selectedValues={[]}
                    onRemove={(selectedCat) => {
                      const selectedIds = selectedCat.map((cat) => {
                        return cat.uid
                      })
                      setFinalCategoryTypeD(selectedIds)
                    }}
                    onSelect={(selectedCat) => {
                      const selectedIds = selectedCat.map((cat) => {
                        return cat.uid
                      })
                      setFinalCategoryTypeD(selectedIds)
                    }}
                  />
                </div>
                <div className="col-md-12 " style={{ marginBottom: "13px" }}>
                  <Form.Select
                  name='zone'
                  onChange={handleChange}
                  value={SignUpData.zone}
                   size="lg"
                    aria-label="Default select example"
                  >
                    <option>Select Zone*</option>
                    {readdivisionData?.map((item) => {
                      return <option key={item}>{item.title}</option>
                    })}
                  </Form.Select>
                </div>
                <div className="col-md-12 selectGender">
                  <ul>
                    <li>I am*</li>
                    <li>
                      <Form.Check
                        onChange={handleChange}
                        value={SignUpData.gender}
                        required
                        type="radio" label="Male" name="gender"  />
                    </li>
                    <li>
                      <Form.Check
                        onChange={handleChange}
                        value={SignUpData.gender}
                        required
                        type="radio" label="female" name="gender" />
                    </li>
                  </ul>
                </div>
                <div className="col-md-12 " style={{ marginBottom: "13px", textAlign: 'start' }}>
                  <label className="m-2" htmlFor="flexRadioDefault1">select Brand <span style={{ color: 'red' }}>*</span></label>
                  <Multiselect
                    name='brand'
                    onChange={handleChange}
                    value={SignUpData.brand}
                    required
                    isObject={true}
                    displayValue="name"
                    options={brand}
                    showCheckbox
                    selectedValues={[]}
                    onRemove={(selectedCat) => {
                      const selectedIds = selectedCat.map((cat) => {
                        return cat.uid
                      })
                      setFinalbrandTypeD(selectedIds)
                    }}
                    onSelect={(selectedCat) => {
                      const selectedIds = selectedCat.map((cat) => {
                        return cat.uid
                      })
                      setFinalbrandTypeD(selectedIds)
                    }}
                  />
                </div>
               
                <div className="col-md-12 " id="proceedBtn">
                  <Button type="button" onClick={submitData}  variant="primary" size="lg">
                    {t('inflencer_signup_button')}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
          <div className="modelImg">
            <img src={img} alt="" className="fluid-img" />
          </div>

        </div>
        <div className="questionFaq">
          <FreqAskQues />
        </div>
      </section>
      <Footer />
    </>
  );
}
export default InfluencerSignup;
