import Header from "../../common/MarxEntertainment/header/Header"
import '../../styles/ourInfluencerStyle.css'
import { Box} from "@mui/material"
import Footer from "../../common/MarxEntertainment/footer/Footer";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../common/SideAbleMenu/SideBar";
import RunningSlide from "../../common/RunningSlideAllMarx/RunningSlide";
import ClebsFeatureData from "../../data/MarxEntertainment/ClebsFeatureData";


const OurInfluencers = () => {
    const [allCategory, setAllCategory] = useState([])
    const [influencer, setInfluencer] = useState([])
    const { t } = useTranslation();

    useEffect(() => {
        const getAllCategoryData = async () => {
            const reqAllCatgData = await axios.get("https://onlineparttimejobs.in/api/category/public", {
                withCredentials: true
            });
            console.log(reqAllCatgData.data)
            setAllCategory(reqAllCatgData.data)
        }
        getAllCategoryData();
    }, []);

   

    return (
        <>
            <Sidebar />
            <Header />
            <div className="main-catgdiv">
                <h1 className="h1tgg">CATEGORIES OF ARTISTS</h1>
                <Box className="catg-box">
                    <div className="all-catgtabs">
                        {allCategory?.map((item) => (
                            <p className='mange-catg'>
                                <Link to={'/'} >{item.name}</Link>
                            </p>
                        ))}
                    </div>
                    <div className="catgtabpanels">
                    </div>
                </Box>
            </div>

            <RunningSlide title="Gallery" images={ClebsFeatureData} />

            <Footer />

        </>
    )
}

export default OurInfluencers

