import { Box, Button, Checkbox, Table, TableCell, TableRow } from "@mui/material"
import Footer from "../../common/MarxEntertainment/footer/Footer"
import MarxWeddingHeader from "../../common/marxWedding/HeaderMarxWedding"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import '../../styles/photographerDetailPgStyle.css'
import { Tab, TabPanel, Tabs, TabsList } from "@mui/base";
import { IoMdCall } from "react-icons/io";
import { Favorite, FavoriteBorder } from "@mui/icons-material";



const PhotographerDetailPage = () => {
    const settings = {
        infinite: true,
        slidesToShow: 1,
        swipeToSlide: true,
    };
    return (
        <>
            <MarxWeddingHeader />

            <Box>
                <div className="">
                    <Link to={'/photographers-detail'}>
                        <Slider className="photographer-sliderr" {...settings}>
                            <div className="photographer-img-div">
                                <img src="https://lh3.googleusercontent.com/FvBQO9X0bqnVMPyoIc5jQEdGRM86nlerW7ksjkY_HamNdppx99WH0TDbOstWHfDS71FmJbypsjRNlGcXjNfsPwrr=w1078-h640-n-rw-c0xffffffff-l95-e31" alt="img" />
                            </div>
                            <div className="photographer-img-div">
                                <img src="https://lh3.googleusercontent.com/YvY5CzWfMwaPa5290Wd4zr-efJXCfx_6fCGP10GxEjoVy2cLZIwOXqV4uceZWD_gtuuSmk0YPDJ9XmZhpoh6kBo=w960-h640-n-rw-c0xffffffff-l95-e31" alt="img" />
                            </div>
                            <div className="photographer-img-div">
                                <img src="https://lh3.googleusercontent.com/vunkQCIqW9Es6aoZ9bN5wcooqKcpfmFTJVjrKAbzt8PJouG6wCBE1a8k9rfPVac62ksozaq2PspPL7PsdjPn3GtKYGNAIcS1xXvCGQ=w1418-h946-n-rw-c0xffffffff-l95-e31" alt="img" />
                            </div>
                            <div className="photographer-img-div">
                                <img src="https://lh3.googleusercontent.com/VpxFajmXSdTWN1UGkdgn4h_dGSWO_BPnBDCLq7X7S8oDYRGP4su9CmRLmz3l7Slw2JqnEssvh8JtZ-VuyqUzap6LDA_KfSh7fbV8=w962-h640-n-rw-c0xffffffff-l95-e31" alt="img" />
                            </div>
                            <div className="photographer-img-div">
                                <img src="https://lh3.googleusercontent.com/j8UdFdQQgMYWcl3JLeOKtAio96cpDHLB4JGfDMgP9hdUT956kZHxMoXZd1mqCdu_FsrYyQrSZZ_bZ6u2Nme1H9zaHK7yJKr8WM1IpA=w476-h318-n-rw-c0xffffffff-l95-e31" alt="img" />
                            </div>
                        </Slider>
                    </Link>
                </div>
            </Box>

            <div className=" row">
                <div className=" col-sm-8">
                    <div className="detail-prof">
                        <h3>Clickmode Studio</h3>
                        <p>Specializations and services</p>

                        <Tabs defaultValue={0}>
                            <TabsList>
                                <div className='prof-btns-box'>
                                    <Tab value={0} size='small' className='tabbtn3'>Wedding photography</Tab>
                                    <Tab value={1} size='small' className='tabbtn2'>Pre wedding</Tab>
                                </div>
                            </TabsList>
                            <TabPanel value={0}>
                                <div className="table">
                                    <Table>
                                        <TableRow>
                                            <TableCell className=" fw-bold">Photography package 1 day</TableCell>
                                            <TableCell align="right">₹5,000 – 10,000</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className=" fw-bold">Photo + video package 1 day</TableCell>
                                            <TableCell align="right">₹10,000 – 30,000</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className=" fw-bold">Pre wedding photography</TableCell>
                                            <TableCell align="right">₹20,000 – 40,000</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className=" fw-bold">Album additionally</TableCell>
                                            <TableCell align="right">₹5,000 – 20,000</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className=" fw-bold">Candid photography, per day</TableCell>
                                            <TableCell align="right">₹10,000 – 20,000</TableCell>
                                        </TableRow>

                                    </Table>
                                </div>
                                <div className="ms-5 mt-5">
                                    <h5 className="my-3 mx-2">Portfolio: Wedding photography</h5>
                                    <div className="wed-portfolio-imgdiv">
                                        <img src="https://lh3.googleusercontent.com/HGZ7Dqxxa3OdMDYJiM1Yxk36_MJT2JmF1jFlM6AK_CyFvKH2_lYmbgVtVOAwzOhB90r908a000E3UthJLNR_hoIqobb1-wuPgYYtT_s=w1200-h800-n-rw-c0xffffffff-l95-e31" alt="img" />
                                        <img src="https://lh3.googleusercontent.com/SNRqWJ_2_YyNcndgn8fVtzpFHy77hUHrNdCQHDZrqY4uyPvBfdCydoPMYfH6L8PrC39sYtgJpOMvOrNPj-pdJyi7qNP6XF7hgLZUTQ=w534-h800-n-rw-c0xffffffff-l95-e31" alt="img" />
                                        <img src="https://lh3.googleusercontent.com/bzGIAeToE7qd57fD7bE7XlZCr01DEcFRAfxWjsI9SA08xjn424O85rO7wnWhrAfIxa4Py-MMcbeAyMiWbwgSLqcxcTacr-irxD6gUQ=w1284-h800-n-rw-c0xffffffff-l95-e31" alt="img" />
                                        <img src="https://lh3.googleusercontent.com/_nP0Kn6ZmJ-sqZFQigRptS_z5ZordE6dF5-KgmWfVQnjEyrs7OZMQUyXNqw7wXUOLKckY9gykiiRqkzAELMMD4Y67KubAzVi_dstiA=w540-h800-n-rw-c0xffffffff-l95-e31" alt="img" />
                                        <img src="https://lh3.googleusercontent.com/UoY5T01AbKeqXQmAJPHfKbd7yZji1GyIoWsAJr1fdiU-6T9PTEtXvxf2Kyt7pI0yjLXLDw9lI6MO1odp7tpBj1G7aw7kZEbcDk0ZkQ=w1212-h800-n-rw-c0xffffffff-l95-e31" alt="img" />
                                        <img src="https://lh3.googleusercontent.com/ki7rmaxwbaB0fYc9HEdaGDVrpXGZvcRbbzeziq5cOS0Zp1CohgW1CLRMYKYs58-QDjePBz-CkyQuvJMZNCdeBJK4ouKdBdqQbMOgoQ=w1200-h800-n-rw-c0xffffffff-l95-e31" alt="img" />
                                        <img src="https://lh3.googleusercontent.com/whvN84YX8QN2gQuNrSYg9Ebl8Cm-SsAjOWjcK3QmECFy8EKvLA9H6Iflf7Ll98s4drr63i6tGHQTVGcBHxYzqlHXA0t7-n9-WddFiA=w1200-h800-n-rw-c0xffffffff-l95-e31" alt="img" />
                                        <img src="https://lh3.googleusercontent.com/wzpQ11iQJirdC9gqyRSrCZcvBrjR5UOgdWxV2_lazWTJ_lNLwX2xMZ7WB4buspy0bkTr35HEEoBkpjLEru8kNjf38oqAkiYEtjeGJA=w534-h800-n-rw-c0xffffffff-l95-e31" alt="img" />
                                        <img src="https://lh3.googleusercontent.com/bVOYjLZw35OkoI-yR8MBAKzsuA1X1hGTYSOGRqGIWmqwpKz-SES9zQALnTmvLY1fcdA7n52k6fsjg08ZxZTBQyKYSf4YhEti14Ef=w534-h800-n-rw-c0xffffffff-l95-e31" alt="img" />
                                        <img src="https://lh3.googleusercontent.com/-8rvfYcqZ-AMraKoJJvDGQzLCeRbF0-4aHBw1hoV5uXu4dUiia1y2i8W55OUerOoGZz-fQa4BA1w1QEJbA0yuchr2I_KPoE-PFQn9Q=w1200-h800-n-rw-c0xffffffff-l95-e31" alt="img" />
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={1}>
                                <div className="ms-5 mt-5">
                                    <h5 className="my-3 mx-2">Portfolio: Pre wedding photography</h5>
                                    <div className="wed-portfolio-imgdiv">
                                        <img src="https://lh3.googleusercontent.com/kM3NT4PC7OtCrtnUhti-K16J9ws_Bwa_5csWWNseTtPut4cCliKegZHiL07iRccU8nTX6AFyPJ0cafnCvnbgXXb2lMZO0XLsSKfU2sQ=w1164-h800-n-rw-c0xffffffff-l95-e31" alt="img" />
                                        <img src="https://lh3.googleusercontent.com/2sEw0iYnAW9pKVdeQqsl4xnJJI4g4qIJ_I-cS0Ftg7iOCUIhiLNUtvlCGAqdjhnlflrjnF-fNBNz4TYFLw074f5EcGWnCxZzyrO_Zg=w1200-h800-n-rw-c0xffffffff-l95-e31" alt="img" />
                                        <img src="https://lh3.googleusercontent.com/6QP0dnQnIkit9KO-hPj4WqtTwdYJJ1Hi-Vz2xEh5kMTA43F8a1rqFr2taAgp0thE2L794bMze35A_jZgJrJk0KY321-stLmplpGIfw=w534-h800-n-rw-c0xffffffff-l95-e31" alt="img" />
                                        <img src="https://lh3.googleusercontent.com/695dQsqIQlPnOcj_A77Geaq5DOgLj4ey8WtBsnIdPZAA1Fz7avcf4Hx--qMXA_rVGM6qICMFP61EnR-LP0Q4tsN5uWhyEo0XI9i1=w1200-h800-n-rw-c0xffffffff-l95-e31" alt="img" />
                                        <img src="https://lh3.googleusercontent.com/eMJ0s7aF5mcMTt3SupcEP9w-NoYA4jLigS0mpdCGlJZq_G-RUaUeL-yOx0LBs9LP5JGbxpphLOylE8j2JU4vsK8A0ol_rZfLl328JIU=w570-h800-n-rw-c0xffffffff-l95-e31" alt="img" />
                                        <img src="https://lh3.googleusercontent.com/DyhtzbwTARh43dANyyncMeIWbkdLV_unNRp_sA0bC2_OwGu7UVfqFYJ0LSVRkyLGFgsU-_rsNV5UMYf2SssFmn7GVaa6MUb_fK8KgQ=w1198-h800-n-rw-c0xffffffff-l95-e31" alt="img" />
                                        <img src="https://lh3.googleusercontent.com/pif1rFVLbgnT_a4y8YbrRQ0HWovcdVcjVOC7pz7g8sZEKblnSU42JNpTB7K6YmMFdrDN6rPAic-eRp3fAPPEAqsmi6aBzF4iA2gv=w1200-h800-n-rw-c0xffffffff-l95-e31" alt="img" />
                                        <img src="https://lh3.googleusercontent.com/umJO9yMlA73rzm0OgQzwSSpdia9eg2qElW5aOiVr5njnLee_7hCf9bcj1IQoFMoAQJQ_cX1MGEgjQ3YVTIk5g59i6kA-gYauIgQY9w=w1200-h800-n-rw-c0xffffffff-l95-e31" alt="img" />
                                        <img src="https://lh3.googleusercontent.com/ih6W0GiOzuldO5VnIUm07l7SeM7rqzcwIumni9KSjoNet9nfVY4u0vuK7cnR-fcbboRdP7WHGG3FhR1i5kT_55I7tj9hnIWllgcUCw=w1200-h800-n-rw-c0xffffffff-l95-e31" alt="img" />
                                        <img src="https://lh3.googleusercontent.com/xHxKJSBqam0TtJBcgyTyHTiLXPsCjCmqonUIbyEzv4pWv1dYfnyIFRSRF_7muB2uUKrwCsr_a4nS0fHt3W0U2KUaS1VN-PG_XicB=w534-h800-n-rw-c0xffffffff-l95-e31" alt="img" />
                                    </div>
                                </div>
                            </TabPanel>

                        </Tabs>
                    </div>
                </div>
                <div className=" col-sm-4 rightbg">
                    <div className='profile-boxdiv'>
                        <div className='img-boxdiv'>
                            <Link to={'/photographers-detail'}>
                                <img src="https://lh3.googleusercontent.com/sMr2inE1MFxk9vdbzrzzdbPB9gDcbcjML-Pfe3BQI17L0RTX7QFHnSUCThGKlGVKc_LA_Hf9_XlQ0NIn4SRRdJbqWg9jTLLaxTWF=w144-h144-n-rj-c0xffffffff-l95-e31" alt="img" />
                            </Link>
                        </div>
                        <div>
                            <p><Link to={'/photographers-detail'}>Clickmode Studio</Link></p>
                        </div>
                        <div className="contact-btn">
                            <Button size='small' className='btn2'><IoMdCall className=' fs-5 me-1' /> show Contact info</Button>
                        </div>
                        <div className="fav-box">
                            <p>
                                <Checkbox icon={<FavoriteBorder />} checkedIcon={<Favorite />} />
                                Add to Favorites
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default PhotographerDetailPage