import ourModelF1 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF1.jpeg'
import ourModelF2 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF2.jpeg'
import ourModelF3 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF3.jpeg'
import ourModelF4 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF4.jpeg'
import ourModelF5 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF5.jpeg'
import ourModelF6 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF6.jpeg'
import ourModelF7 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF7.jpeg'
import ourModelF8 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF8.jpeg'
import ourModelF9 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF9.jpeg'
import ourModelF10 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF10.jpeg'
import ourModelF11 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF11.jpeg'
import ourModelF12 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF12.jpeg'
import ourModelF13 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF13.jpeg'
import ourModelF14 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF14.jpeg'
import ourModelF15 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF15.jpeg'
import ourModelF16 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF16.jpeg'
import ourModelF17 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF17.jpeg'
import ourModelF18 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF18.jpeg'
import ourModelF19 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF19.jpeg'
import ourModelF20 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF20.jpeg'
import ourModelF21 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF21.jpeg'
import ourModelF22 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF22.jpeg'
import ourModelF23 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF23.jpeg'
import ourModelF24 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF24.jpeg'
import ourModelF25 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF25.jpeg'
import ourModelF26 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF26.jpeg'
import ourModelF27 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF27.jpeg'
import ourModelF28 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF28.jpeg'
import ourModelF29 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF29.jpeg'
import ourModelF30 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF30.jpeg'
import ourModelF31 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF31.jpeg'
import ourModelF32 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF32.jpeg'
import ourModelF33 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF33.jpeg'
import ourModelF34 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF34.jpeg'
import ourModelF35 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF35.jpeg'
import ourModelF36 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF36.jpeg'
import ourModelF37 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF37.jpeg'
import ourModelF38 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF38.jpeg'
import ourModelF39 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF39.jpeg'
import ourModelF40 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF40.jpeg'
import ourModelF41 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF41.jpeg'
import ourModelF42 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF42.jpeg'
import ourModelF43 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF43.jpeg'
import ourModelF44 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF44.jpeg'
import ourModelF45 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF45.jpeg'
import ourModelF46 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF46.jpeg'
import ourModelF47 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF47.jpeg'
import ourModelF48 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF48.jpeg'
import ourModelF49 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF49.jpeg'
import ourModelF50 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF50.jpeg'
import ourModelF51 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF51.jpeg'
import ourModelF52 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF52.jpeg'
import ourModelF53 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF53.jpeg'
import ourModelF54 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF54.jpeg'
import ourModelF55 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF55.jpeg'
import ourModelF56 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF56.jpeg'
import ourModelF57 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF57.jpeg'
import ourModelF58 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF58.jpeg'
import ourModelF59 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF59.jpeg'
import ourModelF60 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF60.jpeg'
import ourModelF61 from '../../../../assest/images/eventOurModelBank/india/female/ourModelF61.jpeg'


const IndModelFemaleData = [
  {
    id:1,
    src: ourModelF1,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'Anshika'
  },
  {
    id:2,
    src: ourModelF2,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'Gaurika'
  },
  {
    id:3,
    src: ourModelF3,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'Vioma'
  },
  {
    id:4,
    src: ourModelF4,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'tasleema'
  },
  {
    id:5,
    src: ourModelF5,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'meghna'
  },
  {
    id:6,
    src: ourModelF6,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'Ajita'
  },
  {
    id:7,
    src: ourModelF7,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'nidhi'
  },
  {
    id:8,
    src: ourModelF8,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'mihira'
  },
  {
    id:9,
    src: ourModelF9,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'shinova'
  },
  {
    id:10,
    src: ourModelF10,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'ritvika'
  },
  {
    id:11,
    src: ourModelF11,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'Gaurika'
  },
  {
    id:12,
    src: ourModelF12,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'zaina'
  },
   {
    id:13,
    src: ourModelF13,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'rashmi'
  },
  {
    id:14,
    src: ourModelF14,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'alankrita'
  },
  {
    id:15,
    src: ourModelF15,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'tanushree'
  },
  {
    id:16,
    src: ourModelF16,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'teena'
  },
  {
    id:17,
    src: ourModelF17,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'namita'

  },
  {
    id:18,
    src: ourModelF18,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'priya'

  },
  {
    id:19,
    src: ourModelF19,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'angela'

  },
  {
    id:20,
    src: ourModelF20,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'tanvi'

  },
  {
    id:21,
    src: ourModelF21,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'ishmeet'

  },
  {
    id:22,
    src: ourModelF22,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'pari'

  },
  {
    id:23,
    src: ourModelF23,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'sneha'

  },
  {
    id:24,
    src: ourModelF24,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'priya'

  },
  {
    id:25,
    src: ourModelF25,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'jahnabi'

  },
  {
    id:26,
    src: ourModelF26,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'akanksha'

  },
  {
    id:27,
    src: ourModelF27,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'rittam'

  },
  {
    id:28,
    src: ourModelF28,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'utkarsha'

  },
  {
    id:29,
    src: ourModelF29,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'debapriya'

  },
  {
    id:30,
    src: ourModelF30,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'zainab'

  },
  {
    id:31,
    src: ourModelF31,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'parul'

  },
  {
    id:32,
    src: ourModelF32,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'diljot'

  },
  {
    id:33,
    src: ourModelF33,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'anchal'

  },
  {
    id:34,
    src: ourModelF34,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'chahat'

  },
  {
    id:35,
    src: ourModelF35,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'rihan'

  },
  {
    id:36,
    src: ourModelF36,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'richa'

  },
  {
    id:37,
    src: ourModelF37,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'atisha'

  },
  {
    id:38,
    src: ourModelF38,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'rhea'

  },
  {
    id:39,
    src: ourModelF39,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'priya'

  },
  {
    id:40,
    src: ourModelF40,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'nikita'

  },
  {
    id:41,
    src: ourModelF41,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'nishi'

  },
  {
    id:42,
    src: ourModelF42,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'saumya'

  },
  {
    id:43,
    src: ourModelF43,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'shivani'

  },
  {
    id:44,
    src: ourModelF44,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'nikita'

  },
  {
    id:45,
    src: ourModelF45,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'trishna'

  },
  {
    id:46,
    src: ourModelF46,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'mansi'

  },
  {
    id:47,
    src: ourModelF47,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'saborni'

  },
  {
    id:48,
    src: ourModelF48,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'swati'

  },
  {
    id:49,
    src: ourModelF49,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'nishi'

  },
  {
    id:50,
    src: ourModelF50,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'yashi'

  },
  {
    id:51,
    src: ourModelF51,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'tanya'

  },
  {
    id:52,
    src: ourModelF52,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'yaaneea'

  },
  {
    id:53,
    src: ourModelF53,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'alia'

  },
  {
    id:54,
    src: ourModelF54,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'vanshika'

  },
  {
    id:55,
    src: ourModelF55,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'yoshita'

  },
  {
    id:56,
    src: ourModelF56,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'twinkle'

  },
  {
    id:57,
    src: ourModelF57,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'akanksha'

  },
  {
    id:58,
    src: ourModelF58,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'mansi'

  },
  {
    id:59,
    src: ourModelF59,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'carol'

  },
  {
    id:60,
    src: ourModelF60,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'natasha'

  },
  {
    id:61,
    src: ourModelF61,
    age:25,
    gender:"female",
    height:'1.6 m',
    workExperience:"image source and profile name as state",
    price:'20k to 40k per day / per hour +GST 18%',
    profileName: 'sravanthi'

  }
];

export default IndModelFemaleData