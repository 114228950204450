
import { Box } from "@mui/material"
import { Link } from "react-router-dom"
import Logo from '../../assest/images/realStatelogo.jpeg'
import '../../styles/realEstateContactUsStyle.css'
import MarxRealEstateFooter from "../../common/MarxRealEstate/FooterMarxRealEstate";

 
const RealEstateContactUs = () => {
    return (
        <>
            <div className="realestate-topdiv-barbox">

            </div>

            <Box className=''>
                <div className="realestate-contactnavbar">
                    <div className="realestate-contactnav-baar">
                        <div className="logo">
                            <Link to={'/marx-group'}>
                                <img
                                    className="realestate-contactlogo"
                                    src={Logo}
                                    alt="logo"
                                />
                            </Link>

                        </div>
                        <div className="company-profile-name">
                            <h1 className="h1tag">Pan India Work Operations</h1>
                        </div>
                    </div>
                </div>

            </Box>

            <div className='bgblackbox'>
                
                <div className="contactusmain">
                    <h1 className=" m-0">Contact Us</h1>
                    <div className="emailphone">
                        <div>
                            <p>Name : Mr. Anand Chaurdhary</p>
                            <p>Designation : Sells Head (Pan India) </p>
                            <p>Office (M) : + 91 – 8506827955. (Whatsapp)</p>
                        </div>
                        <div className='emaildiv'>
                            <p>Email Id : info@Marxgroupworldwide.com</p>
                            <p>Email Id : Bookings@Marxgroupworldwide.com</p>
                            <p>Email Id : Queries@Marxgroupworldwide.com</p>
                            <p>Email Id : Worldwide@Marxgroupworldwide.com</p>
                            <p className='webadd'>Website Address : www.MarxGroupWorldwide.com</p>
                        </div>
                    </div>
                    <h4 className='add m-0 text-center py-3'>Regd. Office Address :- MGC. 318, 3rd floor, V-Complex, MG Road Gurugram Haryana - 122002 - India. </h4>
                </div>
            </div>

            <MarxRealEstateFooter />
        </>
    )
}

export default RealEstateContactUs
