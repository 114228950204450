import comingsoon1 from '../../../assest/images/comingsoon1.jpeg'


const DestinationWeddCityData = [
    {
        id: 1,
        name:'Delhi',
        imgSrc: comingsoon1,
    },
    {
        id: 2,
        name:'Delhi',
        imgSrc: comingsoon1,
    },
    {
        id: 3,
        name:'Delhi',
        imgSrc: comingsoon1,
    }, {
        id: 4,
        name:'Delhi',
        imgSrc: comingsoon1,
    }, {
        id: 5,
        name:'Delhi',
        imgSrc: comingsoon1,
    }, {
        id: 6,
        name:'Delhi',
        imgSrc:comingsoon1,
    }, {
        id: 7,
        name:'Delhi',
        imgSrc:comingsoon1,
    }
];
export default DestinationWeddCityData;