import { useEffect } from "react";
import './GoogleLaguageTranslateStyle.css'

const GoogleLaguageTranslate = () => {
    useEffect(() => {
        const googleTranslateElementInit = () => {
            new window.google.translate.TranslateElement(
                {
                    pageLanguage: "en",
                    autoDisplay: false,
                    attribution: null, // Set attribution to null to remove "Powered by Google"
                    className: 'scdf'
                },
                "google_translate_element"
            );
        };

        // Check if the script has already been added to the document
        if (!window.google || !window.google.translate) {
            const addScript = document.createElement("script");
            addScript.id = "google-translate-script";
            addScript.src =
                "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
            addScript.async = true;
            addScript.defer = true;

            // Set up a callback function for when the script is loaded
            addScript.onload = googleTranslateElementInit;

            document.body.appendChild(addScript);
        } else {
            // If Google Translate is already defined, initialize it immediately
            googleTranslateElementInit();
        }

        // Clean up function to remove the script when the component is unmounted 
        return () => {
            const script = document.getElementById("google-translate-script");
            if (script) {
                document.body.removeChild(script);
            }
        };
    }, []);
    return (
        <>
            <div id="google_translate_element"></div>
        </>
    )
}

export default GoogleLaguageTranslate
