 
import WithBioWithoutRunningGallery from "../../../common/GalleryWitthBioAllMaxWithoutRunningGallery/WithBioWithoutRunningGallery"
 import MarxEventFooter from "../../../common/Marx_Event/MarxEventFooter/MarxEventFooter"
 import MarxEventHeader from "../../../common/Marx_Event/MarxEventHeader/MarxEventHeader"
 import IndModelMaleData from "../../../data/marxEvent/ourModelbank/indian/indianModelMaleData"
 
 const MarxFashionStylist = () => {
   return (
     <>
         <MarxEventHeader />
         <WithBioWithoutRunningGallery ModelData={IndModelMaleData}  heading={"Mar'x Fashion Stylist"} detailpg={'marxfashion-stylist'}/>
         <MarxEventFooter />
 
     </>
   )
 }
 
 export default MarxFashionStylist
 