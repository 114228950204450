import { Box, Button } from "@mui/material"
import Footer from "../common/MarxEntertainment/footer/Footer"
import Header from "../common/MarxEntertainment/header/Header"
import '../styles/instagramPackageStyle.css'
import { Tab, TabPanel, Tabs, TabsList } from "@mui/base"
import { Link } from "react-router-dom";
import Checkbox from '@mui/material/Checkbox';
import { Favorite, FavoriteBorder } from "@mui/icons-material";


const YoutubePackage = () => {
  return (
    <>
      <Header />
      <div className="price-text">
        <div className="div-price">
          <h2>Abaris Managed Plans</h2>
        </div>
      </div>
      <Box>
        <div>
          <Tabs defaultValue={1} defaultChecked selectionFollowsFocus={true}>
            <TabsList>
              <div className="price-div">
                <Tab className="price-tab1" value={0}>Instagram Packages</Tab>
                <Tab className="price-tab2" value={1}>YouTube Packages</Tab>
              </div>
            </TabsList>
            <TabPanel value={0}>
              <div className="price-tabpanel">
                <div style={{ textAlign: 'center' }}>
                  <h5 className=" fw-bold pt-5">INSTAGRAM PACKAGES</h5>
                </div>

                <div className="packages-plans">
                  <div className="nano-package">
                    <h4 className="package-name-nano">Nano Insta Package</h4>
                    <div className="nano-type">
                      <ul>
                        <li>Type: Nano Celebrities</li>
                        <li>
                          <p>Average Followers:</p>
                          <span>1000 - 10000</span>
                        </li>
                        <li>
                          <select>
                            <option>Select Categories</option>
                            <option>Beauty</option>
                            <option>Auto</option>
                            <option>Books</option>
                            <option>Education</option>
                          </select>
                        </li>
                        <li>
                          <select>
                            <option>Choose Count</option>
                            <option>1 Influencer</option>
                            <option>3 Influencer</option>
                            <option>5 Influencer</option>
                            <option>10 Influencer</option>
                            <option>25 Influencer</option>
                            <option>50 Influencer</option>
                            <option>100 Influencer</option>
                          </select>
                        </li>
                      </ul>
                    </div>
                    <div className="deliver-nano">
                      <h6>Deliverables by each Influencer</h6>
                      <ul>
                        <li>
                          <Checkbox />
                          <label htmlFor="">Swipe Up Story - 1</label>
                        </li>
                        <li>
                          <Checkbox />
                          <label htmlFor="">Static Post - 1</label>
                        </li>
                        <li>
                          <Checkbox />
                          <label htmlFor="">Video Post - 1</label>
                        </li>
                        <li>
                          <Checkbox />
                          <label htmlFor="">Reel - 1</label>
                        </li>
                        <li>
                          <Checkbox />
                          <label htmlFor="">Carousel Post - 1</label>
                        </li>
                      </ul>
                    </div>
                    <div className="btn-div text-center mt-5">
                      <h5 className=" fw-bold">Cost:--</h5>
                      <p className="gst-p">18% GST to be added Product & Delivery Costs not Included</p>
                      <Button variant='contained'>get started</Button>
                    </div>
                  </div>

                  <div className="nano-package">
                    <h4 className="package-name-micro">Micro Insta Package</h4>

                    <div className="nano-type">
                      <ul>
                        <li>Type: Micro Celebrities</li>
                        <li>
                          <p>Average Followers:</p>
                          <span>10000 - 100000</span>
                        </li>
                        <li>
                          <select>
                            <option>Select Categories</option>
                            <option>
                              <div>
                                <input type="radio" />
                                <label htmlFor="">Beauty</label>
                              </div>
                            </option>
                            <option>Auto</option>
                            <option>Books</option>
                            <option>Education</option>
                          </select>
                        </li>
                        <li>
                          <select>
                            <option>Choose Count</option>
                            <option>1 Influencer</option>
                            <option>3 Influencer</option>
                            <option>5 Influencer</option>
                            <option>10 Influencer</option>
                            <option>25 Influencer</option>
                            <option>50 Influencer</option>
                            <option>100 Influencer</option>
                          </select>
                        </li>
                      </ul>
                    </div>

                    <div className="deliver-nano">
                      <h6>Deliverables by each Influencer</h6>
                      <ul>
                        <li>
                          <Checkbox />
                          <label htmlFor="">Swipe Up Story - 1</label>
                        </li>
                        <li>
                          <Checkbox />
                          <label htmlFor="">Static Post - 1</label>
                        </li>
                        <li>
                          <Checkbox />
                          <label htmlFor="">Video Post - 1</label>
                        </li>
                        <li>
                          <Checkbox />
                          <label htmlFor="">Reel - 1</label>
                        </li>
                        <li>
                          <Checkbox />
                          <label htmlFor="">Carousel Post - 1</label>
                        </li>
                      </ul>
                    </div>
                    <div className="btn-div text-center mt-5">
                      <h5 className=" fw-bold">Cost:--</h5>
                      <p className="gst-p">18% GST to be added Product & Delivery Costs not Included</p>
                      <Button variant='contained'>get started</Button>
                    </div>
                  </div>

                  <div className="nano-package">
                    <h4 className="package-name-macro">Macro Insta Package</h4>

                    <div className="nano-type">
                      <ul>
                        <li>Type: Macro Celebrities</li>
                        <li>
                          <p>Average Followers:</p>
                          <span>10000 - 1000000</span>
                        </li>
                        <li>
                          <select>
                            <option>Select Categories</option>
                            <option>
                              <div>
                                <input type="radio" />
                                <label htmlFor="">Beauty</label>
                              </div>
                            </option>
                            <option>Auto</option>
                            <option>Books</option>
                            <option>Education</option>
                          </select>
                        </li>
                        <li>
                          <select>
                            <option>Choose Count</option>
                            <option>1 Influencer</option>
                            <option>3 Influencer</option>
                            <option>5 Influencer</option>
                            <option>10 Influencer</option>
                            <option>25 Influencer</option>
                            <option>50 Influencer</option>
                            <option>100 Influencer</option>
                          </select>
                        </li>
                      </ul>
                    </div>

                    <div className="deliver-nano">
                      <h6>Deliverables by each Influencer</h6>
                      <ul>
                        <li>
                          <Checkbox />
                          <label htmlFor="">Swipe Up Story - 1</label>
                        </li>
                        <li>
                          <Checkbox />
                          <label htmlFor="">Static Post - 1</label>
                        </li>
                        <li>
                          <Checkbox />
                          <label htmlFor="">Video Post - 1</label>
                        </li>
                        <li>
                          <Checkbox />
                          <label htmlFor="">Reel - 1</label>
                        </li>
                        <li>
                          <Checkbox />
                          <label htmlFor="">Carousel Post - 1</label>
                        </li>
                      </ul>
                    </div>
                    <div className="btn-div text-center mt-5">
                      <h5 className=" fw-bold">Cost:--</h5>
                      <p className="gst-p">18% GST to be added Product & Delivery Costs not Included</p>
                      <Button variant='contained'>get started</Button>
                    </div>
                  </div>

                  <div className="nano-package">
                    <h4 className="package-name-mega">Mega Insta Package</h4>

                    <div className="nano-type">
                      <ul>
                        <li>Type: Mega Celebrities</li>
                        <li>
                          <p>Average Followers:</p>
                          <span>1000000 - 3000000</span>
                        </li>
                        <li>
                          <select>
                            <option>Select Categories</option>
                            <option>
                              <div>
                                <input type="radio" />
                                <label htmlFor="">Beauty</label>
                              </div>
                            </option>
                            <option>Auto</option>
                            <option>Books</option>
                            <option>Education</option>
                          </select>
                        </li>
                        <li>
                          <select>
                            <option>Choose Count</option>
                            <option>1 Influencer</option>
                            <option>3 Influencer</option>
                            <option>5 Influencer</option>
                            <option>10 Influencer</option>
                            <option>25 Influencer</option>
                            <option>50 Influencer</option>
                            <option>100 Influencer</option>
                          </select>
                        </li>
                      </ul>
                    </div>

                    <div className="deliver-nano">
                      <h6>Deliverables by each Influencer</h6>
                      <ul>
                        <li>
                          <Checkbox />
                          <label htmlFor="">Swipe Up Story - 1</label>
                        </li>
                        <li>
                          <Checkbox />
                          <label htmlFor="">Static Post - 1</label>
                        </li>
                        <li>
                          <Checkbox />
                          <label htmlFor="">Video Post - 1</label>
                        </li>
                        <li>
                          <Checkbox />
                          <label htmlFor="">Reel - 1</label>
                        </li>
                        <li>
                          <Checkbox />
                          <label htmlFor="">Carousel Post - 1</label>
                        </li>
                      </ul>
                    </div>
                    <div className="btn-div text-center mt-5">
                      <h5 className=" fw-bold">Cost:--</h5>
                      <p className="gst-p">18% GST to be added Product & Delivery Costs not Included</p>
                      <Button variant='contained'>get started</Button>
                    </div>
                  </div>
                </div>

                <div className="choose-plan">
                  <div className="div1">
                    <h1>DIFFICULT TO CHOOSE?</h1>
                    <p>Have doubts about what will suit your business the most? We'll gladly answer all your questions!</p>
                  </div>
                  <div className="div2">
                    <Button variant='contained'>get in touch</Button>
                  </div>
                </div>
              </div>

              <Box className='how-we-work'>
                <h1>How we work and our 100% Moneyback Guarantee</h1>
                <ul>
                  <li>Once you book a package our dedicated Service Managers will contact you within 24 Hrs</li>
                  <li>Based on your requirement Service Manager will search and compile the suitable list of influencers for your project</li>
                  <li>You will be requested to choose the best influencers from the list for your project</li>
                  <li>If you are not satisfied with our list of infleunecrs we will present you with a 2nd or 3rd list</li>
                  <li>If you are still not happy with our list (within 7 days) we will refund your booking. No questions asked</li>
                  <li>If you are done with the seelction of influencers then in the next phase we will take complate responsiblity of the project</li>
                  <li>We will coordinate with all infleuencers, sharing art work, videos and other materials as required</li>
                  <li>Closely monitor the progress of the project and follow up with them</li>
                  <li>Finally submit a comprehensive project execution report to you</li>
                </ul>
              </Box>

              <div className="manage-plan">
                <div className="div1">
                  <h1>SELF MANAGED CAMPAIGNS</h1>
                  <p>Start a Self Managed Influencer Marketing campaign and get influencer contact details for FREE. Discuss project details and pay them directly. No agency commission to be paid.</p>
                </div>
                <div className="div2">
                  <Button variant='contained'>start for free</Button>
                </div>
              </div>

              <div className="type-infulencer">
                <div className="back">
                  <div className="maindivInfluncermarketing">
                    <h2>Type of Celebrities</h2>
                  </div>
                </div>
                <div className="maindivInfluncermarketing">
                  <div className="ulk">
                    <ul>
                      <li>
                        <div className="small-circle">
                          <img
                            className=""
                            src="https://www.influglue.com/files/medialibrary/9bfb51da1ae2d00619bf278ff7946e5d.jpg"
                            alt="img"
                          />
                        </div>
                        <h3>NANO <span className="span"> INFLUENCER</span></h3>
                        <div>
                          <p>1,000 To 10,000</p>
                          <span>FOLLOWERS</span>
                        </div>
                      </li>
                      <li>
                        <div className="small-circle">
                          <img
                            className=""
                            src="https://www.influglue.com/files/medialibrary/4d9da35f4cb1fbeeb7178f21c10fdea7.jpg"
                            alt="img"
                          />
                        </div>
                        <h3>MICRO <span className="span"> INFLUENCER</span></h3>
                        <div>
                          <p>1,000 To 10,000</p>
                          <span>FOLLOWERS</span>
                        </div>
                      </li>
                      <li>
                        <div className="small-circle">
                          <img
                            className=""
                            src="https://www.influglue.com/files/medialibrary/92f68ec61cf76b4e6194527d026cdd89.jpg"
                            alt="img"
                          />
                        </div>
                        <h3>MACRO <span className="span"> INFLUENCER</span></h3>
                        <div>
                          <p>1,000 To 10,000</p>
                          <span>FOLLOWERS</span>
                        </div>
                      </li>
                      <li>
                        <div className="small-circle">
                          <img
                            className=""
                            src="https://www.influglue.com/files/medialibrary/e66b7583054b468c808b3fb94f1fb70a.jpg"
                            alt="img"
                          />
                        </div>
                        <h3>MEGA <span className="span"> INFLUENCER</span></h3>
                        <div>
                          <p>1,000 To 10,000</p>
                          <span>FOLLOWERS</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="m-5">
                <div className="infulencerdiv-tag">
                  <h3>Nano Celebrities</h3>
                  <p>Nano influencers are influencers who have followers between 1000 to 10000. Most of the nano influencers does not maintain a theme on their Instagram or other social media profile, they will give a feeling of the “guy next door”. These influencers are great for marketing because they maintain a closer relationship with their followers through one-on-one communication which is more engaging and intimate. You will find them participating in all giveaways, contests, providing answers to all your questions/comments etc. They know their loyal followers and interact with them to strengthen their relationship more. Moreover, their engagement rate is much higher that is why brands love to work with multiple nano influencers for their campaign.</p>
                </div>
                <div className="infulencerdiv-tag">
                  <h3>Micro Celebrities</h3>
                  <p>A micro-influencer is someone who has followers between 10000 to 100,000, they focus on a specific niche or area while creating content, this is why they are considered industry experts. They are extremely vocal about various things happening around their niche which keeps them relevant all the time. Today every small to a big brand are planning to engage in micro-influencer marketing campaigns. Though they have a comparatively smaller number of audience than the mega and celebrity influencers but their sizeable followers are exclusively uniform which attracts various brands to work with them for endorsement projects. Micro-influencers are favourite for brands with limited marketing budgets.</p>
                </div>
                <div className="infulencerdiv-tag">
                  <h3>Macro influencers</h3>
                  <p>Macro influencers are content creators with followers between 100,000 to 1000000, they lie somewhere between micro and mega. Over time macro-influencers have gained a reputation because of their skills and knowledge in a particular niche. Their content is supposed to be informative and entertaining at the same time. Usually, they are the first to talk about new trends in the society that includes launching new brands/products, discounts/coupons/giveaways etc. They usually maintain a theme on their profile to make it top-notch so that when brands take a look at their profile get a fair idea about their content quality.</p>
                </div>
                <div className="infulencerdiv-tag">
                  <h3>Mega influencers</h3>
                  <p>Mega influencers got the highest ranking among all somewhat same as celebrity influencers though not entirely. They have over a million followers and prefer to work with brands with a high reputation in the market instead of emerging brands. They are the brand ambassadors for more than one brand and work on a commission basis apart from charging their regular fee for curating promotional posts. Because of their large following, mega influencers provide brands with a notable greater reach, brands with higher marketing budgets prefer to work with this category of influencers because of huge matters to them overall. Brands that want to promote products that appeal to the masses must work with mega influencers.</p>
                </div>
              </div>

              <div className=" d-flex flex-wrap justify-content-center">
                    <div className="cardbox m-3">
                        <Link to={'/influencer-detail'}>
                            <div className="cardimg-div1">
                                <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
                                <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                            </div>
                        </Link>
                        <div className="cardcontent-div">
                            <h6>BUSHRA</h6>
                            <p>Delhi</p>
                            <div>
                                <Button size="small" variant="outlined">Health</Button>
                                <Button size="small" variant="outlined">Lifestyle</Button>
                                <Button size="small" variant="outlined">Parenting</Button>
                                <Button size="small" variant="outlined">Education</Button>
                                <Button size="small" variant="outlined">Food</Button>
                                <Button size="small" variant="outlined">Mom</Button>
                            </div>
                            <div>
                                <ul>
                                    <li>
                                        <p>35.6K</p>
                                        <span>Followers</span>
                                    </li>
                                    <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                        <p> 1.3K</p>
                                        <span>Posts</span>
                                    </li>
                                    <li>
                                        <p>1.03%</p>
                                        <span>Engagement</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="cardbox m-3">
                        <div className="cardimg-div2">
                            <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
                            <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                        </div>
                        <div className="cardcontent-div">
                            <h6>KRITIKA</h6>
                            <p>Delhi</p>
                            <div>
                                <Button size="small" variant="outlined">Beauty</Button>
                                <Button size="small" variant="outlined">Lifestyle</Button>
                                <Button size="small" variant="outlined">Women's Fashion</Button>
                                <Button size="small" variant="outlined">Travel</Button>
                                <Button size="small" variant="outlined">Entertainments</Button>
                            </div>
                            <div>
                                <ul>
                                    <li>
                                        <p>1.8M</p>
                                        <span>Followers</span>
                                    </li>
                                    <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                        <p> 6.4K</p>
                                        <span>Posts</span>
                                    </li>
                                    <li>
                                        <p>2.63%</p>
                                        <span>Engagement</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="cardbox m-3">
                        <div className="cardimg-div3">
                            <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
                            <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                        </div>
                        <div className="cardcontent-div">
                            <h6>SANDY</h6>
                            <p>Bangalore</p>
                            <div>
                                <Button size="small" variant="outlined">Couple</Button>
                                <Button size="small" variant="outlined">Lifestyle</Button>
                                <Button size="small" variant="outlined">Travel</Button>
                                <Button size="small" variant="outlined">Photography</Button>
                                <Button size="small" variant="outlined">Food</Button>
                            </div>
                            <div>
                                <ul>
                                    <li>
                                        <p>39.6K</p>
                                        <span>Followers</span>
                                    </li>
                                    <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                        <p> 630</p>
                                        <span>Posts</span>
                                    </li>
                                    <li>
                                        <p>0.53%</p>
                                        <span>Engagement</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="cardbox m-3">
                        <div className="cardimg-div4">
                            <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
                            <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                        </div>
                        <div className="cardcontent-div">
                            <h6>PRERNA</h6>
                            <p>Mumbai</p>
                            <div>
                                <Button size="small" variant="outlined">Women's Fashion</Button>
                                <Button size="small" variant="outlined">Lifestyle</Button>
                                <Button size="small" variant="outlined">Parenting</Button>
                                <Button size="small" variant="outlined">Travel</Button>
                                <Button size="small" variant="outlined">Fitness</Button>
                                <Button size="small" variant="outlined">Mom</Button>
                            </div>
                            <div>
                                <ul>
                                    <li>
                                        <p>85.6K</p>
                                        <span>Followers</span>
                                    </li>
                                    <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                        <p> 3.3K</p>
                                        <span>Posts</span>
                                    </li>
                                    <li>
                                        <p>1.53%</p>
                                        <span>Engagement</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="cardbox m-3">
                                <div className="cardimg-div5">
                                    <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
                                    <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                                </div>
                                <div className="cardcontent-div">
                                    <h6>Deepa</h6>
                                    <p>Bangalore</p>
                                    <div>
                                        <Button size="small" variant="outlined">Health</Button>
                                        <Button size="small" variant="outlined">Lifestyle</Button>
                                        <Button size="small" variant="outlined">Parenting</Button>
                                        <Button size="small" variant="outlined">Education</Button>
                                        <Button size="small" variant="outlined">Food</Button>
                                        <Button size="small" variant="outlined">Mom</Button>
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <p>35.6K</p>
                                                <span>Followers</span>
                                            </li>
                                            <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                                <p> 1.3K</p>
                                                <span>Posts</span>
                                            </li>
                                            <li>
                                                <p>1.03%</p>
                                                <span>Engagement</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                    </div>
                    <div className="cardbox m-3">
                        <div className="cardimg-div6">
                            <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
                            <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                        </div>
                        <div className="cardcontent-div">
                            <h6>DIMPLE</h6>
                            <p>Mumbai</p>
                            <div>
                                <Button size="small" variant="outlined">Health</Button>
                                <Button size="small" variant="outlined">Lifestyle</Button>
                                <Button size="small" variant="outlined">Beauty</Button>
                                <Button size="small" variant="outlined">Travel</Button>
                                <Button size="small" variant="outlined">Mom</Button>
                            </div>
                            <div>
                                <ul>
                                    <li>
                                        <p>35.6K</p>
                                        <span>Followers</span>
                                    </li>
                                    <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                        <p> 1.3K</p>
                                        <span>Posts</span>
                                    </li>
                                    <li>
                                        <p>1.03%</p>
                                        <span>Engagement</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="cardbox m-3">
                        <Link to={'/influencer-detail'}>
                            <div className="cardimg-div1">
                                <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
                                <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                            </div>
                        </Link>
                        <div className="cardcontent-div">
                            <h6>BUSHRA</h6>
                            <p>Delhi</p>
                            <div>
                                <Button size="small" variant="outlined">Health</Button>
                                <Button size="small" variant="outlined">Lifestyle</Button>
                                <Button size="small" variant="outlined">Parenting</Button>
                                <Button size="small" variant="outlined">Education</Button>
                                <Button size="small" variant="outlined">Food</Button>
                                <Button size="small" variant="outlined">Mom</Button>
                            </div>
                            <div>
                                <ul>
                                    <li>
                                        <p>35.6K</p>
                                        <span>Followers</span>
                                    </li>
                                    <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                        <p> 1.3K</p>
                                        <span>Posts</span>
                                    </li>
                                    <li>
                                        <p>1.03%</p>
                                        <span>Engagement</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="cardbox m-3">
                        <div className="cardimg-div2">
                            <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
                            <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                        </div>
                        <div className="cardcontent-div">
                            <h6>KRITIKA</h6>
                            <p>Delhi</p>
                            <div>
                                <Button size="small" variant="outlined">Beauty</Button>
                                <Button size="small" variant="outlined">Lifestyle</Button>
                                <Button size="small" variant="outlined">Women's Fashion</Button>
                                <Button size="small" variant="outlined">Travel</Button>
                                <Button size="small" variant="outlined">Entertainments</Button>
                            </div>
                            <div>
                                <ul>
                                    <li>
                                        <p>1.8M</p>
                                        <span>Followers</span>
                                    </li>
                                    <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                        <p> 6.4K</p>
                                        <span>Posts</span>
                                    </li>
                                    <li>
                                        <p>2.63%</p>
                                        <span>Engagement</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="cardbox m-3">
                        <div className="cardimg-div3">
                            <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
                            <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                        </div>
                        <div className="cardcontent-div">
                            <h6>SANDY</h6>
                            <p>Bangalore</p>
                            <div>
                                <Button size="small" variant="outlined">Couple</Button>
                                <Button size="small" variant="outlined">Lifestyle</Button>
                                <Button size="small" variant="outlined">Travel</Button>
                                <Button size="small" variant="outlined">Photography</Button>
                                <Button size="small" variant="outlined">Food</Button>
                            </div>
                            <div>
                                <ul>
                                    <li>
                                        <p>39.6K</p>
                                        <span>Followers</span>
                                    </li>
                                    <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                        <p> 630</p>
                                        <span>Posts</span>
                                    </li>
                                    <li>
                                        <p>0.53%</p>
                                        <span>Engagement</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="cardbox m-3">
                        <div className="cardimg-div4">
                            <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
                            <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                        </div>
                        <div className="cardcontent-div">
                            <h6>PRERNA</h6>
                            <p>Mumbai</p>
                            <div>
                                <Button size="small" variant="outlined">Women's Fashion</Button>
                                <Button size="small" variant="outlined">Lifestyle</Button>
                                <Button size="small" variant="outlined">Parenting</Button>
                                <Button size="small" variant="outlined">Travel</Button>
                                <Button size="small" variant="outlined">Fitness</Button>
                                <Button size="small" variant="outlined">Mom</Button>
                            </div>
                            <div>
                                <ul>
                                    <li>
                                        <p>85.6K</p>
                                        <span>Followers</span>
                                    </li>
                                    <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                        <p> 3.3K</p>
                                        <span>Posts</span>
                                    </li>
                                    <li>
                                        <p>1.53%</p>
                                        <span>Engagement</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="cardbox m-3">
                                <div className="cardimg-div5">
                                    <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
                                    <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                                </div>
                                <div className="cardcontent-div">
                                    <h6>Deepa</h6>
                                    <p>Bangalore</p>
                                    <div>
                                        <Button size="small" variant="outlined">Health</Button>
                                        <Button size="small" variant="outlined">Lifestyle</Button>
                                        <Button size="small" variant="outlined">Parenting</Button>
                                        <Button size="small" variant="outlined">Education</Button>
                                        <Button size="small" variant="outlined">Food</Button>
                                        <Button size="small" variant="outlined">Mom</Button>
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <p>35.6K</p>
                                                <span>Followers</span>
                                            </li>
                                            <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                                <p> 1.3K</p>
                                                <span>Posts</span>
                                            </li>
                                            <li>
                                                <p>1.03%</p>
                                                <span>Engagement</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                    </div>
                    <div className="cardbox m-3">
                        <div className="cardimg-div6">
                            <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
                            <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                        </div>
                        <div className="cardcontent-div">
                            <h6>DIMPLE</h6>
                            <p>Mumbai</p>
                            <div>
                                <Button size="small" variant="outlined">Health</Button>
                                <Button size="small" variant="outlined">Lifestyle</Button>
                                <Button size="small" variant="outlined">Beauty</Button>
                                <Button size="small" variant="outlined">Travel</Button>
                                <Button size="small" variant="outlined">Mom</Button>
                            </div>
                            <div>
                                <ul>
                                    <li>
                                        <p>35.6K</p>
                                        <span>Followers</span>
                                    </li>
                                    <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                        <p> 1.3K</p>
                                        <span>Posts</span>
                                    </li>
                                    <li>
                                        <p>1.03%</p>
                                        <span>Engagement</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </TabPanel>
            <TabPanel value={1}>
            <div className="price-tabpanel">
                <div style={{ textAlign: 'center' }}>
                  <h5 className=" fw-bold pt-5">YOUTUBE PACKAGES</h5>
                </div>

                <div className="packages-plans">
                  <div className="nano-package">
                    <h4 className="package-name-nano">Nano YouTube Package</h4>
                    <div className="nano-type">
                      <ul>
                        <li>Type: Nano Celebrities</li>
                        <li>
                          <p>Average Followers:</p>
                          <span>1000 - 10000</span>
                        </li>
                        <li>
                          <select>
                            <option>Select Categories</option>
                            <option>Beauty</option>
                            <option>Auto</option>
                            <option>Books</option>
                            <option>Education</option>
                          </select>
                        </li>
                        <li>
                          <select>
                            <option>Choose Count</option>
                            <option>1 Influencer</option>
                            <option>3 Influencer</option>
                            <option>5 Influencer</option>
                            <option>10 Influencer</option>
                            <option>25 Influencer</option>
                            <option>50 Influencer</option>
                            <option>100 Influencer</option>
                          </select>
                        </li>
                      </ul>
                    </div>
                    <div className="deliver-nano">
                      <h6>Deliverables by each Influencer</h6>
                      <ul>
                        <li>
                          <Checkbox />
                          <label htmlFor="">Integrated Video - 1</label>
                        </li>
                        <li>
                          <Checkbox />
                          <label htmlFor="">Dedicated Video - 1</label>
                        </li>
                      </ul>
                    </div>
                    <div className="btn-div text-center mt-5">
                      <h5 className=" fw-bold">Cost:--</h5>
                      <p className="gst-p">18% GST to be added Product & Delivery Costs not Included</p>
                      <Button variant='contained'>get started</Button>
                    </div>
                  </div>

                  <div className="nano-package">
                    <h4 className="package-name-micro">Micro YouTube Package</h4>

                    <div className="nano-type">
                      <ul>
                        <li>Type: Micro Celebrities</li>
                        <li>
                          <p>Average Followers:</p>
                          <span>10000 - 100000</span>
                        </li>
                        <li>
                          <select>
                            <option>Select Categories</option>
                            <option>
                              <div>
                                <input type="radio" />
                                <label htmlFor="">Beauty</label>
                              </div>
                            </option>
                            <option>Auto</option>
                            <option>Books</option>
                            <option>Education</option>
                          </select>
                        </li>
                        <li>
                          <select>
                            <option>Choose Count</option>
                            <option>1 Influencer</option>
                            <option>3 Influencer</option>
                            <option>5 Influencer</option>
                            <option>10 Influencer</option>
                            <option>25 Influencer</option>
                            <option>50 Influencer</option>
                            <option>100 Influencer</option>
                          </select>
                        </li>
                      </ul>
                    </div>

                    <div className="deliver-nano">
                      <h6>Deliverables by each Influencer</h6>
                      <ul>
                        <li>
                          <Checkbox />
                          <label htmlFor="">Integrated Video - 1</label>
                        </li>
                        <li>
                          <Checkbox />
                          <label htmlFor="">Dedicated Video - 1</label>
                        </li>
                      </ul>
                    </div>
                    <div className="btn-div text-center mt-5">
                      <h5 className=" fw-bold">Cost:--</h5>
                      <p className="gst-p">18% GST to be added Product & Delivery Costs not Included</p>
                      <Button variant='contained'>get started</Button>
                    </div>
                  </div>

                  <div className="nano-package">
                    <h4 className="package-name-macro">Macro YouTube Package</h4>

                    <div className="nano-type">
                      <ul>
                        <li>Type: Macro Celebrities</li>
                        <li>
                          <p>Average Followers:</p>
                          <span>10000 - 1000000</span>
                        </li>
                        <li>
                          <select>
                            <option>Select Categories</option>
                            <option>
                              <div>
                                <input type="radio" />
                                <label htmlFor="">Beauty</label>
                              </div>
                            </option>
                            <option>Auto</option>
                            <option>Books</option>
                            <option>Education</option>
                          </select>
                        </li>
                        <li>
                          <select>
                            <option>Choose Count</option>
                            <option>1 Influencer</option>
                            <option>3 Influencer</option>
                            <option>5 Influencer</option>
                            <option>10 Influencer</option>
                            <option>25 Influencer</option>
                            <option>50 Influencer</option>
                            <option>100 Influencer</option>
                          </select>
                        </li>
                      </ul>
                    </div>

                    <div className="deliver-nano">
                      <h6>Deliverables by each Influencer</h6>
                      <ul>
                        <li>
                          <Checkbox />
                          <label htmlFor="">Integrated Video - 1</label>
                        </li>
                        <li>
                          <Checkbox />
                          <label htmlFor="">Dedicated Video - 1</label>
                        </li>
                      </ul>
                    </div>
                    <div className="btn-div text-center mt-5">
                      <h5 className=" fw-bold">Cost:--</h5>
                      <p className="gst-p">18% GST to be added Product & Delivery Costs not Included</p>
                      <Button variant='contained'>get started</Button>
                    </div>
                  </div>

                  <div className="nano-package">
                    <h4 className="package-name-mega">Mega YouTube Package</h4>

                    <div className="nano-type">
                      <ul>
                        <li>Type: Mega Celebrities</li>
                        <li>
                          <p>Average Followers:</p>
                          <span>1000000 - 3000000</span>
                        </li>
                        <li>
                          <select>
                            <option>Select Categories</option>
                            <option>
                              <div>
                                <input type="radio" />
                                <label htmlFor="">Beauty</label>
                              </div>
                            </option>
                            <option>Auto</option>
                            <option>Books</option>
                            <option>Education</option>
                          </select>
                        </li>
                        <li>
                          <select>
                            <option>Choose Count</option>
                            <option>1 Influencer</option>
                            <option>3 Influencer</option>
                            <option>5 Influencer</option>
                            <option>10 Influencer</option>
                            <option>25 Influencer</option>
                            <option>50 Influencer</option>
                            <option>100 Influencer</option>
                          </select>
                        </li>
                      </ul>
                    </div>

                    <div className="deliver-nano">
                      <h6>Deliverables by each Influencer</h6>
                      <ul>
                        <li>
                          <Checkbox />
                          <label htmlFor="">Integrated Video - 1</label>
                        </li>
                        <li>
                          <Checkbox />
                          <label htmlFor="">Dedicated Video - 1</label>
                        </li>
                      </ul>
                    </div>
                    <div className="btn-div text-center mt-5">
                      <h5 className=" fw-bold">Cost:--</h5>
                      <p className="gst-p">18% GST to be added Product & Delivery Costs not Included</p>
                      <Button variant='contained'>get started</Button>
                    </div>
                  </div>
                </div>

                <div className="choose-plan">
                  <div className="div1">
                    <h1>DIFFICULT TO CHOOSE?</h1>
                    <p>Have doubts about what will suit your business the most? We'll gladly answer all your questions!</p>
                  </div>
                  <div className="div2">
                    <Button variant='contained'>get in touch</Button>
                  </div>
                </div>
              </div>

              <Box className='how-we-work'>
                <h1>How we work and our 100% Moneyback Guarantee</h1>
                <ul>
                  <li>Once you book a package our dedicated Service Managers will contact you within 24 Hrs</li>
                  <li>Based on your requirement Service Manager will search and compile the suitable list of influencers for your project</li>
                  <li>You will be requested to choose the best influencers from the list for your project</li>
                  <li>If you are not satisfied with our list of infleunecrs we will present you with a 2nd or 3rd list</li>
                  <li>If you are still not happy with our list (within 7 days) we will refund your booking. No questions asked</li>
                  <li>If you are done with the seelction of influencers then in the next phase we will take complate responsiblity of the project</li>
                  <li>We will coordinate with all infleuencers, sharing art work, videos and other materials as required</li>
                  <li>Closely monitor the progress of the project and follow up with them</li>
                  <li>Finally submit a comprehensive project execution report to you</li>
                </ul>
              </Box>

              <div className="manage-plan">
                <div className="div1">
                  <h1>SELF MANAGED CAMPAIGNS</h1>
                  <p>Start a Self Managed Influencer Marketing campaign and get influencer contact details for FREE. Discuss project details and pay them directly. No agency commission to be paid.</p>
                </div>
                <div className="div2">
                  <Button variant='contained'>start for free</Button>
                </div>
              </div>

              <div className="type-infulencer">
                <div className="back">
                  <div className="maindivInfluncermarketing">
                    <h2>Type of Celebrities</h2>
                  </div>
                </div>
                <div className="maindivInfluncermarketing">
                  <div className="ulk">
                    <ul>
                      <li>
                        <div className="small-circle">
                          <img
                            className=""
                            src="https://www.influglue.com/files/medialibrary/9bfb51da1ae2d00619bf278ff7946e5d.jpg"
                            alt="img"
                          />
                        </div>
                        <h3>NANO <span className="span"> INFLUENCER</span></h3>
                        <div>
                          <p>1,000 To 10,000</p>
                          <span>FOLLOWERS</span>
                        </div>
                      </li>
                      <li>
                        <div className="small-circle">
                          <img
                            className=""
                            src="https://www.influglue.com/files/medialibrary/4d9da35f4cb1fbeeb7178f21c10fdea7.jpg"
                            alt="img"
                          />
                        </div>
                        <h3>MICRO <span className="span"> INFLUENCER</span></h3>
                        <div>
                          <p>1,000 To 10,000</p>
                          <span>FOLLOWERS</span>
                        </div>
                      </li>
                      <li>
                        <div className="small-circle">
                          <img
                            className=""
                            src="https://www.influglue.com/files/medialibrary/92f68ec61cf76b4e6194527d026cdd89.jpg"
                            alt="img"
                          />
                        </div>
                        <h3>MACRO <span className="span"> INFLUENCER</span></h3>
                        <div>
                          <p>1,000 To 10,000</p>
                          <span>FOLLOWERS</span>
                        </div>
                      </li>
                      <li>
                        <div className="small-circle">
                          <img
                            className=""
                            src="https://www.influglue.com/files/medialibrary/e66b7583054b468c808b3fb94f1fb70a.jpg"
                            alt="img"
                          />
                        </div>
                        <h3>MEGA <span className="span"> INFLUENCER</span></h3>
                        <div>
                          <p>1,000 To 10,000</p>
                          <span>FOLLOWERS</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="m-5">
                <div className="infulencerdiv-tag">
                  <h3>Nano Celebrities</h3>
                  <p>Nano influencers are influencers who have followers between 1000 to 10000. Most of the nano influencers does not maintain a theme on their Instagram or other social media profile, they will give a feeling of the “guy next door”. These influencers are great for marketing because they maintain a closer relationship with their followers through one-on-one communication which is more engaging and intimate. You will find them participating in all giveaways, contests, providing answers to all your questions/comments etc. They know their loyal followers and interact with them to strengthen their relationship more. Moreover, their engagement rate is much higher that is why brands love to work with multiple nano influencers for their campaign.</p>
                </div>
                <div className="infulencerdiv-tag">
                  <h3>Micro Celebrities</h3>
                  <p>A micro-influencer is someone who has followers between 10000 to 100,000, they focus on a specific niche or area while creating content, this is why they are considered industry experts. They are extremely vocal about various things happening around their niche which keeps them relevant all the time. Today every small to a big brand are planning to engage in micro-influencer marketing campaigns. Though they have a comparatively smaller number of audience than the mega and celebrity influencers but their sizeable followers are exclusively uniform which attracts various brands to work with them for endorsement projects. Micro-influencers are favourite for brands with limited marketing budgets.</p>
                </div>
                <div className="infulencerdiv-tag">
                  <h3>Macro influencers</h3>
                  <p>Macro influencers are content creators with followers between 100,000 to 1000000, they lie somewhere between micro and mega. Over time macro-influencers have gained a reputation because of their skills and knowledge in a particular niche. Their content is supposed to be informative and entertaining at the same time. Usually, they are the first to talk about new trends in the society that includes launching new brands/products, discounts/coupons/giveaways etc. They usually maintain a theme on their profile to make it top-notch so that when brands take a look at their profile get a fair idea about their content quality.</p>
                </div>
                <div className="infulencerdiv-tag">
                  <h3>Mega influencers</h3>
                  <p>Mega influencers got the highest ranking among all somewhat same as celebrity influencers though not entirely. They have over a million followers and prefer to work with brands with a high reputation in the market instead of emerging brands. They are the brand ambassadors for more than one brand and work on a commission basis apart from charging their regular fee for curating promotional posts. Because of their large following, mega influencers provide brands with a notable greater reach, brands with higher marketing budgets prefer to work with this category of influencers because of huge matters to them overall. Brands that want to promote products that appeal to the masses must work with mega influencers.</p>
                </div>
              </div>

              <div className=" d-flex flex-wrap justify-content-center">
                    <div className="cardbox m-3">
                        <Link to={'/influencer-detail'}>
                            <div className="cardimg-div1">
                                <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
                                <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                            </div>
                        </Link>
                        <div className="cardcontent-div">
                            <h6>BUSHRA</h6>
                            <p>Delhi</p>
                            <div>
                                <Button size="small" variant="outlined">Health</Button>
                                <Button size="small" variant="outlined">Lifestyle</Button>
                                <Button size="small" variant="outlined">Parenting</Button>
                                <Button size="small" variant="outlined">Education</Button>
                                <Button size="small" variant="outlined">Food</Button>
                                <Button size="small" variant="outlined">Mom</Button>
                            </div>
                            <div>
                                <ul>
                                    <li>
                                        <p>35.6K</p>
                                        <span>Followers</span>
                                    </li>
                                    <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                        <p> 1.3K</p>
                                        <span>Posts</span>
                                    </li>
                                    <li>
                                        <p>1.03%</p>
                                        <span>Engagement</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="cardbox m-3">
                        <div className="cardimg-div2">
                            <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
                            <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                        </div>
                        <div className="cardcontent-div">
                            <h6>KRITIKA</h6>
                            <p>Delhi</p>
                            <div>
                                <Button size="small" variant="outlined">Beauty</Button>
                                <Button size="small" variant="outlined">Lifestyle</Button>
                                <Button size="small" variant="outlined">Women's Fashion</Button>
                                <Button size="small" variant="outlined">Travel</Button>
                                <Button size="small" variant="outlined">Entertainments</Button>
                            </div>
                            <div>
                                <ul>
                                    <li>
                                        <p>1.8M</p>
                                        <span>Followers</span>
                                    </li>
                                    <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                        <p> 6.4K</p>
                                        <span>Posts</span>
                                    </li>
                                    <li>
                                        <p>2.63%</p>
                                        <span>Engagement</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="cardbox m-3">
                        <div className="cardimg-div3">
                            <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
                            <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                        </div>
                        <div className="cardcontent-div">
                            <h6>SANDY</h6>
                            <p>Bangalore</p>
                            <div>
                                <Button size="small" variant="outlined">Couple</Button>
                                <Button size="small" variant="outlined">Lifestyle</Button>
                                <Button size="small" variant="outlined">Travel</Button>
                                <Button size="small" variant="outlined">Photography</Button>
                                <Button size="small" variant="outlined">Food</Button>
                            </div>
                            <div>
                                <ul>
                                    <li>
                                        <p>39.6K</p>
                                        <span>Followers</span>
                                    </li>
                                    <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                        <p> 630</p>
                                        <span>Posts</span>
                                    </li>
                                    <li>
                                        <p>0.53%</p>
                                        <span>Engagement</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="cardbox m-3">
                        <div className="cardimg-div4">
                            <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
                            <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                        </div>
                        <div className="cardcontent-div">
                            <h6>PRERNA</h6>
                            <p>Mumbai</p>
                            <div>
                                <Button size="small" variant="outlined">Women's Fashion</Button>
                                <Button size="small" variant="outlined">Lifestyle</Button>
                                <Button size="small" variant="outlined">Parenting</Button>
                                <Button size="small" variant="outlined">Travel</Button>
                                <Button size="small" variant="outlined">Fitness</Button>
                                <Button size="small" variant="outlined">Mom</Button>
                            </div>
                            <div>
                                <ul>
                                    <li>
                                        <p>85.6K</p>
                                        <span>Followers</span>
                                    </li>
                                    <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                        <p> 3.3K</p>
                                        <span>Posts</span>
                                    </li>
                                    <li>
                                        <p>1.53%</p>
                                        <span>Engagement</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="cardbox m-3">
                                <div className="cardimg-div5">
                                    <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
                                    <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                                </div>
                                <div className="cardcontent-div">
                                    <h6>Deepa</h6>
                                    <p>Bangalore</p>
                                    <div>
                                        <Button size="small" variant="outlined">Health</Button>
                                        <Button size="small" variant="outlined">Lifestyle</Button>
                                        <Button size="small" variant="outlined">Parenting</Button>
                                        <Button size="small" variant="outlined">Education</Button>
                                        <Button size="small" variant="outlined">Food</Button>
                                        <Button size="small" variant="outlined">Mom</Button>
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <p>35.6K</p>
                                                <span>Followers</span>
                                            </li>
                                            <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                                <p> 1.3K</p>
                                                <span>Posts</span>
                                            </li>
                                            <li>
                                                <p>1.03%</p>
                                                <span>Engagement</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                    </div>
                    <div className="cardbox m-3">
                        <div className="cardimg-div6">
                            <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
                            <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                        </div>
                        <div className="cardcontent-div">
                            <h6>DIMPLE</h6>
                            <p>Mumbai</p>
                            <div>
                                <Button size="small" variant="outlined">Health</Button>
                                <Button size="small" variant="outlined">Lifestyle</Button>
                                <Button size="small" variant="outlined">Beauty</Button>
                                <Button size="small" variant="outlined">Travel</Button>
                                <Button size="small" variant="outlined">Mom</Button>
                            </div>
                            <div>
                                <ul>
                                    <li>
                                        <p>35.6K</p>
                                        <span>Followers</span>
                                    </li>
                                    <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                        <p> 1.3K</p>
                                        <span>Posts</span>
                                    </li>
                                    <li>
                                        <p>1.03%</p>
                                        <span>Engagement</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="cardbox m-3">
                        <Link to={'/influencer-detail'}>
                            <div className="cardimg-div1">
                                <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
                                <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                            </div>
                        </Link>
                        <div className="cardcontent-div">
                            <h6>BUSHRA</h6>
                            <p>Delhi</p>
                            <div>
                                <Button size="small" variant="outlined">Health</Button>
                                <Button size="small" variant="outlined">Lifestyle</Button>
                                <Button size="small" variant="outlined">Parenting</Button>
                                <Button size="small" variant="outlined">Education</Button>
                                <Button size="small" variant="outlined">Food</Button>
                                <Button size="small" variant="outlined">Mom</Button>
                            </div>
                            <div>
                                <ul>
                                    <li>
                                        <p>35.6K</p>
                                        <span>Followers</span>
                                    </li>
                                    <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                        <p> 1.3K</p>
                                        <span>Posts</span>
                                    </li>
                                    <li>
                                        <p>1.03%</p>
                                        <span>Engagement</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="cardbox m-3">
                        <div className="cardimg-div2">
                            <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
                            <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                        </div>
                        <div className="cardcontent-div">
                            <h6>KRITIKA</h6>
                            <p>Delhi</p>
                            <div>
                                <Button size="small" variant="outlined">Beauty</Button>
                                <Button size="small" variant="outlined">Lifestyle</Button>
                                <Button size="small" variant="outlined">Women's Fashion</Button>
                                <Button size="small" variant="outlined">Travel</Button>
                                <Button size="small" variant="outlined">Entertainments</Button>
                            </div>
                            <div>
                                <ul>
                                    <li>
                                        <p>1.8M</p>
                                        <span>Followers</span>
                                    </li>
                                    <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                        <p> 6.4K</p>
                                        <span>Posts</span>
                                    </li>
                                    <li>
                                        <p>2.63%</p>
                                        <span>Engagement</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="cardbox m-3">
                        <div className="cardimg-div3">
                            <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
                            <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                        </div>
                        <div className="cardcontent-div">
                            <h6>SANDY</h6>
                            <p>Bangalore</p>
                            <div>
                                <Button size="small" variant="outlined">Couple</Button>
                                <Button size="small" variant="outlined">Lifestyle</Button>
                                <Button size="small" variant="outlined">Travel</Button>
                                <Button size="small" variant="outlined">Photography</Button>
                                <Button size="small" variant="outlined">Food</Button>
                            </div>
                            <div>
                                <ul>
                                    <li>
                                        <p>39.6K</p>
                                        <span>Followers</span>
                                    </li>
                                    <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                        <p> 630</p>
                                        <span>Posts</span>
                                    </li>
                                    <li>
                                        <p>0.53%</p>
                                        <span>Engagement</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="cardbox m-3">
                        <div className="cardimg-div4">
                            <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
                            <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                        </div>
                        <div className="cardcontent-div">
                            <h6>PRERNA</h6>
                            <p>Mumbai</p>
                            <div>
                                <Button size="small" variant="outlined">Women's Fashion</Button>
                                <Button size="small" variant="outlined">Lifestyle</Button>
                                <Button size="small" variant="outlined">Parenting</Button>
                                <Button size="small" variant="outlined">Travel</Button>
                                <Button size="small" variant="outlined">Fitness</Button>
                                <Button size="small" variant="outlined">Mom</Button>
                            </div>
                            <div>
                                <ul>
                                    <li>
                                        <p>85.6K</p>
                                        <span>Followers</span>
                                    </li>
                                    <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                        <p> 3.3K</p>
                                        <span>Posts</span>
                                    </li>
                                    <li>
                                        <p>1.53%</p>
                                        <span>Engagement</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="cardbox m-3">
                                <div className="cardimg-div5">
                                    <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
                                    <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                                </div>
                                <div className="cardcontent-div">
                                    <h6>Deepa</h6>
                                    <p>Bangalore</p>
                                    <div>
                                        <Button size="small" variant="outlined">Health</Button>
                                        <Button size="small" variant="outlined">Lifestyle</Button>
                                        <Button size="small" variant="outlined">Parenting</Button>
                                        <Button size="small" variant="outlined">Education</Button>
                                        <Button size="small" variant="outlined">Food</Button>
                                        <Button size="small" variant="outlined">Mom</Button>
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                <p>35.6K</p>
                                                <span>Followers</span>
                                            </li>
                                            <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                                <p> 1.3K</p>
                                                <span>Posts</span>
                                            </li>
                                            <li>
                                                <p>1.03%</p>
                                                <span>Engagement</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                    </div>
                    <div className="cardbox m-3">
                        <div className="cardimg-div6">
                            <img src="https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414" alt="img" />
                            <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                        </div>
                        <div className="cardcontent-div">
                            <h6>DIMPLE</h6>
                            <p>Mumbai</p>
                            <div>
                                <Button size="small" variant="outlined">Health</Button>
                                <Button size="small" variant="outlined">Lifestyle</Button>
                                <Button size="small" variant="outlined">Beauty</Button>
                                <Button size="small" variant="outlined">Travel</Button>
                                <Button size="small" variant="outlined">Mom</Button>
                            </div>
                            <div>
                                <ul>
                                    <li>
                                        <p>35.6K</p>
                                        <span>Followers</span>
                                    </li>
                                    <li style={{ borderRight: '1px solid grey', borderLeft: '1px solid grey' }}>
                                        <p> 1.3K</p>
                                        <span>Posts</span>
                                    </li>
                                    <li>
                                        <p>1.03%</p>
                                        <span>Engagement</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </TabPanel>
          </Tabs>
        </div>
      </Box>

      <Footer />
    </>
  )
}

export default YoutubePackage
