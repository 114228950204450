import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import '../../styles/marxMotionPictureHomeStyle.css'
import MarxMotionPictureHeader from "../../common/MarxMotionPicture/HeaderMarxMotionPicture";
import MarxMotionPictureFooter from "../../common/MarxMotionPicture/FooterMarxMotionPicture";
import RunningSlide from "../../common/RunningSlideAllMarx/RunningSlide";
import { useEffect } from "react";
import TopSliderAllMax from "../../common/TopSliderAllMarx/TopSliderAllMarx";
import AllDemoData from "../../data/AllDemoData";



const MarxMotionPictureHome = () => {
    useEffect(() => {
        document.title ="Mar'x Motion Picture Alliance Pvt.Ltd.";
      }, []);

    return (
        <>
            <MarxMotionPictureHeader />

            <div className="ventures">
                <h1>COMING SOON...</h1>
            </div>
            
            {/* <TopSliderAllMax title={"Product"} images={AllDemoData} />

            <div className="main-motionpicdiv">
                <Box className="mainmotionpicdiv-box">
                    <div className="allmotionpictabs">
                        <h5 className=" text-center fw-bold mb-4"> INDEX </h5>
                        <p className='mange-motionpictab'>
                            <Link to={'/'} >MARS </Link>
                        </p>
                    </div>
                    <div className="motionpictabpanels">
                        <div>
                            <div className="about-marx-motionpic">
                                <h1>About Us</h1>
                                <p></p>
                            </div>

                            <div className="about-marx-motionpic">
                                <h1>What We Do</h1>
                                <div>
                                    <p></p>
                                </div>
                            </div>

                            <div className="about-marx-motionpic">
                                <h1>Our Services</h1>
                                <div className="servcedivptag">
                                    <p></p>
                                </div>
                            </div>

                            <div className="about-marx-motionpic">
                                <h1>Who We Are</h1>
                                <div>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </div>

            <RunningSlide title="Product" images={AllDemoData}/>
            <RunningSlide title="Designing" images={AllDemoData}/> */}

            <MarxMotionPictureFooter />
        </>
    )
}

export default MarxMotionPictureHome
