import { Link } from "react-router-dom"
import WeddingFooter from "../../common/marxWedding/FooterMarxWedding"
import MarxWeddingHeader from "../../common/marxWedding/HeaderMarxWedding"
import '../../styles/weddingOurVendorStyle.css'
import { Box } from "@mui/material"

const WeddingOurVendors = () => {
  return (
    <>
      <MarxWeddingHeader />

      <div className="main-vendorweddingdiv">
        <h1 className="h1tgg">Our Vendors</h1>
        <Box className="mainweddvendor-box">
          <div className="allweddvendortabs">
            <h4 className=" text-center pb-3">CATEGORY</h4>
            <p className='mangevendor-weddtab'>
              <Link to={'/'} >Mar'x Mehndi Wala Vendor</Link>
            </p>
            <p className='mangevendor-weddtab'>
              <Link to={'/'} >Mar'x Ghodi Wala Vendor</Link>
            </p>
            <p className='mangevendor-weddtab'>
              <Link to={'/'} >Mar'x Band Wala Vendor</Link>
            </p>
            <p className='mangevendor-weddtab'>
              <Link to={'/'} >Mar'x Bagpiper Band Vendor</Link>
            </p>
            <p className='mangevendor-weddtab'>
              <Link to={'/'} >Mar'x Wedding Card Vendor</Link>
            </p>
            <p className='mangevendor-weddtab'>
              <Link to={'/'} >Mar'x Chopper service Vendor</Link>
            </p>
            <p className='mangevendor-weddtab'>
              <Link to={'/'} >Mar'x Airplane service Vendor</Link>
            </p>
            <p className='mangevendor-weddtab'>
              <Link to={'/'} >Mar'x Flowers Decor Vendor</Link>
            </p>
            <p className='mangevendor-weddtab'>
              <Link to={'/'} >Mar'x Lightning Vendor</Link>
            </p>
            <p className='mangevendor-weddtab'>
              <Link to={'/'} >Mar'x Sound Vendor</Link>
            </p>
            <p className='mangevendor-weddtab'>
              <Link to={'/'} >Mar'x Stage Set-up Vendor</Link>
            </p>
            <p className='mangevendor-weddtab'>
              <Link to={'/'} >Mar'x Catering Vendor</Link>
            </p>
            <p className='mangevendor-weddtab'>
              <Link to={'/'} >Mar'x Gifts & Hamper Vendor</Link>
            </p>
            <p className='mangevendor-weddtab'>
              <Link to={'/'} >Mar'x Photography Vendor</Link>
            </p>
            <p className='mangevendor-weddtab'>

              <Link to={'/'} >Mar'x Videography Vendor</Link>
            </p>
            <p className='mangevendor-weddtab'>
              <Link to={'/'} >Mar'x Astrologer Vendor</Link>
            </p>
            <p className='mangevendor-weddtab'>
              <Link to={'/'} >Mar'x Luxury Car Vendor</Link>
            </p>
            <p className='mangevendor-weddtab'>
              <Link to={'/'} >Mar'x Print Media Coverage Vendor</Link>
            </p>
            <p className='mangevendor-weddtab'>
              <Link to={'/'} >Mar'x Electronic Media Coverage Vendor</Link>
            </p>
            <p className='mangevendor-weddtab'>
              <Link to={'/'} >Mar'x Digital Media Vendor</Link>
            </p>
            <p className='mangevendor-weddtab'>
              <Link to={'/'} >Mar'x Makeup Artist Vendor</Link>
            </p>
            <p className='mangevendor-weddtab'>
              <Link to={'/'} >Mar'x Wardrobe Designing Vendor</Link>
            </p>
            <p className='mangevendor-weddtab'>
              <Link to={'/'} >Mar'x Hair Styling Vendor</Link>
            </p>
            <p className='mangevendor-weddtab'>
              <Link to={'/'} >Mar'x Wedding Cake Vendor</Link>
            </p>
          </div>
          <div className="weddvendortabpanels">
          </div>
        </Box>
      </div>
      <WeddingFooter />
    </>
  )
}

export default WeddingOurVendors





// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import { Box } from '@mui/material';
// import MarxWeddingHeader from '../../common/marxWedding/HeaderMarxWedding';
// import WeddingFooter from '../../common/marxWedding/FooterMarxWedding';
// import '../../styles/weddingOurVendorStyle.css';
// import GalleryWitthBioAllMax from '../../common/GalleryWitthBioAllMax/GalleryWitthBioAllMax';
// import IndModelMaleData from '../../data/marxEvent/ourModelbank/indian/indianModelMaleData';

// const WeddingOurVendors = () => {
//   // State to manage the selected vendor
//   const [selectedVendor, setSelectedVendor] = useState(null);

//   // Function to handle the click event of the vendor tabs
//   const handleVendorClick = (vendorName) => {
//     setSelectedVendor(vendorName);
//   };

//   return (
//     <>
//       <MarxWeddingHeader />
//       <div className="main-vendorweddingdiv">
//         <h1 className="h1tgg">Our Vendors</h1>
//         <Box className="mainweddvendor-box">
//           <div className="allweddvendortabs">
//             {/* Mapping through the list of vendors */}
//             {[
//               'Mehndi Wala',
//               'Ghodi Wala',
//               'Band Wala',
//               'Bagpiper Band',
//               'Wedding Card Vendor',
//               'Chopper service Vendor',
//               'Airplane service Vendor',
//               'Flowers Decor Vendor',
//               'Lightning Vendor',
//               'Sound Vendor',
//               'Stage Set-up Vendor',
//               'Catering Vendor',
//               'Gifts & Hamper Vendor',
//               'Photography Vendor',
//               'Videography Vendor',
//               'Astrologer Vendor',
//               'Luxury Car Vendor',
//               'Print Media Coverage Vendor',
//               'Electronic Media Coverage Vendor',
//               'Digital Media Vendor',
//               'Makeup Artist Vendor',
//               'Wardrobe Designing Vendor',
//               'Hair Styling Vendor',
//               'Wedding Cake Vendor',
//             ].map((vendor, index) => (
//               <p className="mangevendor-weddtab" key={index} onClick={() => handleVendorClick(vendor)}>
//                 {vendor}
//               </p>
//             ))}
//           </div>
//           <div className="weddvendortabpanels">
//             {/* Render the photo gallery based on the selected vendor */}
//             {selectedVendor && (
//               <div>
//                 {/* Here you would render the photo gallery component based on the selectedVendor state */}
//                 {/* Example: <PhotoGallery vendor={selectedVendor} /> */}
//                 {/* <h2>Photo Gallery for {selectedVendor}</h2> */}
//                 <GalleryWitthBioAllMax vendor={selectedVendor} heading={`Photo Gallery for ${selectedVendor}`} title={'Gallery'} detailpg={"wedding-vendors#"} ModelData={IndModelMaleData} />
//               </div>
//             )}
//           </div>
//         </Box>
//       </div>
//       <WeddingFooter />
//     </>
//   );
// };

// export default WeddingOurVendors;


