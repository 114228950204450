import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import '../../styles/marxAcademyHomeStyle.css'
import MarxAcademyHeader from "../../common/MarxAcademy/HeaderMarxAcademy";
import MarxAcademyFooter from "../../common/MarxAcademy/FooterMarxAcademy";
import RunningSlide from "../../common/RunningSlideAllMarx/RunningSlide";
import { useEffect } from "react";
import TopSliderAllMax from "../../common/TopSliderAllMarx/TopSliderAllMarx";
import AllDemoData from "../../data/AllDemoData";



const MarxAcademyHome = () => {
    useEffect(() => {
        document.title ="Mar'x Academy Of Fashion & Pageants Pvt.Ltd.";
      }, []);

    return (
        <>
            <MarxAcademyHeader />
            <div className="ventures">
                <h1>COMING SOON...</h1>
            </div>
            
            {/* <TopSliderAllMax title={"Product"} images={AllDemoData} />

            <div className="main-academydiv">
                <Box className="mainacademydiv-box">
                    <div className="allacademytabs">
                        <h5 className=" text-center fw-bold mb-4"> INDEX </h5>
                        <p className='mange-academytab'>
                            <Link to={'/'} >MARS </Link>
                        </p>
                    </div>
                    <div className="academytabpanels">
                        <div>
                            <div className="about-marx-academy">
                                <h1>About Us</h1>
                                <p></p>
                            </div>

                            <div className="about-marx-academy">
                                <h1>What We Do</h1>
                                <div>
                                    <p></p>
                                </div>
                            </div>

                            <div className="about-marx-academy">
                                <h1>Our Services</h1>
                                <div className="servcedivptag">
                                    <p></p>
                                </div>
                            </div>

                            <div className="about-marx-academy">
                                <h1>Who We Are</h1>
                                <div>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </div>

            <RunningSlide title="Product" images={AllDemoData}/>
            <RunningSlide title="Designing" images={AllDemoData}/> */}

            <MarxAcademyFooter />
        </>
    )
}

export default MarxAcademyHome
