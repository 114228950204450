import { Formik, Form, Field } from "formik";
import "./queryStyle.css";

// Messages
const required = "This field is required";
const maxLength = "Your input exceeds the maximum length";

// Error Component
const errorMessage = error => {
    return <div className="invalid-feedback">{error}</div>;
};

const validateFullName = value => {
    let error;
    if (!value) {
        error = required;
    }
    return error;
};

const validateCompanyName = value => {
    let error;
    if (!value) {
        error = required;
    }
    return error;
};

const validateEmail = value => {
    let error;
    if (!value) {
        error = required;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
        error = "Invalid email address";
    }
    return error;
};

const validatePhoneNumber = value => {
    let error;
    if (!value) {
        error = required;
    }
    return error;
};

const validateDescription = value => {
    let error;
    if (!value) {
        error = required;
    }
    return error;
};

const QueryForm = () => {
    return (
        <div className="queryform">
            <Formik
                initialValues={{
                    fullName: "",
                    companyName: "",
                    email: "",
                    phoneNumber: "",
                    description: ""
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                        console.log(JSON.stringify(values, null, 2));
                        setSubmitting(false);
                    }, 400);
                }}
            >
                {({ errors, touched }) => (
                    <div className="containerform">
                        <div className="col-sm-12">
                            <h3 className="h3tag">For Query</h3>
                        </div>
                        <div className="col-sm-12">
                            <Form>
                                <div className="form-group">
                                    <Field
                                        className="form-control"
                                        type="text"
                                        placeholder="Full Name"
                                        name="fullName"
                                        validate={validateFullName}
                                    />
                                    {errors.fullName && touched.fullName && errorMessage(errors.fullName)}
                                </div>
                                <div className="form-group">
                                    <Field
                                        className="form-control"
                                        type="text"
                                        placeholder="Company Name"
                                        name="companyName"
                                        validate={validateCompanyName}
                                    />
                                    {errors.companyName && touched.companyName && errorMessage(errors.companyName)}
                                </div>
                                <div className="form-group">
                                    <Field
                                        type="email"
                                        name="email"
                                        className="form-control"
                                        placeholder="Email"
                                        validate={validateEmail}
                                    />
                                    {errors.email && touched.email && errorMessage(errors.email)}
                                </div>
                                <div className="form-group">
                                    <Field
                                        className="form-control"
                                        type="tel"
                                        placeholder="Phone Number"
                                        name="phoneNumber"
                                        validate={validatePhoneNumber}
                                    />
                                    {errors.phoneNumber && touched.phoneNumber && errorMessage(errors.phoneNumber)}
                                </div>
                                <div className="form-group">
                                    <Field
                                        component="textarea"
                                        style={{height:'100px'}}
                                        className="form-control"
                                        placeholder="Description"
                                        name="description"
                                        validate={validateDescription}
                                    />
                                    {errors.description && touched.description && errorMessage(errors.description)}
                                </div>
                                <div className="form-group">
                                    <button className="btn btn-primary" type="submit">
                                        Submit
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </div>
                )}
            </Formik>
        </div>
    );
}

export default QueryForm;
