
import GalleryPhotos from "../../../common/Gallery/Gallery"
import WeddingFooter from "../../../common/marxWedding/FooterMarxWedding"
import MarxWeddingHeader from "../../../common/marxWedding/HeaderMarxWedding"
import SecondPicsData from "../../../data/marxEvent/SecondPicsData"

const WeddingSoundSetupDesigns = () => {
    return (
        <>
            <MarxWeddingHeader />
            <GalleryPhotos GalleryData={SecondPicsData} title={"Mar'x Sound Set-up Designs"} />
            <WeddingFooter />
        </>
    )
}

export default WeddingSoundSetupDesigns

