const WeddingServicesData = [
    {
        id: 1,
        name: "MAR'X Wedding cards",
    },
    {
        id: 2,
        name: "MAR'X Bagpiper Band",
    },
    {
        id: 3,
        name: "MAR'X Wedding Planner",
    },
    {
        id: 4,
        name: "MAR'X Wing Aircraft",
    },
    {
        id: 5,
        name: "MAR'X Wedding Decor",
    },
    {
        id: 6,
        name: "MAR'X Cocktail Organizers",
    },
    {
        id: 7,
        name: "MAR'X Sufi Night Organizers",
    },
    {
        id: 8,
        name: "MAR'X Reception Organizers",
    },
    {
        id: 9,
        name: "MAR'X Indian Theme weddings",
    },
    {
        id: 10,
        name: "MAR'X Holographic Weddings",
    },
    {
        id: 11,
        name: "MAR'X Waterfall Weddings City",
    },
    {
        id: 12,
        name: "MAR'X Hydraulic stage",
    },
    {
        id: 13,
        name: "MAR'X Ghazal Night Organizers",
    },
    {
        id: 14,
        name: "MAR'X Mehndi Night Organizers",
    }
    ,
    {
        id: 15,
        name: "MAR'X Sai - Sandhya Organizers",
    }
    ,
    {
        id: 16,
        name: "MAR'X Wedding venue",
    }
    ,
    {
        id: 17,
        name: "MAR'X Roka / Sagan Organizers",
    }
    ,
    {
        id: 18,
        name: "MAR'X Mata Ki Chowki Organizers",
    }
    ,
    {
        id: 19,
        name: "MAR'X Indian Cuisines for weddings",
    }
    ,
    {
        id: 20,
        name: "MAR'X International Theme weddings",
    }
    ,
    {
        id: 21,
        name: "MAR'X Luxury Mandap for weddings ",
    },
    {
        id: 22,
        name: "MAR'X Indian Celebrities for Weddings",
    },
    {
        id: 23,
        name: "MAR'X International Acts / Performers ",
    },
    {
        id: 24,
        name: "MAR'X Digital / Still photography",
    },
    {
        id: 25,
        name: " MAR'X International Cuisines for weddings ",
    },
    {
        id: 26,
        name: "MAR'X Luxury Cars for weddings ",
    },
    {
        id: 27,
        name: " MAR'X Dance Choreographers for weddings",
    },
    {
        id: 28,
        name: "MAR'X Star Night Performances for Weddings ",
    },
    {
        id: 29,
        name: "MAR'X Wedding Return Gifts / Merchandise ",
    },
    {
        id: 30,
        name: "MAR'X Print Media coverage / Live Electronic Media coverage / Live Social Media coverage",
    },
    {
        id: 31,
        name: "MAR'X International Celebrities for Weddings",
    }, {
        id: 32,
        name: "MAR'X Tranportation for weddings",
    },
    {
        id: 33,
        name: "MAR'X Logistics Managements for weddings",
    }
];

export default WeddingServicesData
 