import ourModelM1 from '../../../../assest/images/eventOurModelBank/india/Male/ourModelM1.jpeg'
import ourModelM2 from '../../../../assest/images/eventOurModelBank/india/Male/ourModelM2.jpeg'
import ourModelM3 from '../../../../assest/images/eventOurModelBank/india/Male/ourModelM3.jpeg'
import ourModelM4 from '../../../../assest/images/eventOurModelBank/india/Male/ourModelM4.jpeg'
import ourModelM5 from '../../../../assest/images/eventOurModelBank/india/Male/ourModelM5.jpeg'
import ourModelM7 from '../../../../assest/images/eventOurModelBank/india/Male/ourModelM7.jpeg'
import ourModelM8 from '../../../../assest/images/eventOurModelBank/india/Male/ourModelM8.jpeg'



const IndModelMaleData = [
  {
    id:1,
    src: ourModelM1,
    age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Ankit'
  },
  {
    id:2,
    src: ourModelM2,
    age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Digansh'
  },
  {
    id:3,
    src: ourModelM3,
    age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Lakshay'
  },
  {
    id:4,
    src: ourModelM4,
    age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Gaurav'
  },
  {
    id:5,
    src: ourModelM5,
    age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Digansh'
  },
  {
    id:7,
    src: ourModelM7,
    age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Bhumanyu'
  },
  {
    id:8,
    src: ourModelM8,
    age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Kabir'
  }
];

export default IndModelMaleData