import clebsFeatured1 from '../../assest/images/entt.clebsFeatured/clebsFeatured1.jpeg'
import clebsFeatured2 from '../../assest/images/entt.clebsFeatured/clebsFeatured2.jpeg'
import clebsFeatured3 from '../../assest/images/entt.clebsFeatured/clebsFeatured3.jpeg'
import clebsFeatured4 from '../../assest/images/entt.clebsFeatured/clebsFeatured4.jpeg'
import clebsFeatured5 from '../../assest/images/entt.clebsFeatured/clebsFeatured5.jpeg'
import clebsFeatured6 from '../../assest/images/entt.clebsFeatured/clebsFeatured6.jpeg'
import clebsFeatured7 from '../../assest/images/entt.clebsFeatured/clebsFeatured7.jpeg'
import clebsFeatured8 from '../../assest/images/entt.clebsFeatured/clebsFeatured8.jpeg'
import clebsFeatured9 from '../../assest/images/entt.clebsFeatured/clebsFeatured9.jpeg'
import clebsFeatured10 from '../../assest/images/entt.clebsFeatured/clebsFeatured10.jpeg'
import clebsFeatured11 from '../../assest/images/entt.clebsFeatured/clebsFeatured11.jpeg'
import clebsFeatured12 from '../../assest/images/entt.clebsFeatured/clebsFeatured12.jpeg'
import clebsFeatured13 from '../../assest/images/entt.clebsFeatured/clebsFeatured13.jpeg'
import clebsFeatured14 from '../../assest/images/entt.clebsFeatured/clebsFeatured14.jpeg'
import clebsFeatured15 from '../../assest/images/entt.clebsFeatured/clebsFeatured15.jpeg'
import clebsFeatured16 from '../../assest/images/entt.clebsFeatured/clebsFeatured16.jpeg'
import clebsFeatured17 from '../../assest/images/entt.clebsFeatured/clebsFeatured17.jpeg'
import clebsFeatured18 from '../../assest/images/entt.clebsFeatured/clebsFeatured18.jpeg'
import clebsFeatured19 from '../../assest/images/entt.clebsFeatured/clebsFeatured19.jpeg'
import clebsFeatured20 from '../../assest/images/entt.clebsFeatured/clebsFeatured20.jpeg'
import clebsFeatured21 from '../../assest/images/entt.clebsFeatured/clebsFeatured21.jpeg'
import clebsFeatured22 from '../../assest/images/entt.clebsFeatured/clebsFeatured22.jpeg'
import clebsFeatured23 from '../../assest/images/entt.clebsFeatured/clebsFeatured23.jpeg'
import clebsFeatured24 from '../../assest/images/entt.clebsFeatured/clebsFeatured24.jpeg'
import clebsFeatured25 from '../../assest/images/entt.clebsFeatured/clebsFeatured25.jpeg'




const ClebsFeatureData = [
    {
        id: 1,
        imgSrc: clebsFeatured1,
    },
    {
        id: 2,
        imgSrc: clebsFeatured2,
    },
    {
        id: 3,
        imgSrc: clebsFeatured3,
    }, {
        id: 4,
        imgSrc: clebsFeatured4,
    }, {
        id: 5,
        imgSrc: clebsFeatured5,
    }, {
        id: 6,
        imgSrc:clebsFeatured6,
    }, {
        id: 7,
        imgSrc:clebsFeatured7,
    }, {
        id: 8,
        imgSrc:clebsFeatured8,
    }, {
        id: 9,
        imgSrc:clebsFeatured9,
    }, {
        id: 10,
        imgSrc:clebsFeatured10,
    },{
        id: 11,
        imgSrc:clebsFeatured11,
    },{
        id: 12,
        imgSrc:clebsFeatured12,
    },{
        id: 13,
        imgSrc:clebsFeatured13,
    },{
        id: 14,
        imgSrc:clebsFeatured14,
    },{
        id: 15,
        imgSrc:clebsFeatured15,
    },{
        id: 16,
        imgSrc:clebsFeatured16,
    },{
        id: 17,
        imgSrc:clebsFeatured17,
    },{
        id: 18,
        imgSrc:clebsFeatured18,
    },{
        id: 19,
        imgSrc:clebsFeatured19,
    },{
        id: 20,
        imgSrc:clebsFeatured20,
    },{
        id: 21,
        imgSrc:clebsFeatured21,
    },{
        id: 22,
        imgSrc:clebsFeatured22,
    },{
        id: 23,
        imgSrc:clebsFeatured23,
    },{
        id: 24,
        imgSrc:clebsFeatured24,
    },{
        id: 25,
        imgSrc:clebsFeatured25,
    }
];
export default ClebsFeatureData;