import { Box, Button } from "@mui/material"
import '../../styles/clientStyle.css'
import { useEffect, useState } from "react"
import axios from "axios"
import Sidebar from "../../common/SideAbleMenu/SideBar"
import MarxGroupHeader from "../../common/marxGroup/MarxGroupHeader"
import MarxGroupFooter from "../../common/marxGroup/MarxGroupFooter"

const OurClients = () => {
    const [client, setClient] = useState()

    useEffect(() => {
        const getClient = async () => {
            const reqClient = await axios.get('https://onlineparttimejobs.in/api/sellerList/public', {
                withCredentials: true
            })
            console.log('client', reqClient.data);
            setClient(reqClient.data)
        }
        getClient();
    }, [])

    const [showCount, setShowCount] = useState(24);
    const [showAll, setShowAll] = useState(false);

    const toggleImages = () => {
        if (!showAll) {
            const newShowCount = Math.min(showCount + 24, client.length);
            setShowCount(newShowCount);
            if (newShowCount === client.length) {
                setShowAll(true);
            }
        } else {
            setShowCount(showCount === 24 ? 3 : 24);
            setShowAll(false);
        }
    };

    return (
        <>
            <Sidebar />
            <MarxGroupHeader />
            <Box>
                <h2 className=" text-center m-5 fw-bold text-uppercase">Our Clients</h2>
                <div className=" d-flex flex-wrap justify-content-center">

                    {client?.slice(0, showCount).map((items) => {
                        return (
                            <div className="client-box">
                                <div className="imglogo">
                                    <img src="https://www.influglue.com/files/medialibrary/bde6b51ab9293f63ae6652a3db538716.jpg" alt="img" />
                                </div>
                                <div>
                                    <h3>{items?.firstname} {items?.lastname}</h3>
                                </div>
                                <div className="img-box">
                                    <img src="https://www.influglue.com/files/medialibrary/bcc22d7fea19389cefc2e519fd6ce7b6.JPG" alt="img" />
                                </div>

                            </div>
                        )
                    })}
                </div>
                <div className="btn-div-bottom">
                    <Button className="" variant="contained" onClick={toggleImages}>
                        {showAll ? "Show Less" : "Show More"}
                    </Button>
                </div>
            </Box>
            <MarxGroupFooter />
        </>
    )
}

export default OurClients
