import Sidebar from '../../common/SideAbleMenu/SideBar'
import MarxGroupFooter from '../../common/marxGroup/MarxGroupFooter'
import MarxGroupHeader from '../../common/marxGroup/MarxGroupHeader'
import '../../styles/privacypolicyStyle.css'

const PrivacyPolicy = () => {
  return (
    <>
      <Sidebar />
      <MarxGroupHeader />
      <div className="privacy-text">
        <div className="div-privacy">
          <h2>Privacy Policy</h2>
        </div>
        <div className="textt">
          <h4>General</h4>
          <p>We, at Abaris, are committed to protecting your privacy. We have prepared this Privacy Policy to describe to you our practices regarding the personal data we collect from users of our website.</p>
          <p>By using the Services, users consent to the collection and use of their Personal Data by us. You also represent to us that you have any and all authorizations necessary to use these Services including using them to process Personal Data. We collect and use the information you provide to us, including information obtained from your use of the Services. Also, we may use the information that we collect for our internal purposes to develop, tune, enhance, and improve our Services, and for advertising and marketing consistent with this Privacy Policy.</p>
          <p>This privacy policy has been compiled to better serve those who are concerned with how their 'Personal Data’ is being used online. Personal Information means data which relate to a living individual who can be identified – (a) from those data, or (b) from those data and other information which is in the possession of, or is likely to come into the possession of, the data controller, and includes any expression of opinion about the individual and any indication of the intentions of the data controller or any other person in respect of the individual</p>
          <p>Please read our privacy policy carefully to get a clear understanding of how our website collects, uses, protects or otherwise handles users’ Personal Data.</p>
          <p>This Privacy Policy is intended to inform users about how our website treats Personal Data that it processes about users. If users do not agree to any part of this Privacy Policy, then we cannot provide its Services to users and users should stop accessing our services.</p>
          <p>By using the Services, You acknowledge, consent and agree that we may collect, process, and use the information that you provide to us and that such information shall only be used by us or third parties acting under our direction, pursuant to confidentiality agreements, to develop, tune, enhance, and improve the Services. Although we may attempt to notify you when changes are made to this Privacy Policy, you are responsible for periodically reviewing any changes which may be made to the Policy. We may, in our sole discretion, modify or revise the Policy at any time, and you agree to be bound by the same.</p>
          <p>Our privacy policy is subject to change at any time without notice. To make sure you are aware of any changes, please review this policy periodically.</p>
        </div>
        <div className="textt">
          <h4>Information We Collect</h4>
          <p>You provide us information about yourself – Your Full Name, Username, E-mail Address. If you correspond with us by e-mail, we may retain the content of your e-mail messages, your e-mail address, and our responses. Additionally, we store information about users’ contacts when users manually enter contact e-mail addresses or transfer contact information from other online social networks. We also collect general information about your use of our services.</p>
        </div>
        <div className="textt">
          <p>*NOTE* : This e-mail and any files transmitted with it are for the sole
            use of the intended Recipient(s) and may contain confidential and
            privileged information. If you are not the intended Recipient, please
            contact the sender by reply e-mail and destroy all copies and the original
            message. Any unauthorized review, use, disclosure, dissemination,
            forwarding, printing or copying of this email or any action taken in faith,
            confidence or trust on this e-mail is strictly prohibited and may be
            unlawful. Before opening any attachments please check them for viruses and
            defects & all the photographs & P.D.F files here in this email are
            protected under copyright act and cannot be Re - produced anywhere in any
            form / Manner in print or website, without the consent & written permission
            from Mar’x Events .Pvt.Ltd. / Mar'x Group of Companies.(M.G.C) Management , as
            they are strictly
            against law . We have emailed them to you because they are part of our work
            & you can see them and consider us for your new projects / work etc....!!</p>
        </div>
      </div>
      <MarxGroupFooter />
    </>
  )
}

export default PrivacyPolicy
