
import MarxMediaHeader from "../../../common/MarxMedia/MarxMediaHeader/MarxMediaHeader"
import MediaFooter from "../../../common/MarxMedia/MarxMediaFooter/MarxMediaFooter"
import GalleryWithNameData from "../../../data/GalleryWithNameData"
import GalleryPhotosWithName from "../../../common/GalleryWithName/GalleryWithName"

const MediaPromotions = () => {
  return (
    <>
      <MarxMediaHeader />
      <GalleryPhotosWithName GalleryInfo={GalleryWithNameData} title={'Promotions'} />
      <MediaFooter />
    </>
  )
}

export default MediaPromotions
