import { Box, Button } from "@mui/material"
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom"
import logo from '../../assest/images/marxacedmy.jpeg'
import './headerMarxAcademyStyle.css'
import { GoHomeFill } from "react-icons/go";


const MarxAcademyHeader = () => {
  const { t } = useTranslation();
  return (
    <>
      <Box className=''>
        <div className="academy-topdiv-barbox">
          <div className="academy-marxghome">
            <Link to='/marx-group' className=""> <Button size="small" className=" bg-white text-black p-1"><GoHomeFill className=' fs-5' /></Button></Link>
          </div>

        </div>

        <div className="academy-navbar">
          <div className="academy-nav-baar">
            <div className="logo">
              <Link to={'/marx-academy'}>
                <img
                  className="academy-logo"
                  src={logo}
                  alt="logo"
                />
              </Link>
            </div>

            <div className="academy-profilename">
              <h1><span className="span1">MAR’X</span> <span className="span2">ACADEMY OF </span> <span className="span3"> FASHION & PAGEANTS </span> <span className="span4"> PVT. LTD. </span></h1>
              <div className="stylistp">
                <p>(The School of Fashion And Grooming)</p>
              </div>
            </div>
          </div>
        </div>


      </Box>

    </>
  )
}

export default MarxAcademyHeader
