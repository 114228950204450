import eventLiveShow1 from '../../assest/images/eventLiveShow/eventLiveShow1.jpeg'
import eventLiveShow2 from '../../assest/images/eventLiveShow/eventLiveShow2.jpeg'
import eventLiveShow3 from '../../assest/images/eventLiveShow/eventLiveShow3.jpeg'
import eventLiveShow4 from '../../assest/images/eventLiveShow/eventLiveShow4.jpeg'
import eventLiveShow5 from '../../assest/images/eventLiveShow/eventLiveShow5.jpeg'
import eventLiveShow6 from '../../assest/images/eventLiveShow/eventLiveShow6.jpeg'
import eventLiveShow7 from '../../assest/images/eventLiveShow/eventLiveShow7.jpeg'
import eventLiveShow8 from '../../assest/images/eventLiveShow/eventLiveShow8.jpeg'
import eventLiveShow9 from '../../assest/images/eventLiveShow/eventLiveShow9.jpeg'
import eventLiveShow10 from '../../assest/images/eventLiveShow/eventLiveShow10.jpeg'
import eventLiveShow11 from '../../assest/images/eventLiveShow/eventLiveShow11.jpeg'
import eventLiveShow12 from '../../assest/images/eventLiveShow/eventLiveShow12.jpeg'
import eventLiveShow13 from '../../assest/images/eventLiveShow/eventLiveShow13.jpeg'
import eventLiveShow14 from '../../assest/images/eventLiveShow/eventLiveShow14.jpeg'
import eventLiveShow15 from '../../assest/images/eventLiveShow/eventLiveShow15.jpeg'
import eventLiveShow16 from '../../assest/images/eventLiveShow/eventLiveShow16.jpeg'
import eventLiveShow17 from '../../assest/images/eventLiveShow/eventLiveShow17.jpeg'
import eventLiveShow18 from '../../assest/images/eventLiveShow/eventLiveShow18.jpeg'
import eventLiveShow19 from '../../assest/images/eventLiveShow/eventLiveShow19.jpeg'
import eventLiveShow20 from '../../assest/images/eventLiveShow/eventLiveShow20.jpeg'
import eventLiveShow21 from '../../assest/images/eventLiveShow/eventLiveShow21.jpeg'
import eventLiveShow22 from '../../assest/images/eventLiveShow/eventLiveShow22.jpeg'
import eventLiveShow23 from '../../assest/images/eventLiveShow/eventLiveShow23.jpeg'
import eventLiveShow24 from '../../assest/images/eventLiveShow/eventLiveShow24.jpeg'
import eventLiveShow25 from '../../assest/images/eventLiveShow/eventLiveShow25.jpeg'
import eventLiveShow26 from '../../assest/images/eventLiveShow/eventLiveShow26.jpeg'
import eventLiveShow27 from '../../assest/images/eventLiveShow/eventLiveShow27.jpeg'



const LiveShowGalleryData = [
    {
        id: 1,
        imgSrc: eventLiveShow1,
    },
    {
        id: 2,
        imgSrc: eventLiveShow2,
    },
    {
        id: 3,
        imgSrc: eventLiveShow3,
    }, {
        id: 4,
        imgSrc: eventLiveShow4,
    }, {
        id: 5,
        imgSrc: eventLiveShow5,
    }, {
        id: 6,
        imgSrc:eventLiveShow6,
    }, {
        id: 7,
        imgSrc:eventLiveShow7,
    }, {
        id: 8,
        imgSrc:eventLiveShow8,
    }, {
        id: 9,
        imgSrc:eventLiveShow9,
    }, {
        id: 10,
        imgSrc:eventLiveShow10,
    },{
        id: 11,
        imgSrc:eventLiveShow11,
    },{
        id: 12,
        imgSrc:eventLiveShow12,
    },{
        id: 13,
        imgSrc:eventLiveShow13,
    },{
        id: 14,
        imgSrc:eventLiveShow14,
    },{
        id: 15,
        imgSrc:eventLiveShow15,
    },{
        id: 16,
        imgSrc:eventLiveShow16,
    },{
        id: 17,
        imgSrc:eventLiveShow17,
    },{
        id: 18,
        imgSrc:eventLiveShow18,
    },{
        id: 19,
        imgSrc:eventLiveShow19,
    },{
        id: 20,
        imgSrc:eventLiveShow20,
    },{
        id: 21,
        imgSrc:eventLiveShow21,
    },{
        id: 22,
        imgSrc:eventLiveShow22,
    },{
        id: 23,
        imgSrc:eventLiveShow23,
    },{
        id: 24,
        imgSrc:eventLiveShow24,
    },{
        id: 25,
        imgSrc:eventLiveShow25,
    },{
        id: 26,
        imgSrc:eventLiveShow26,
    },{
        id: 27,
        imgSrc:eventLiveShow27,
    }
];
export default LiveShowGalleryData;