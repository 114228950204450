import media1 from '../../assest/images/MediaProductBrandingPromotion/media1.jpeg'
import media2 from '../../assest/images/MediaProductBrandingPromotion/media2.jpeg'
import media3 from '../../assest/images/MediaProductBrandingPromotion/media3.jpeg'
import media4 from '../../assest/images/MediaProductBrandingPromotion/media4.jpeg'
import media5 from '../../assest/images/MediaProductBrandingPromotion/media5.jpeg'
import media6 from '../../assest/images/MediaProductBrandingPromotion/media6.jpeg'
import media7 from '../../assest/images/MediaProductBrandingPromotion/media7.jpeg'
import media8 from '../../assest/images/MediaProductBrandingPromotion/media8.jpeg'
import media9 from '../../assest/images/MediaProductBrandingPromotion/media9.jpeg'
import media10 from '../../assest/images/MediaProductBrandingPromotion/media10.jpeg'
import media11 from '../../assest/images/MediaProductBrandingPromotion/media11.jpeg'
import media12 from '../../assest/images/MediaProductBrandingPromotion/media12.jpeg'
import media13 from '../../assest/images/MediaProductBrandingPromotion/media13.jpeg'
import media14 from '../../assest/images/MediaProductBrandingPromotion/media14.jpeg'
import media15 from '../../assest/images/MediaProductBrandingPromotion/media15.jpeg'
import media16 from '../../assest/images/MediaProductBrandingPromotion/media16.jpeg'
import media17 from '../../assest/images/MediaProductBrandingPromotion/media17.jpeg'



const TopMarxMediaSlideData = [
    {
        id: 1,
        imgSrc: media1,
    },
    {
        id: 2,
        imgSrc: media2,
    },
    {
        id: 3,
        imgSrc: media3,
    }, {
        id: 4,
        imgSrc: media4,
    }, {
        id: 5,
        imgSrc: media5,
    }, {
        id: 6,
        imgSrc:media6,
    }, {
        id: 7,
        imgSrc:media7,
    }, {
        id: 8,
        imgSrc:media8,
    }, {
        id: 9,
        imgSrc:media9,
    }, {
        id: 10,
        imgSrc:media10,
    },{
        id: 11,
        imgSrc:media11,
    },{
        id: 12,
        imgSrc:media12,
    },{
        id: 13,
        imgSrc:media13,
    },{
        id: 14,
        imgSrc:media14,
    },{
        id: 15,
        imgSrc:media15,
    },{
        id: 16,
        imgSrc:media16,
    },{
        id: 17,
        imgSrc:media17,
    }
];
export default TopMarxMediaSlideData;