import './resetPasswordStyle.css'
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";


const ResetPassword = () => {
    const navigate = useNavigate()
    function update() {
        navigate('/marx-group-login')
    }
    return (
        <>

            <div className="forgotpassword">
                <div className="card d-margin">
                    <div>
                        <h1 className="ph-center">Reset password</h1>
                    </div>
                    <hr></hr>

                    <form>
                        <div className="margin-everywhere">
                            <div className="inp-div">
                                <label className="margin-everywhere" htmlFor="exampleInputPassword1">
                                    <b>New Password</b>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Password"
                                ></input>
                            </div>

                            <div className="inp-div">
                                <label className="margin-everywhere" htmlFor="exampleInputPassword1">
                                    <b>Confirm Password</b>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Password"
                                ></input>
                            </div>
                            <Button fullWidth onClick={update} type="button" className="btnbtn">
                                 Reset Password
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ResetPassword
