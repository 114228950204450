
import GalleryPhotos from "../../../common/Gallery/Gallery"
import Footer from "../../../common/MarxEntertainment/footer/Footer"
import Header from "../../../common/MarxEntertainment/header/Header"
import Sidebar from "../../../common/SideAbleMenu/SideBar"
import SecondPicsData from "../../../data/marxEvent/SecondPicsData"

const EntertainmentGujratiTalent = () => {
    return (
        <>
            <Sidebar />
            <Header />
            <GalleryPhotos GalleryData={SecondPicsData} title={"MAR'X GUJARATI TALENT "} />
            <Footer />
        </>
    )
}

export default EntertainmentGujratiTalent
