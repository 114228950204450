import { HiViewGrid } from "react-icons/hi";
import { IoMenuOutline } from "react-icons/io5";
import "./style.css";
import { useEffect, useState } from "react";

const Sort = ({ onGridClick, onMenuClick, grid, data }) => {

  const [sortBy, setSortBy] = useState("highest price");
  const [sortedData, setSortedData] = useState([]);

  const sortData = () => {
    let sorted = [...data];
    if (sortBy === "lowest price") {
      sorted.sort((a, b) => a.price - b.price);
      console.log("lowest price",sorted.sort((a, b) => a.price - b.price));
    } else if (sortBy === "highest price") {
      sorted.sort((a, b) => b.price - a.price);
      console.log("highest price", sorted.sort((a, b) => b.price - a.price));
    } else if (sortBy === "a-z") {
      sorted.sort((a, b) => a.name.localeCompare(b.name));
      console.log("a-z", sorted.sort((a, b) => a.name.localeCompare(b.name)));
    } else if (sortBy === "z-a") {
      sorted.sort((a, b) => b.name.localeCompare(a.name));
      console.log("z-a",sorted.sort((a, b) => b.name.localeCompare(a.name)));
    }
    setSortedData(sorted);
  };

  useEffect(() => {
    sortData();
  }, [sortBy, data]);


  const handleChange = (e) => {
    setSortBy(e.target.value);
  };

  return (
    <>
      <div className="stylediv">
        <div>
          <HiViewGrid
            onClick={onGridClick}
            className={`menuicon ${grid ? "active" : ""}`}
          />
          <IoMenuOutline
            onClick={onMenuClick}
            className={`menuicon ${!grid ? "active" : ""}`}
          />
        </div>
        <div>{`${sortedData.length}`} Total Products</div>
        <div>
          <select value={sortBy} onChange={handleChange}>
            <option value="lowest price">lowest price</option>
            <option value="highest price">highest price</option>
            <option value="a-z">A-Z</option>
            <option value="z-a">Z-A</option>
          </select>
        </div>
      </div>
    </>
  );
};

export default Sort;