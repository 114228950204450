import mediaoutdoorMarket1 from '../../assest/images/mediaOutdoorBrandingAndMarketing/mediaoutdoorMarket1.jpeg'
import mediaoutdoorMarket2 from '../../assest/images/mediaOutdoorBrandingAndMarketing/mediaoutdoorMarket2.jpeg'
import mediaoutdoorMarket3 from '../../assest/images/mediaOutdoorBrandingAndMarketing/mediaoutdoorMarket3.jpeg'
import mediaoutdoorMarket4 from '../../assest/images/mediaOutdoorBrandingAndMarketing/mediaoutdoorMarket4.jpeg'
import mediaoutdoorMarket5 from '../../assest/images/mediaOutdoorBrandingAndMarketing/mediaoutdoorMarket5.jpeg'
import mediaoutdoorMarket6 from '../../assest/images/mediaOutdoorBrandingAndMarketing/mediaoutdoorMarket6.jpeg'
import mediaoutdoorMarket7 from '../../assest/images/mediaOutdoorBrandingAndMarketing/mediaoutdoorMarket7.jpeg'
import mediaoutdoorMarket8 from '../../assest/images/mediaOutdoorBrandingAndMarketing/mediaoutdoorMarket8.jpeg'
import mediaoutdoorMarket9 from '../../assest/images/mediaOutdoorBrandingAndMarketing/mediaoutdoorMarket9.jpeg'
import mediaoutdoorMarket10 from '../../assest/images/mediaOutdoorBrandingAndMarketing/mediaoutdoorMarket10.jpeg'
import mediaoutdoorMarket11 from '../../assest/images/mediaOutdoorBrandingAndMarketing/mediaoutdoorMarket11.jpeg'
import mediaoutdoorMarket12 from '../../assest/images/mediaOutdoorBrandingAndMarketing/mediaoutdoorMarket12.jpeg'
import mediaoutdoorMarket13 from '../../assest/images/mediaOutdoorBrandingAndMarketing/mediaoutdoorMarket13.jpeg'
import mediaoutdoorMarket14 from '../../assest/images/mediaOutdoorBrandingAndMarketing/mediaoutdoorMarket14.jpeg'
import mediaoutdoorMarket15 from '../../assest/images/mediaOutdoorBrandingAndMarketing/mediaoutdoorMarket15.jpeg'
import mediaoutdoorMarket16 from '../../assest/images/mediaOutdoorBrandingAndMarketing/mediaoutdoorMarket16.jpeg'
import mediaoutdoorMarket17 from '../../assest/images/mediaOutdoorBrandingAndMarketing/mediaoutdoorMarket17.jpeg'
import mediaoutdoorMarket18 from '../../assest/images/mediaOutdoorBrandingAndMarketing/mediaoutdoorMarket18.jpeg'
import mediaoutdoorMarket19 from '../../assest/images/mediaOutdoorBrandingAndMarketing/mediaoutdoorMarket19.jpeg'
import mediaoutdoorMarket20 from '../../assest/images/mediaOutdoorBrandingAndMarketing/mediaoutdoorMarket20.jpeg'
import mediaoutdoorMarket21 from '../../assest/images/mediaOutdoorBrandingAndMarketing/mediaoutdoorMarket21.jpeg'
import mediaoutdoorMarket22 from '../../assest/images/mediaOutdoorBrandingAndMarketing/mediaoutdoorMarket22.jpeg'
import mediaoutdoorMarket23 from '../../assest/images/mediaOutdoorBrandingAndMarketing/mediaoutdoorMarket23.jpeg'
import mediaoutdoorMarket24 from '../../assest/images/mediaOutdoorBrandingAndMarketing/mediaoutdoorMarket24.jpeg'
import mediaoutdoorMarket25 from '../../assest/images/mediaOutdoorBrandingAndMarketing/mediaoutdoorMarket25.jpeg'
import mediaoutdoorMarket26 from '../../assest/images/mediaOutdoorBrandingAndMarketing/mediaoutdoorMarket26.jpeg'
import mediaoutdoorMarket27 from '../../assest/images/mediaOutdoorBrandingAndMarketing/mediaoutdoorMarket27.jpeg'
import mediaoutdoorMarket28 from '../../assest/images/mediaOutdoorBrandingAndMarketing/mediaoutdoorMarket28.jpeg'
import mediaoutdoorMarket29 from '../../assest/images/mediaOutdoorBrandingAndMarketing/mediaoutdoorMarket29.jpeg'
import mediaoutdoorMarket30 from '../../assest/images/mediaOutdoorBrandingAndMarketing/mediaoutdoorMarket30.jpeg'

const MediaOutDoorMarketingData = [
    {
        id:1,
        imgSrc: mediaoutdoorMarket1,
    },
    {
        id:2,
        imgSrc: mediaoutdoorMarket2,
    },
    {
        id:3,
        imgSrc: mediaoutdoorMarket3,
    }, {
        id:4,
        imgSrc: mediaoutdoorMarket4,
    }, {
        id:5,
        imgSrc: mediaoutdoorMarket5,
    }, {
        id:6,
        imgSrc: mediaoutdoorMarket6,
    }, {
        id:7,
        imgSrc: mediaoutdoorMarket7,
    }, {
        id:8,
        imgSrc: mediaoutdoorMarket8,
    }, {
        id:9,
        imgSrc: mediaoutdoorMarket9,
    }, {
        id:10,
        imgSrc: mediaoutdoorMarket10,
    },{
        id:11,
        imgSrc: mediaoutdoorMarket11,
    },{
        id:12,
        imgSrc: mediaoutdoorMarket12,
    },{
        id:13,
        imgSrc: mediaoutdoorMarket13,
    },{
        id:14,
        imgSrc: mediaoutdoorMarket14,
    },{
        id:15,
        imgSrc: mediaoutdoorMarket15,
    },{
        id:16,
        imgSrc: mediaoutdoorMarket16,
    },{
        id:17,
        imgSrc: mediaoutdoorMarket17,
    },{
        id:18,
        imgSrc: mediaoutdoorMarket18,
    },{
        id:19,
        imgSrc: mediaoutdoorMarket19,
    },{
        id:20,
        imgSrc: mediaoutdoorMarket20,
    },{
        id:21,
        imgSrc: mediaoutdoorMarket21,
    },{
        id:22,
        imgSrc: mediaoutdoorMarket22,
    },{
        id:23,
        imgSrc: mediaoutdoorMarket23,
    },{
        id:24,
        imgSrc: mediaoutdoorMarket24,
    },{
        id:25,
        imgSrc: mediaoutdoorMarket25,
    },{
        id:26,
        imgSrc: mediaoutdoorMarket26,
    },{
        id:27,
        imgSrc: mediaoutdoorMarket27,
    },{
        id:28,
        imgSrc: mediaoutdoorMarket28,
    },{
        id:29,
        imgSrc: mediaoutdoorMarket29,
    },{
        id:30,
        imgSrc: mediaoutdoorMarket30,
    }
];
export default MediaOutDoorMarketingData