import comingsoon1 from '../../assest/images/comingsoon1.jpeg'




const SecondPicsData = [
    {
        id: 1,
        src: comingsoon1,
    },
    {
        id: 2,
        src: comingsoon1,
    },
    {
        id: 3,
        src: comingsoon1,
    }, {
        id: 4,
        src: comingsoon1,
    }, {
        id: 5,
        src: comingsoon1,
    }, {
        id: 6,
        src:comingsoon1,
    }, {
        id: 7,
        src:comingsoon1,
    }, {
        id: 8,
        src:comingsoon1,
    }, {
        id: 9,
        src:comingsoon1,
    }, {
        id: 10,
        src:comingsoon1,
    },{
        id: 11,
        src:comingsoon1,
    },{
        id: 12,
        src:comingsoon1,
    },{
        id: 13,
        src:comingsoon1,
    },{
        id: 14,
        src:comingsoon1,
    }
];
export default SecondPicsData;