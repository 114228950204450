
import GalleryPhotos from '../../../common/Gallery/Gallery'
import SecondPicsData from '../../../data/marxEvent/SecondPicsData'
import MarxRealEstateHeader from '../../../common/MarxRealEstate/HeaderMarxRealEstate'
import MarxRealEstateFooter from '../../../common/MarxRealEstate/FooterMarxRealEstate'

const RealEstateSchoolLands = () => {
    return (
        <>
            <MarxRealEstateHeader />
            <GalleryPhotos  GalleryData={SecondPicsData} title={'School Lands'}/>
            <MarxRealEstateFooter />
        </>
    )
}

export default RealEstateSchoolLands
