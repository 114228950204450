import { configureStore } from "@reduxjs/toolkit";
import { postApi } from "../Services/Post";
import { setupListeners } from "@reduxjs/toolkit/query";
import { ProductApi } from "../Services/productApi";

//add services to store
export const store = configureStore({
    reducer: {
        [postApi.reducerPath]: postApi.reducer,
        [ProductApi.reducerPath]: ProductApi.reducer
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware().concat(postApi.middleware, ProductApi.middleware)
    }
});
setupListeners(store.dispatch); 


