import { Link } from "react-router-dom"
import WeddingFooter from "../../common/marxWedding/FooterMarxWedding"
import MarxWeddingHeader from "../../common/marxWedding/HeaderMarxWedding"
import WeddingServicesData from '../../data/weddingServicesData'
import '../../styles/ServicesWeddingStyle.css'

const ServicesWedding = () => {
  return (
    <>
    <MarxWeddingHeader/>
      
    <div className="weddserviceBlock">
        <div className="mainweddservice">
          <div className="mainweddserviceblock">
            <h2>Our Services</h2>
            <div className="inner-weddservice-block">
              {WeddingServicesData?.map((item) => {
                return (
                  <div className="block" key={item.id}>
                    <Link to={`${item.id}`}>
                      <h6>{item.name}</h6>
                    </Link>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <WeddingFooter/>
    </>
  )
}

export default ServicesWedding
