import norah from '../assest/images/norah.jpeg'
import media2 from '../assest/images/MediaProductBrandingPromotion/media2.jpeg'
import enttCelebs10 from '../assest/images/entt.CelebsGallery/enttCelebs10.jpeg'
import weddDecorPhotography17 from '../assest/images/weddDecorGallery/weddDecorPhotography17.jpeg'
import media1 from '../assest/images/MediaProductBrandingPromotion/media1.jpeg'
import Outdoor from '../assest/images/Outdoor&OOH Branding.jpeg'
import LiveConcert from '../assest/images/LiveConcert.jpeg'
import Fabrication from '../assest/images/Fabrication&Stalls.jpeg'
import ExhibitionDesigning from '../assest/images/ExhibitionDesigning.jpeg'
import Clothing from '../assest/images/Clothing Brand.jpeg'
import ngo from '../assest/images/ngo.jpeg'
import FashionShows from '../assest/images/FashionShows.jpeg'
import productlaunchevent from '../assest/images/productlaunchevent.jpeg'
import grandOpening from '../assest/images/grandOpening.jpeg'
import awardnight from '../assest/images/awardnight.jpeg'
import pressevent from '../assest/images/pressevent.jpeg'
import corporateEvent from '../assest/images/corporateEvent.jpeg'
import dealerInvestormeet from '../assest/images/dealerInvestormeet.jpeg'
import virtualeventmanagement from '../assest/images/virtualeventmanagement.jpeg'
import eventsponsor from '../assest/images/eventsponsor.jpeg'
import fmcg from '../assest/images/fmcg.jpeg'
import fashionshow1 from '../assest/images/fashionshow1.jpeg'
import socialEvent from '../assest/images/socialEvent.jpeg'

const OurServiceData = [
    {
        imgSrc: corporateEvent,
        department: "Corporate Events",
        description: "Through understanding a company's unique brand and identifying their target audience, our team designs an event concept and coordinates all technical aspects before the event begins.we provide you with exclusive and amazing venues from all over India where you'll find all the luxurious amenities and all types of world class cuisine. We promise you the exquisite & splendid time for your delegates, business associates, & employees."
    },
    {
        imgSrc: norah,
        department: "Entertainment | Artist Management",
        description: "SKY Events have years of experience and extensive database of talented celebrity performers to suit all of  your entertainment needs.From Celebrity Singers, Musicians, Folk Singers, Dancers, Actors and reality show contestants, Rock Bands, as well as chart topping  stand up comedians, our priority is to find the most unique and exceptional  act to suit you and your event. We handle Corporate, Concerts, College events and provide incredible artists,  at best cost."
    },
    {
        imgSrc:productlaunchevent,
        department: "Product Launch Events",
        description: " At Sky Events we understand the importance of first impressions and the need for a high impact event that introduces your product launch in style.Our dedicated events team are experienced in all elements of launch events delivery, so you can concentrate  on unveiling the product and leave the planning, logistics, themes, product launch idea, Celebrity Presence, Media Coverages and venue management to us.  "
    },{
        imgSrc: media2,
        department: "Brand Integration",
        description: "Product Placement is the process through which our company integrates a product into a selected production for clear visibility. Although the product is visible, it is often not the focus, as it needs to fit almost seamlessly into the context of a scene or story. Types of placements: Visual, Verbal and Signage.  Brand Integration is an amplified type of product placement in which the brand is interwoven within the script.Call us for Brand Integration in Movies / TV Serials / Music Videos / OTT Web Series & Radio."
    }, {
        imgSrc:enttCelebs10,
        department: "Celebrity Endorsements",
        description: " Our celebrity partners increase consumer engagement and sales through implied endorsement. Imagine your product next to a brand-appropriate celebrity in your upcoming  TV Commercials, Print Campaigns, Promotions, Personal Appearances, Speaking Engagements and Co-Branded retail POS, or walking the red carpet at your next product launch.  We are handling Celebrity Endorsements queries for Bollywood, South Indian, Sport Celebrities , TV Artists  and many more."
    }, {
        imgSrc:virtualeventmanagement,
        department: "Virtual Event Management",
        description: "We are providing Virtual Event Platform, Online Exhibition, Digital Performance, Online Fairs for Education, Property, Fashion, etc. We help in the live streaming of Events (webcasting), providing software and execution.   Few important features to look into are: customization abilities, variety of space types, networking opportunity, Payment capabilities, as well as analytics and reporting.  "
    }, {
        imgSrc:dealerInvestormeet,
        department: "Dealers - Investors Meet",
        description: "Sometimes, when you are announcing a new distribution initiative or want to motivate your distribution, sales channel, dealers, investors or the share holders, a spectacular event with right kind of information, speakers and entertainment is the need of the hour. Our specialized team does everything from selecting the venue to hospitality and catering for any business meet."
    }, {
        imgSrc: socialEvent,
        department: "Social Events",
        description: " There is a huge extent of customization in case of a MICE (Team Offsite) trip in terms of destinations, hotel requirements, conference needs, sightseeing to be covered, gala dinner venue, team building activities, entertainment, etc. Our prime focus is always to cater to our customer's specific needs and the intent is to delight the customer.  We are handling corporate team outings across India."
    }, {
        imgSrc: eventsponsor,
        department: "Event Sponsorship",
        description: "Sky Events India has helped events of various media houses, digital platforms, colleges (IIT’s, NIT's, IIM's etc), fashion shows and exhibitions with Relevant Sponsorship. we have partnered with Corporates, NGOs, colleges across India, Fashion Designers, Newspapers, and with TV Channels. Please Check our Event Sponsorship page for more details."
    },
    {
        imgSrc:grandOpening,
        department: "Grand Openings",
        description: "With a little planning, you can turn your new store opening into a “can’t miss” affair, that will drive customers to your grand opening. Let us suggest you few innovative ideas and take care of all the arrangements"
    },{
        imgSrc:pressevent,
        department: "Press Events",
        description: " A press conference is a cost-effective promotional tool for small business owners because successful events can be organized on a small budget, and the resulting news coverage is akin to free advertising. We help you to get coverage in all desired media."
    },{
        imgSrc: awardnight,
        department: "Award Nights",
        description: "Events like Awards Night requires great deal of staging connection and encapsulating sound, vision and styling altogether. Proper use of space, table configuration and also multimedia presentation and everything, at SKY Events we take care of all. "
    },{
        imgSrc: "https://img1.wsimg.com/isteam/ip/c76862d1-4b72-4973-80c5-7d12414010ec/633bbcda-1c4f-4728-aa62-d26e03a2fc10.jpg",
        department: "Conferences | Seminars",
        description: " We are managing national or regional conferences across categories and topics.  Our conference management arm is designed to help you get your message across to your audience in the most effective, professional way.Registrations, Venue, Food, Accommodation, Transportation, Materials, Staff and Volunteer Management, etc. on us."
    },{
        imgSrc: "https://img1.wsimg.com/isteam/ip/c76862d1-4b72-4973-80c5-7d12414010ec/633bbcda-1c4f-4728-aa62-d26e03a2fc10.jpg",
        department: "GALA Meet and Greet Events",
        description: " We are managing national or regional conferences across categories and topics.  Our conference management arm is designed to help you get your message across to your audience in the most effective, professional way.Registrations, Venue, Food, Accommodation, Transportation, Materials, Staff and Volunteer Management, etc. on us."
    },{
        imgSrc:weddDecorPhotography17,
        department: "Big-Fat Indian & Destination Weddings",
        description: " We are managing national or regional conferences across categories and topics.  Our conference management arm is designed to help you get your message across to your audience in the most effective, professional way.Registrations, Venue, Food, Accommodation, Transportation, Materials, Staff and Volunteer Management, etc. on us."
    },{
        imgSrc: fashionshow1,
        department: "Fashion Shows / Fashion weeks / Fashion Carnivals / Fashion Tour / Beauty Peagents",
        description: " We are managing national or regional conferences across categories and topics.  Our conference management arm is designed to help you get your message across to your audience in the most effective, professional way.Registrations, Venue, Food, Accommodation, Transportation, Materials, Staff and Volunteer Management, etc. on us."
    },{
        imgSrc: LiveConcert,
        department: "Live In Concerts",
        description: " We are managing national or regional conferences across categories and topics.  Our conference management arm is designed to help you get your message across to your audience in the most effective, professional way.Registrations, Venue, Food, Accommodation, Transportation, Materials, Staff and Volunteer Management, etc. on us."
    },{
        imgSrc: media1,
        department: "Digital Media & Digital Branding",
        description: " We are managing national or regional conferences across categories and topics.  Our conference management arm is designed to help you get your message across to your audience in the most effective, professional way.Registrations, Venue, Food, Accommodation, Transportation, Materials, Staff and Volunteer Management, etc. on us."
    },{
        imgSrc:Outdoor,
        department: "Outdoor Branding And OOH",
        description: " We are managing national or regional conferences across categories and topics.  Our conference management arm is designed to help you get your message across to your audience in the most effective, professional way.Registrations, Venue, Food, Accommodation, Transportation, Materials, Staff and Volunteer Management, etc. on us."
    },{
        imgSrc:ExhibitionDesigning,
        department: "Exhibition Designing",
        description: " We are managing national or regional conferences across categories and topics.  Our conference management arm is designed to help you get your message across to your audience in the most effective, professional way.Registrations, Venue, Food, Accommodation, Transportation, Materials, Staff and Volunteer Management, etc. on us."
    },{
        imgSrc:Fabrication,
        department: "Fabrication & Stalls Designing",
        description: " We are managing national or regional conferences across categories and topics.  Our conference management arm is designed to help you get your message across to your audience in the most effective, professional way.Registrations, Venue, Food, Accommodation, Transportation, Materials, Staff and Volunteer Management, etc. on us."
    },{
        imgSrc:ngo,
        department: "Trust NGO",
        description: " We are managing national or regional conferences across categories and topics.  Our conference management arm is designed to help you get your message across to your audience in the most effective, professional way.Registrations, Venue, Food, Accommodation, Transportation, Materials, Staff and Volunteer Management, etc. on us."
    },{
        imgSrc:Clothing,
        department: "Clothing Brands",
        description: " We are managing national or regional conferences across categories and topics.  Our conference management arm is designed to help you get your message across to your audience in the most effective, professional way.Registrations, Venue, Food, Accommodation, Transportation, Materials, Staff and Volunteer Management, etc. on us."
    },{
        imgSrc: fmcg,
        department: "FMCG",
        description: " We are managing national or regional conferences across categories and topics.  Our conference management arm is designed to help you get your message across to your audience in the most effective, professional way.Registrations, Venue, Food, Accommodation, Transportation, Materials, Staff and Volunteer Management, etc. on us."
    },{
        imgSrc: FashionShows,
        department: "Fashion And Grooming Academy",
        description: " We are managing national or regional conferences across categories and topics.  Our conference management arm is designed to help you get your message across to your audience in the most effective, professional way.Registrations, Venue, Food, Accommodation, Transportation, Materials, Staff and Volunteer Management, etc. on us."
    },{
        imgSrc: "https://img1.wsimg.com/isteam/ip/c76862d1-4b72-4973-80c5-7d12414010ec/633bbcda-1c4f-4728-aa62-d26e03a2fc10.jpg",
        department: "Production House For Hollywood, Bollywood And Tollywood",
        description: " We are managing national or regional conferences across categories and topics.  Our conference management arm is designed to help you get your message across to your audience in the most effective, professional way.Registrations, Venue, Food, Accommodation, Transportation, Materials, Staff and Volunteer Management, etc. on us."
    },{
        imgSrc: "https://img1.wsimg.com/isteam/ip/c76862d1-4b72-4973-80c5-7d12414010ec/633bbcda-1c4f-4728-aa62-d26e03a2fc10.jpg",
        department: "Real Estate And Developers",
        description: " We are managing national or regional conferences across categories and topics.  Our conference management arm is designed to help you get your message across to your audience in the most effective, professional way.Registrations, Venue, Food, Accommodation, Transportation, Materials, Staff and Volunteer Management, etc. on us."
    }
];
export default OurServiceData