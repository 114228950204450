import EcomProductCard from "./EcomProductCard"

const ProductList = ({ grid, filteredProducts }) => {
    console.log('filtered--Products', filteredProducts )

    return (
        <>
            <div className={grid ? "displayflexxxx" : "displayblockkk"}>
                { filteredProducts && filteredProducts?.map((currItem) => (
                    <EcomProductCard key={currItem.id} {...currItem} />
                ))}
            </div>
        </>
    )
}

export default ProductList
