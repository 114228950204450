 import MarxEventFooter from "../../../../common/Marx_Event/MarxEventFooter/MarxEventFooter"
 import MarxEventHeader from "../../../../common/Marx_Event/MarxEventHeader/MarxEventHeader"
 import '../../ouModelBank/india/femaleDetailpgStyle.css'
 import { useParams } from "react-router-dom"
 import IndModelKidsData from "../../../../data/marxEvent/ourModelbank/indian/IndianModelKidsData"
 import GalleryWitthBioDetailpgAllMax from "../../../../common/GalleryWitthBioAllMax/GalleryWitthBioDetailpgAllMax"
 
 
 const IndBeautyPageantsTitleHolderDetailPg = () => {
     let { id } = useParams();
     console.log(id)
 
     const singleData = IndModelKidsData.find((item) => item.id == id)
     console.log(singleData)
    
     return (
         <>
             <MarxEventHeader />
             <GalleryWitthBioDetailpgAllMax  allData={singleData} />
             <MarxEventFooter />
 
 
         </>
     )
 }
 
 export default IndBeautyPageantsTitleHolderDetailPg
 