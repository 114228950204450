
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import '../../styles/marxAgroFoodHomeStyle.css'
import MarxAgroFoodHeader from "../../common/MarxAgroFood/HeaderMarxAgroFood";
import MarxAgroFoodFooter from "../../common/MarxAgroFood/FooterMarxAgroFood";
import RunningSlide from "../../common/RunningSlideAllMarx/RunningSlide";
import { useEffect } from "react";
import TopSliderAllMax from "../../common/TopSliderAllMarx/TopSliderAllMarx";
import AllDemoData from "../../data/AllDemoData";



const MarxAgroFoodHome = () => {
    useEffect(() => {
        document.title ="Mar'x Agro Foods India Pvt.Ltd.";
      }, []);
   
    return (
        <>
            <MarxAgroFoodHeader />
            <div className="ventures">
                <h1>COMING SOON...</h1>
            </div>
            
            {/* <TopSliderAllMax title={"Product"} images={AllDemoData} />


            <div className="main-agrofooddiv">
                <Box className="mainagrofooddiv-box">
                    <div className="allagrofoodtabs">
                        <h5 className=" text-center fw-bold mb-4"> INDEX </h5>
                        <p className='mange-agrofoodtab'>
                            <Link to={'/'} >MARS </Link>
                        </p>
                    </div>
                    <div className="agrofoodtabpanels">
                        <div>
                            <div className="about-marx-agrofood">
                                <h1>About Us</h1>
                                <p></p>
                            </div>

                            <div className="about-marx-agrofood">
                                <h1>What We Do</h1>
                                <div>
                                    <p></p>
                                </div>
                            </div>

                            <div className="about-marx-agrofood">
                                <h1>Our Services</h1>
                                <div className="servcedivptag">
                                    <p></p>
                                </div>
                            </div>

                            <div className="about-marx-agrofood">
                                <h1>Who We Are</h1>
                                <div>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </div>

            <RunningSlide title="Product" images={AllDemoData}/>
            <RunningSlide title="Designing" images={AllDemoData}/> */}

            <MarxAgroFoodFooter />
        </>
    )
}

export default MarxAgroFoodHome
