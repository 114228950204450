import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import OurInfluencers from "./page/MarxEntertainment/OurInfluencers";
import InfluencerDetail from "./page/InfluencerDetail";
import AdvertiserSignUp from "./page/AdvertiserSignUp";
import Services from "./page/MarxEntertainment/Services";
import Campaigns from "./page/Campaigns";
import Contact from './page/Contact/Contact'
import Blog from './page/MarxGroup/Blog/Blog'
import AboutUs from "./page/aboutUs/AboutUs";
import Faq from "./page/MarxGroup/faq/Faq";
import HowItsWork from "./page/howItsWork/HowItsWork";
import InstagramPackage from "./page/InstagramPackage";
import Testimonials from "./page/MarxGroup/Testimonials";
import CampaignDetail from "./page/CampaignDetail";
import Jobs from "./page/MarxGroup/jobs/Jobs";
import GuestPosts from "./page/guestPosts/GuestPosts";
import YoutubePackage from "./page/YoutubePackage";
import ContentMarketing from "./page/contentMarketing/ContentMarketing";
import Book from "./page/Books/Books";
import OurClients from "./page/MarxGroup/OurClients";
import InfluencerSignup from "./page/InfluencerSignup/InfluencerSignup";
import LoginStep1 from "./page/LoginStep1";
import SignUp from "./page/SignUp";
import FemaleInfluencer from "./page/FemaleInfluencer/FemaleInfluencer";
import FaqInfluenser from "./page/MarxGroup/faq/FaqInfluencer";
import AdvertiserSignUpMoreDetails from "./page/AdvertiserSignUpMoreDetails";
import PrivacyPolicy from "./page/MarxGroup/PrivacyPolicy";
import TermAndCondition from "./page/MarxGroup/TermAndCondition";
import SocialMediaAssets from "./page/SocialMediaAssets";
import ClientRegistForm from "./page/ClientRegstForm";
import CompanyIndex from "./page/CompanyIndex";
import LandingPage from "./page/LandingPage";
import MarxWedding from "./page/marxWedding/MarxWedding";
import PhotographerDetailPage from "./page/marxWedding/PhotographerDetailPage";
import Cardmenu from "./page/cart/Filter_by_category";
import WishlistCart from "./page/cart/WishlistCart";
import PhotoGallery from "./page/PhotoGallery";
import HomeMarxEvent from "./page/MarxEvents/HomeMarxEvent";
import OurTeam from "./page/OurTeam";
import VideoGallery from "./page/MarxGroup/VideoGallery";
import MarxGroup from "./page/MarxGroup/MarxGroup";
import OurServices from "./page/MarxGroup/OurServices";
import ContactUs from "./page/MarxGroup/ContactUs";
import SiteMap from "./page/MarxGroup/SiteMap";
import Packages from "./page/Packages";
import Pay from "./page/Pay";
import MarxGroupLogin from "./components/MarxGroup/MarxGroupLogin";
import MarxGroupSignUp from "./components/MarxGroup/MarxGroupSignUp";
import BillingAddress from "./page/billingAdd/BillingAddress";
import MarxMediaHome from "./page/MarxMedia/MarxMediaHome";
import EditProfile from "./components/MarxGroup/EditProfile";
import EventServices from "./page/MarxEvents/EventServices";
import EventShowGallery from "./page/MarxEvents/EventShowGallery";
import Album1Pics from "./page/MarxEvents/MarxEventPhotoGallery/Album1Pics";
import OurProjects from "./page/MarxEvents/OurProjects";
import ForgotPassword from "./components/MarxGroup/ForgotPassword";
import ResetPassword from "./components/MarxGroup/ResetPassword";
import EventRegistrationForm from "./page/MarxEvents/MarxEventRegistrationForm/EventRegistrationForm";
import WeddingOurVendors from "./page/marxWedding/WeddingOurVendors";
import { useState, useEffect } from "react";
import { FaAngleUp } from "react-icons/fa";
import EmptyPage from "./page/EmptyPage";
import ServicesWedding from "./page/marxWedding/ServicesWedding";
import IndModelbankFemale from "./page/MarxEvents/ouModelBank/india/IndModelbankFemale";
import Otp from "./components/MarxGroup/Otp";
import IndModelbankMale from "./page/MarxEvents/ouModelBank/india/IndModelbankMale";
import AdvertiseHere from "./page/MarxGroup/AdvertiseHere";
import BuyOurFranchise from "./page/MarxGroup/BuyOurFranchise";
import FemaleModelDetailpg from "./page/MarxEvents/ouModelBank/india/FemaleModelDetailpg";
import InternModelFemale from "./page/MarxEvents/ouModelBank/international/InternModelFemale";
import InternFemaleDetailpg from "./page/MarxEvents/ouModelBank/international/internFemaleDetailPg";
import InternModelMale from "./page/MarxEvents/ouModelBank/international/InternModelMale";
import MaleModelDetailpg from "./page/MarxEvents/ouModelBank/india/MaleModelDetailpg";
import InternMaleDetailpg from "./page/MarxEvents/ouModelBank/international/InternMaleDetailpg";
import Cart from "./page/cart/Cart";
// import GoToCart from "./page/cart/GoToCart";
import MarxFoundationHome from "./page/MarxFoundation/MarxFoundationHome";
import MarxFashionCoutureHome from "./page/MarxFashionCouture/MarxFashionCoutureHome";
import MarxAgroFoodHome from "./page/MarxAgroFood/MarxAgroFoodHome";
import MarxRealEstateHome from "./page/MarxRealEstate/MarxRealEstateHome";
import MarxMotionPictureHome from "./page/MarxMotionPicture/MarxMotionPictureHome";
import MarxAcademyHome from "./page/MarxAcademy/MarxAcademyHome";
import MarxEntertainmentHome from "./page/MarxEntertainment/MarxEntertainmentHomes";
import RegistrationForm from "./page/MarxEntertainment/marxEntmntRegistrationForm/RegistrationForm";
import Products from "./page/EcomerceApp/Products";
import PurchaseHistory from "./page/MarxEntertainment/PurchaseHistory/PurchaseHistory";
import TransactionHistory from "./page/MarxEntertainment/TransactionHistory/TransactionHistory";
import IndKidsModelbank from "./page/MarxEvents/ouModelBank/india/IndKidsModelbank";
import IndKidsModelDetailpg from "./page/MarxEvents/ouModelBank/india/indKidsModelDetailpg";
import InternKidsModelbank from "./page/MarxEvents/ouModelBank/international/InternKidsModelbank";
import InternKidsDetailPg from "./page/MarxEvents/ouModelBank/international/InternKidsDetailPg";
import IndBeautyPageantsWinner from "./page/MarxEvents/BeautyPageantsWinner/India/IndBeautyPageantsWinner";
import IndBeautyPageantsWinnerDetailPg from "./page/MarxEvents/BeautyPageantsWinner/India/IndBeautyPageantsWinnerDetailPg";
import InternBeautyPageantsWinner from "./page/MarxEvents/BeautyPageantsWinner/International/InternBeautyPageantsWinner";
import InternBeautyPageantsWinnerDetailPg from "./page/MarxEvents/BeautyPageantsWinner/International/InternBeautyPageantsWinnerDetailPg";
import IndBeautyPageantsContestants from "./page/MarxEvents/BeautyPageantsContestant/India/IndBeautyPageantsContestants";
import IndBeautyPageantsContestantsDetailPg from "./page/MarxEvents/BeautyPageantsContestant/India/IndBeautyPageantsContestantsDetailPg";
import InternBeautyPageantsContestantsDetailPg from "./page/MarxEvents/BeautyPageantsContestant/International/InternBeautyPageantsContestantsDetailPg";
import InternBeautyPageantsContestants from "./page/MarxEvents/BeautyPageantsContestant/International/InternBeautyPageantsContestants";
import IndBeautyPageantsTitleHolder from "./page/MarxEvents/BeautyPageantsTitleHolder/India/IndBeautyPageantsTitleHolder";
import IndBeautyPageantsTitleHolderDetailPg from "./page/MarxEvents/BeautyPageantsTitleHolder/India/IndBeautyPageantsTitleHolderDetailPg";
import InternBeautyPageantsTitleHolder from "./page/MarxEvents/BeautyPageantsTitleHolder/International/InternBeautyPageantsTitleHolder";
import InternBeautyPageantsTitleHolderDetailPg from "./page/MarxEvents/BeautyPageantsTitleHolder/International/InternBeautyPageantsTitleHolderDetailPg";
import Album2Pics from "./page/MarxEvents/MarxEventPhotoGallery/Album2Pics";
import Page3Events from "./page/MarxEvents/MarxEventPhotoGallery/Page3Events";
import AwardsAndAchievements from "./page/MarxEvents/MarxEventPhotoGallery/AwardsAndAchievements";
import HallOfFame from "./page/MarxEvents/MarxEventPhotoGallery/HallOfFame";
import PressMediaCoverage from "./page/MarxEvents/MarxEventPhotoGallery/PressAndMediaCoverage";
import EventsFilterSearch from "./page/MarxEvents/EventsFilterSearch";
import EventSubscriptionPackages from "./page/MarxEvents/EventsSubscription";
import EventElectronicMediaCoverage from "./page/MarxEvents/EventElectronicMediaCoverage";
import EventShowVideoReel from "./page/MarxEvents/EventShowsVideoReel";
import MarxSkinExperts from "./page/MarxEvents/MarxEventsIndex/MarxSkinExperts";
import MarxSkinExpertDetailPage from "./page/MarxEvents/MarxEventsIndex/MarxSkinExpertDetailPage";
import MarxFitnessExperts from "./page/MarxEvents/MarxEventsIndex/MarxFitnessExpert";
import MarxFitnessExpertDetailPage from "./page/MarxEvents/MarxEventsIndex/MarxFitnessExpertDetailPage";
import MarxDictionExperts from "./page/MarxEvents/MarxEventsIndex/MarxDictionExperts";
import MarxDictionExpertDetailPage from "./page/MarxEvents/MarxEventsIndex/MarxDictionExpertDetailPage";
import MarxThematicShow from "./page/MarxEvents/MarxEventsIndex/MarxThematicShow";
import MarxSocialCauseShows from "./page/MarxEvents/MarxEventsIndex/MarxSocialCauseShows";
import MarxProductLaunchShow from "./page/MarxEvents/MarxEventsIndex/MarxProductLaunchShow";
import MarxConceptualizationAndShowDirection from "./page/MarxEvents/MarxEventsIndex/MarxConceptualizationAndShowDirection";
import MarxFashionStylist from "./page/MarxEvents/MarxEventsIndex/MarxFashionStylist";
import MarxFashionStylistDetailPage from "./page/MarxEvents/MarxEventsIndex/MarxFashionStylistDetailPage";
import MarxMakeupArtist from "./page/MarxEvents/MarxEventsIndex/MarxMakeupArtist";
import MarxMakeupArtistDetailPage from "./page/MarxEvents/MarxEventsIndex/MarxMakeupArtistDetailPage";
import MarxGroomingExpert from "./page/MarxEvents/MarxEventsIndex/MarxGroomingExpert";
import MarxGroomingExpertDetailPage from "./page/MarxEvents/MarxEventsIndex/MarxGroomingExpertDetailPage";
import MarxLifeCoachExpert from "./page/MarxEvents/MarxEventsIndex/MarxLifeCoachExpert";
import MarxLifeCoachExpertDetailPage from "./page/MarxEvents/MarxEventsIndex/MarxLifeCoachExpertDetailPage";
import MarxFashionDesigner from "./page/MarxEvents/MarxEventsIndex/MarxFashionDesigner";
import MarxFashionDesignerDetailPage from "./page/MarxEvents/MarxEventsIndex/MarxFashionDesignerDetailPage";
import MarxImageConsultant from "./page/MarxEvents/MarxEventsIndex/MarxImageConsultant";
import MarxImageConsultantDetailPage from "./page/MarxEvents/MarxEventsIndex/MarxImageConsultantDetailPage";
import MarxAccessoriesDesigner from "./page/MarxEvents/MarxEventsIndex/MarxAccessoriesDesigner";
import MarxAccessoriesDesignerDetailPage from "./page/MarxEvents/MarxEventsIndex/MarxAccessoriesDesignerDetailPage";
import MarxLeadershipQualityexpert from "./page/MarxEvents/MarxEventsIndex/MarxLeadershipQualityexpert";
import MarxLeadershipQualityexpertDetailPage from "./page/MarxEvents/MarxEventsIndex/MarxLeadershipQualityexpertDetailPage";
import EntertainmentFilterSearch from "./page/MarxEntertainment/EntertainmentFilterSearch";
import ServicesGallery from "./page/MarxEntertainment/ServicesGallery";
import EntertainmentShowGallery from "./page/MarxEntertainment/EntertainmentShowGallery";
import EntertainmentHallofFame from "./page/MarxEntertainment/EntertainmentHallofFame";
import EntertainmentShowsVideoReel from "./page/MarxEntertainment/EntertainmentShowsVideoReel";
import EntertainmentDebutTalent from "./page/MarxEntertainment/EntertainmentIndex/EntertainmentDebutTalent";
import EntertainmentIndianTalent from "./page/MarxEntertainment/EntertainmentIndex/EntertainmentIndianTalent";
import EntertainmentOdiyaTalent from "./page/MarxEntertainment/EntertainmentIndex/EntertainmentOdiyaTalent";
import EntertainmentSportsTalent from "./page/MarxEntertainment/EntertainmentIndex/EntertainmentSportsTalent";
import EntertainmentBengaliTalent from "./page/MarxEntertainment/EntertainmentIndex/EntertainmentBengaliTalent";
import EntertainmentMarathiTalent from "./page/MarxEntertainment/EntertainmentIndex/EntertainmentMarathiTalent";
import EntertainmentGujratiTalent from "./page/MarxEntertainment/EntertainmentIndex/EntertainmentGujratiTalent";
import EntertainmentRegionalTalent from "./page/MarxEntertainment/EntertainmentIndex/EntertainmentRegionalTalent";
import EntertainmentExclusiveTalent from "./page/MarxEntertainment/EntertainmentIndex/EntertainmentExclusiveTalent";
import EntertainmentClassicalTalent from "./page/MarxEntertainment/EntertainmentIndex/EntertainmentClassicalTalent";
import EntertainmentBollywoodTalent from "./page/MarxEntertainment/EntertainmentIndex/EntertainmentBollywoodTalent";
import EntertainmentTollywoodTalent from "./page/MarxEntertainment/EntertainmentIndex/EntertainmentTollywoodTalent";
import EntertainmentKollywoodTalent from "./page/MarxEntertainment/EntertainmentIndex/EntertainmentKollywoodTalent";
import EntertainmentPollywoodTalent from "./page/MarxEntertainment/EntertainmentIndex/EntertainmentPollywoodTalent";
import EntertainmentHollywoodTalent from "./page/MarxEntertainment/EntertainmentIndex/EntertainmentHollywoodTalent";
import EntertainmentPerformanceTalent from "./page/MarxEntertainment/EntertainmentIndex/EntertainmentPerformanceTalent";
import EntertainmentInternationalTalent from "./page/MarxEntertainment/EntertainmentIndex/EntertainmentInternationalTalent";
import EntertainmentConcertTalent from "./page/MarxEntertainment/EntertainmentIndex/EntertainmentConcertTalent";
import EntertainmentNonExclusiveTalent from "./page/MarxEntertainment/EntertainmentIndex/EntertainmentNonExclusiveTalent";
import EntertainmentTelevisionShoapTalent from "./page/MarxEntertainment/EntertainmentIndex/EntertainmentTelevisionShoapTalent";
import EntertainmentQuery from "./page/MarxEntertainment/EntertainmentQuery";
import EventsQuery from "./page/MarxEvents/EventsQuery";
import WeddingFilterSearch from "./page/marxWedding/WeddingFilterSearch";
import WeddingGallery from "./page/marxWedding/WeddingGallery";
import WeddingEventsPics from "./page/marxWedding/WeddingEventsPics";
import PreWeddingEventsPics from "./page/marxWedding/PreWeddingEventsPics";
import DestinationWedding from "./page/marxWedding/DestinationWedding";
import WeddingRegistrationForm from "./page/marxWedding/WeddingRegistrationForm";
import WeddingVideoShowsReel from "./page/marxWedding/WeddingVideoShowsReel";
import WeddingSubscriptionPlan from "./page/marxWedding/WeddingSubscriptionPlan";
import WeddingStageSetupDesigns from "./page/marxWedding/MarxWeddingIndex/WeddingStageSetupDesigns";
import WeddingSoundSetupDesigns from "./page/marxWedding/MarxWeddingIndex/WeddingSoundSetupDesigns";
import WeddingLightSetupDesigns from "./page/marxWedding/MarxWeddingIndex/WeddingLightSetupDesigns";
import WeddingGraphicSetupDesigns from "./page/marxWedding/MarxWeddingIndex/WeddingGraphicSetupDesigns";
import WeddingFloralSetupDesigns from "./page/marxWedding/MarxWeddingIndex/WeddingFloralSetupDesigns";
import WeddingArabicSetupDesigns from "./page/marxWedding/MarxWeddingIndex/WeddingArabicSetupDesigns";
import WeddingPackages from "./page/marxWedding/MarxWeddingIndex/WeddingPackages";
import WeddingPackagesDetailpg from "./page/marxWedding/MarxWeddingIndex/WeddingPackagesDetailpg";
import WeddingCustomMadePackages from "./page/marxWedding/MarxWeddingIndex/WeddingCustomMadePackages";
import WeddingCustomMadePackagesDetailpg from "./page/marxWedding/MarxWeddingIndex/WeddingCustomMadePackagesDetailpg";
import WeddingTravelPackages from "./page/marxWedding/MarxWeddingIndex/WeddingTravelPackages";
import WeddingTravelPackagesDetailpg from "./page/marxWedding/MarxWeddingIndex/WeddingTravelPackagesDetailpg";
import WeddingHoneymoonPackages from "./page/marxWedding/MarxWeddingIndex/WeddingHoneymoonPackages";
import WeddingHoneymoonPackagesDetailpg from "./page/marxWedding/MarxWeddingIndex/WeddingHoneymoonPackagesDetailpg";
import WeddQuery from "./page/marxWedding/WeddQuery";
import MediaShowsGallery from "./page/MarxMedia/MediaShowsGallery";
import MediaPrint from "./page/MarxMedia/MediaPrint";
import MediaElectronic from "./page/MarxMedia/MediaElectronic";
import MediaQuery from "./page/MarxMedia/MediaQuery";
import EcomProductDetails from "./page/EcomerceApp/EcomProductDetail";
import WeddingOurFebricationWork from "./page/marxWedding/WeddingOurFebricationWork";
import UAE from "./page/marxWedding/DestinationWedding/UAE";
import DelhiPics from "./page/marxWedding/DestinationWedding/DelhiPics";
import WeddingHotelAndVenueIndia from "./page/marxWedding/WeddingHotelAndVenue/WeddingHotelAndVenueIndia";
import WeddingHotelAndVenueInternational from "./page/marxWedding/WeddingHotelAndVenue/WeddingHotelAndVenueInternational";
import IndiaBanquetHall from "./page/marxWedding/WeddingHotelAndVenue/India/IndiaBanquetHall";
import IndiaBanquetHallDetailpg from "./page/marxWedding/WeddingHotelAndVenue/India/IndiaBanquetHallDetailpg";
import IndiaResortsDetailpg from "./page/marxWedding/WeddingHotelAndVenue/India/IndiaResortsDetailpg";
import IndiaResorts from "./page/marxWedding/WeddingHotelAndVenue/India/IndiaResorts";
import India3StarPropertyDetailpg from "./page/marxWedding/WeddingHotelAndVenue/India/India3StarPropertyDetailpg";
import India3StarProperty from "./page/marxWedding/WeddingHotelAndVenue/India/India3StarProperty";
import India5StarPropertyDetailpg from "./page/marxWedding/WeddingHotelAndVenue/India/India5StarPropertyDetailpg";
import India5StarProperty from "./page/marxWedding/WeddingHotelAndVenue/India/India5StarProperty";
import IndiaFortPlacesDetailpg from "./page/marxWedding/WeddingHotelAndVenue/India/IndiaFortPlacesDetailpg";
import IndiaFortPlaces from "./page/marxWedding/WeddingHotelAndVenue/India/IndiaFortPlaces";
import IndiaFarmHouseDetailpg from "./page/marxWedding/WeddingHotelAndVenue/India/IndiaFarmHouseDetailpg";
import IndiaFarmHouse from "./page/marxWedding/WeddingHotelAndVenue/India/IndiaFarmHouse";
import InternationalBanquetHall from "./page/marxWedding/WeddingHotelAndVenue/International/InternationalBanquetHall";
import InternationalBanquetHallDetailpg from "./page/marxWedding/WeddingHotelAndVenue/International/InternationalBanquetHallDetailpg";
import InternationalResorts from "./page/marxWedding/WeddingHotelAndVenue/International/InternationalResorts";
import InternationalResortsDetailpg from "./page/marxWedding/WeddingHotelAndVenue/International/InternationalResortsDetailpg";
import International3StarProperty from "./page/marxWedding/WeddingHotelAndVenue/International/International3StarProperty";
import International3StarPropertyDetailpg from "./page/marxWedding/WeddingHotelAndVenue/International/International3StarPropertyDetailpg";
import International5StarProperty from "./page/marxWedding/WeddingHotelAndVenue/International/International5StarProperty";
import International5StarPropertyDetailpg from "./page/marxWedding/WeddingHotelAndVenue/International/International5StarPropertyDetailpg";
import InternationalFarmHouse from "./page/marxWedding/WeddingHotelAndVenue/International/InternationalFarmHouse";
import InternationalFarmHouseDetailpg from "./page/marxWedding/WeddingHotelAndVenue/International/InternationalFarmHouseDetailpg";
import InternationalIsland from "./page/marxWedding/WeddingHotelAndVenue/International/InternationalIsland";
import InternationalIslandDetailpg from "./page/marxWedding/WeddingHotelAndVenue/International/InternationalIslandDetailpg";
import WeddingPreWeddingPackages from "./page/marxWedding/MarxWeddingIndex/WeddingPreWeddingPackages";
import WeddingPreWeddingPackagesDetailpg from "./page/marxWedding/MarxWeddingIndex/WeddingPreWeddingPackagesDetailpg";
import WeddingDestinationWeddingPackagesDetailpg from "./page/marxWedding/MarxWeddingIndex/WeddingDestinationWeddingPackagesDetailpg";
import WeddingDestinationWeddingPackages from "./page/marxWedding/MarxWeddingIndex/WeddingDestinationWeddingPackages";
import MediaFilterSearch from "./page/MarxMedia/MediaFilterSearch";
import MediaRegistrationForm from "./page/MarxMedia/MediaRegistrationForm";
import MediaShowVideoReel from "./page/MarxMedia/MediaShowVideoReel";
import MediaSubscriptionPlans from "./page/MarxMedia/MediaSubscriptionPlans";
import MediaBranding from "./page/MarxMedia/mediaServices/MediaBranding";
import MediaMarketing from "./page/MarxMedia/mediaServices/MediaMarketing";
import MediaDesigning from "./page/MarxMedia/mediaServices/MediaDesigning";
import MediaAdvertising from "./page/MarxMedia/mediaServices/MediaAdvertising";
import MediaPromotions from "./page/MarxMedia/mediaServices/MediaPromotions";
import MediaCommunication from "./page/MarxMedia/mediaServices/MediaCommunication";
import MediaPublicRelations from "./page/MarxMedia/mediaServices/MediaPublicRelations";
import FoundationShowsGallery from "./page/MarxFoundation/FoundationShowsGallery";
import FoundationShowVideoReel from "./page/MarxFoundation/FoundationShowVideoReel";
import FoundationPressMediaCoverage from "./page/MarxFoundation/FoundationPressMediaCoverage";
import RealEstatePriceFilterSearch from "./page/MarxRealEstate/RealEstatePriceFilterSearch";
import RealEstatePropertyVideoReel from "./page/MarxRealEstate/RealEstatePropertyVideoReel";
import RealEstateGalleryPics from "./page/MarxRealEstate/RealEstateGalleryPics";
import RealEstateLuxuryPenthouse from "./page/MarxRealEstate/Services/RealEstateLuxuryPenthouse";
import RealEstateLuxuryVillas from "./page/MarxRealEstate/Services/RealEstateLuxuryVillas";
import RealEstateLuxuryResorts from "./page/MarxRealEstate/Services/RealEstateLuxuryResorts";
import RealEstateLuxuryFarmhouse from "./page/MarxRealEstate/Services/RealEstateLuxuryFarmhouse";
import RealEstateLuxury3StarHotel from "./page/MarxRealEstate/Services/RealEstateLuxury3StarHotel";
import RealEstateLuxury5StarHotel from "./page/MarxRealEstate/Services/RealEstateLuxury5StarHotel";
import RealEstateLuxury7StarHotel from "./page/MarxRealEstate/Services/RealEstateLuxury7StarHotel";
import RealEstateLuxuryApartments from "./page/MarxRealEstate/Services/RealEstateLuxuryApartments";
import RealEstateAgricultureLands from "./page/MarxRealEstate/Services/RealEstateAgricultureLands";
import RealEstateCommercialLands from "./page/MarxRealEstate/Services/RealEstateCommercialLands";
import RealEstateWarehouse from "./page/MarxRealEstate/Services/RealEstateWarehouse";
import RealEstateIndustrialLands from "./page/MarxRealEstate/Services/RealEstateIndustrialLands";
import RealEstateUniversityLands from "./page/MarxRealEstate/Services/RealEstateUniversityLands";
import RealEstateSchoolLands from "./page/MarxRealEstate/Services/RealEstateSchoolLands";
import RealEstateCollegeLands from "./page/MarxRealEstate/Services/RealEstateCollegeLands";
import RealEstateBuilderFloors from "./page/MarxRealEstate/Services/RealEstateBuilderFloors";
import RealEstatePrivateSectorLands from "./page/MarxRealEstate/Services/RealEstatePrivateSectorLands";
import RealEstateGovernmentSectorLands from "./page/MarxRealEstate/Services/RealEstateGovernmentSectorLands";
import RealEstateContactUs from "./page/MarxRealEstate/RealEstateContactUs";
import FoundationContactUs from "./page/MarxFoundation/FoundationContactUs";


function App() {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <BrowserRouter>
        <div className="top-to-btm">
          {showTopBtn && (
            <FaAngleUp
              className="icon-position icon-style "
              style={{ zIndex: 199 }}
              onClick={goToTop}
            />
          )}
        </div>
        <Routes>

          {/* Marx Group */}

          <Route path='/' element={<LandingPage />} />
          <Route path='/marx-group-login' element={<MarxGroupLogin />} />
          <Route path='/marx-group-signup' element={<MarxGroupSignUp />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/forgot-password/otp' element={<Otp />} />
          <Route path='/forgot-password/reset-password' element={<ResetPassword />} />
          <Route path='/marx-group' element={<MarxGroup />} />
          <Route path='/our-service' element={<OurServices />} />
          <Route path='/contactus' element={<ContactUs />} />
          <Route path='/site-map' element={<SiteMap />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/term-condition' element={<TermAndCondition />} />
          <Route path='/our-team' element={<OurTeam />} />
          <Route path='/video-gallery' element={<VideoGallery />} />
          <Route path='/edit-profile' element={<EditProfile />} />
          <Route path='/advertise-here' element={<AdvertiseHere />} />
          <Route path='/buy-our-franchise' element={<BuyOurFranchise />} />
          <Route path='/our-client' element={<OurClients />} />
          <Route path='/testimonials' element={<Testimonials />} />
          <Route path='/jobs' element={<Jobs />} />
          <Route path='/faq' element={<Faq />} />
          <Route path='/faq/influencer/:id' element={<FaqInfluenser />} />
          <Route path='/blog' element={<Blog />} />


          {/* Marx entertainment */}

          <Route path='/marx-entertainment' element={<MarxEntertainmentHome />} />
          <Route path='/our-influencers' element={<OurInfluencers />} />
          <Route path='/entertainment-filtersearch' element={<EntertainmentFilterSearch />} />
          <Route path='/influencer-detail/:_id/:firstname' element={<InfluencerDetail />} />
          <Route path='/advertiser-signup' element={<AdvertiserSignUp />} />
          <Route path='/advertiser-signup/more-details/:token' element={<AdvertiserSignUpMoreDetails />} />
          <Route path='/service' element={<Services />} />
          <Route path='/service/:_id' element={<ServicesGallery />} />
          <Route path='/index' element={<CompanyIndex />} />
          <Route path='/packages' element={<Packages />} />
          <Route path='/registration-from' element={<RegistrationForm />} />
          <Route path='/pay/:id' element={<Pay />} />
          <Route path='/campaigns' element={<Campaigns />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/about' element={<AboutUs />} />
          <Route path='/how-its-work' element={<HowItsWork />} />
          <Route path='/instagram-plan' element={<InstagramPackage />} />
          <Route path='/youtube-plan' element={<YoutubePackage />} />
          <Route path='/guest-posts' element={<GuestPosts />} />
          <Route path='/campaign-detail' element={<CampaignDetail />} />
          <Route path='/content-marketing' element={<ContentMarketing />} />
          <Route path='/category/books' element={<Book />} />
          <Route path='/influencer-signup' element={<InfluencerSignup />} />
          <Route path='/sign-in' element={<LoginStep1 />} />
          <Route path='/sign-up' element={<SignUp />} />
          <Route path='/blog/top-10-female-fashion-influencers-in-india-in-2024' element={<FemaleInfluencer />} />
          <Route path='/influencer-signup/social-media-assets' element={<SocialMediaAssets />} />
          <Route path='/influencer-signup/client-registration-form' element={<ClientRegistForm />} />
          <Route path='/history' element={<PurchaseHistory />} />
          <Route path='/transaction' element={<TransactionHistory />} />
          <Route path='/entertainment-showgallery' element={<EntertainmentShowGallery />} />
          <Route path='/entertainment-halloffame' element={<EntertainmentHallofFame />} />
          <Route path='/entertainment-showsvideoreel' element={<EntertainmentShowsVideoReel />} />
          <Route path='/ent-debuttalent' element={<EntertainmentDebutTalent />} />
          <Route path='/ent-indiantalent' element={<EntertainmentIndianTalent />} />
          <Route path='/ent-odiyatalent' element={<EntertainmentOdiyaTalent />} />
          <Route path='/ent-sportstalent' element={<EntertainmentSportsTalent />} />
          <Route path='/ent-bengalitalent' element={<EntertainmentBengaliTalent />} />
          <Route path='/ent-marathitalent' element={<EntertainmentMarathiTalent />} />
          <Route path='/ent-gujratitalent' element={<EntertainmentGujratiTalent />} />
          <Route path='/ent-regionaltalent' element={<EntertainmentRegionalTalent />} />
          <Route path='/ent-exclusivetalent' element={<EntertainmentExclusiveTalent />} />
          <Route path='/ent-classicaltalent' element={<EntertainmentClassicalTalent />} />
          <Route path='/ent-bollywoodtalent' element={<EntertainmentBollywoodTalent />} />
          <Route path='/ent-tollywoodtalent' element={<EntertainmentTollywoodTalent />} />
          <Route path='/ent-kollywoodtalent' element={<EntertainmentKollywoodTalent />} />
          <Route path='/ent-pollywoodtalent' element={<EntertainmentPollywoodTalent />} />
          <Route path='/ent-hollywoodtalent' element={<EntertainmentHollywoodTalent />} />
          <Route path='/ent-performancetalent' element={<EntertainmentPerformanceTalent />} />
          <Route path='/ent-internationaltalent' element={<EntertainmentInternationalTalent />} />
          <Route path='/ent-concerttalent' element={<EntertainmentConcertTalent />} />
          <Route path='/ent-nonexclusivetalent' element={<EntertainmentNonExclusiveTalent />} />
          <Route path='/ent-televisionshoaptalent' element={<EntertainmentTelevisionShoapTalent />} />
          <Route path='/ent-query' element={<EntertainmentQuery />} />



          {/* Marx wedding */}

          <Route path='/marx-wedding' element={<MarxWedding />} />
          <Route path='/wedding-vendors' element={<WeddingOurVendors />} />
          <Route path='/photographers-detail' element={<PhotographerDetailPage />} />
          <Route path='/services-wedding' element={<ServicesWedding />} />
          <Route path='/services-wedding/:id' element={<WeddingGallery />} />
          <Route path='/wedding-filtersearch' element={<WeddingFilterSearch />} />
          <Route path='/wedding-events-pics' element={<WeddingEventsPics />} />
          <Route path='/prewedding-events-pics' element={<PreWeddingEventsPics />} />
          <Route path='/destination-wedding' element={<DestinationWedding />} />
          <Route path='/destination-wedding/city-UAE-pics' element={<UAE />} />
          <Route path='/destination-wedding/city-UAE-pics/Delhi-pics' element={<DelhiPics />} />
          <Route path='/destination-wedding/city-Turkey-pics' element={<DestinationWedding />} />
          <Route path='/destination-wedding/city-Switzerland-pics' element={<DestinationWedding />} />
          <Route path='/destination-wedding/city-USA-pics' element={<DestinationWedding />} />
          <Route path='/destination-wedding/city-Canada-pics' element={<DestinationWedding />} />
          <Route path='/destination-wedding/city-Malaysia-pics' element={<DestinationWedding />} />
          <Route path='/destination-wedding/city-Singapore-pics' element={<DestinationWedding />} />
          <Route path='/wedding-registration-form' element={<WeddingRegistrationForm />} />
          <Route path='/wedding-hotelvenue-india' element={<WeddingHotelAndVenueIndia />} />
          <Route path='/wedding-hotelvenue-india/banquethall' element={<IndiaBanquetHall />} />
          <Route path='/wedding-hotelvenue-india/banquethall/:id' element={<IndiaBanquetHallDetailpg />} />
          <Route path='/wedding-hotelvenue-india/resorts' element={<IndiaResorts />} />
          <Route path='/wedding-hotelvenue-india/resorts/:id' element={<IndiaResortsDetailpg />} />
          <Route path='/wedding-hotelvenue-india/3-star-property' element={<India3StarProperty />} />
          <Route path='/wedding-hotelvenue-india/3-star-property/:id' element={<India3StarPropertyDetailpg />} />
          <Route path='/wedding-hotelvenue-india/5-star-property' element={<India5StarProperty />} />
          <Route path='/wedding-hotelvenue-india/5-star-property/:id' element={<India5StarPropertyDetailpg />} />
          <Route path='/wedding-hotelvenue-india/fort-places' element={<IndiaFortPlaces />} />
          <Route path='/wedding-hotelvenue-india/fort-places/:id' element={<IndiaFortPlacesDetailpg />} />
          <Route path='/wedding-hotelvenue-india/farmhouse' element={<IndiaFarmHouse />} />
          <Route path='/wedding-hotelvenue-india/farmhouse/:id' element={<IndiaFarmHouseDetailpg />} />
          <Route path='/wedding-hotelvenue-international' element={<WeddingHotelAndVenueInternational />} />
          <Route path='/wedding-hotelvenue-international/banquethall' element={<InternationalBanquetHall />} />
          <Route path='/wedding-hotelvenue-international/banquethall/:id' element={<InternationalBanquetHallDetailpg />} />
          <Route path='/wedding-hotelvenue-international/resorts' element={<InternationalResorts />} />
          <Route path='/wedding-hotelvenue-international/resorts/:id' element={<InternationalResortsDetailpg />} />
          <Route path='/wedding-hotelvenue-international/3-star-property' element={<International3StarProperty />} />
          <Route path='/wedding-hotelvenue-international/3-star-property/:id' element={<International3StarPropertyDetailpg />} />
          <Route path='/wedding-hotelvenue-international/5-star-property' element={<International5StarProperty />} />
          <Route path='/wedding-hotelvenue-international/5-star-property/:id' element={<International5StarPropertyDetailpg />} />
          <Route path='/wedding-hotelvenue-international/island' element={<InternationalIsland />} />
          <Route path='/wedding-hotelvenue-international/island/:id' element={<InternationalIslandDetailpg />} />
          <Route path='/wedding-hotelvenue-international/farmhouse' element={<InternationalFarmHouse />} />
          <Route path='/wedding-hotelvenue-international/farmhouse/:id' element={<InternationalFarmHouseDetailpg />} />
          <Route path='/wedding-videoshowsreel' element={<WeddingVideoShowsReel />} />
          <Route path='/wedding-subscriptionplans' element={<WeddingSubscriptionPlan />} />
          <Route path='/wedd-stagesetupdesigns' element={<WeddingStageSetupDesigns />} />
          <Route path='/wedd-soundsetupdesigns' element={<WeddingSoundSetupDesigns />} />
          <Route path='/wedd-lightsetupdesigns' element={<WeddingLightSetupDesigns />} />
          <Route path='/wedd-graphicsetupdesigns' element={<WeddingGraphicSetupDesigns />} />
          <Route path='/wedd-floralsetupdesigns' element={<WeddingFloralSetupDesigns />} />
          <Route path='/wedd-arabicsetupdesigns' element={<WeddingArabicSetupDesigns />} />
          <Route path='/wedd-packages' element={<WeddingPackages />} />
          <Route path='/wedd-packages/:id' element={<WeddingPackagesDetailpg />} />
          <Route path='/wedd-custommadepackages' element={<WeddingCustomMadePackages />} />
          <Route path='/wedd-custommadepackages/:id' element={<WeddingCustomMadePackagesDetailpg />} />
          <Route path='/wedd-travelpackages' element={<WeddingTravelPackages />} />
          <Route path='/wedd-travelpackages/:id' element={<WeddingTravelPackagesDetailpg />} />
          <Route path='/wedd-honeymoonpackages' element={<WeddingHoneymoonPackages />} />
          <Route path='/wedd-honeymoonpackages/:id' element={<WeddingHoneymoonPackagesDetailpg />} />
          <Route path='/wedd-preweddingpackages' element={<WeddingPreWeddingPackages />} />
          <Route path='/wedd-preweddingpackages/:id' element={<WeddingPreWeddingPackagesDetailpg />} />
          <Route path='/wedd-destinationweddingpackages' element={<WeddingDestinationWeddingPackages />} />
          <Route path='/wedd-destinationweddingpackages/:id' element={<WeddingDestinationWeddingPackagesDetailpg />} />
          <Route path='/wedd-query' element={<WeddQuery />} />
          <Route path='/wedding-ourfebricationwork' element={<WeddingOurFebricationWork />} />



          {/* Marx event */}

          <Route path='/marx-event-home' element={<HomeMarxEvent />} />
          <Route path='/event-services' element={<EventServices />} />
          <Route path='/event-shows-gallery' element={<EventShowGallery />} />
          <Route path='/album1-pics' element={<Album1Pics />} />
          <Route path='/album2-pics' element={<Album2Pics />} />
          <Route path='/event-our-work' element={<OurProjects />} />
          <Route path='/ourmodel-india-male' element={<IndModelbankMale />} />
          <Route path='/male-model-detail-pg/:id' element={<MaleModelDetailpg />} />
          <Route path='/ourmodel-india-female' element={<IndModelbankFemale />} />
          <Route path='/female-model-detail-pg/:id' element={<FemaleModelDetailpg />} />
          <Route path='/ourmodel-india-kids' element={<IndKidsModelbank />} />
          <Route path='/kids-model-detail-pg/:id' element={<IndKidsModelDetailpg />} />
          <Route path='/ourmodel-international-female' element={<InternModelFemale />} />
          <Route path='/international-female-model-detail-pg/:id' element={<InternFemaleDetailpg />} />
          <Route path='/ourmodel-international-male' element={<InternModelMale />} />
          <Route path='/international-male-model-detail-pg/:id' element={<InternMaleDetailpg />} />
          <Route path='/ourmodel-international-kids' element={<InternKidsModelbank />} />
          <Route path='/international-kids-model-detail-pg/:id' element={<InternKidsDetailPg />} />
          <Route path='/ind-beauty-pageants-winner' element={<IndBeautyPageantsWinner />} />
          <Route path='/ind-beauty-pageants-winner-detail-pg/:id' element={<IndBeautyPageantsWinnerDetailPg />} />
          <Route path='/international-beauty-pageants-winner' element={<InternBeautyPageantsWinner />} />
          <Route path='/intern-beauty-pageants-winner-detail-pg/:id' element={<InternBeautyPageantsWinnerDetailPg />} />
          <Route path='/ind-beauty-pageants-contestants' element={<IndBeautyPageantsContestants />} />
          <Route path='/ind-beauty-pageants-contestants-detail-pg/:id' element={<IndBeautyPageantsContestantsDetailPg />} />
          <Route path='/international-beauty-pageants-contestants' element={<InternBeautyPageantsContestants />} />
          <Route path='/intern-beauty-pageants-contestants-detail-pg/:id' element={<InternBeautyPageantsContestantsDetailPg />} />
          <Route path='/ind-beauty-pageants-titleholder' element={<IndBeautyPageantsTitleHolder />} />
          <Route path='/ind-beauty-pageants-titleholder-detail-pg/:id' element={<IndBeautyPageantsTitleHolderDetailPg />} />
          <Route path='/international-beauty-pageants-titleholder' element={<InternBeautyPageantsTitleHolder />} />
          <Route path='/intern-beauty-pageants-titleholder-detail-pg/:id' element={<InternBeautyPageantsTitleHolderDetailPg />} />
          <Route path='/page3-events' element={<Page3Events />} />
          <Route path='/awards-achievements' element={<AwardsAndAchievements />} />
          <Route path='/hall-of-fame' element={<HallOfFame />} />
          <Route path='/press-media-coverage' element={<PressMediaCoverage />} />
          <Route path='/event-filter-search' element={<EventsFilterSearch />} />
          <Route path='/event-subscription-plan' element={<EventSubscriptionPackages />} />
          <Route path='/electronic-media-coverage' element={<EventElectronicMediaCoverage />} />
          <Route path='/event-showsvideo-reel' element={<EventShowVideoReel />} />
          <Route path='/marxskin-expert' element={<MarxSkinExperts />} />
          <Route path='/marxskin-expert/:id' element={<MarxSkinExpertDetailPage />} />
          <Route path='/marxfitness-expert' element={<MarxFitnessExperts />} />
          <Route path='/marxfitness-expert/:id' element={<MarxFitnessExpertDetailPage />} />
          <Route path='/marxdiction-expert' element={<MarxDictionExperts />} />
          <Route path='/marxdiction-expert/:id' element={<MarxDictionExpertDetailPage />} />
          <Route path='/marxfashion-stylist' element={<MarxFashionStylist />} />
          <Route path='/marxfashion-stylist/:id' element={<MarxFashionStylistDetailPage />} />
          <Route path='/marxmakeup-artist' element={<MarxMakeupArtist />} />
          <Route path='/marxmakeup-artist/:id' element={<MarxMakeupArtistDetailPage />} />
          <Route path='/marxgrooming-expert' element={<MarxGroomingExpert />} />
          <Route path='/marxgrooming-expert/:id' element={<MarxGroomingExpertDetailPage />} />
          <Route path='/marxlifecoach-expert' element={<MarxLifeCoachExpert />} />
          <Route path='/marxlifecoach-expert/:id' element={<MarxLifeCoachExpertDetailPage />} />
          <Route path='/marxfashion-designer' element={<MarxFashionDesigner />} />
          <Route path='/marxfashion-designer/:id' element={<MarxFashionDesignerDetailPage />} />
          <Route path='/marximage-consultant' element={<MarxImageConsultant />} />
          <Route path='/marximage-consultant/:id' element={<MarxImageConsultantDetailPage />} />
          <Route path='/marxaccessories-designer' element={<MarxAccessoriesDesigner />} />
          <Route path='/marxaccessories-designer/:id' element={<MarxAccessoriesDesignerDetailPage />} />
          <Route path='/marxleadership-qualityexpert' element={<MarxLeadershipQualityexpert />} />
          <Route path='/marxleadership-qualityexpert/:id' element={<MarxLeadershipQualityexpertDetailPage />} />
          <Route path='/marxthematic-shows' element={<MarxThematicShow />} />
          <Route path='/marxsocialcause-shows' element={<MarxSocialCauseShows />} />
          <Route path='/marxproduct-launchshow' element={<MarxProductLaunchShow />} />
          <Route path='/marxconceptualization-showdirection' element={<MarxConceptualizationAndShowDirection />} />
          <Route path='/event-query' element={<EventsQuery />} />
          <Route path='/event-registration-form' element={<EventRegistrationForm />} />
          <Route path='/cart/:id' element={<Cart />} />
          {/* <Route path='/cart' element={<GoToCart />} /> */}



          {/* Marx media */}

          <Route path='/marx-media' element={<MarxMediaHome />} />
          <Route path='/media-filtersearch' element={<MediaFilterSearch />} />
          <Route path='/media-registrationform' element={<MediaRegistrationForm />} />
          <Route path='/media-shows-gallery' element={<MediaShowsGallery />} />
          <Route path='/media-print' element={<MediaPrint />} />
          <Route path='/media-electronic' element={<MediaElectronic />} />
          <Route path='/media-showvideoreel' element={<MediaShowVideoReel />} />
          <Route path='/media-subscriptionplans' element={<MediaSubscriptionPlans />} />
          <Route path='/media-query' element={<MediaQuery />} />
          <Route path='/mediaservice-branding' element={<MediaBranding />} />
          <Route path='/mediaservice-marketing' element={<MediaMarketing />} />
          <Route path='/mediaservice-designing' element={<MediaDesigning />} />
          <Route path='/mediaservice-advertising' element={<MediaAdvertising />} />
          <Route path='/mediaservice-promotions' element={<MediaPromotions />} />
          <Route path='/mediaservice-communication' element={<MediaCommunication />} />
          <Route path='/mediaservice-publicrelations' element={<MediaPublicRelations />} />



          {/* Marx foundation */}

          <Route path='/marx-foundation' element={<MarxFoundationHome />} />
          <Route path='/foundation-shows-gallery' element={<FoundationShowsGallery />} />
          <Route path='/foundation-showvideoreel' element={<FoundationShowVideoReel />} />
          <Route path='/foundation-pressmediacoverage' element={<FoundationPressMediaCoverage />} />
          <Route path='/foundation-contactus' element={<FoundationContactUs />} />

          {/* Marx Real Estate */}

          <Route path='/marx-real-estate' element={<MarxRealEstateHome />} />
          <Route path='/realestate-pricefiltersearch' element={<RealEstatePriceFilterSearch />} />
          <Route path='/realestate-propertyvideoreel' element={<RealEstatePropertyVideoReel />} />
          <Route path='/realestate-gallerypics' element={<RealEstateGalleryPics />} />
          <Route path='/realestate-luxurypenthouse' element={<RealEstateLuxuryPenthouse />} />
          <Route path='/realestate-luxuryvillas' element={<RealEstateLuxuryVillas />} />
          <Route path='/realestate-luxuryresorts' element={<RealEstateLuxuryResorts />} />
          <Route path='/realestate-luxuryfarmhouse' element={<RealEstateLuxuryFarmhouse />} />
          <Route path='/realestate-luxury3starhotel' element={<RealEstateLuxury3StarHotel />} />
          <Route path='/realestate-luxury5starhotel' element={<RealEstateLuxury5StarHotel />} />
          <Route path='/realestate-luxury7starhotel' element={<RealEstateLuxury7StarHotel />} />
          <Route path='/realestate-luxuryapartments' element={<RealEstateLuxuryApartments />} />
          <Route path='/realestate-agriculturelands' element={<RealEstateAgricultureLands />} />
          <Route path='/realestate-commerciallands' element={<RealEstateCommercialLands />} />
          <Route path='/realestate-warehouse' element={<RealEstateWarehouse />} />
          <Route path='/realestate-industriallands' element={<RealEstateIndustrialLands />} />
          <Route path='/realestate-universitylands' element={<RealEstateUniversityLands />} />
          <Route path='/realestate-schoollands' element={<RealEstateSchoolLands />} />
          <Route path='/realestate-collegelands' element={<RealEstateCollegeLands />} />
          <Route path='/realestate-builderfloors' element={<RealEstateBuilderFloors />} />
          <Route path='/realestate-privatesectorlands' element={<RealEstatePrivateSectorLands />} />
          <Route path='/realestate-governmentsectorlands' element={<RealEstateGovernmentSectorLands />} />
          <Route path='/realestate-contactus' element={<RealEstateContactUs />} />





          {/* Marx fashion Couture */}

          <Route path='/marx-fashion-couture' element={<MarxFashionCoutureHome />} />

          {/* Marx Agro Food */}

          <Route path='/marx-agro-food' element={<MarxAgroFoodHome />} />


          {/* Marx Motion Picture */}

          <Route path='/marx-motion-picture' element={<MarxMotionPictureHome />} />

          {/* Marx Academy */}

          <Route path='/marx-academy' element={<MarxAcademyHome />} />


          <Route path='/cardmenu' element={<Cardmenu />} />
          <Route path='/cart-wishlist/:id' element={<WishlistCart />} />
          <Route path='/billingAddress' element={<BillingAddress />} />
          <Route path='/emptypage' element={<EmptyPage />} />
          <Route path='/photo-gallery' element={<PhotoGallery />} />

          {/* Ecommeroce app */}
          <Route path='/filter' element={<Products />} />
          <Route path='/filter/:id' element={<EcomProductDetails />} />


        </Routes>
      </BrowserRouter>

    </>
  );
}

export default App;
