import { Button } from "@mui/material"
import Footer from "../common/MarxEntertainment/footer/Footer"
import Header from "../common/MarxEntertainment/header/Header"
import '../styles/campaignDetailStyle.css'
import { LuCalendarDays } from "react-icons/lu";
import { MdCheckCircle } from "react-icons/md";


const CampaignDetail = () => {
    return (
        <>
            <Header />
            <div className="camp-detail-box">
                <div className="divbox">
                    <h1>Campaign Details</h1>
                </div>

                <div className="leftcampd-div">
                    <div className="right-camp-ad-box col-sm-7">
                        <div className="product-promotion">
                            <h4>Veirdo Promotion</h4>
                            <div>Campaign ID: 1816</div>
                        </div>

                        <div className="prod-by">
                            <ul>
                                <li>
                                    <p className="mb-0">Posted By:</p>
                                    <img src="https://www.influglue.com/public/frontend/images/male_placeholder.png" alt="img" />
                                    <div>Ruksar Shaikh</div>
                                </li>
                                <li>
                                    <p className="mb-0">Posted On:</p>
                                    <LuCalendarDays className="calander-icon" />
                                    <div>28 Nov, 2023</div>
                                </li>
                                <li className="brand-manage">Brand Managed</li>
                            </ul>
                        </div>

                        <div className="product-detail-box mt-5">
                            <ul>
                                <li>
                                    <strong>Product Type</strong>
                                    <small>:</small>
                                    <span>Product</span>
                                </li>
                                <li>
                                    <strong>Brand Name</strong>
                                    <small>:</small>
                                    <span>Veirdo</span>
                                </li>
                                <li>
                                    <strong>Category</strong>
                                    <small>:</small>
                                    <span>Men's Fashion</span>
                                </li>
                                <li>
                                    <strong>Promotion Cities</strong>
                                    <small>:</small>
                                    <span>Ahmedabad, Bangalore, Chennai, Delhi, Ghaziabad, Gurgaon, Hyderabad, Indore, Noida, Pune</span>
                                </li>
                                <li>
                                    <strong>Want to achieve</strong>
                                    <small>:</small>
                                    <span>Brand Activation, Social Media Marketing, Brand Promotion, Content Outreach, Product Reviews</span>
                                </li>
                                <li>
                                    <strong>Celebrities Needed</strong>
                                    <small>:</small>
                                    <span>5 - 10</span>
                                </li>
                                <li>
                                    <strong>Payment Option</strong>
                                    <small>:</small>
                                    <span>Open for Negotiations</span>
                                </li>
                                <li>
                                    <strong>Expected Start</strong>
                                    <small>:</small>
                                    <span>Within 2 weeks</span>
                                </li>
                                <li>
                                    <strong>Application Count</strong>
                                    <small>:</small>
                                    <span>7 Celebrities applied</span>
                                </li>
                                <li>
                                    <strong>Application Remaining</strong>
                                    <small>:</small>
                                    <span>53</span>
                                </li>
                                <li>
                                    <strong>Apply Within</strong>
                                    <small>:</small>
                                    <span>13 Dec, 2023</span>
                                </li>
                            </ul>
                        </div>

                        <div className="overview-task">
                            <h5>Brand Overview</h5>
                            <p>We refuse to be just another brand of apparels. Veirdo intends to provide a product line as unique as its name and a plan that creates standards to set new trends at unbeatable prices!</p>
                        </div>
                        <div className="overview-task">
                            <h5>Task Details</h5>
                            <p>Need Celebrities for Flipkart product reviews.</p>
                        </div>

                        <div className="camp-btn">
                            <Button className="btn1">Apply Now</Button>
                        </div>
                        <div className="camp-notice">
                            <small>*Brand will shortlist profiles. Once shorlisted applicants will be able to view brand contact details.</small>
                        </div>
                    </div>
                    <div className="similar-div col-md-3">
                        <h4>SIMILAR CAMPAIGNS</h4>
                        <p><MdCheckCircle style={{color:'#3745d6'}}/> MensClub Fashion Promotion</p>
                        <p><MdCheckCircle style={{color:'#3745d6'}}/> DENZFOX Promotion</p>
                    </div>
                </div>

            </div>
            <Footer />
        </>
    )
}

export default CampaignDetail
