
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../styles/foundationShowsVideoReelsStyle.css'
import { Link } from 'react-router-dom';
import MarxFoundationFooter from '../../common/MarxFoundation/FooterMarxFoundation';
import MarxFoundationHeader from '../../common/MarxFoundation/HeaderMarxFoundation';

const FoundationShowVideoReel = () => {
    const [videos, setVideos] = useState([]);

    useEffect(() => {
        const fetchVideos = async () => {
            try {
                const response = await axios.get(
                    `https://www.googleapis.com/youtube/v3/search?key=AIzaSyD-AZ-67w2WhyRWHrx_INx4YnVc8t4m4D0&channelId=UCxtU9Xa-Xb4qIHd--_TVLJQ&part=snippet,id&order=date&maxResults=20`
                );

                setVideos(response.data.items);
            } catch (error) {
                console.error('Error fetching videos: ', error);
            }
        };

        fetchVideos();
    }, []);

    return (
        <>
            <MarxFoundationHeader />
            <div className='foundationshow-videoreel-div'>
                <h1>Shows Video Reel</h1>
                <div className="foundationshow-videos">
                    {videos.map((video) => (
                        <div key={video.id.videoId} className="foundationshow-video-reel">
                            <Link
                                to={`https://www.youtube.com/watch?v=${video.id.videoId}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img className='proimg' src={video.snippet.thumbnails.medium.url} alt={video.snippet.title} />
                                <h2>{video.snippet.title}</h2>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>

            <MarxFoundationFooter />
        </>
    );
};

export default FoundationShowVideoReel;
