import { Box, Button } from "@mui/material"
import '../../styles/eventSubscriptionStyle.css'
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import MarxEventHeader from "../../common/Marx_Event/MarxEventHeader/MarxEventHeader";
import MarxEventFooter from "../../common/Marx_Event/MarxEventFooter/MarxEventFooter";

const EventSubscriptionPackages = () => {
    const [packageInfo, setPackageInfo] = useState()
    const navigate = useNavigate();
    useEffect(() => {
        const getPackageData = async () => {
            const reqCatData = await axios.get("https://onlineparttimejobs.in/api/sellerpackage/public", {
                withCredentials: true
            });
            console.log(reqCatData.data)
            setPackageInfo(reqCatData.data);
        }
        getPackageData();
    }, []);

    const addToCartbtn = (id) => {
        console.log("Selected package ID:", id);
        navigate(`/pay/${id}`);
    };
    return (
        <>
            <MarxEventHeader />

            <Box>
                <div className="event-packge-tabpanel">
                    <div className="event-packge-text">
                        <div className="event-div-packge">
                            <h2>Mar'x Events Plans</h2>
                        </div>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <h5 className=" fw-bold pt-1">(PACKAGES)</h5>
                    </div>

                    <div className="event-packages-plans">
                        {packageInfo && packageInfo?.map((item, i) => (
                            <div key={item._id} className="event-nano-package">

                                <div className="event-imgboxdiv">
                                    <img src={item.logo.url} alt="img" />

                                </div>

                                <div className="event-packge-detail">
                                    <p>Package Name: <span>{item.name}</span></p>
                                    <p>Price : <span>₹{item.countries.amount}</span></p>
                                    <p>Max. number of enquires: <span>{item.no_of_enquiries}</span></p>
                                    <p>Max. number of categories: <span>{item.max_categories}</span></p>
                                    <p>Max. number of services: <span>{item.max_services}</span></p>
                                    <p>Duration: <span>{item.duration}</span></p>
                                </div>

                                <div className="event-btn-div text-center mt-5">
                                    <Button onClick={(() => { addToCartbtn(item._id) })} variant='contained'>Add To Cart</Button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Box>

            <MarxEventFooter />
        </>
    )
}

export default EventSubscriptionPackages



