import React, { useEffect } from "react";
import "./Header.css";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Link } from "react-router-dom";
import marxEntLogo from '../../../assest/images/marxentLogo.jpeg'
import { useTranslation } from 'react-i18next';
import { Box, Button } from "@mui/material";
import { GoHomeFill } from "react-icons/go";
import marxEntertmntaudiomp3 from '../../../assest/mp3/marxEntertmnt.mpeg'
import ReactAudioPlayer from "react-audio-player";

function Header() {
  const { t } = useTranslation();

  return (
    <>
      <Box className=''>
        <div className="entt-topdiv-bar">
          <div className="entt-home-btn-div">
            <Link to='/marx-group' className=""> <Button size="small" className=" bg-white text-black p-1"><GoHomeFill className=' fs-5' /></Button></Link>
          </div>

          <div className="bbbbb">
            <div className=" d-flex">
              <ReactAudioPlayer
                src={marxEntertmntaudiomp3}
                autoPlay
                loop
                controls
                className="entt-audioplayer"
              />
            </div>
          </div>
        </div>

        <div className=" bg-black text-white">
          <div className="entt-nav">
            <div className="entt-nav-bar">
              <div className="logo">
                <Link to={'/marx-entertainment'}>
                  <img
                    className="img-logo"
                    src={marxEntLogo}
                    alt="logo"

                  />
                </Link>
              </div>
              <div className="companyname">
                <h1><span className="span1">MAR’X</span> <span className="span2">ENTERTAINMENT</span> <span className="span3"> WORLDWIDE</span> <span className="span4">PVT. LTD.</span></h1>
                <div className="stylistp">
                  <p>(The Artist Manager's)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
}
export default Header;

