import movieimg1 from '../../assest/images/entt.FilmPromotion/movieimg1.jpeg'
import movieimg2 from '../../assest/images/entt.FilmPromotion/movieimg2.jpeg'
import movieimg3 from '../../assest/images/entt.FilmPromotion/movieimg3.jpeg'
import movieimg4 from '../../assest/images/entt.FilmPromotion/movieimg4.jpeg'
import movieimg5 from '../../assest/images/entt.FilmPromotion/movieimg5.jpeg'
import movieimg6 from '../../assest/images/entt.FilmPromotion/movieimg6.jpeg'
import movieimg7 from '../../assest/images/entt.FilmPromotion/movieimg7.jpeg'
import movieimg8 from '../../assest/images/entt.FilmPromotion/movieimg8.jpeg'
import movieimg9 from '../../assest/images/entt.FilmPromotion/movieimg9.jpeg'
import movieimg10 from '../../assest/images/entt.FilmPromotion/movieimg10.jpeg'



const FilmPromotionData = [
    {
        id: 1,
        imgSrc: movieimg1,
    },
    {
        id: 2,
        imgSrc: movieimg2,
    },
    {
        id: 3,
        imgSrc: movieimg3,
    }, {
        id: 4,
        imgSrc: movieimg4,
    }, {
        id: 5,
        imgSrc: movieimg5,
    }, {
        id: 6,
        imgSrc:movieimg6,
    }, {
        id: 7,
        imgSrc:movieimg7,
    }, {
        id: 8,
        imgSrc:movieimg8,
    }, {
        id: 9,
        imgSrc:movieimg9,
    }, {
        id: 10,
        imgSrc:movieimg10,
    }
];
export default FilmPromotionData