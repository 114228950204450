import React from "react";
import "../howItsWorkBlock/HowItsworkBlock.css";
function HowItsWorkBlock() {
  return (
    <>
      <div className="howitswork">
        <div className="mainwapperHowits">
          <div className="contentblockhiw">
            <h2>How Does It Work For Celebrities /?</h2>
            <h3>
              Promote brands through exciting campaigns with your social
              presence and earn real money.
            </h3>
            <p>
              Abaris Celebrities / Artists Marketing is the one-stop solution for brands,
              media partners, and powerful personalities. Take up new campaigns,
              promote through your social presence, and earn real money.
            </p>
            <ul>
              <li>
                <span>01</span>
                <img
                  src="https://www.influglue.com/public/frontend/images/promote.png?v=1688734414"
                  alt="img"
                />
                <h3>Promote</h3>
                <p>Share our content through your social media platforms.</p>
              </li>
              <li>
                <span>02</span>
                <img
                  src="https://www.influglue.com/public/frontend/images/report.png?v=1688734414"
                  alt="img"
                />
                <h3>REPORT</h3>
                <p>
                  Check your progress through the detailed analytical report.
                </p>
              </li>
              <li>
                <span>03</span>
                <img
                  src="https://www.influglue.com/public/frontend/images/get-paid.png?v=1688734414"
                  alt="img"
                />
                <h3>GET PAID</h3>
                <p>Earn real money for what you love to do.</p>
              </li>
            </ul>
          </div>
          <div className="heroImgtwo">
            <img
              className=""
              src="https://www.influglue.com/public/frontend/images/influncer/Saheli.jpg?v=1688734414"
              alt="img"
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default HowItsWorkBlock;
