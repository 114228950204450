import weddentmnt1 from '../../assest/images/weddEntt.Gallery/weddentmnt1.jpeg'
import weddentmnt2 from '../../assest/images/weddEntt.Gallery/weddentmnt2.jpeg'
import weddentmnt3 from '../../assest/images/weddEntt.Gallery/weddentmnt3.jpeg'
import weddentmnt4 from '../../assest/images/weddEntt.Gallery/weddentmnt4.jpeg'
import weddentmnt5 from '../../assest/images/weddEntt.Gallery/weddentmnt5.jpeg'
import weddentmnt6 from '../../assest/images/weddEntt.Gallery/weddentmnt6.jpeg'
import weddentmnt7 from '../../assest/images/weddEntt.Gallery/weddentmnt7.jpeg'
import weddentmnt8 from '../../assest/images/weddEntt.Gallery/weddentmnt8.jpeg'
import weddentmnt9 from '../../assest/images/weddEntt.Gallery/weddentmnt9.jpeg'
import weddentmnt10 from '../../assest/images/weddEntt.Gallery/weddentmnt10.jpeg'
import weddentmnt11 from '../../assest/images/weddEntt.Gallery/weddentmnt11.jpeg'
import weddentmnt12 from '../../assest/images/weddEntt.Gallery/weddentmnt12.jpeg'






const EntertainmentGalleryData = [
    {
        id: 1,
        imgSrc: weddentmnt1,
    },
    {
        id: 2,
        imgSrc: weddentmnt2,
    },
    {
        id: 3,
        imgSrc: weddentmnt3,
    }, {
        id: 4,
        imgSrc: weddentmnt4,
    }, {
        id: 5,
        imgSrc: weddentmnt5,
    }, {
        id: 6,
        imgSrc:weddentmnt6,
    }, {
        id: 7,
        imgSrc:weddentmnt7,
    }, {
        id: 8,
        imgSrc:weddentmnt8,
    }, {
        id: 9,
        imgSrc:weddentmnt9,
    }, {
        id: 10,
        imgSrc:weddentmnt10,
    },{
        id: 11,
        imgSrc:weddentmnt11,
    },{
        id: 12,
        imgSrc:weddentmnt12,
    }
];
export default EntertainmentGalleryData;