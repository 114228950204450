import Footer from "../../common/MarxEntertainment/footer/Footer"
import Header from "../../common/MarxEntertainment/header/Header"
import QueryForm from "../../common/QueryForm/QueryForm"
import Sidebar from "../../common/SideAbleMenu/SideBar"

const EntertainmentQuery = () => {
    return (
        <>
            <Sidebar />
            <Header />
            <QueryForm />
            <Footer />
        </>
    )
}

export default EntertainmentQuery
