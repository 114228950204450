import modelBankGallery1 from '../../assest/images/eventModelBankGallery/modelBankGallery1.jpg'
import modelBankGallery2 from '../../assest/images/eventModelBankGallery/modelBankGallery2.jpg'
import modelBankGallery3 from '../../assest/images/eventModelBankGallery/modelBankGallery3.jpg'
import modelBankGallery4 from '../../assest/images/eventModelBankGallery/modelBankGallery4.jpg'
import modelBankGallery5 from '../../assest/images/eventModelBankGallery/modelBankGallery5.jpg'
import modelBankGallery6 from '../../assest/images/eventModelBankGallery/modelBankGallery6.jpg'
import modelBankGallery7 from '../../assest/images/eventModelBankGallery/modelBankGallery7.jpg'
import modelBankGallery8 from '../../assest/images/eventModelBankGallery/modelBankGallery8.jpg'
import modelBankGallery9 from '../../assest/images/eventModelBankGallery/modelBankGallery9.jpeg'
import modelBankGallery10 from '../../assest/images/eventModelBankGallery/modelBankGallery10.jpeg'
import modelBankGallery11 from '../../assest/images/eventModelBankGallery/modelBankGallery11.jpeg'
import modelBankGallery12 from '../../assest/images/eventModelBankGallery/modelBankGallery12.jpeg'
import modelBankGallery13 from '../../assest/images/eventModelBankGallery/modelBankGallery13.jpeg'
import modelBankGallery14 from '../../assest/images/eventModelBankGallery/modelBankGallery14.jpeg'
import modelBankGallery15 from '../../assest/images/eventModelBankGallery/modelBankGallery15.jpeg'
import modelBankGallery16 from '../../assest/images/eventModelBankGallery/modelBankGallery16.jpeg'
import modelBankGallery17 from '../../assest/images/eventModelBankGallery/modelBankGallery17.jpeg'
import modelBankGallery18 from '../../assest/images/eventModelBankGallery/modelBankGallery18.jpeg'
import modelBankGallery19 from '../../assest/images/eventModelBankGallery/modelBankGallery19.jpeg'
import modelBankGallery20 from '../../assest/images/eventModelBankGallery/modelBankGallery20.jpeg'
import modelBankGallery21 from '../../assest/images/eventModelBankGallery/modelBankGallery21.jpg'
import modelBankGallery22 from '../../assest/images/eventModelBankGallery/modelBankGallery22.jpg'
import modelBankGallery23 from '../../assest/images/eventModelBankGallery/modelBankGallery23.jpg'
import modelBankGallery24 from '../../assest/images/eventModelBankGallery/modelBankGallery24.jpg'
import modelBankGallery25 from '../../assest/images/eventModelBankGallery/modelBankGallery25.jpg'
import modelBankGallery26 from '../../assest/images/eventModelBankGallery/modelBankGallery26.jpg'
import modelBankGallery27 from '../../assest/images/eventModelBankGallery/modelBankGallery27.jpg'
import modelBankGallery28 from '../../assest/images/eventModelBankGallery/modelBankGallery28.jpg'
import modelBankGallery29 from '../../assest/images/eventModelBankGallery/modelBankGallery29.jpg'




const TopMarxEventData = [
    {
        id: 1,
        imgSrc: modelBankGallery1,
    },
    {
        id: 2,
        imgSrc: modelBankGallery2,
    },
    {
        id: 3,
        imgSrc: modelBankGallery3,
    }, {
        id: 4,
        imgSrc: modelBankGallery4,
    }, {
        id: 5,
        imgSrc: modelBankGallery5,
    }, {
        id: 6,
        imgSrc:modelBankGallery6,
    }, {
        id: 7,
        imgSrc:modelBankGallery7,
    }, {
        id: 8,
        imgSrc:modelBankGallery8,
    }, {
        id: 9,
        imgSrc:modelBankGallery9,
    }, {
        id: 10,
        imgSrc:modelBankGallery10,
    },{
        id: 11,
        imgSrc:modelBankGallery11,
    },{
        id: 12,
        imgSrc:modelBankGallery12,
    },{
        id: 13,
        imgSrc:modelBankGallery13,
    },{
        id: 14,
        imgSrc:modelBankGallery14,
    },{
        id: 15,
        imgSrc:modelBankGallery15,
    },{
        id: 16,
        imgSrc:modelBankGallery16,
    },{
        id: 17,
        imgSrc:modelBankGallery17,
    },{
        id: 18,
        imgSrc:modelBankGallery18,
    },{
        id: 19,
        imgSrc:modelBankGallery19,
    },{
        id: 20,
        imgSrc:modelBankGallery20,
    },{
        id: 21,
        imgSrc:modelBankGallery21,
    },{
        id: 22,
        imgSrc:modelBankGallery22,
    },{
        id: 23,
        imgSrc:modelBankGallery23,
    },{
        id: 24,
        imgSrc:modelBankGallery24,
    },{
        id: 25,
        imgSrc:modelBankGallery25,
    },{
        id: 26,
        imgSrc:modelBankGallery26,
    },{
        id: 27,
        imgSrc:modelBankGallery27,
    },{
        id: 28,
        imgSrc:modelBankGallery28,
    },{
        id: 29,
        imgSrc:modelBankGallery29,
    }
];
export default TopMarxEventData;