import { useEffect, useState } from "react"
import FilterSection from "./FilterSection"
import ProductList from "./ProductList"
import Sort from "./Sort"
import { useGetAllDataQuery } from "../../Services/productApi"

const Products = () => {
    const [grid, setGrid] = useState(true);
    const { data, isLoading, isSuccess, isError } = useGetAllDataQuery();
    console.log("response-information :", data, isLoading, isSuccess, isError);
    const [searchTerm, setSearchTerm] = useState('');



    if (isSuccess) {
        console.log("Data----:", data);
    }

    if (isLoading) {
        return <div className=" fw-bold fs-1 text-danger">Loading...</div>;
    }

    if (isError) {
        return <div className=" fw-bold fs-1 text-danger">Error</div>;
    }


    // Filter products based on search term
    const filteredProducts = data?.filter(product =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
    ) || [];

    console.log("all products---------", filteredProducts)



    const handleGridClick = () => {
        setGrid(true);
        console.log("true")
    }

    const handleMenuClick = () => {
        setGrid(false);
        console.log("false")

    }

    return (
        <>
            <div className="row">
                <div className="col-sm-3">
                    <FilterSection searchTerm={searchTerm} setSearchTerm={setSearchTerm} filteredProducts={filteredProducts} />
                </div>
                <div className="col-sm-8">
                    <div>
                        <Sort
                            onGridClick={handleGridClick}
                            onMenuClick={handleMenuClick}
                            grid={grid}
                            data={filteredProducts}
                        />
                    </div>
                    <div>
                        <ProductList grid={grid} filteredProducts={filteredProducts} />
                    </div>
                </div>

            </div>

        </>
    )
}

export default Products
