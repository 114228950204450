
import Card from 'react-bootstrap/Card';
import DataCard from "../../data/CardData";
import { useState } from "react"
import { Button } from "@mui/material"
import { MdOutlineShoppingCart } from "react-icons/md";
import { Link } from 'react-router-dom';

const Cardmenu = () => {

  const [showData, SetShowData] = useState(DataCard);

  const filterResult = (catgItem) => {
    try {
      const showResult = DataCard.filter((curnData) => {
        return curnData.category === catgItem;
      });
      // const showprice = DataCard.filter((item) => {
      //   return item.price >= 100;
      // });

      SetShowData(showResult);
      // SetShowData(showprice);
      console.log(showResult);
      // console.log(showprice);
    }
    catch (error) {
      console.log('not working')
    }
  }

  return (
    <>
      <div className=' d-flex justify-content-end bg-black text-white p-3 mb-4'>
        <MdOutlineShoppingCart className=' fs-4' />
        <span>0</span>
      </div>
      <div className=" row">
        <div className=" col-sm-3">
          <Button onClick={() => { filterResult("men") }} className=" w-100 my-1" variant="contained">men's clothing</Button>
          <Button onClick={() => { filterResult("women") }} className=" w-100 my-1" variant="contained">women's clothing</Button>
          <Button onClick={() => { filterResult("electronics") }} className=" w-100 my-1" variant="contained">electronics</Button>
          <Button onClick={() => { filterResult("jewelery") }} className=" w-100 my-1" variant="contained">jewelery</Button>
          <Button onClick={() => { SetShowData(DataCard) }} className=" w-100 my-1" variant="contained">All</Button>
          {/* <Button onClick={()=>{filterResult()}} className=" w-100 my-1" variant="contained">price</Button> */}
        </div>
        <div className=" col-sm-9">

          <div className="row">
            {showData?.map((item) => {
              return (
                <Card className=" col-md-3 m-3" key={item.id}>
                  <Card.Img variant="top" src={item.image} />
                  <Card.Body>
                    <Card.Title>{item.title}</Card.Title>
                    <Card.Text>
                      {item.price}
                    </Card.Text>
                    <Link to={`/cart-wishlist/${item.id}`}>
                    <Button variant="contained">add to cart</Button>
                    </Link>
                  </Card.Body>
                </Card>
              )
            })}

          </div>

        </div>
      </div>
    </>
  )
}
export default Cardmenu