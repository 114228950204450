import React from 'react';
import * as RiIcons from 'react-icons/ri';
import { FcBusinesswoman } from "react-icons/fc";
import { FcServices } from "react-icons/fc";
import { FcHome } from "react-icons/fc";
import { FcPlanner } from "react-icons/fc";
import { FaHandHoldingDollar } from "react-icons/fa6";
import { PiInstagramLogoFill } from "react-icons/pi";
import { BsYoutube } from "react-icons/bs";
import { IoMdPeople } from "react-icons/io";
import { FcAdvertising } from "react-icons/fc";
import { BiLogoBlogger } from "react-icons/bi";
import { FcContacts } from "react-icons/fc";


export const MarxEntertainmentSidebarData = [
  {
    title: 'Home',
    path: '/marx-group',
    icon: <FcHome />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: 'Artists & Celebrity',
    path: '/our-influencers',
    icon: <FcBusinesswoman />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: 'Services',
    path: '/our-service',
    icon: <FcServices />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: 'Start a Campaign',
    path: '/advertiser-signup',
    icon: <FcPlanner />
  },
  // {
  //   title: 'Pricing ',
  //   path: '/pricing',
  //   icon: <FaHandHoldingDollar style={{ color: 'orange' }} />,
  //   iconClosed: <RiIcons.RiArrowDownSFill />,
  //   iconOpened: <RiIcons.RiArrowUpSFill />,
  //   subNav: [
  //     {
  //       title: 'Instagram Packages',
  //       path: '/instagram-plan',
  //       icon: <PiInstagramLogoFill style={{ color: ' #C13584' }} />
  //     },
  //     {
  //       title: 'YouTube Packages',
  //       path: '/youtube-plan',
  //       icon: <BsYoutube style={{ color: ' rgb(219, 34, 34)' }} />
  //     },
  //     {
  //       title: '/Guest Post Packages',
  //       path: '//guest-posts',
  //       icon: <IoMdPeople style={{ color: 'orange' }} />
  //     }
  //   ]
  // },
  {
    title: 'Subscription',
    path: '/instagram-plan',
    icon: <FaHandHoldingDollar style={{ color: 'orange' }} />,
    // iconClosed: <RiIcons.RiArrowDownSFill />,
    // iconOpened: <RiIcons.RiArrowUpSFill />,

    // subNav: [
    //   {
    //     title: 'Instagram Package',
    //     path: '/instagram-plan',
    //     icon: <PiInstagramLogoFill style={{ color: ' #C13584' }} />,
    //   },
    //   {
    //     title: 'Youtube Package',
    //     path: '/youtube-plan',
    //     icon: <BsYoutube style={{ color: ' rgb(219, 34, 34)' }} />,
    //   },
    //   {
    //     title: 'Guest Post Package',
    //     path: '/guest-posts',
    //     icon: <IoMdPeople style={{ color: 'orange' }} />
    //   }
    // ]
  },
  {
    title: 'Campaigns',
    path: '/Campaigns',
    icon: <FcAdvertising />,

    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
  },
  {
    title: 'Blog',
    path: '/Blog',
    icon: <BiLogoBlogger style={{ color: 'orange' }} />
  },
  {
    title: 'Contact',
    path: '/contact',
    icon: <FcContacts />
  }

];
