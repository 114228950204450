
import GalleryPhotos from "../../common/Gallery/Gallery"
import Sidebar from "../../common/SideAbleMenu/SideBar"
import WeddingFooter from "../../common/marxWedding/FooterMarxWedding"
import MarxWeddingHeader from "../../common/marxWedding/HeaderMarxWedding"
import OurFebricationWorkData from "../../data/MarxWedding/OurFebricationWorkData"

const WeddingOurFebricationWork = () => {
    return (
        <>
            <Sidebar />
            <MarxWeddingHeader />
            <GalleryPhotos GalleryData={OurFebricationWorkData} title={'Our Febrication Work'} />
            <WeddingFooter />
        </>
    )
}

export default WeddingOurFebricationWork
