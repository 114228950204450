
import { Box, Button, IconButton, InputAdornment, TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import './marxGroupLoginStyle.css'
import { useState } from 'react';
import axios from 'axios';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Form from 'react-bootstrap/Form';


const MarxGroupLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginD, setLoginD] = useState([])
  const [showPassword, setShowPassword] = useState(false);
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [bordrColor, setBordrColor] = useState("");
  const [shake, setShake] = useState(false); // State for controlling shake effect

  console.log('loginD', loginD);

  const navigate = useNavigate()

  const handleLoginmodel = () => {
    navigate('/marx-group-signup')
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const onCloseBtn = () => {
    navigate('/');

  };

  const getLoginData = async () => {
    try {
      if (!email || !password) {
        // You can display an error message or handle it in any way you prefer.
        setErrorEmail('enter email')
        setErrorPassword('enter password')
        console.log("Email and password are required.");
        setBordrColor('1px solid red');
        setShake(true); // Trigger shake effect
        setTimeout(() => setShake(false), 500); // Turn off shake effect after 500ms
        return;
      }
      const reqLoginData = await axios.post(
        "https://onlineparttimejobs.in/api/sellerList/login",
        {
          email: email,
          password: password,
        },
      );

      console.log("login successful", reqLoginData.data);
      setLoginD(reqLoginData.data);


      // const logintoken = reqLoginData.data.token;
      // window.localStorage.setItem("token", logintoken);
      navigate('/marx-group');
    } catch (error) {
      console.log("Login failed", error);
      // Check if the error is due to invalid credentials
      if (error.response && error.response.status === 401) {
        console.log("Invalid email or password");
        setErrorEmail('Invalid email or password');
        setErrorPassword('Invalid email or password');
        setBordrColor('1px solid red');
        setShake(true); // Trigger shake effect
        setTimeout(() => setShake(false), 500); // Turn off shake effect after 500ms
        return;
      } else {
        setErrorEmail('Invalid email or password');
        setErrorPassword('Invalid email or password');
        setBordrColor('1px solid red');
        console.log("An error occurred:", error.message);
        setShake(true); // Trigger shake effect
        setTimeout(() => setShake(false), 500); // Turn off shake effect after 500ms
        return;
      }
    }
  }

  const onLginClick = () => {
    // console.log("login message","login suceessfully")
    // navigate('/marx-group')
    getLoginData();
  };
  const onForgotPassword = () => {
    navigate('/forgot-password')
  };


  return (
    <>

      <Box className='bg-img-star'>
        <Box className='modelloginbox'>
          <CloseIcon onClick={onCloseBtn} className='closeiconbtn' />

          <Box className='loginboxdiv'>
            <h5 className=" text-center mb-4 fw-bold">Sign In</h5>
            <div>
              <TextField
                onChange={handleEmailChange}
                className={shake ? 'shake-animation' : ''}
                style={{ border: bordrColor, borderRadius: '4px' }}
                size="small" placeholder="Email" fullWidth />
              <p className="error">{errorEmail}</p>
            </div>

            <div className="mt-3">
              <TextField
                // className='passwordinput'
                onChange={handlePasswordChange}
                className={`passwordinput ${shake ? 'shake-animation' : ''}`}
                size="small" placeholder="Password" fullWidth
                type={showPassword ? 'text' : 'password'}
                style={{ border: bordrColor, borderRadius: '4px' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility}>
                        {showPassword ? <VisibilityIcon className=' text-white' /> : <VisibilityOffIcon className=' text-white' />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <p className="error">{errorPassword}</p>

            </div>
            <div className='keepsignin'>
              <p>
                <Form.Check
                  className='chckk'
                  label="keep me sign in"
                  name="group1"
                />
              </p>
            </div>

          </Box>

          <div className='loginbtnnbox'>
            <Button onClick={onLginClick} size='small'>LogIn</Button>
          </div>
          <div className='forgotpassworddiv'>
            <Button onClick={onForgotPassword} size='small'>Forgot Password ?</Button>
          </div>
          <div className='loginbtnnbox'>
            <p>OR</p>
          </div>
          <div className='loginbtnnbox'>
            <Button className='mx-5' onClick={handleLoginmodel} size='small' fullWidth>SignUp</Button>
          </div>
        </Box>

      </Box>


    </>
  )
}

export default MarxGroupLogin;
