import {createApi ,fetchBaseQuery} from '@reduxjs/toolkit/query/react';

//create api services
export const ProductApi=createApi({
    reducerPath:'productApi',
    baseQuery: fetchBaseQuery({
        baseUrl: 'https://api.pujakaitem.com/api/',
    }),
    endpoints:(builder)=>({
        getAllData: builder.query({
            query:()=>({
                url:'products',
                method:"GET"
            })
        })
    })
    
})

export const {useGetAllDataQuery}=ProductApi;
