import { Rating } from "@mui/material"
import '../../styles/testimonialStyle.css'
import { TfiLocationPin } from "react-icons/tfi";
import MarxGroupFooter from "../../common/marxGroup/MarxGroupFooter";
import MarxGroupHeader from "../../common/marxGroup/MarxGroupHeader";
import { useEffect, useState } from "react";
import axios from "axios";


const Testimonials = () => {
    const [Comment, setComment] = useState();

    const Comments = async () => {
        try {
            const response = await axios.get('https://api.pujakaitem.com/api/products');
            console.log('Comments', response.data);
            setComment(response.data)
        } catch (error) {
            console.error('Error fetching product data:', error);
        }
    }

    useEffect(() => {
        Comments();
    }, [])
    return (
        <>
            <MarxGroupHeader />

            <div className="testimonial-box">
                <div className="div-box">
                    <h1>Testimonials</h1>
                </div>
                <div>
                    {Comment && Comment?.map((item, index) => (
                        <div key={index} className="comnt-box">
                            <div>
                                <img src={item.image} alt="img" />
                            </div>
                            <div className="detail-box">
                                <div className="person-info">
                                    <h4>{item.name}</h4>
                                    <p><TfiLocationPin className="mb-1 me-1" />{item.company}</p>
                                </div>
                                <p>
                                    <Rating
                                        name="Rating"
                                        precision={0.5}
                                        value={item.price}
                                        readOnly
                                    />
                                </p>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <MarxGroupFooter />
        </>
    )
}

export default Testimonials
