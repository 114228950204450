import indkids1 from '../../../../assest/images/eventOurModelBank/india/kids/indkids1.jpeg'
import indkids2 from '../../../../assest/images/eventOurModelBank/india/kids/indkids2.jpeg'
import indkids3 from '../../../../assest/images/eventOurModelBank/india/kids/indkids3.jpeg'
import indkids4 from '../../../../assest/images/eventOurModelBank/india/kids/indkids4.jpeg'
import indkids5 from '../../../../assest/images/eventOurModelBank/india/kids/indkids5.jpeg'
import indkids6 from '../../../../assest/images/eventOurModelBank/india/kids/indkids6.jpeg'




const IndModelKidsData = [
  {
    id:1,
    src: indkids1,
    age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Ankit'
  },
  {
    id:2,
    src: indkids2,
    age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Digansh'
  },
  {
    id:3,
    src: indkids3,
    age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Lakshay'
  },
  {
    id:4,
    src: indkids4,
    age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Gaurav'
  },
  {
    id:5,
    src: indkids5,
    age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Digansh'
  },
  {
    id:6,
    src: indkids6,
    age:5,
    gender:"female",
    height:'1.2 m',
    workExperience:"image source and profile name as state",
    profileName: 'riya'
  }
];

export default IndModelKidsData