import GalleryPhotos from '../../common/Gallery/Gallery'
import SecondPicsData from '../../data/marxEvent/SecondPicsData'
import MarxRealEstateHeader from '../../common/MarxRealEstate/HeaderMarxRealEstate'
import MarxRealEstateFooter from '../../common/MarxRealEstate/FooterMarxRealEstate'

const RealEstateGalleryPics = () => {
    return (
        <>
            <MarxRealEstateHeader />
            <GalleryPhotos  GalleryData={SecondPicsData} title={'Gallery Pics'}/>
            <MarxRealEstateFooter />
        </>
    )
}

export default RealEstateGalleryPics
