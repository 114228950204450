import eventCelbs1 from '../../assest/images/eventExclusiveFeatured/eventCelbs1.jpeg'
import eventCelbs2 from '../../assest/images/eventExclusiveFeatured/eventCelbs2.jpeg'
import eventCelbs3 from '../../assest/images/eventExclusiveFeatured/eventCelbs3.jpeg'
import eventCelbs4 from '../../assest/images/eventExclusiveFeatured/eventCelbs4.jpeg'
import eventCelbs5 from '../../assest/images/eventExclusiveFeatured/eventCelbs5.jpeg'
import eventCelbs6 from '../../assest/images/eventExclusiveFeatured/eventCelbs6.jpeg'




const ExclusiveFeaturedData = [
    {
        id: 1,
        imgSrc:eventCelbs1,
    },
    {
        id: 2,
        imgSrc:eventCelbs2,
    },
    {
        id: 3,
        imgSrc:eventCelbs3,
    }, {
        id: 4,
        imgSrc:eventCelbs4,
    }, {
        id: 5,
        imgSrc:eventCelbs5,
    }, {
        id: 6,
        imgSrc:eventCelbs6,
    }
];
export default ExclusiveFeaturedData;