import { Link } from "react-router-dom"
import MarxEventHeader from "../../../common/Marx_Event/MarxEventHeader/MarxEventHeader"
import MarxEventFooter from "../../../common/Marx_Event/MarxEventFooter/MarxEventFooter"
import '../../../styles/eventRegistrationFormStyle.css'
import { Box } from "@mui/material"

const EventRegistrationForm = () => {
    return (
        <>
            <MarxEventHeader />
            <Box className="event-registforms">
                <h1>Registration Forms</h1>
                <ul className="event-form-name">
                    <li><Link>India & International Model Registration Form</Link></li>
                    <li><Link> Beauty Pageants Winner / Contestant / Title Holder Registration Form</Link></li>
                    <li><Link>Vendor Registration Form</Link></li>
                    <li><Link>Fashion Folio Registration Form</Link></li>
                    <li><Link>Registration Form</Link></li>
                    <li><Link>Registration Form</Link></li>
                    <li><Link>Registration Form</Link></li>
                    <li><Link>Registration Form</Link></li>
                    <li><Link>Registration Form</Link></li>
                    <li><Link>Registration Form</Link></li>
                    <li><Link>Registration Form</Link></li>
                    <li><Link>Registration Form</Link></li>
                    <li><Link>Registration Form</Link></li>
                </ul>
            </Box>
            <MarxEventFooter />
        </>
    )
}

export default EventRegistrationForm
