import comingsoon1 from '../assest/images/comingsoon1.jpeg'




const GalleryWithNameData = [
    {
        id: 1,
        name:'Billu',
        src: comingsoon1,
    },
    {
        id: 2,
        name:'aman',
        src: comingsoon1,
    },
    {
        id: 3,
        name:'abcd',
        src: comingsoon1,
    }, {
        id: 4,
        name:'Billu',
        src: comingsoon1,
    }, {
        id: 5,
        name:'Billu',
        src: comingsoon1,
    }, {
        id: 6,
        name:'Billu',
        src:comingsoon1,
    }, {
        id: 7,
        name:'Billu',
        src:comingsoon1,
    }, {
        id: 8,
        name:'Billu',
        src:comingsoon1,
    }, {
        id: 9,
        name:'Billu',
        src:comingsoon1,
    }, {
        id: 10,
        name:'Billu',
        src:comingsoon1,
    },{
        id: 11,
        name:'Billu',
        src:comingsoon1,
    },{
        id: 12,
        name:'Billu',
        src:comingsoon1,
    },{
        id: 13,
        name:'Billu',
        src:comingsoon1,
    },{
        id: 14,
        name:'Billu',
        src:comingsoon1,
    }
];
export default GalleryWithNameData;