import { FaCheckCircle } from "react-icons/fa";
import { useParams } from "react-router-dom";
import DataCard from "../../data/CardData";

const WishlistCart = () => {
  let {id}=useParams();
   console.log(id);

   const singleData= DataCard.find((item)=>item.id==id);
   console.log(singleData)

   const {price,image}=singleData;
  return (
    <>
      <div className=" d-flex">
        <div>
          <img style={{width:'70px'}} src={image} alt="img" />
        </div>
        <div>
          <p><FaCheckCircle/> Added To Cart</p>
          <p>Price : {price}</p>
        </div>
      </div>
    </>
  )
}

export default WishlistCart
