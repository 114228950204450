import './galleryWithBioAllMaxStyle.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const GalleryWitthBioAllMax = ({heading,title,detailpg,ModelData}) => {
  // Define the number of slides to show for laptop and mobile
  const slidesToShowLaptop = 4;
  const slidesToShowMobile = 1;

  // Set the initial slidesToShow based on the screen width
  const initialSlidesToShow = window.innerWidth >= 768 ? slidesToShowLaptop : slidesToShowMobile;

  const settings = {
    infinite: true,
    slidesToShow: initialSlidesToShow,
    swipeToSlide: true,
    arrows: true,
    autoplay: true,
    speed: 700,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: '600px', // adjust this breakpoint according to your design
        settings: {
          slidesToShow: slidesToShowMobile,
        },
      },
      {
        breakpoint: '768px', // adjust this breakpoint according to your design
        settings: {
          slidesToShow: slidesToShowLaptop,
        },
      }


      // Add more breakpoints as needed
    ],
  };
  return (
    <>
        <div className="gallerymodel-Container">
           <h1 className="gallerymodel-title">{heading}</h1>  
          <div className="galleryflexbox">
            {ModelData.map((item) => (
              <Link className="gallery-anchrtag" to={`/${detailpg}/${item.id}`}>
                <div key={item.id} className='galleryphoto-container'>
                  <img className="gallery-photoimg" src={item.src} alt={item.profileName} />
                  <p className="gallery-photocaption">{item.profileName}</p>
                </div>
              </Link>
            ))}
          </div>
        </div>

        <div className="imgg-sldddiv">
           <h1 className=' text-white fw-bold ms-3'>{title}</h1> 
          <div className="divvb">
              <Slider className="sliderr-box" {...settings}>
                {ModelData.map((item) => (
                  <div className="img-slidebox">
                    <img src={item.src} alt="img" />
                  </div>
                ))}
              </Slider>
          </div>
        </div>
    </>
  )
}

export default GalleryWitthBioAllMax
