import "./Blog.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import img7 from "../../../assest/images/267779c774cfb39494104a87deee12f7.jpg";
import Pagination from '@mui/material/Pagination';
import { useEffect, useState } from "react";
import axios from "axios";
import MarxGroupFooter from "../../../common/marxGroup/MarxGroupFooter";
import MarxGroupHeader from "../../../common/marxGroup/MarxGroupHeader";

function Blog() {
  const [blog, setBlog] = useState();

  useEffect(() => {
    const getBlogData = async () => {
      const reqAllBlogData = await axios.get("https://onlineparttimejobs.in/api/blogs", {
        withCredentials: true
      });
      console.log("Blog data", reqAllBlogData?.data)
      setBlog(reqAllBlogData.data)
    }
    getBlogData();
  }, []);

  return (
    <>
      <MarxGroupHeader/>
      <div className="containerdiv" style={{ padding: "10px" }}>
        <div className="row">
          <div className="col-md-12">
            <div className="breadcrum">
              <div className="main wrapper">
                <a href="/marx-group">Home</a>
                <span>{">>"}</span>
                Blog
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="mid-content">
        <div className="containerdiv">
          <div className="row top-sectionRow">
            <div className="col-md-12 top-sectionHeading">
              <h2>TRENDING</h2>
            </div>
            <div className="top-section" id="top-section">
              <div className="lefttopsection">
                {blog?.slice(0, 1).map((item) => {
                    return (
                      <div className="top-sectionBanner">
                      <img src={item.meta_img.url} alt="img" className="img-fluid" />
                      <div className="top-sectionLabel">
                        <div className="top-sectionBannerLabel">
                          <h2>
                          {item.title}
                          </h2>
                        </div>
                        <div className="date">Nov 22,2023</div>
                      </div>
                    </div>
                    )
                  })}
              </div>
              <div className="righttopsection">
                <div className="top-leftSection">
                  {blog?.slice(0, 4).map((item) => {
                    return (
                      <div className="left-section1 col-md-12">
                        <a href="/">
                          <div className="imgBlock">
                            <img src={item.meta_img.url} alt="img" className="img-fluid" />
                          </div>
                          <div className="textBlock">
                            <h3>
                              {item.title}
                            </h3>
                            <div className="date">Oct 16,2023</div>
                          </div>
                        </a>
                      </div>
                    )

                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="containerdiv">
          <div className="row down-sectionRow">
            <div className="col-md-8 leftside">
              <div className="col-md-12 down-colheading ">
                <h2>ALL POSTS</h2>
              </div>
              <div className="col-md-12  " style={{ marginBottom: "20px" }}>
                <Form.Select
                  aria-label="Default select example"
                  id="down-colSelect"
                >
                  <option>All Categories</option>
                  <option value="1">Celebrities marketing</option>
                  <option value="2">Social Celebrities marketing</option>
                </Form.Select>
              </div>
              <div className="col-md-12 wrap-downSectionBlock">
                {blog?.map((it)=>{
                  return (
                    <div className="downBlock">
                    <a href="/">
                      <div className="img-holder">
                        <img src={it.meta_img.url} alt="" className="img-fluid" />
                      </div>
                      <h2>{it.title}</h2>
                      <div className="dateDown">Nov 22,2023</div>
                    </a>
                  </div>
                  )
                })}
              </div>
              <div className="col-md-12 down-pageholder ">
                <Pagination count={10} />
              </div>
            </div>
            <div className="col-md-4 rightside">
              <div className="wrap">
                <div className="col-md-12 downside-colheading ">
                  <h2>SEARCH </h2>
                </div>
                <div className="col-md-12 search-submit">
                  <input type="text" placeholder="Search your blog topic" />
                  <Button variant="dark">SUBMIT</Button>
                </div>
              </div>

              <div className="col-md-12">
                <div className="downside-section">
                  <div className="downside-sectionBlock">
                    <a href="/" style={{ textDecoration: "none" }}>
                      <h2>Want to start your first campaign?</h2>
                      <hr />
                      <p>Sign up with us and connect with various brands</p>
                      <span>Sign Up as Celebrities</span>
                    </a>
                  </div>
                </div>
                <div className="downside-section">
                  <div
                    className="downside-sectionBlock"
                    style={{ backgroundColor: "#00ce60" }}
                  >
                    <a href="/" style={{ textDecoration: "none" }}>
                      <h2>Want to start your first campaign?</h2>
                      <hr />
                      <p>Sign up with us and connect with various brands</p>
                      <span style={{ color: "#00ce60" }}>
                        Sign Up as Celebrities
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="downside-heading2">
                  <h2>DOWNLOAD EBOOKS</h2>
                </div>
                <div className="ebook-downSection">
                  <div className="ebookBlock">
                    <a href="/">
                      <div className="ebook-cover">
                        <img src={img7} alt="" className="img-fluid" />
                      </div>
                      <div className="ebook-text">
                        <h3>How To Become An Celebrities</h3>
                        <span>DOWNLOAD</span>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="ebook-downSection">
                  <div className="ebookBlock">
                    <a href="/">
                      <div className="ebook-cover">
                        <img src={img7} alt="" className="img-fluid" />
                      </div>
                      <div className="ebook-text">
                        <h3>How To Become An Celebrities</h3>
                        <span>DOWNLOAD</span>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="ebook-downSection">
                  <div className="ebookBlock">
                    <a href="/">
                      <div className="ebook-cover">
                        <img src={img7} alt="" className="img-fluid" />
                      </div>
                      <div className="ebook-text">
                        <h3>How To Become An Celebrities</h3>
                        <span>DOWNLOAD</span>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="downside-button">
                  <a href="/">View All E-books</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <MarxGroupFooter/>

    </>
  );
}
export default Blog;
