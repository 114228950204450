import img1 from '../assest/images/img1.avif'
import img2 from '../assest/images/img2.avif'
import img3 from '../assest/images/img3.avif'
import img4 from '../assest/images/img4.avif'
import img5 from '../assest/images/img5.avif'
import img6 from '../assest/images/img6.avif'
import img7 from '../assest/images/slid1.jpg'
import img8 from '../assest/images/sld2.jpg'
import img9 from '../assest/images/sld3.jpg'
import img10 from '../assest/images/img1.avif'
import img11 from '../assest/images/img3.avif'
import img12 from '../assest/images/img2.avif'



 const photos = [
    {
      src: img1,
      width: 4,
      height: 3,
      name:"billu"
    },
    {
      src: img2,
      width: 1,
      height: 1,
      name:"billu"

    },
    {
      src: img3,
      width: 4,
      height: 3,
      name:"billu"

    },
    {
      src: img4,
      width: 4,
      height: 3,
      name:"billu"

    },
    {
      src: img5,
      width: 4,
      height: 3,
      name:"billu"

    },
    {
      src: img6,
      width: 4,
      height: 3,
      name:"billu"

    },
    {
      src: img7,
      width: 4,
      height: 3,
      name:"billu"

    },
    {
      src: img8,
      width: 4,
      height: 3,
      name:"billu"

    },
    {
      src: img9,
      width: 4,
      height: 3,
      name:"billu"

    },
    {
      src: img10,
      width: 4,
      height: 3,
      name:"billu"

    },
    {
      src: img11,
      width: 4,
      height: 3,
      name:"billu"

    },
    {
      src: img12,
      width: 4,
      height: 3,
      name:"billu"

    }
  ];

  export default photos