import Footer from "../../common/marxGroup/MarxGroupFooter"
import MarxGroupHeader from "../../common/marxGroup/MarxGroupHeader"
import subscribeImg from '../../assest/images/subscribeletter.jpg'


const AdvertiseHere = () => {
  return (
    <>
     <MarxGroupHeader/>
     <div className=" d-flex justify-content-center">
        <img src={subscribeImg} alt="img" />
      </div>
      <Footer/>
    </>
  )
}

export default AdvertiseHere
