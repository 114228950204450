import MarxEventFooter from "../../common/Marx_Event/MarxEventFooter/MarxEventFooter"
import MarxEventHeader from "../../common/Marx_Event/MarxEventHeader/MarxEventHeader"
import Dropdown from 'react-bootstrap/Dropdown';
import '../../styles/homeMarxEventStyle.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";

import upcoming1 from '../../assest/images/upcoming1.jpeg'
import upcoming2 from '../../assest/images/upcoming2.jpeg'
import upcoming3 from '../../assest/images/upcoming3.jpeg'

import RunningSlide from "../../common/RunningSlideAllMarx/RunningSlide";
import LiveShowGalleryData from "../../data/marxEvent/LiveShowGalleryData";
import ExclusiveFeaturedData from "../../data/marxEvent/ExclusiveFeaturedData";
import { useEffect } from "react";
import TopSliderAllMax from "../../common/TopSliderAllMarx/TopSliderAllMarx";
import TopMarxEventData from "../../data/marxEvent/TopMarxEventData";



const HomeMarxEvent = () => {
  useEffect(() => {
    document.title = "Mar’x Events Pvt. Ltd.";
  }, []);



  // const slidesToShowNewsLaptop = 3;
  // const slidesToShowNewsMobile = 1;

  // // Set the initial slidesToShow based on the screen width
  // const SlidesToShowInItialise = window.innerWidth >= 768 ? slidesToShowNewsLaptop : slidesToShowNewsMobile;

  const latestNews = {
    dots: false,
    infinite: true,
    speed: 700,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '1px',
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: '1px',
        },
      },
      {
        breakpoint: 9999, // A large value to ensure the last breakpoint always applies
        settings: {
          slidesToShow: 3,
        },
      }
    ],
  };
  return (
    <>
      <MarxEventHeader />

      <TopSliderAllMax title={"Model's Bank Gallery"} images={TopMarxEventData} />

      <div className="">
        <div className="event-navbrlists">
          <ul>
            <li>
              <Link to="/marx-event-home">Home</Link>
            </li>
            <li>
              <Link to="/event-Services">Services</Link>
            </li>
           
            <li>
              <Link to="/page3-events">Page 3 Events Gallery</Link>
            </li>
            <li>
              <Link to="/event-registration-form">Registration Form</Link>
            </li>
          
            <li>
              <Link to="/event-shows-gallery">Events / Shows Pics</Link>
            </li>
           
          
          
            <li>
              <Link to="/event-filter-search">Filter Search</Link>
            </li>
            <li className="">
              <Dropdown>
                <Dropdown.Toggle variant="" id="dropdown-basic">
                  Beauty Pageants Winners
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/ind-beauty-pageants-winner">Indian
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/international-beauty-pageants-winner">International</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>

            <li>
              <Link to="/electronic-media-coverage">Electronic Media Coverage</Link>
            </li>
            <li>
              <Link to="/hall-of-fame">Hall Of Fame</Link>
            </li>
            
           
            <li>
              <Link to="/press-media-coverage">Press & Media Coverage</Link>
            </li>
            <li>
              <Link to="/event-subscription-plan">Subscription Plan</Link>
            </li>
          
            <li className="">
              <Dropdown>
                <Dropdown.Toggle variant="" id="dropdown-basic">
                  Beauty Pageants Title Holder
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/ind-beauty-pageants-titleholder">Indian
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/international-beauty-pageants-titleholder">International</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li>
              <Link to="/event-our-work">Our Work</Link>
            </li>
           
           
            <li>
              <Link to="/awards-achievements">Award & Achievements</Link>
            </li>
            <li className="">
              <Dropdown className="custom-dropdown">
                <Dropdown.Toggle className="modelbank" variant="" id="dropdown-basic">
                  Our Model Bank
                </Dropdown.Toggle>

                <Dropdown.Menu className="custom-dropdown-menu">
                  <Dropdown.Item className="action">Indian
                    <Dropdown.Menu className="nested-dropdown-menu">
                      <Dropdown.Item as={Link} to="/ourmodel-india-male">Male</Dropdown.Item>
                      <Dropdown.Item as={Link} to="/ourmodel-india-female">Female</Dropdown.Item>
                      <Dropdown.Item as={Link} to="/ourmodel-india-kids">Kids</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown.Item>
                  <Dropdown.Item className="action2">International
                    <Dropdown.Menu className="nested-dropdown-menu">
                      <Dropdown.Item as={Link} to="/ourmodel-international-male">Male</Dropdown.Item>
                      <Dropdown.Item as={Link} to="/ourmodel-international-female">Female</Dropdown.Item>
                      <Dropdown.Item as={Link} to="/ourmodel-international-kids">Kids</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="">
              <Dropdown>
                <Dropdown.Toggle variant="" id="dropdown-basic">
                  Beauty Pageants Contestants
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/ind-beauty-pageants-contestants">Indian
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="/international-beauty-pageants-contestants">International</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li>
              <Link to="/event-showsvideo-reel">Shows Video Reel</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="main-eventdiv">
        <Box className="maineventdiv-box">
          <div className="alleventtabs">
            <h5 className=" text-center fw-bold mb-4">INDEX </h5>
            <p className='mange-eventtab'>
              <Link to={'/marxskin-expert'} >MAR'X SKIN EXPERT’S </Link>
            </p>

            <p className='mange-eventtab'>
              <Link to={'/marxfitness-expert'} > MAR'X FITNESS EXPERT’S</Link>
            </p>

            <p className='mange-eventtab'>
              <Link to={'/marxdiction-expert'} >MAR'X DICTION EXPERT’S </Link>
            </p>
            <p className='mange-eventtab'>
              <Link to={'/marxthematic-shows'} >MAR'X THEMATIC SHOWS</Link>
            </p>
            <p className='mange-eventtab'>
              <Link to={'/marxfashion-stylist'} >MAR'X FASHION STYLIST’S</Link>
            </p>
            <p className='mange-eventtab'>
              <Link to={'/marxmakeup-artist'} >MAR'X MAKE - UP ARTIST’S</Link>
            </p>
            <p className='mange-eventtab'>
              <Link to={'/marxgrooming-expert'} >MAR'X GROOMING EXPERT’S</Link>
            </p>
            <p className='mange-eventtab'>
              <Link to={'/marxlifecoach-expert'} >MAR’X LIFE COACH EXPERT’S </Link>
            </p>
            <p className='mange-eventtab'>
              <Link to={'/marxsocialcause-shows'} >MAR’X SOCIAL CAUSE SHOWS</Link>
            </p>

            <p className='mange-eventtab'>
              <Link to={'/marxfashion-designer'} >MAR'X FASHION DESIGNER'S</Link>
            </p>
            <p className='mange-eventtab'>
              <Link to={'/marximage-consultant'} >MAR'X IMAGE – CONSULTANT’S</Link>
            </p>

            <p className='mange-eventtab'>
              <Link to={'/marxaccessories-designer'} >MAR'X ACCESSORIES DESIGNER'S</Link>
            </p>
            <p className='mange-eventtab'>
              <Link to={'/marxproduct-launchshow'} >MAR’X PRODUCT LAUNCH SHOWS</Link>
            </p>

            <p className='mange-eventtab'>
              <Link to={'/marxleadership-qualityexpert'} >MAR'X LEADERSHIP QUALITIES EXPERT’S</Link>
            </p>
            <p className='mange-eventtab'>
              <Link to={'/marxconceptualization-showdirection'} >MAR'X CONCEPTUALIZATION & SHOW DIRECTION</Link>
            </p>

          </div>
          <div className="eventtabpanels">
            <div>
              <div className="about-marx-event">
                <h1>About Us</h1>
                <p>
                  The Name Of Company Mar'x Has Been Inspired From The Famous German Philosopher, Political Economist, Historian, Political Theorist, Sociologist, Communist & Revolutionary ‘’karl Marx’’ As He Ruled The Entire World In 18th Century With His   Modified Ideas, Hard Work, Determination & Will Power To Achieve Goals In Life. Which Inspires Us To Do Something In Life As An  Inspiration.
                </p>
                <p>
                  Mar’x Events Pvt. Ltd.(The Fashion Consultants) Which Started In The Early Yr. 2001. Has Covered A Long Distance Of Work Experience For More Than 24 Yrs. In The Fashion Industry Till Now And Has Emerged As One Of The Biggest Fashion House Conglomerate In India, As We Believe In Taking On Work Which We Think We Can Contribute To The Fashion Industry, Since Our Forte Is Communication We Take On Each  Project As A Challenge Towards That End. Our Clients Always Have Something To Convey To Their Audience – A Feeling, An Emotion, A Philosophy, A Revolutionary Piece Of Information Or Creation.
                </p>
                <p>
                  As A Fashion Director / Grooming Expert / Fashion Stylist /  Fashion Mentor / Fashion Blogger / Fashion Influencer / Ramp Walk Trainer / Indian & International Beauty Pageant Consultant, We Work On That Basic Philosophy & Immediate  Need To Be Communicated By Our Client To The Audience. At Mar’x We Work On Conceptualization & Direction Of Fashion Shows / Beauty Pageants / Fashion Weeks On Pan – India Level.
                </p>

              </div>

              <div className="about-marx-event">
                <h1>What We Do</h1>
                <div>
                  <p>Fashion Shows & Beauty Pageants Is A Profession Picked In India In The Early 95s  & It Didn't Take To Long Before India Was Globally Recognized With Our Models & Designers Making It Big At International Levels, Mar'x  Which Was Started In Year 2001. Has Over Carved For It Self In The Fashion Industry.</p>
                  <p>We At Mar’x Events Produce Many Bespoke Events Such As Designer Fashion Shows, Corporate Shows, Beauty Pageants, Fashion Weeks, Consumer Product Launches, Fashion & Brand / Product Related Consultancy Services, Ace Indian & International Model’s For Fashion Shows, Designer Shows, Consumer Product Launches, Print Shoots, Catalogue Shoots, Calendar Shoots, International Destination Shoots, Fashion Folio Shoots, Theme Based Designer Shoots & Other Things Related To The Fashion & Glamour Industry. As We Keep Ourself Updated To Give U The Best Information & Happenings Connected To The Fashion & Glamour Industry.</p>
                  <p>So All You People Out There Who Wonder How The Dream Team Function The Answer Is With The Very Determination Power, Willingness, Hard Work & Utopian Dreams. We Live In Glamour Business Since More Than A Decade But We Work In Reality .</p>
                </div>
              </div>

              <div className="about-marx-event">
                <h1>Who We Are</h1>
                <div>
                  <p>
                    Every Member Of The Mar'x Team Is Also Privileged To Suffix Their Name With ` Marx ` A Title That Invest Them With To Having Special Talent. We Are Glad To Introduce You ‘’ Mar’x Media Pvt.ltd.’’(The Leading Pioneers) As Our Production & Advertising House On Pan – India Level Which Specialized Into Events Production Of Lights, Sound, Stage Set – Up Decor, Runway Set – Up For Fashion Shows / Beauty Pageants/ Fashion Weeks, Backdrops, Green Rooms, Booking Of Show Venues, Outdoor Branding, Indoor Advt., Print Advt., Commercial Advt., Digital Marketing, Licensing, Ticketing, Led Video Walls, Videographers & Photographers, Led Screens, Projectors, Plasma Monitors, Security, Vanity Van, Etc......!! & All Kind Of Elements For Making A Event Successful .do Explore To Know More....!
                  </p>
                </div>
              </div>

              <div className="about-marx-event">
                <h1>WHAT WE THINK</h1>
                <div>
                  <p>Females Are The Most Beautiful & Gorgeous Creation Of God , But Even This
                    Creatation Needs To Amplify The Beauty Given By " His Honesty " , So The
                    Females Are Always More Fashion Conscious Than Men.</p>
                  <p>
                    This Part Of Our Is Dedicated To All The Females Who Want To Be A Part
                    Of This Effort To Redifine The World Of Beauty & Fashion .so Come On
                    Lets Join Hands Together To Make " Beautiful" The Most Beautiful .
                  </p>
                  <p>Through This Portion We Want To Provide You With The Knowledge
                    Of The Best Fashion Stuff & We Want To Promote The Best You Can
                    Find Everything Which As Female Needs To Look More Beautiful . An
                    Intrest In Her Looks Can Half Even The Course Of Time For A Women.</p>
                </div>
              </div>


              <div className="about-marx-event">
                <h1>SHOW CONCEPTUALIZATION IS ABOUT STYLE</h1>
                <div>
                  <p>He Is Passionate With The Idea Of Exploring New Things & Recycling
                    Old Themes Into New Ones . He Belive In One Thing That Runways Show
                    Is The Team Work Where People Are Handling Different Kinds Of Work
                    Profile Like Sound & Light Engineers,backstage Management,model’s
                    Make – Up Expert, Stage Set- Up Designers, ,fashion Stylist, Fashion
                    Designer’s, Accessory Designers, Fashion Photographers, Host/
                    Emcee, Celebrity Showstoppers & Fashion Director Plays Inseprable
                    Role. So If You Have Zeal To Accomplish , It's Only The Potential &
                    Grace To Realizeyour Goal. Yes I Realised When I Saw Indian Model &
                    Fashion Director Mr.‘marc Robinson’ First Time On Television As The
                    Grooming Mentor For Beauty Pageants & Fashion Weeks In India ,as
                    He Was Training The Model’s With Ramp Walk & Personality
                    Development Skills By Adding Fashion & Music Into Theme Sequence,
                    Which Has Made Him As My Work Inspiration & Mentor. As Many
                    People Know That I Started My Career As A Ramp Model In Delhi &
                    Finally Landed Up As A "Beauty Pageant Director" & ‘’grooming
                    Expert’’.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </div>

      <RunningSlide title="LIVE SHOWS GALLERY" images={LiveShowGalleryData} />
      <RunningSlide title="EXCLUSIVE FEATURED" images={ExclusiveFeaturedData} />

      <div className="upcoming-sldddiv">
        <h1 className=' text-white fw-bold ms-3'>UP-COMING PROJECTS</h1>
        <div className="diveventlatest">
          <Slider className="eventslidrbox" {...latestNews}>
            <div className="eventdiv-slide">
              <img src={upcoming1} alt="img" />
            </div>
            <div className="eventdiv-slide">
              <img src={upcoming2} alt="img" />
            </div>
            <div className="eventdiv-slide">
              <img src={upcoming3} alt="img" />
            </div>
            <div className="eventdiv-slide">
              <img src={upcoming2} alt="img" />
            </div>
          </Slider>
        </div>
      </div>

      <MarxEventFooter />
    </>
  )
}

export default HomeMarxEvent
