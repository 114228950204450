import { Box, Button, TextField } from "@mui/material"
import './editProfileStyle.css'
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";

const EditProfile = () => {
   const navigate =useNavigate()
    const profileClose=()=>{
        navigate('/marx-group')
    }
    return (
        <>
            <div className="editp-div">
                <Box className='profileboxvisible'>
                    <CloseIcon onClick={profileClose} className='closeiconbtn' />
                    <h5 className=" text-center">Personal Information</h5>
                    <div className="namediv">
                        <TextField size="small" placeholder="First Name" />
                        <TextField size="small" placeholder="Last Name" />
                    </div>
                    <div className="genderdiv">
                        <ul className="">
                            <li>Gender : </li>
                            <li>
                                <input
                                    required
                                    type="radio" name="gender"
                                />
                                <span>Male</span>
                            </li>
                            <li>
                                <input
                                    required
                                    type="radio" name="gender"
                                />
                                <span>Female</span>
                            </li>
                        </ul>
                    </div>
                    <div className="inputdiv">
                        <TextField size="small" placeholder="Email" fullWidth />
                    </div>
                    <div className="inputdiv mt-3">
                        <TextField size="small" placeholder="Contact No" fullWidth />
                    </div>
                    <div>
                        <textarea rows={6} className="edittextarea" placeholder="Your Bio in short Description.(400 words)." />
                    </div>
                    <div className=" d-flex justify-content-center align-items-center mt-2">
                        <Button variant="contained">Save</Button>
                    </div>
                </Box>
            </div>
        </>
    )
}

export default EditProfile
