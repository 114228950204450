import Footer from "../common/MarxEntertainment/footer/Footer"
import Header from "../common/MarxEntertainment/header/Header"
import '../styles/PayStyle.css'
import { IoIosCheckmark } from "react-icons/io";
import Form from 'react-bootstrap/Form';
import { PiChatsFill } from "react-icons/pi";
import { GoQuestion } from "react-icons/go";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import Sidebar from "../common/SideAbleMenu/SideBar";
import chequeImg from '../assest/images/cheque.png'
import gateway from '../assest/images/gateway.png'


const Pay = () => {
    const [byChequeOpen, setByChequeOpen] = useState(false);
    const [getDataById, setGetDataById] = useState();

    const params = useParams()
    console.log(params)
    const [category, setCategoryType] = useState([])
    const [industry, setIndustry] = useState([])
    const [finalCategoryTypeD, setFinalCategoryTypeD] = useState()
    const [finalIndustryTypeD, setFinalIndustryTypeD] = useState()
    const [paymentData, SetpaymentData] = useState({
        services: [],
        category: [],
        receiptNo: '',
        chequeNo: '',
        note: '',
        imagefile: ''
    })
    const [file, setFile] = useState()

    const getCategoryData = async () => {
        const getCategoryName = []
        const reqCatData = await axios.get("https://onlineparttimejobs.in/api/category/public", {
            withCredentials: true
        });
        console.log(reqCatData.data)
        for (let i = 0; i < reqCatData.data.length; i++) {
            getCategoryName.push({ name: reqCatData.data[i]?.name, uid: reqCatData.data[i]?.uid })
        };
        if (getCategoryName.length) {
            setCategoryType(getCategoryName);
        }
    }

    const getIndustryData = async () => {
        const getIndustryName = []
        const reqData = await axios.get("https://onlineparttimejobs.in/api/industry/public", {
            withCredentials: true
        });
        console.log(reqData.data)
        for (let i = 0; i < reqData.data.length; i++) {
            getIndustryName.push({ name: reqData.data[i]?.name, uid: reqData.data[i]?.uid })
        };
        if (getIndustryName.length) {
            setIndustry(getIndustryName);
        }
    }

    useEffect(() => {
        getCategoryData();
        getIndustryData();
    }, []);

    const openformBox1 = () => {
        console.log('close box');
        setByChequeOpen(false);
    };
    const openformBox2 = () => {
        console.log('Open box');
        setByChequeOpen(true);
    };

    useEffect(() => {
        const getPackgDataById = async () => {
            const reqDataById = await axios.get(`https://onlineparttimejobs.in/api/sellerpackage/public/${params.id}`, {
                withCredentials: true
            });
            console.log(reqDataById.data)
            setGetDataById(reqDataById.data);
        }
        getPackgDataById();
    }, [params.id]);

    const handleChange = (e) => {
        const inpName = e.target.name;
        const inpVar = e.target.value;
        const cloned = { ...paymentData }
        cloned[inpName] = inpVar;
        SetpaymentData(cloned);
    }

    const onImageSelect = (e) => {
        setFile(e.target.files[0]);
    }

    const onSubmitBtn = async (e) => {
        try {
            e.preventDefault();
            let formData = new FormData();
            formData.append('category_id', JSON.stringify(finalCategoryTypeD));
            formData.append('service_id', JSON.stringify(finalIndustryTypeD));
            formData.append('receiptNo', paymentData.receiptNo);
            formData.append('chequeNo', paymentData.chequeNo);
            formData.append('note', paymentData.note);
            formData.append('image', file);
            formData.append("package_id", params.id)
            // Check if payment gateway is selected
            if (byChequeOpen) {
                // Cheque is selected
                formData.append('transType', 'cheque');
            } else {
                // Payment gateway is selected
                formData.append('transType', 'Payment Gateway');
            }
            const add_paymtdetail = await axios.post('https://onlineparttimejobs.in/api/sellerPackage/purchase', formData,
                {
                    headers: {
                        Authorization: 'Bearer ' + window.localStorage.getItem("token"),
                    }
                }
            );
            SetpaymentData(add_paymtdetail);
            console.log(add_paymtdetail.data);
            console.log('Form data submitted successfully!');

        } catch (error) {
            console.log('Error submitting form:', error)
        }
    }
    return (
        <>
            <Sidebar/>
            <Header />
            <div className="mainpaymntbox">
                <h1 className="h1tag">You’re almost there! Complete your order</h1>

                {getDataById && getDataById?.map((itm) => (
                    <div key={itm._id} className="planweoffer">
                        <div className="allsolution">
                            <div>
                                <h4><PiChatsFill className="topicon" />All-in-one solution</h4>
                            </div>
                            <div className="allptag">
                                <p><IoIosCheckmark className="iconyes" />Max. Number of Services <span className="boldtag">({itm.max_services})</span><GoQuestion className="quesicon" /></p>
                                <p><IoIosCheckmark className="iconyes" />Max. Number of Category <span className="boldtag">({itm.max_categories})</span><GoQuestion className="quesicon" /></p>
                                <p><IoIosCheckmark className="iconyes" />Max. Number of Enquiry <span className="boldtag">({itm.no_of_enquiries})</span><GoQuestion className="quesicon" /></p>
                            </div>
                        </div>
                        <div className="allsolution">
                            <div>
                                <h4><PiChatsFill className="topicon" />Duration</h4>
                            </div>
                            <div className="allptag">
                                <p><IoIosCheckmark className="iconyes" />Applicable for <span className="boldtag">{itm.duration}</span> days<GoQuestion className="quesicon" /></p>
                            </div>
                        </div>
                    </div>
                ))}

                <div class="select_payment">
                    <h2 class="cart_step_title">Select Payment</h2>
                    <div class="select_payment_main">
                        <div class="select_payment_container row">
                            <div class="select_payment_method col-sm-4">
                                <div id="paymentmethod_razorpay_card" class="payment-method" onClick={openformBox1}>
                                    <label class="">
                                        <input type="radio" name="radio-select" class="radio_input" value="razorpay_card" /><span class="radio_box"></span>
                                        <h4 class="paymnt_type">Payment Gateway</h4>
                                    </label>
                                    <div class="paymentmethod-logo"><img src={gateway} class="paymentmethod_logo_img" alt="Credit Card" /></div>
                                </div>
                                <div id="paymentmethod_razorpay_upi" class="payment-method" onClick={openformBox2}>
                                    <label class="">
                                        <input type="radio" name="radio-select" class="radio_input" value="razorpay_upi" /><span class="radio_box"></span>
                                        <h4 class="paymnt_type">By Cheque</h4>
                                    </label>
                                    <div class="paymentmethod-logo"><img src={chequeImg} class="paymentmethod_logo_img" alt="UPI" /></div>
                                </div>

                                {/* <div id="paymentmethod_paytm" class="payment-method">
                                    <label class="">
                                        <input type="radio" name="radio-select" class="radio_input" value="paytm" /><span class="radio_box"></span>
                                        <h4 class="paymnt_type">PayTM</h4>
                                    </label>
                                    <div class="paymentmethod-logo"><img src="https://cart.hostinger.com/assets/payments/dlocal_apm.PW.svg" class="paymentmethod_logo_img" alt="PayTM" /></div>
                                </div>

                                <div id="paymentmethod_netbanking" class="payment-method">
                                    <label class="">
                                        <input type="radio" name="radio-select" class="radio_input" value="netbanking" /><span class="radio_box"></span>
                                        <h4 class="paymnt_type">Net Banking</h4>
                                    </label>
                                    <div class="paymentmethod-logo"><img src="https://cart.hostinger.com/assets/payments/dlocal_apm.NB.svg" class="paymentmethod_logo_img" alt="Net Banking" /></div>
                                </div>

                                <div id="paymentmethod_paypal" class="payment-method">
                                    <label class="">
                                        <input type="radio" name="radio-select" class="radio_input" value="paypal" /><span class="radio_box"></span>
                                        <h4 class="paymnt_type">PayPal</h4>
                                    </label>
                                    <div class="paymentmethod-logo"><img src="https://cart.hostinger.com/assets/payments/braintree_paypal.svg" class="paymentmethod_logo_img" alt="PayPal" /></div>
                                </div>
                                <div id="paymentmethod_googlepay" class="payment-method payment-method">
                                    <label class="">
                                        <input type="radio" name="radio-select" class="radio_input" value="googlepay" /><span class="radio_box"></span>
                                        <h4 class="paymnt_type">Google Pay</h4>
                                    </label>
                                    <div class="paymentmethod-logo"><img src="https://cart.hostinger.com/assets/payments/checkout.googlepay.svg" class="paymentmethod_logo_img" alt="Google Pay" /></div>
                                </div>
                                <div id="paymentmethod-coingate" class="payment-method">
                                    <label class="">
                                        <input type="radio" name="radio-select" class="radio_input" value="coingate" /><span class="radio_box"></span>
                                        <h4 class="paymnt_type">Coingate</h4>
                                    </label>
                                    <div class="paymentmethod-logo"><img src="https://cart.hostinger.com/assets/payments/coingate.svg" class="paymentmethod_logo_img" alt="Coingate" /></div>
                                </div> */}
                            </div>
                            <div id="payment-overview" class="payment-overview col-sm-8">
                                <div>
                                    <div class="cart-estimate">
                                        {/* <div class="cart_estimate_info">
                                            <div class="cart_estimate_plan_info">
                                                <div class="plan_info">
                                                    <div class="">
                                                        <h4 class="plan-info-name"> Business Web Hosting - 12 Months Plan</h4>
                                                    </div>

                                                    <div class="pricing">
                                                        <div class="old-price">₹8,388.00</div>
                                                        <h4 class="">₹4,668.00</h4>
                                                    </div>
                                                </div>
                                                <div class="cart_estimate_perks">

                                                    <div class="payment-addon">
                                                        <div class="divicon">
                                                            <span class="paymentaddon_icon">
                                                                <IoIosCheckmark className="iconyes" />
                                                            </span>
                                                            <h6 class="paymentaddon_name">Daily Backup</h6>
                                                        </div>

                                                        <div class="payment_addon_pricing">
                                                            <div class="old-price">₹1,380.00</div>
                                                            <h5 class="paymentaddon_price">₹0.00</h5>
                                                        </div>
                                                    </div>
                                                    <div class="payment-addon">
                                                        <div class="divicon">
                                                            <span class="paymentaddon_icon">
                                                                <IoIosCheckmark className="iconyes" />
                                                            </span>
                                                            <h6 class="paymentaddon_name">Domain Name</h6>
                                                        </div>

                                                        <div class="payment_addon_pricing">
                                                            <div class="old-price">₹799.00</div>
                                                            <h5 class="paymentaddon_price">₹0.00</h5>
                                                        </div>
                                                    </div>
                                                    <div class="payment-addon">
                                                        <div class="divicon">
                                                            <span class="paymentaddon_icon">
                                                                <IoIosCheckmark className="iconyes" />
                                                            </span>
                                                            <h6 class="paymentaddon_name">Domain WHOIS Privacy Protection</h6>
                                                        </div>

                                                        <div class="payment_addon_pricing">
                                                            <h5 class="paymentaddon_price">₹0.00</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div class="cart_estimate_details">
                                            <div class="input-wrapper">
                                                <label className="m-1" htmlFor="flexRadioDefault1">Category Type <span style={{ color: 'red' }}>*</span></label>
                                                <Multiselect
                                                    name='category'
                                                    onChange={handleChange}
                                                    value={paymentData.category}
                                                    required
                                                    className="multiSelect"
                                                    isObject={true}
                                                    displayValue="name"
                                                    options={category}
                                                    showCheckbox
                                                    selectedValues={[]}
                                                    onRemove={(selectedCat) => {
                                                        const selectedIds = selectedCat.map((cat) => {
                                                            return cat.uid
                                                        })
                                                        setFinalCategoryTypeD(selectedIds)
                                                    }}
                                                    onSelect={(selectedCat) => {
                                                        const selectedIds = selectedCat.map((cat) => {
                                                            return cat.uid
                                                        })
                                                        setFinalCategoryTypeD(selectedIds)
                                                    }}
                                                />
                                            </div>
                                            <div class="input-wrapper">
                                                <label className="m-2" htmlFor="flexRadioDefault1">Service Type <span style={{ color: 'red' }}>*</span></label>
                                                <Multiselect
                                                    name='services'
                                                    onChange={handleChange}
                                                    value={paymentData.industry}
                                                    required
                                                    isObject={true}
                                                    displayValue="name"
                                                    options={industry}
                                                    showCheckbox
                                                    selectedValues={[]}
                                                    onRemove={(selectedCat) => {
                                                        const selectedIds = selectedCat.map((cat) => {
                                                            return cat.uid
                                                        })
                                                        setFinalIndustryTypeD(selectedIds)
                                                    }}
                                                    onSelect={(selectedCat) => {
                                                        const selectedIds = selectedCat.map((cat) => {
                                                            return cat.uid
                                                        })
                                                        setFinalIndustryTypeD(selectedIds)
                                                    }}
                                                />
                                            </div>

                                            {byChequeOpen && (
                                                <div className="pymnt_detail" >
                                                    <div class="input-wrapper">
                                                        <Form.Control onChange={handleChange} name="receiptNo" fullWidth size="small" class="input_input" placeholder="Enter Recipt No." password="false" type="text" />
                                                    </div>
                                                    <div class="input-wrapper">
                                                        <Form.Control onChange={handleChange} name="chequeNo" fullWidth size="small" class="input_input" placeholder="Enter Cheque No." password="false" type="text" />
                                                    </div>
                                                    <div class="input-wrapper">
                                                        <Form.Control onChange={handleChange} name="note" fullWidth size="small" class="input_input" placeholder="Enter Note" password="false" type="text" />
                                                    </div>
                                                    <div class="input-wrapper">
                                                        <Form.Control onChange={onImageSelect} name="imagefile" fullWidth size="small" class="input_input" placeholder="" password="false" type="file" />
                                                    </div>
                                                </div>
                                            )}


                                            <div className="balnce">
                                                <div class="cart_estimate_discounts">
                                                    <div class="cart_estimate_line_title">Plan Discount - 44%</div>
                                                    <h4 class="cart_estimate_price text-success"> -₹3,720.00</h4>
                                                </div>

                                                <div class="cart_estimate_discounts">
                                                    <div class="cart_estimate_line_title">
                                                        Taxes &amp; Fees
                                                    </div>
                                                    <h4 class="cart_estimate_price">₹840.24</h4>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="cart_estimate_total">

                                            <div class="totalprice">
                                                <div class="cart_estimate_line_title">
                                                    <h3>Total</h3>
                                                </div>
                                                <div class="prizce">
                                                    <div class="old-price">₹9,897.84</div>
                                                    <h3 class="cart_estimate_price">₹5,508.24</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="payment_overview_actions">
                                    <div class=" payment_overview_action">
                                        <button onClick={onSubmitBtn} class="" id="cart-submit-payment" variant="contained">Submit Secure Payment</button>
                                    </div>
                                    <p class="payment_overview_disclaimer">By checking out you agree with our <a href="/" target="_blank">Terms of Service</a> and confirm that you have read our <a href="/" target="_blank">Privacy Policy</a>. You can cancel recurring payments at any time.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >

            </div >
            <Footer />
        </>
    )
}

export default Pay





