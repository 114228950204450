import flag1 from '../../assest/images/countriesFlag/flag_ind.jpg'
import flag2 from '../../assest/images/countriesFlag/Flag_usa.png'
import flag3 from '../../assest/images/countriesFlag/Flag_uae.png'
import flag4 from '../../assest/images/countriesFlag/flag_Malaysia.png'
import flag5 from '../../assest/images/countriesFlag/Myanmar_flag.png'
import flag6 from '../../assest/images/countriesFlag/Flag_of_Oman.png'
import flag7 from '../../assest/images/countriesFlag/Flag_of_the_United_Kingdom.png'
import flag8 from '../../assest/images/countriesFlag/Flag_of_Canada.png'
import flag9 from '../../assest/images/countriesFlag/Flag_of_Kuwait.png'
import flag10 from '../../assest/images/countriesFlag/Flag_of_South_Africa.png'
import flag11 from '../../assest/images/countriesFlag/Flag_of_Nigeria.png'
import flag12 from '../../assest/images/countriesFlag/Flag_of_Mauritius.png'
import flag13 from '../../assest/images/countriesFlag/Flag_of_Sri_Lanka.png'
import flag14 from '../../assest/images/countriesFlag/Flag_of_Qatar.png'
import flag15 from '../../assest/images/countriesFlag/Flag_of_Australia.png'
import flag16 from '../../assest/images/countriesFlag/Flag_of_Nepal.png'
import flag17 from '../../assest/images/countriesFlag/Flag_of_Trinidad_and_Tobago.png'
import flag18 from '../../assest/images/countriesFlag/Flag_of_Singapore.png'
import flag19 from '../../assest/images/countriesFlag/Flag_of_Bahrain.png'
import flag20 from '../../assest/images/countriesFlag/Flag_of_Fiji.png'
import flag21 from '../../assest/images/countriesFlag/Flag_of_Guyana.png'
import flag22 from '../../assest/images/countriesFlag/Flag_of_France.png'
import flag23 from '../../assest/images/countriesFlag/Flag_of_Germany.png'
import flag24 from '../../assest/images/countriesFlag/Flag_of_New_Zealand.png'
import flag25 from '../../assest/images/countriesFlag/Flag_of_Italy.png'
import flag26 from '../../assest/images/countriesFlag/Flag_of_Bangladesh.png'
import flag27 from '../../assest/images/countriesFlag/flag_of_Jamaica.png'
import flag28 from '../../assest/images/countriesFlag/Flag_of_Kenya.png'
import flag29 from '../../assest/images/countriesFlag/Flag_of_Tanzania.png'
import flag30 from '../../assest/images/countriesFlag/Flag_of_Spain.png'
import flag31 from '../../assest/images/countriesFlag/Flag_of_China.png'
import flag32 from '../../assest/images/countriesFlag/Flag_of_Sweden.png'
import flag33 from '../../assest/images/countriesFlag/Flag_of_Romania.png'
import flag34 from '../../assest/images/countriesFlag/Flag_of_Burkina.png'
import flag35 from '../../assest/images/countriesFlag/Flag_of_Mali.png'
import flag36 from '../../assest/images/countriesFlag/Flag_of_the_Republic_of_China_tiwan.png'
import flag37 from '../../assest/images/countriesFlag/Flag_of_South_Korea.png'
import flag38 from '../../assest/images/countriesFlag/Flag_of_Cameroon.png'
import flag39 from '../../assest/images/countriesFlag/Flag_of_Venezuela.png'
import flag40 from '../../assest/images/countriesFlag/Flag_of_Madagascar.png'
import flag41 from '../../assest/images/countriesFlag/Flag_of_Mozambique.png'
import flag42 from '../../assest/images/countriesFlag/Flag_of_Ghana.png'
import flag43 from '../../assest/images/countriesFlag/Flag_of_Peru.png'
import flag44 from '../../assest/images/countriesFlag/Flag_of_Morocco.png'
import flag45 from '../../assest/images/countriesFlag/Flag_of_Switzerland.png'
import flag46 from '../../assest/images/countriesFlag/Flag_of_Poland.png'
import flag47 from '../../assest/images/countriesFlag/Flag_of_Argentina.png'
import flag48 from '../../assest/images/countriesFlag/Flag_of_Algeria.png'
import flag49 from '../../assest/images/countriesFlag/Flag_of_Sudan.png'
import flag50 from '../../assest/images/countriesFlag/Flag_of_Uganda.png'
import flag51 from '../../assest/images/countriesFlag/Flag_of_North_Korea.png'
import flag52 from '../../assest/images/countriesFlag/Flag_of_Colombia.png'
import flag53 from '../../assest/images/countriesFlag/Flag_of_Turkey.png'
import flag54 from '../../assest/images/countriesFlag/Flag_of_Vietnam.png'
import flag55 from '../../assest/images/countriesFlag/Flag_of_Egypt.png'
import flag56 from '../../assest/images/countriesFlag/Flag_of_Japan.png'
import flag57 from '../../assest/images/countriesFlag/Flag_of_Ethiopia.png'
import flag58 from '../../assest/images/countriesFlag/Flag_of_Mexico.png'
import flag59 from '../../assest/images/countriesFlag/Flag_of_Russia.png'
import flag60 from '../../assest/images/countriesFlag/Flag_of_Brazil.png'
import flag61 from '../../assest/images/countriesFlag/Flag_of_Indonesia.png'
import flag62 from '../../assest/images/countriesFlag/Flag_of_Cuba.png'
import flag63 from '../../assest/images/countriesFlag/Flag_of_Belgium.png'
import flag64 from '../../assest/images/countriesFlag/Flag_of_Denmark.png'
import flag65 from '../../assest/images/countriesFlag/Flag_of_Bolivia.png'
import flag66 from '../../assest/images/countriesFlag/Flag_of_Zimbabwe.png'
import flag67 from '../../assest/images/countriesFlag/Flag_of_Cambodia.png'
import flag68 from '../../assest/images/countriesFlag/Flag_of_the_Netherlands.png'
import flag69 from '../../assest/images/countriesFlag/Flag_of_Senegal.png'
import flag70 from '../../assest/images/countriesFlag/Flag_of_Guatemala.png'
import flag71 from '../../assest/images/countriesFlag/Flag_of_Ecuador.png'
import flag72 from '../../assest/images/countriesFlag/Flag_of_Hong_Kong.png'
import flag73 from '../../assest/images/countriesFlag/Flag_of_Greece.png'
import flag74 from '../../assest/images/countriesFlag/Flag_of_Portugal.png'
import flag75 from '../../assest/images/countriesFlag/Flag_of_Austria.png'
import flag76 from '../../assest/images/countriesFlag/Flag_of_Belarus.png'
import flag77 from '../../assest/images/countriesFlag/Flag_of_the_Philippines.png'
import flag78 from '../../assest/images/countriesFlag/Flag_of_Thailand.png'



const CountriesData = [
    {
        imgSrc:flag1,
        name: 'India',
    },
    {
        imgSrc:flag2,
        name: 'USA',
    },
    {
        imgSrc:flag3,
        name: 'UAE',
    },
    {
        imgSrc:flag4,
        name: 'MALAYSIA',
    },
    {
        imgSrc:flag5,
        name: "MYANMAR",
    },
    {
        imgSrc:flag6,
        name: 'Oman',
    },
    {
        imgSrc:flag7,
        name: 'UK',
    },
    {
        imgSrc:flag8,
        name: "Canada",
    },
    {
        imgSrc:flag9,
        name: 'Kuwait',
    },
    {
        imgSrc:flag10,
        name: 'South Africa',
    },
    {
        imgSrc:flag11,
        name: 'Nigeria',
    },
    {
        imgSrc:flag12,
        name: 'Mauritius',
    },
    {
        imgSrc:flag13,
        name: 'Srilanka',
    },
    {
        imgSrc:flag14,
        name: 'Qatar',
    },
    {
        imgSrc:flag15,
        name: 'Australia',
    },
    {
        imgSrc:flag16,
        name: 'Nepal',
    },
    {
        imgSrc:flag17,
        name: 'Trinidad & Tobago',
    },
    {
        imgSrc:flag18,
        name: 'Singapore',
    },
    {
        imgSrc:flag19,
        name: 'Bahrain',
    },
    {
        imgSrc:flag20,
        name: 'Fiji',
    },
    {
        imgSrc:flag21,
        name: 'Guyana',
    },
    {
        imgSrc:flag22,
        name: 'France',
    },
    {
        imgSrc:flag23,
        name: 'Germany',
    },
    {
        imgSrc:flag24,
        name: 'New Zealand',
    },
    {
        imgSrc:flag25,
        name: 'Italy',
    },
    {
        imgSrc:flag26,
        name: 'Bangladesh',
    },
    {
        imgSrc:flag27,
        name: 'Jamaica',
    },
    {
        imgSrc:flag28,
        name: 'Kenya'
    },
    {
        imgSrc:flag29,
        name: 'Tanzania',
    },
    {
        imgSrc:flag30,
        name: 'Spain',
    },
    {
        imgSrc:flag31,
        name: 'China',
    },
    {
        imgSrc:flag32,
        name: 'Sweden',
    },
    {
        imgSrc:flag33,
        name: 'Romania',
    },
    {
        imgSrc:flag34,
        name: 'Burkina',
    },
    {
        imgSrc:flag35,
        name: 'Mali',
    },
    {
        imgSrc:flag36,
        name: 'Taiwan',
    },
    {
        imgSrc:flag37,
        name: 'South Korea',
    },
    {
        imgSrc:flag38,
        name: 'Cameroon',
    },
    {
        imgSrc:flag39,
        name: 'Venezuela',
    },
    {
        imgSrc:flag40,
        name: 'Madagascar',
    },
    {
        imgSrc:flag41,
        name: 'Mozambique',
    },
    {
        imgSrc:flag42,
        name: 'Ghana',
    },
    {
        imgSrc:flag43,
        name: 'Peru',
    },
    {
        imgSrc:flag44,
        name: 'Morocco',
    },
    {
        imgSrc:flag45,
        name: 'Switzerland',
    },
    {
        imgSrc:flag46,
        name: 'Poland',
    },
    {
        imgSrc:flag47,
        name: 'Argentina',
    },
    {
        imgSrc:flag48,
        name: 'Algeria',
    },
    {
        imgSrc:flag49,
        name: 'Sudan',
    },
    {
        imgSrc:flag50,
        name: 'Uganda',
    },
    {
        imgSrc:flag51,
        name: 'North Korea',
    },
    {
        imgSrc:flag52,
        name: 'Columbia',
    },
    {
        imgSrc:flag53,
        name: 'Turkey',
    },
    {
        imgSrc:flag54,
        name: 'Vietnam',
    },
    {
        imgSrc:flag55,
        name: 'Egypt',
    },
    {
        imgSrc:flag56,
        name: 'Japan',
    },
    {
        imgSrc:flag57,
        name: 'Ethiopia',
    },
    {
        imgSrc:flag58,
        name: 'Mexico',
    },
    {
        imgSrc:flag59,
        name: 'Russia',
    },
    {
        imgSrc:flag60,
        name: 'Brazil',
    },
    {
        imgSrc:flag61,
        name: 'Indonesia',
    },
    {
        imgSrc:flag62,
        name: 'Cuba',
    },
    {
        imgSrc:flag63,
        name: 'Belgium',
    },
    {
        imgSrc:flag64,
        name: 'Denmark',
    },
    {
        imgSrc:flag65,
        name: 'Bolivia',
    },
    {
        imgSrc:flag66,
        name: 'Zimbabwe',
    },
    {
        imgSrc:flag67,
        name: 'Cambodia',
    },
    {
        imgSrc:flag68,
        name: 'Netherlands',
    },
    {
        imgSrc:flag69,
        name: 'Senegal',
    },
    {
        imgSrc:flag70,
        name: 'Guatemala',
    },
    {
        imgSrc:flag71,
        name: 'Ecuador',
    },
    {
        imgSrc:flag72,
        name: 'Hong kong',
    },
    {
        imgSrc:flag73,
        name: 'Greece',
    },
    {
        imgSrc:flag74,
        name: 'Portugal',
    },
    {
        imgSrc:flag75,
        name: 'Austria',
    },
    {
        imgSrc:flag76,
        name: 'Belarus',
    },
    {
        imgSrc:flag77,
        name: 'Phillipines',
    },{
        imgSrc:flag78,
        name: 'Thailand',
    },
];

export default CountriesData
 