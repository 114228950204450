import enttOurWork1 from '../../assest/images/entt.OurWork/enttOurWork1.jpeg'
import enttOurWork2 from '../../assest/images/entt.OurWork/enttOurWork2.jpeg'
import enttOurWork3 from '../../assest/images/entt.OurWork/enttOurWork3.jpeg'
import enttOurWork4 from '../../assest/images/entt.OurWork/enttOurWork4.jpeg'
import enttOurWork5 from '../../assest/images/entt.OurWork/enttOurWork5.jpeg'
import enttOurWork6 from '../../assest/images/entt.OurWork/enttOurWork6.jpeg'
import enttOurWork7 from '../../assest/images/entt.OurWork/enttOurWork7.jpeg'
import enttOurWork8 from '../../assest/images/entt.OurWork/enttOurWork8.jpeg'
import enttOurWork9 from '../../assest/images/entt.OurWork/enttOurWork9.jpeg'
import enttOurWork10 from '../../assest/images/entt.OurWork/enttOurWork10.jpeg'
import enttOurWork11 from '../../assest/images/entt.OurWork/enttOurWork11.jpeg'
import enttOurWork12 from '../../assest/images/entt.OurWork/enttOurWork12.jpeg'



const EnttOurWorkData = [
    {
        id: 1,
        imgSrc: enttOurWork1,
    },
    {
        id: 2,
        imgSrc: enttOurWork2,
    },
    {
        id: 3,
        imgSrc: enttOurWork3,
    }, {
        id: 4,
        imgSrc: enttOurWork4,
    }, {
        id: 5,
        imgSrc: enttOurWork5,
    }, {
        id: 6,
        imgSrc:enttOurWork6,
    }, {
        id: 7,
        imgSrc:enttOurWork7,
    }, {
        id: 8,
        imgSrc:enttOurWork8,
    }, {
        id: 9,
        imgSrc:enttOurWork9,
    }, {
        id: 10,
        imgSrc:enttOurWork10,
    },{
        id: 11,
        imgSrc:enttOurWork11,
    },{
        id: 12,
        imgSrc:enttOurWork12,
    }
];
export default EnttOurWorkData;