import { Link } from "react-router-dom"
import '../../styles/mediaRegistrationFormStyle.css'
import { Box } from "@mui/material"
import MarxMediaHeader from "../../common/MarxMedia/MarxMediaHeader/MarxMediaHeader"
import MediaFooter from "../../common/MarxMedia/MarxMediaFooter/MarxMediaFooter"

const MediaRegistrationForm = () => {
    return (
        <>
            <MarxMediaHeader />
            <Box className="media-registforms">
                <h1>Registration Forms</h1>
                <ul className="media-form-name">
                    <li><Link> Registration Form</Link></li>
                    <li><Link> Registration Form</Link></li>
                    <li><Link>VendorRegistration Form</Link></li>
                    <li><Link>FashionFolio Registration Form</Link></li>
                    <li><Link>Registration Form</Link></li>
                    <li><Link>Registration Form</Link></li>
                    <li><Link>Registration Form</Link></li>
                    <li><Link>Registration Form</Link></li>
                    <li><Link>Registration Form</Link></li>
                    <li><Link>Registration Form</Link></li>
                    <li><Link>Registration Form</Link></li>
                    <li><Link>Registration Form</Link></li>
                    <li><Link>Registration Form</Link></li>
                </ul>
            </Box>
            <MediaFooter />
        </>
    )
}

export default MediaRegistrationForm
