import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { useCart } from 'react-use-cart';
import { BsCartPlus } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import FormatePrice from '../../Helpers/FormatePrice';

const EcomProductCard = (currItem) => {
    let { image, price, name, id, category } = currItem;
    
    return (
        <Card
            style={{ width: '18rem', height: 'auto' }}
            className='bg-lihgt text-black text-center p-0 overflow-hidden shadow mx-auto mb-4'>
            <Link to={`/filter/${id}`}>
                <div style={{
                    background: 'white', height: '15rem', overflow: 'hidden', display: 'flex',
                    justifyContent: 'center', alignItems: 'center', marginBottom: 'inherit'
                }}>
                    <div style={{ width: '15rem' }}>
                        <Card.Img variant="top" src={image} className="img-fluid" />
                    </div>
                </div>
            </Link>
            <Card.Body>
                <Card.Title style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap',fontSize:'22px' }}>
                    {name}
                </Card.Title>
                <Card.Title style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap',fontSize:'14px' }}>
                    {category}
                </Card.Title>
                <Card.Title>
                    Rs. <span className="h6">{<FormatePrice price={price}/>}</span>
                </Card.Title>

                <Button
                    className='bg-light-primary d-flex align-item-center m-auto border-0'
                >
                    <BsCartPlus size="1.5rem" className='me-1' />
                    Add to cart
                </Button>
            </Card.Body>
        </Card>
    );
};

export default EcomProductCard;