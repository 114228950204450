
import GalleryWitthBioAllMax from "../../../../common/GalleryWitthBioAllMax/GalleryWitthBioAllMax"
import MarxEventFooter from "../../../../common/Marx_Event/MarxEventFooter/MarxEventFooter"
import MarxEventHeader from "../../../../common/Marx_Event/MarxEventHeader/MarxEventHeader"
import InternModelFemaleData from "../../../../data/marxEvent/ourModelbank/international/InternModelFemaleData"
import '../../ouModelBank/india/IndModelMaleStyle.css'


const InternModelFemale = () => {
  return (
    <>
      <MarxEventHeader />
      <GalleryWitthBioAllMax ModelData={InternModelFemaleData} heading={"Photo Gallery of International Female model"} title={'GALLERY'} detailpg={'international-female-model-detail-pg'} />
      <MarxEventFooter />

    </>
  )
}

export default InternModelFemale
 
 
 
 

