import MarxEventFooter from "../../common/Marx_Event/MarxEventFooter/MarxEventFooter"
import MarxEventHeader from "../../common/Marx_Event/MarxEventHeader/MarxEventHeader"
import '../../styles/eventShowGalleryStyle.css'
import { Link } from "react-router-dom"
import GalleryPicsData from "../../data/marxEvent/GalleryPicsData"

const EventShowGallery = () => {
  return (
    <>
      <MarxEventHeader />

      <div className="event-show-gallery">
        <h1>Gallery</h1>
        <div className="imagealbum">
          {GalleryPicsData.map((url, index) => (
            <Link to={`/album${url.id}-pics`} key={url.id}>
              <img src={url.imgSrc} alt={`img${url.id}`} />
            </Link>
          ))}
        </div>
      </div>


      <MarxEventFooter />
    </>
  )
}

export default EventShowGallery
