
import GalleryPhotos from '../../../common/Gallery/Gallery'
import SecondPicsData from '../../../data/marxEvent/SecondPicsData'
import MarxRealEstateHeader from '../../../common/MarxRealEstate/HeaderMarxRealEstate'
import MarxRealEstateFooter from '../../../common/MarxRealEstate/FooterMarxRealEstate'

const RealEstateLuxury5StarHotel = () => {
    return (
        <>
            <MarxRealEstateHeader />
            <GalleryPhotos  GalleryData={SecondPicsData} title={'Luxury 5 Star Hotel'}/>
            <MarxRealEstateFooter />
        </>
    )
}

export default RealEstateLuxury5StarHotel
