import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './topSliderAllMarxStyle.css';


const TopSliderAllMax = ({ title, images }) => {
  // number of slides to show for laptop and mobile
  //  const SlidesToShowLaptop = 3;
  //  const SlidesToShowMobile = 1;

  //  // initial slidesToShow based on the screen width
  //  const initialSlidesToShow = window.innerWidth >= 768 ? SlidesToShowLaptop : SlidesToShowMobile;

  const settings = {
    dots: false,
    infinite: true,
    speed: 700,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: '1px',
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: '1px',
        },
      },
      {
        breakpoint: 9999, // A large value to ensure the last breakpoint always applies
        settings: {
          slidesToShow: 3,
        },
      }
    ],
  };

  return (
    <>
      <div className="topSlider-divmain">
        <h1 className='fw-bold ms-3 text-white'>{title}</h1>
        <Slider {...settings}>
          {images?.map((item, index) => (
            <div key={item.id} className="item-topimg">
              <img src={item.imgSrc} alt={`img-${item.id}`} />
            </div>
          ))}
        </Slider>
      </div>
    </>
  )
}

export default TopSliderAllMax
