import { Box, Button } from "@mui/material"
import '../styles/landingStyle.css'
import videoo from '../assest/video/web  intro.mp4'
import { useEffect, useRef, useState } from "react";
import { MdOutlineVolumeUp } from "react-icons/md";
import logoimg from '../assest/images/marxgrouplogo.jpeg'
import { useNavigate } from "react-router-dom";



const LandingPage = () => {
    const [showVideo, setShowVideo] = useState(true);
    const videoElemnt = useRef(null);
    const [isMuted, setIsMuted] = useState(true);
    const [videoPlayed, setVideoPlayed] = useState(false);
    const navigate = useNavigate()

    const attemptPlay = () => {
        videoElemnt &&
            videoElemnt.current &&
            videoElemnt.current.play().then(() => {
                // Video played successfully
                setVideoPlayed(true);
                setShowVideo(false);
            })
                .catch((error) => {
                    console.error("Error attempting to play", error);
                });
    };

    useEffect(() => {
        // After 8 seconds, hide the video and reveal the rest of the content
        const timeoutId = setTimeout(() => {
            attemptPlay();
        }, 7000);

        // Cleanup the timeout to avoid memory leaks
        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    const handleLoginmodel = () => {
        navigate('/marx-group-login')
    }

    return (
        <>
            {showVideo && (
                <div className="vidio-box">
                    <Button className="audiobtn" onClick={() => { setIsMuted(false) }}><MdOutlineVolumeUp className="fs-4" /></Button>
                    <video
                        className="video-tag"
                        playsInline
                        muted={isMuted}
                        autoPlay
                        alt="All the devices"
                        src={videoo}
                        ref={videoElemnt}
                    />
                </div>

            )}


            {videoPlayed && !showVideo && (
                <>
                    <Box className='full-landingpg'>
                        <div className="loginmain-div">
                            <div className="loginlogo">
                                <img src={logoimg} alt="img" />
                            </div>
                            <div className="hading">
                                <h1 className="year-celebrate">Celebrating 24 Years Successfully</h1>
                            </div>
                            <div className="btnnndiv">
                                <Button onClick={handleLoginmodel} variant="contained">Enter</Button>
                            </div>
                        </div>
                    </Box>
                </>

            )}


        </>
    )
}

export default LandingPage
