
import GalleryWitthBioAllMax from "../../../../common/GalleryWitthBioAllMax/GalleryWitthBioAllMax"
import Sidebar from "../../../../common/SideAbleMenu/SideBar"
import IndModelMaleData from "../../../../data/marxEvent/ourModelbank/indian/indianModelMaleData"
import WeddingFooter from "../../../../common/marxWedding/FooterMarxWedding"
import MarxWeddingHeader from "../../../../common/marxWedding/HeaderMarxWedding"

const India5StarProperty = () => {
  return (
    <>
      <Sidebar />
        <MarxWeddingHeader />
        <GalleryWitthBioAllMax ModelData={IndModelMaleData}  heading={"India 5 Star Property"} title={'GALLERY'} detailpg={'wedding-hotelvenue-india/5-star-property'}/>
        <WeddingFooter />

    </>
  )
}

export default India5StarProperty
