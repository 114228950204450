import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import '../../styles/marxFashionCoutureHomeStyle.css'
import MarxFashionCoutureHeader from "../../common/MarxFashionCouture/HeaderMarxFashionCouture";
import MarxFashionCoutureFooter from "../../common/MarxFashionCouture/FooterMarxFashionCouture";
// import RunningSlide from "../../common/RunningSlideAllMarx/RunningSlide";
import { useEffect } from "react";
// import TopSliderAllMax from "../../common/TopSliderAllMarx/TopSliderAllMarx";
// import AllDemoData from "../../data/AllDemoData";


const MarxFashionCoutureHome = () => {
    useEffect(() => {
        document.title ="Mar'x Fashion Couture Pvt.Ltd";
      }, []);
    return (
        <>
            <MarxFashionCoutureHeader />
            <div className="ventures">
                <h1>COMING SOON...</h1>
            </div>

            {/* <TopSliderAllMax title={"Product"} images={AllDemoData} />

            <div className="main-fashioncouturediv">
                <Box className="mainfashioncouturediv-box">
                    <div className="allfashioncouturetabs">
                        <h5 className=" text-center fw-bold mb-4"> INDEX </h5>
                        <p className='mange-fashioncouturetab'>
                            <Link to={'/'} >MARS </Link>
                        </p>
                    </div>
                    <div className="fashioncouturetabpanels">
                        <div>
                            <div className="about-marx-fashioncouture">
                                <h1>About Us</h1>
                                <p></p>
                            </div>

                            <div className="about-marx-fashioncouture">
                                <h1>What We Do</h1>
                                <div>
                                    <p></p>
                                </div>
                            </div>

                            <div className="about-marx-fashioncouture">
                                <h1>Our Services</h1>
                                <div className="servcedivptag">
                                    <p></p>
                                </div>
                            </div>

                            <div className="about-marx-fashioncouture">
                                <h1>Who We Are</h1>
                                <div>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </div>

            <RunningSlide title="Product" images={AllDemoData}/>
            <RunningSlide title="Designing" images={AllDemoData}/> */}


            <MarxFashionCoutureFooter />
        </>
    )
}

export default MarxFashionCoutureHome
