import weddFebricationWork1 from '../../assest/images/weddOurFebricationWork/weddFebricationWork1.jpeg'
import weddFebricationWork2 from '../../assest/images/weddOurFebricationWork/weddFebricationWork2.jpeg'
import weddFebricationWork3 from '../../assest/images/weddOurFebricationWork/weddFebricationWork3.jpeg'
import weddFebricationWork4 from '../../assest/images/weddOurFebricationWork/weddFebricationWork4.jpeg'
import weddFebricationWork5 from '../../assest/images/weddOurFebricationWork/weddFebricationWork5.jpeg'
import weddFebricationWork6 from '../../assest/images/weddOurFebricationWork/weddFebricationWork6.jpeg'
import weddFebricationWork7 from '../../assest/images/weddOurFebricationWork/weddFebricationWork7.jpeg'
import weddFebricationWork8 from '../../assest/images/weddOurFebricationWork/weddFebricationWork8.jpeg'
import weddFebricationWork9 from '../../assest/images/weddOurFebricationWork/weddFebricationWork9.jpeg'
import weddFebricationWork10 from '../../assest/images/weddOurFebricationWork/weddFebricationWork10.jpeg'
import weddFebricationWork11 from '../../assest/images/weddOurFebricationWork/weddFebricationWork11.jpeg'
import weddFebricationWork12 from '../../assest/images/weddOurFebricationWork/weddFebricationWork12.jpeg'
import weddFebricationWork13 from '../../assest/images/weddOurFebricationWork/weddFebricationWork13.jpeg'
import weddFebricationWork14 from '../../assest/images/weddOurFebricationWork/weddFebricationWork14.jpeg'
import weddFebricationWork15 from '../../assest/images/weddOurFebricationWork/weddFebricationWork15.jpeg'
import weddFebricationWork16 from '../../assest/images/weddOurFebricationWork/weddFebricationWork16.jpeg'
import weddFebricationWork17 from '../../assest/images/weddOurFebricationWork/weddFebricationWork17.jpeg'
import weddFebricationWork18 from '../../assest/images/weddOurFebricationWork/weddFebricationWork18.jpeg'
import weddFebricationWork19 from '../../assest/images/weddOurFebricationWork/weddFebricationWork19.jpeg'
import weddFebricationWork20 from '../../assest/images/weddOurFebricationWork/weddFebricationWork20.jpeg'
import weddFebricationWork21 from '../../assest/images/weddOurFebricationWork/weddFebricationWork21.jpeg'
import weddFebricationWork22 from '../../assest/images/weddOurFebricationWork/weddFebricationWork22.jpeg'
import weddFebricationWork23 from '../../assest/images/weddOurFebricationWork/weddFebricationWork23.jpeg'
import weddFebricationWork24 from '../../assest/images/weddOurFebricationWork/weddFebricationWork24.jpeg'




const OurFebricationWorkData = [
    {
        id: 1,
        src: weddFebricationWork1,
    },
    {
        id: 2,
        src: weddFebricationWork2,
    },
    {
        id: 3,
        src: weddFebricationWork3,
    }, {
        id: 4,
        src: weddFebricationWork4,
    }, {
        id: 5,
        src: weddFebricationWork5,
    }, {
        id: 6,
        src:weddFebricationWork6,
    }, {
        id: 7,
        src:weddFebricationWork7,
    }, {
        id: 8,
        src:weddFebricationWork8,
    }, {
        id: 9,
        src:weddFebricationWork9,
    }, {
        id: 10,
        src:weddFebricationWork10,
    },{
        id: 11,
        src:weddFebricationWork11,
    },{
        id: 12,
        src:weddFebricationWork12,
    },{
        id: 13,
        src:weddFebricationWork13,
    },{
        id: 14,
        src:weddFebricationWork14,
    },{
        id: 15,
        src:weddFebricationWork15,
    },{
        id: 16,
        src:weddFebricationWork16,
    },{
        id: 17,
        src:weddFebricationWork17,
    },{
        id: 18,
        src:weddFebricationWork18,
    },{
        id: 19,
        src:weddFebricationWork19,
    },{
        id: 20,
        src:weddFebricationWork20,
    },{
        id: 21,
        src:weddFebricationWork21,
    },{
        id: 22,
        src:weddFebricationWork22,
    },{
        id: 23,
        src:weddFebricationWork23,
    },{
        id: 24,
        src:weddFebricationWork24,
    }
];
export default OurFebricationWorkData;