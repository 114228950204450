
import GalleryWitthBioAllMax from "../../../../common/GalleryWitthBioAllMax/GalleryWitthBioAllMax"
import MarxEventFooter from "../../../../common/Marx_Event/MarxEventFooter/MarxEventFooter"
import MarxEventHeader from "../../../../common/Marx_Event/MarxEventHeader/MarxEventHeader"
import IndModelKidsData from "../../../../data/marxEvent/ourModelbank/indian/IndianModelKidsData"
import '../../ouModelBank/india/IndModelMaleStyle.css'


const InternKidsModelbank = () => {
  return (
    <>
      <MarxEventHeader />
      <GalleryWitthBioAllMax ModelData={IndModelKidsData} heading={"Photo Gallery of International Kids model"} title={'GALLERY'} detailpg={'international-kids-model-detail-pg'} />
      <MarxEventFooter />

    </>
  )
}

export default InternKidsModelbank
 
 
 
 

