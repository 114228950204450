import React from "react";
import "./NotSureBlock.css";
function NotSureBlock() {
  return (
    <>
      <div className="notsureblock">
        <div className="mainwapperNotsure">
          <h2>Not Sure How to Get Started?</h2>
          <div className="innerblocksecnotsure">
            <div className="photoholder">
              <img alt='img' src="https://www.influglue.com/public/frontend/images/influncer/Janvi2.jpg?v=1688734414" />
            </div>
            <div className="contactformHoldrer">
              <h2>
                Submit a Question
                <span>
                  No time for a call? No Problem Send your query and our expert
                  will contact with you shortly
                </span>
              </h2>
              <div className="innernotsure">
                <form>
                  <div className="inputHolder">
                    <input
                      className="name"
                      type="text"
                      placeholder="Your Name*"
                    />
                  </div>
                  <div className="inputHolder">
                    <input
                      className="email"
                      type="text"
                      placeholder="Your Email*"
                    />
                  </div>
                  <div className="inputHolder">
                    <textarea
                      className="messages"
                      placeholder="How can we help you"
                    />
                  </div>
                  <input
                    className="guestpostbtn"
                    type="button"
                    value="Send Message"
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default NotSureBlock;
