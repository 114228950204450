import { Box, Modal } from "@mui/material";
import { LiaWindowCloseSolid } from "react-icons/lia";


const ImageOpenModel = ({src,isModalOpen,handleClose}) => {
    return (
        <>
            {/* Modal */}
            <Modal
                open={isModalOpen}
                onClose={handleClose}
                className="modal"
            >
                <Box className="modal-content">
                    <LiaWindowCloseSolid className="close" onClick={handleClose} />
                    <img src={src} alt="big-img" />
                </Box>
            </Modal>
        </>
    )
}

export default ImageOpenModel
