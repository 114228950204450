import React from "react";
import "./ContentMarketing.css";
import jh from "../../assest/images/imgg3.jfif";
import BenifitsOfContentMarketing from "../../components/benifitsOfContentmarketing/BenifitsOfContentMarketing";
import WhatTheydoforBrand from "../../components/whatInfluenserDoForYourBrand/WhatTheydoforBrand";
import FreqAskQues from "../../components/FreqAskQues";
import OurWorkSlider from "../../components/ourWorkSlider/OurWorkSlider";
import Header from "../../common/MarxEntertainment/header/Header";
import Footer from "../../common/MarxEntertainment/footer/Footer";

function ContentMarketing() {
  return (
    <>
    <Header />
      <div className="topserviceBanner">
        <div className="d-flex">
          <div className="col-md-7">
            <div className="banneContent">
              <h1 className="bannerh1content">
                Welcome to the Nation’s Most Authentic Content Marketing Agency
              </h1>
              <p className="paracontentmark">
                Content marketing is the form of creating publishing and
                distributing marketing content for your target audience. The
                main aim of such marketing is to attract, acquire and engage a
                specific group of audience to drive profitable customer action.
                If implemented properly it can provide multiple benefits to
                online businesses.
              </p>
              <a href="/" className="startbtnc">
                Start A Campaign
              </a>
            </div>
          </div>
          <div className="col-md-5">
            <div className="hi"></div>
            <div className="contentMarketingHeroImg">
              <img src={jh} alt="9a0f68298086cc5a18f09e8e62b90399c.jpg" />
            </div>
          </div>
        </div>
      </div>
      <div className="whatPromotionBlock">
        <div className="mainWapperPromotionBlock">
          <div className="innerPromotion">
            <div className="photoblockPromotion">
              <div className="bli"></div>
              <div className="secondblock">
                <img alt="img" src="https://www.influglue.com/files/medialibrary/d94176fa3c34199e45871f31299f3c65.jpg" />
              </div>
              <div className="firstblockp">
                <img alt="img" src="https://www.influglue.com/files/medialibrary/c8981c6d3ba01374846f0f1dfa271068.jpg" />
              </div>
            </div>
            <div className="congtent">
              <h2>What is content marketing?</h2>
              <p>
                Content marketing is the form of creating publishing and
                distributing marketing content for your target audience. The
                main aim of such marketing is to attract, acquire and engage a
                specific group of audience to drive profitable customer action.
                Content marketing is one of the most effective and useful
                marketing strategies that provide numerous benefits to online
                businesses that are looking to make a solid impression in this
                competitive market.
              </p>
            </div>
          </div>
        </div>
      </div>
      <BenifitsOfContentMarketing />
      <WhatTheydoforBrand />
      <div className="whyChose">
        <div className="mainwapperWhyChose">
          <h2>Why Choose Abaris?</h2>
          <p>
            At Abaris we are committed to making content marketing inexpensive
            and available for everyone. Content marketing is not only a
            buzzword, it is also a necessity in today’s time since it helps
            establish a leading voice in your industry. You need customers with
            high brand affinity who will act as brand advocates and will vouch
            for your products by telling stories about them to the general
            audience.
          </p>
          <p>
            We believe online reputation plays a big part in developing and
            sustaining brand affinity. Instead of trying to sell high-quality
            products brands must focus on sharing a trustworthy voice in the
            industry which is possible through top-notch relevant content.
          </p>
          <ul>
            <li>
              <div className="ber"></div>
              Good quality brand-centric content
            </li>
            <li>
              <div className="ber"></div>
              Original content Copyscape passed
            </li>
            <li>
              <div className="ber"></div>
              Content from experienced writers
            </li>
            <li>
              <div className="ber"></div>
              Target audience-specific content
            </li>
            <li>
              <div className="ber"></div>
              On-time delivery
            </li>
            <li>
              <div className="ber"></div>
              Guaranteed ROI
            </li>
          </ul>
        </div>
      </div>
      <div className="howDoesitWork">
        <div className="mainwapperHowDoes">
          <h2>How Does it Work?</h2>
          <div className="innerHowDoes">
            <div className="block">
              <h3>Determine your goals</h3>
              <div className="innerblockHowDoes">
                <p>
                  Discuss your content marketing goals with our team, tell them
                  what you want to highlight and achieve from our service so
                  that our content specialist can work on it.
                </p>
              </div>
              <div className="arrowafter"></div>
            </div>
            <div className="block">
              <h3>Research and create conten</h3>
              <div className="innerblockHowDoes">
                <p>
                  Let the team brainstorm and come up with good quality original
                  content that can fulfil all your marketing requirements.
                </p>
              </div>
              <div className="arrowafter"></div>
            </div>
            <div className="block">
              <h3>Distribute them across different channels</h3>
              <div className="innerblockHowDoes">
                <p>
                  After submitting the content, it is time to disseminate them
                  over various channels so that you can achieve your marketing
                  agenda.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FreqAskQues />
      <div className="container">
        <OurWorkSlider />
      </div>
      <div className="knowMore">
        <div className="mainWapperKnowmore">
          <div className="contentKnowmore">
            <h2>Want to Know More About Us?</h2>
            <p>
              We are a team of experienced digital marketers, tech buddies and
              content creators who wish to achieve the next level of creativity
              in marketing not only to stand out among others but also to get
              noticed by the biggest social media platforms. Our team members
              handle and operate campaigns from different cities of the country,
              we also have regular content creators on board with us who helps
              us in finetuning our strategy to deliver the best. We believe in a
              personalised approach with our clients with the perfect blend of
              creativity and technology.
            </p>
            <p>
              Connect with us at your convenience, one of our marketing
              executives will get back to you at their earliest.
            </p>
            <a href="/" className="startbtn123">
              Contact Us
            </a>
          </div>
          <div className="heroimgKnowmore">
            <img alt="img" src="https://www.influglue.com/files/medialibrary/6de7e3e820d96956fb1380c6a82d5bd4.jpg" />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default ContentMarketing;


