import React from "react";
import "./GuestPost.css";
import TrustedByBussiness from "../../components/trustedByBuussinessOwner/TrustedByBussiness";
import WhyGuestPost from "../../components/whyGuestPost/WhyGuestPost";
import { GoDotFill } from "react-icons/go";
import FreqAskQues from "../../components/FreqAskQues";
import NotSureBlock from "../../components/notSureBlock/NotSureBlock";
import Header from "../../common/MarxEntertainment/header/Header";
import Footer from "../../common/MarxEntertainment/footer/Footer";
function GuestPosts() {
  return (
    <>
    <Header/>
      <div className="breadcurmb">
        <div className="mainwapperabout">
          <a href="/">Home</a>
          <span>»</span>
          Pricing Plans
          <span>»</span>
          Guest Posts
        </div>
      </div>
      <div className="guestPosts">
        <div className="combopackblock">
          <div className="conatiner titleblock row">
            <h1 className="bigt-title d-flex justify-content-center ">
              Get The Best Quality Guest-Post Backlinks <br /> From Real
              Influencer Sites with Real Traffic
            </h1>
          </div>
          <div className="mainpackege">
            <div className="mainwapguest">
              <h5>NO BLINDSPOT. YOU CHOOSE THE DOMAINS.</h5>
              <form>
                <div className="innerGuestSection">
                  <div className="block">
                    <div className="top blue">
                      <h3>Start Up</h3>
                      <p>6 Guestpost Backlinks /m</p>
                    </div>
                    <div className="mid">
                      <small className="sml">INR 15000</small>
                      <div className="price">
                        <span>INR</span>
                        12000
                      </div>
                      <div className="saveblock blue">Save INR 3000</div>
                      <h4>For 6 HQ Links / month</h4>
                    </div>
                    <div className="last">
                      <ul style={{ listStyle: "disc", marginLeft: "-18px" }}>
                        <li>2 X DA 20 + Guestpost</li>
                        <li>2 X DA 30 + Guestpost</li>
                        <li>1 X DA 40 + Guestpost</li>
                        <li>1 X DA 50 + Guestpost</li>
                      </ul>
                    </div>
                    <div className="bottomblock">
                      <input
                        className="btngetstarted"
                        type="button"
                        value="Get Started"
                      />
                    </div>
                  </div>

                  <div className="block">
                    <div className="top orange">
                      <h3>Growth</h3>
                      <p>11 Guestpost Backlinks /m</p>
                    </div>
                    <div className="mid">
                      <small className="sml">INR 35000</small>
                      <div className="price">
                        <span>INR</span>
                        30000
                      </div>
                      <div className="saveblock orange">Save INR 5000</div>
                      <h4>For 11 HQ Links / month</h4>
                    </div>
                    <div className="last">
                      <ul style={{ listStyle: "disc", marginLeft: "-18px" }}>
                        <li>3 X DA 20 + Guestpost</li>
                        <li>3 X DA 30 + Guestpost</li>
                        <li>3 X DA 40 + Guestpost</li>
                        <li>2 X DA 50 + Guestpost</li>
                      </ul>
                    </div>
                    <div className="bottomblock">
                      <input
                        className="btngetstarted"
                        type="button"
                        value="Get Started"
                      />
                    </div>
                  </div>

                  <div className="block">
                    <div className="top green">
                      <h3>SCALE</h3>
                      <p>24 Guestpost Backlinks /m</p>
                    </div>
                    <div className="mid">
                      <small className="sml">INR 60000</small>
                      <div className="price">
                        <span>INR</span>
                        45000
                      </div>
                      <div className="saveblock green">Save INR 15000</div>
                      <h4>For 24 HQ Links / month</h4>
                    </div>
                    <div className="last">
                      <ul style={{ listStyle: "disc", marginLeft: "-18px" }}>
                        <li>6 X DA 20 + Guestpost</li>
                        <li>6 X DA 30 + Guestpost</li>
                        <li>6 X DA 40 + Guestpost</li>
                        <li>6 X DA 50 + Guestpost</li>
                      </ul>
                    </div>
                    <div className="bottomblock">
                      <input
                        className="btngetstarted"
                        type="button"
                        value="Get Started"
                      />
                    </div>
                  </div>
                </div>
              </form>
              <div className="difficultblock">
                <div className="leftblock">
                  <h2>Difficult to Choose?</h2>
                  <p>
                    Have doubts about what will suit your business the most?
                    We'll gladly answer all your questions!
                  </p>
                </div>
                <div className="rightblock">
                  <a href>G/et in Touch</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="howitWorkGuest">
        <div className="back">
          <div className="mainwapperhowguest">
            <div className="titleblockguesthow">
              <h2>How it Works</h2>
              <p>100% Transarent process Of Scoring Powerful Backlinks</p>
            </div>
            <div className="ulkg">
              <div className="innerhiwblock">
                <div className="block">
                  <div className="blocktitle">
                    <h4>
                      STEP 1<span>Pick your plan</span>
                    </h4>
                    <div className="iconholderguest">
                      <img alt="img" src="https://www.influglue.com/files/medialibrary/0a04fdfb831a489047606a68aa37217a.png" />
                    </div>
                  </div>
                  <p>
                    Based on your budget you can choose a plan that will cater
                    to your needs. Check the available options carefully then
                    you pick the one you like.
                  </p>
                </div>
                <div className="block">
                  <div className="blocktitle">
                    <h4>
                      STEP 2<span>Secure your placement</span>
                    </h4>
                    <div className="iconholderguest">
                      <img alt="img" src="https://www.influglue.com/files/medialibrary/0a04fdfb831a489047606a68aa37217a.png" />
                    </div>
                  </div>
                  <p>
                    As soon as you choose your plan our team will help you
                    secure your placement which will help you to improve your
                    search engine ranking gracefully.
                  </p>
                </div>
                <div className="block">
                  <div className="blocktitle">
                    <h4>
                      STEP 3<span>Finalise the domains</span>
                    </h4>
                    <div className="iconholderguest">
                      <img alt="img" src="https://www.influglue.com/files/medialibrary/0a04fdfb831a489047606a68aa37217a.png" />
                    </div>
                  </div>
                  <p>
                    Choose the best domains that would resonate well with your
                    business and would help you achieve your marketing agenda
                    better. More options are now at your fingertips.
                  </p>
                </div>
                <div className="block">
                  <div className="blocktitle">
                    <h4>
                      STEP 4<span>Content crafting</span>
                    </h4>
                    <div className="iconholderguest">
                      <img alt="img" src="https://www.influglue.com/files/medialibrary/0a04fdfb831a489047606a68aa37217a.png" />
                    </div>
                  </div>
                  <p>
                    If you believe content is the king, you would get top
                    quality tailored content to your backlinks need. Our
                    creative writers will ensure SEO compliance and niche
                    relevancy.
                  </p>
                </div>
                <div className="block">
                  <div className="blocktitle">
                    <h4>
                      STEP 5<span>Guest post live</span>
                    </h4>
                    <div className="iconholderguest">
                      <img alt="img" src="https://www.influglue.com/files/medialibrary/0a04fdfb831a489047606a68aa37217a.png" />
                    </div>
                  </div>
                  <p>
                    It’s time to live your guest post and observe its
                    performance with all the high-quality backlinks to your
                    target URL.
                  </p>
                </div>
                <div className="block">
                  <div className="blocktitle">
                    <h4>
                      STEP 6<span>Track progress</span>
                    </h4>
                    <div className="iconholderguest">
                      <img alt="img" src="https://www.influglue.com/files/medialibrary/0a04fdfb831a489047606a68aa37217a.png" />
                    </div>
                  </div>
                  <p>
                    Check the performance of your project on your dashboard. Get
                    a final report from us as soon as we finish.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TrustedByBussiness />
      <WhyGuestPost />
      <div className="blueAdblocks">
        <div className="innerblueads">
          <p>Spending Money on SEO is an Investment</p>
          <h3>
            Abaris Secures The Best Return On Your Link Building Investments.
          </h3>
          <a href="/">Start A Campaign</a>
        </div>
      </div>
      <div className="moneyBackBlock">
        <div className="mainwapperMoneyBlock">
          <div className="moneybackinner">
            <h2>How we work and our 100% Moneyback Guarantee</h2>
            <ul>
              <li>
                <span>
                  <GoDotFill style={{ color: "#595aff" }} />
                </span>{" "}
                Once you book a package our dedicated Service Managers will
                contact you within 24 Hrs
              </li>
              <li>
                <span>
                  <GoDotFill style={{ color: "#595aff" }} />
                </span>{" "}
                Based on your requirement Service Manager will search and
                compile the suitable list of influencers for your project
              </li>
              <li>
                <span>
                  <GoDotFill style={{ color: "#595aff" }} />
                </span>{" "}
                Based on your requirement Service Manager will search and
                compile the suitable list of influencers for your project
              </li>
              <li>
                <span>
                  <GoDotFill style={{ color: "#595aff" }} />
                </span>{" "}
                If you are not satisfied with our list of infleunecrs we will
                present you with a 2nd or 3rd list
              </li>
              <li>
                <span>
                  <GoDotFill style={{ color: "#595aff" }} />
                </span>{" "}
                If you are still not happy with our list (within 7 days) we will
                refund your booking. No questions asked
              </li>
              <li>
                <span>
                  <GoDotFill style={{ color: "#595aff" }} />
                </span>{" "}
                If you are done with the seelction of influencers then in the
                next phase we will take complate responsiblity of the project
              </li>
              <li>
                <span>
                  <GoDotFill style={{ color: "#595aff" }} />
                </span>{" "}
                We will coordinate with all infleuencers, sharing art work,
                videos and other materials as required
              </li>
              <li>
                <span>
                  <GoDotFill style={{ color: "#595aff" }} />
                </span>{" "}
                Closely monitor the progress of the project and follow up with
                them
              </li>
              <li>
                <span>
                  <GoDotFill style={{ color: "#595aff" }} />
                </span>{" "}
                Finally submit a comprehensive project execution report to you{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <FreqAskQues />
      <NotSureBlock />
      <Footer />
    </>
  );
}
export default GuestPosts;


