import MarxEventFooter from "../../../common/Marx_Event/MarxEventFooter/MarxEventFooter"
import MarxEventHeader from "../../../common/Marx_Event/MarxEventHeader/MarxEventHeader"
import GalleryPhotos from "../../../common/Gallery/Gallery";
import Album1PicsData from "../../../data/Album1PicsData";


const Album1Pics = () => {

  return (
    <>
      <MarxEventHeader />
      <GalleryPhotos GalleryData={Album1PicsData} title={'Album1 Photo Gallery'} />
      <MarxEventFooter />
    </>
  )
}

export default Album1Pics
