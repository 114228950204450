import img1 from '../assest/images/img1.avif'
import img2 from '../assest/images/img2.avif'
import img3 from '../assest/images/img3.avif'
import img4 from '../assest/images/img4.avif'
import img5 from '../assest/images/img5.avif'
import img6 from '../assest/images/img6.avif'
import img7 from '../assest/images/slid1.jpg'
import img8 from '../assest/images/sld2.jpg'
import img9 from '../assest/images/sld3.jpg'
import img10 from '../assest/images/img1.avif'
import img11 from '../assest/images/img3.avif'
import img12 from '../assest/images/img2.avif'



const Album1PicsData = [
  {
    src: img1,
    profileName: 'billu'
  },
  {
    src: img2,
    profileName: 'amana'
  },
  {
    src: img3,
    profileName: 'amir'
  },
  {
    src: img4,
    profileName: 'rihan'
  },
  {
    src: img5,
    profileName: 'nizam'
  },
  {
    src: img6,
    profileName: 'junaid'
  },
  {
    src: img7,
    profileName: 'atif'
  },
  {
    src: img8,
    profileName: 'junaid'
  },
  {
    src: img9,
    profileName: 'nizam'
  },
  {
    src: img10,
    profileName: 'rihan'
  },
  {
    src: img11,
    profileName: 'junaid'
  },
  {
    src: img12,
    profileName: 'billu'
  },
   {
    src: img1,
    profileName: 'aman'
  },
  {
    src: img2,
    profileName: 'atif'
  },
  {
    src: img3,
    profileName: 'nizam'
  },
  {
    src: img4,
    profileName: 'rihan'
  },
  {
    src: img5,
    profileName: 'rihan'

  }
];

export default Album1PicsData