import GalleryPhotos from "../../../common/Gallery/Gallery"
import WeddingFooter from "../../../common/marxWedding/FooterMarxWedding"
import MarxWeddingHeader from "../../../common/marxWedding/HeaderMarxWedding"
import SecondPicsData from "../../../data/marxEvent/SecondPicsData"

const WeddingStageSetupDesigns = () => {
    return (
        <>
            <MarxWeddingHeader />
            <GalleryPhotos GalleryData={SecondPicsData} title={"Mar'x Stage and Set-Up Designs "} />
            <WeddingFooter />
        </>
    )
}

export default WeddingStageSetupDesigns
