import React, { useEffect, useState } from 'react'
import MarxWeddingHeader from '../../common/marxWedding/HeaderMarxWedding'
import '../../styles/marxWeddingStyle.css'
import { Box } from '@mui/material'
import { Link } from 'react-router-dom'
import WeddingFooter from '../../common/marxWedding/FooterMarxWedding'
import RunningSlide from '../../common/RunningSlideAllMarx/RunningSlide'
import EntertainmentGalleryData from '../../data/MarxWedding/EntertainmentGalleryData'
import WeddFoodData from '../../data/MarxWedding/WeddFoodData'
import TopMarxWeddSlideData from '../../data/MarxWedding/TopMarxWeddSlideData'
import TopSliderAllMax from '../../common/TopSliderAllMarx/TopSliderAllMarx'
import PhotoShootGalleryData from '../../data/MarxWedding/PhotoShootGalleryData'




const MarxWedding = () => {
  useEffect(() => {
    document.title = "Mar'x luxury Wedding India Pvt.Ltd";
  }, []);

  const [openHotelVenue, setOpenHotelVenue] = useState(false)

  const toggleHotelVenueBox = () => {
    console.log('Toggling box');
    setOpenHotelVenue(!openHotelVenue);
  };

  return (
    <>
      <MarxWeddingHeader />
      <TopSliderAllMax title={"Decor Gallery"} images={TopMarxWeddSlideData} />

      <div className="">
        <div className="event-navbrlists">
          <ul>
            <li>
              <Link to="/marx-wedding">Home</Link>
            </li>
            <li>
              <Link to="/services-wedding">Services</Link>
            </li>
            <li>
              <Link to="/wedding-filtersearch">Filter Search</Link>
            </li>
            <li>
              <Link to="/wedding-videoshowsreel">Video Shows Reel</Link>
            </li>
            <li>
              <Link to="/destination-wedding">Destination Wedding</Link>
            </li>
            <li>
              <Link to="/prewedding-events-pics">Pre-Wedding Gallery</Link>
            </li>
            <li>
              <Link to="/wedding-subscriptionplans">Subscription Plans</Link>
            </li>

            <li>
              <Link to="/wedding-events-pics"> Main Wedding Gallery</Link>
            </li>
            <li>
              <Link to="/wedding-registration-form">Registration Form</Link>
            </li>
            <li>
              <Link onClick={toggleHotelVenueBox} to="">Hotel & Venue</Link>
              <Box className={openHotelVenue ? 'visible-hotelvenue' : 'hidden-hotelvenue'}>
                <div className="ind-int-divbox">
                  <Link to={'/wedding-hotelvenue-india'}>India</Link>
                  <Link to={'/wedding-hotelvenue-international'}>International</Link>
                </div>
              </Box>
            </li>
            <li>
              <Link to="/wedding-ourfebricationwork">Our Febrication Work</Link>
            </li>

           
           
          

          </ul>
        </div>
      </div>
      <div className="main-weddingdiv">
        <Box className="mainwedddiv-box">
          <div className="allweddtabs">
            <h5 className=" text-center fw-bold mb-4">INDEX </h5>
            <p className='mange-weddtab'>
              <Link to={'/wedd-travelpackages'} >Mar'x Travel Packages</Link>
            </p>
            <p className='mange-weddtab'>
              <Link to={'/wedd-packages'} >Mar'x Wedding Packages</Link>
            </p>
            <p className='mange-weddtab'>
              <Link to={'/wedd-custommadepackages'} >Mar'x Custom Made Packages</Link>
            </p>
            <p className='mange-weddtab'>
              <Link to={'/wedd-honeymoonpackages'} >Mar'x Honeymoon Packages </Link>
            </p>
            <p className='mange-weddtab'>
              <Link to={'/wedd-preweddingpackages'} >Mar'x Pre - Wedding Packages</Link>
            </p> <p className='mange-weddtab'>
              <Link to={'/wedd-destinationweddingpackages'} >Mar'x Destination Wedding Packages</Link>
            </p>
            <p className='mange-weddtab'>
              <Link to={'/wedd-stagesetupdesigns'} >Mar'x Stage and Set-Up Designs </Link>
            </p>
            <p className='mange-weddtab'>
              <Link to={'/wedd-soundsetupdesigns'} >Mar'x Sound Set-up Designs</Link>
            </p>
            <p className='mange-weddtab'>
              <Link to={'/wedd-lightsetupdesigns'} >Mar'x Lights Set-up Designs </Link>
            </p>
            <p className='mange-weddtab'>
              <Link to={'/wedd-graphicsetupdesigns'} >Mar'x Graphics Set-up Designs </Link>
            </p>
            <p className='mange-weddtab'>
              <Link to={'/wedd-floralsetupdesigns'} >Mar'x Floral Set-up Designs</Link>
            </p>
            <p className='mange-weddtab'>
              <Link to={'/wedd-arabicsetupdesigns'} >Mar'x Arabic Set-up Designs  </Link>
            </p>
          </div>
          <div className="weddtabpanels">
            <div>
              <div className="about-marx-wedd">
                <h1>About Us</h1>
                <p>The Name of the Company MAR'X has been Inspired from the World famous
                  German Philosopher, Political Economist, Historian, Political Theorist,
                  Sociologist, Communist, Revolutionary & Legendary Mr. Karl Mar'x , as he
                  ruled the entire world in 18th Century with his modified Ideas, Hard Work,
                  Determination & will Power to Achieve Goals in Life , which Inspires us to do
                  Something in Life as an Inspiratio.
                </p>
                <p>
                  It feel's Immense Proud & Pleasure to Announce Mar'x Luxury Weddings India
                  Pvt.Ltd.(The House of Royalty) as our 3rd Biggest Running Venture under the
                  Wing's of Mar'x Group of Companies.(M.g.c) on Pan – India / Worldwide Level,
                  Which caters there services into Big Fat Weddings in India & ww, as our Hard core
                  team of highly Professionals from the Industry will give you the best opinions &
                  suggestions with best market price to make your wedding Day into a Sparking
                  Memorable Day for your entire life, because our motive is not to earn money in one
                  day, but yes to earn Goodwill & Relations with our Valuable Clients for Future.
                </p>
              </div>

              <div className="about-marx-wedd">
                <h1>Who We Are</h1>
                <div>
                  <p>
                    MLWI Pvt.Ltd.(The House of Royalty) which is set - up with a team of 10 Highly
                    Professional Managers from the wedding Industry will also give you the Vision of
                    International Standards from Destination weddings / Theme weddings to
                    International Cuisines / Celebrity Guest Appearances to International Acts
                    Performances etc....! all under one Single Roof, As we will take our work
                    Responsibilities from the starting day of your Wedding Schedule till the end date as
                    to make assure that our clients have no worries in terms of any Preparations left for
                    their Son / Daughter Dream Wedding Day
                  </p>
                </div>
              </div>

              <div className="about-marx-wedd">
                <h1>What We Do</h1>
                <div>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </div>
      <div className='weddingbox'>
        <RunningSlide title="Photoshoot Gallery" images={PhotoShootGalleryData} />
        <RunningSlide title="ENTERTAINMENT GALLERY" images={EntertainmentGalleryData} />
        <RunningSlide title="CUISINE GALLERY" images={WeddFoodData} />
      </div>

      <WeddingFooter />
    </>
  )
}

export default MarxWedding
