import GalleryWitthBioAllMax from "../../../../common/GalleryWitthBioAllMax/GalleryWitthBioAllMax"
import MarxEventFooter from "../../../../common/Marx_Event/MarxEventFooter/MarxEventFooter"
import MarxEventHeader from "../../../../common/Marx_Event/MarxEventHeader/MarxEventHeader"
import InternModelMaleData from "../../../../data/marxEvent/ourModelbank/international/InternModelMaleData"
import '../../ouModelBank/india/IndModelMaleStyle.css'


const InternModelMale = () => {
  return (
    <>
      <MarxEventHeader />
      <GalleryWitthBioAllMax ModelData={InternModelMaleData} heading={"Photo Gallery of International Male model"} title={'GALLERY'} detailpg={'international-male-model-detail-pg'} />
      <MarxEventFooter />

    </>
  )
}

export default InternModelMale
 
 
 
 

