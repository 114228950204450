
import MarxMediaHeader from "../../../common/MarxMedia/MarxMediaHeader/MarxMediaHeader"
import MediaFooter from "../../../common/MarxMedia/MarxMediaFooter/MarxMediaFooter"
import GalleryPhotosWithName from "../../../common/GalleryWithName/GalleryWithName"
import GalleryWithNameData from "../../../data/GalleryWithNameData"

const MediaBranding = () => {
    return (
        <>
            <MarxMediaHeader />
            <GalleryPhotosWithName GalleryInfo={GalleryWithNameData} title={'Branding'}/>
            <MediaFooter />
        </>
    )
}

export default MediaBranding
