import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import FadeIn from "react-fade-in";
import Carousel, { Modal, ModalGateway } from "react-images";
import photos from "../data/Photos";
import '../styles/photoGalleryStyle.css'
import Header from '../common/MarxEntertainment/header/Header'
import Footer from "../common/MarxEntertainment/footer/Footer";

export default function PhotoGallery() {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };


  return (
    <>
    <Header/>
      <FadeIn>
        <div className="homeContainer">
          <h1 className="galleryTitle">Photo Gallery</h1>
          <Gallery className='photosimg' photos={photos} onClick={openLightbox} />
          <ModalGateway className='dddddd'>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox} className='sdsfghjfdasfgh'>
                <Carousel className='scdfghggg'
                  currentIndex={currentImage}
                  views={photos.map((photo) => ({
                    ...photo,
                    srcset: photo.srcSet,
                    caption: photo.title,
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </div>
      </FadeIn>
      <Footer/>
    </>

  );
}

