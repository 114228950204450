import { Link } from "react-router-dom"
import Footer from "../../../common/MarxEntertainment/footer/Footer"
import Header from "../../../common/MarxEntertainment/header/Header"
import '../../../styles/registrationFormStyle.css'
import Sidebar from "../../../common/SideAbleMenu/SideBar"

const RegistrationForm = () => {
    return (
        <>
        <Sidebar/>
            <Header />
            <div className="regist-form">
                <h1>Registration Forms</h1>
                <ul className="formss">
                    <li><Link>Artist & Celebrity Registration Form</Link></li>
                    <li><Link>Client Registration Form</Link></li>
                    <li><Link>Vendor Registration Form</Link></li>
                    <li><Link>Internship Registration Form</Link></li>
                    <li><Link>Internship Registration Form</Link></li>
                    <li><Link>Internship Registration Form</Link></li>
                    <li><Link>Internship Registration Form</Link></li>
                    <li><Link>Internship Registration Form</Link></li>
                    <li><Link>Internship Registration Form</Link></li>
                    <li><Link>Internship Registration Form</Link></li>
                    <li><Link>Internship Registration Form</Link></li>
                    <li><Link>Internship Registration Form</Link></li>
                    <li><Link>Internship Registration Form</Link></li>
                </ul>
            </div>
            <Footer />
        </>
    )
}

export default RegistrationForm
