import { Favorite, FavoriteBorder } from "@mui/icons-material";
import { Box, Button, Checkbox } from "@mui/material"
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import '../../styles/weddFilterSearchStyle.css'
import { IoMdArrowDropdown } from "react-icons/io";
import { useTranslation } from 'react-i18next';
import MarxWeddingHeader from "../../common/marxWedding/HeaderMarxWedding";
import WeddingFooter from "../../common/marxWedding/FooterMarxWedding";

const WeddingFilterSearch = () => {
    const [allCategory, setAllCategory] = useState([])
    const [readZoneData, setReadZoneData] = useState([])
    const [price, setPrice] = useState([])
    const [index, setIndex] = useState([])
    const [country, setCountry] = useState([])
    const [grade, setGrade] = useState([])
    const [influencer, setInfluencer] = useState([])
    const { t } = useTranslation();

    useEffect(() => {
        const getAllCategoryData = async () => {
            const reqAllCatgData = await axios.get("https://onlineparttimejobs.in/api/category/public", {
                withCredentials: true
            });
            console.log(reqAllCatgData.data)
            setAllCategory(reqAllCatgData.data)
        }
        getAllCategoryData();
    }, []);

    const GetZoneData = async () => {
        try {
            const redZoneData = await axios.get('https://onlineparttimejobs.in/api/division/public', { withCredentials: true })
            console.log(redZoneData.data.name);
            setReadZoneData(redZoneData.data);
            console.log(redZoneData.data);
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        GetZoneData();
    }, []);

    const GetPriceData = async () => {
        try {
            const redPriceData = await axios.get('https://onlineparttimejobs.in/api/influence_pricerange/public', { withCredentials: true })
            console.log(redPriceData.data.name);
            setPrice(redPriceData.data);
            console.log(redPriceData.data);
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        GetPriceData();
    }, []);

    useEffect(() => {
        const getIndexData = async () => {
            const reqIndexData = await axios.get("https://onlineparttimejobs.in/api/influenceIndexGroup/public", {
                withCredentials: true
            });
            console.log(reqIndexData.data)
            setIndex(reqIndexData.data)
        }
        getIndexData();
    }, []);

    useEffect(() => {
        const getCountryData = async () => {
            const reqCountryData = await axios.get("https://onlineparttimejobs.in/api/country", {
                withCredentials: true
            });
            console.log(reqCountryData.data)
            setCountry(reqCountryData.data)
        }
        getCountryData();
    }, []);

    useEffect(() => {
        const getGradeData = async () => {
            const reqGradeData = await axios.get("https://onlineparttimejobs.in/api/influence_grademaster/public", {
                withCredentials: true
            });
            console.log(reqGradeData.data)
            setGrade(reqGradeData.data)
        }
        getGradeData();
    }, []);


    useEffect(() => {
        const getInfluencerData = async () => {
            const reqAllInfluencerData = await axios.get("https://onlineparttimejobs.in/api/sellerList/public", {
                withCredentials: true
            });
            console.log("influencer data", reqAllInfluencerData.data)
            setInfluencer(reqAllInfluencerData.data)
        }
        getInfluencerData();
    }, []);

    const toggleCategories = () => {
        var categoryList = document.getElementById('categoryList');
        categoryList.style.display = (categoryList.style.display === 'none') ? 'block' : 'none';
    }
    const toggleZone = () => {
        var zoneList = document.getElementById('zoneList');
        zoneList.style.display = (zoneList.style.display === 'none') ? 'block' : 'none';
    }
    const togglePrice = () => {
        var priceList = document.getElementById('priceList');
        priceList.style.display = (priceList.style.display === 'none') ? 'block' : 'none';
    }
    const toggleGender = () => {
        var genderList = document.getElementById('genderList');
        genderList.style.display = (genderList.style.display === 'none') ? 'block' : 'none';
    }

    const toggleIndex = () => {
        var indexList = document.getElementById('indexList');
        indexList.style.display = (indexList.style.display === 'none') ? 'block' : 'none';
    }
    const toggleCountry = () => {
        var countryList = document.getElementById('countryList');
        countryList.style.display = (countryList.style.display === 'none') ? 'block' : 'none';
    }
    const toggleGrade = () => {
        var gradeList = document.getElementById('gradeList');
        gradeList.style.display = (gradeList.style.display === 'none') ? 'block' : 'none';
    }



    const [showCount, setShowCount] = useState(12);
    const [showAll, setShowAll] = useState(false);

    const toggleImages = () => {
        if (!showAll) {
            const newShowCount = Math.min(showCount + 12, influencer.length);
            setShowCount(newShowCount);
            if (newShowCount === influencer.length) {
                setShowAll(true);
            }
        } else {
            setShowCount(showCount === 12 ? 3 : 12);
            setShowAll(false);
        }
    };

    return (
        <>
            <MarxWeddingHeader />
         
            <Box>
                <div className="wedd-celebrity-filtration-div">
                    <div className="wedd-leftsidediv col-sm-3">
                        <div className="wedd-filterdiv">
                            <h5>{t('ourInfluencer_filter')}</h5>
                            <img src="https://www.influglue.com/public/frontend/images/filtericon.png" alt="img" />
                        </div>

                        <div className="wedd-city-div">
                            <div onClick={toggleCategories} className="wedd-clickdiv">
                                <h6>{t('ourInfluencer_category')}</h6>
                                <IoMdArrowDropdown />
                            </div>
                            <ul id="categoryList" class="wedd-category-list">
                                {allCategory?.map((items) => (
                                    <li key={items}>{items.name}</li>
                                ))}
                            </ul>
                        </div>

                        <div className="wedd-city-div">
                            <div onClick={toggleZone} className="wedd-clickdiv">
                                <h6>{t('ourInfluencer_zone')}</h6>
                                <IoMdArrowDropdown />
                            </div>
                            <ul id="zoneList" class="wedd-zone-list">
                                {readZoneData?.map((div) => (
                                    <li key={div}>{div.title}</li>
                                ))}
                            </ul>
                        </div>


                        <div className="wedd-city-div">
                            <div onClick={togglePrice} className="wedd-clickdiv">
                                <h6>{t('ourInfluencer_price')}</h6>
                                <IoMdArrowDropdown />
                            </div>
                            <ul id="priceList" class="wedd-price-list">
                                {price?.map((itm) => (
                                    <li key={itm}>{itm.name}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="wedd-city-div">
                            <div className="wedd-clickdiv">
                                <h6>{t('ourInfluencer_byName')}</h6>
                                <IoMdArrowDropdown />
                            </div>
                        </div>
                        <div className="wedd-city-div">
                            <div onClick={toggleGender} className="wedd-clickdiv">
                                <h6>{t('ourInfluencer_byGender')}</h6>
                                <IoMdArrowDropdown />
                            </div>
                            <ul id="genderList" class="wedd-gender-list">
                                <li>Male</li>
                                <li>Female</li>
                            </ul>
                        </div>
                        <div className="wedd-city-div">
                            <div onClick={toggleIndex} className="wedd-clickdiv">
                                <h6>{t('ourInfluencer_industry')} </h6>
                                <IoMdArrowDropdown />
                            </div>
                            <ul id="indexList" class="wedd-index-list">
                                {index?.map((itm) => (
                                    <li key={itm}>{itm.name}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="wedd-city-div">
                            <div onClick={toggleCountry} className="wedd-clickdiv">
                                <h6>{t('ourInfluencer_country')}</h6>
                                <IoMdArrowDropdown />
                            </div>
                            <ul id="countryList" class="wedd-country-list">
                                {country?.map((itm) => (
                                    <li key={itm}>{itm.name}</li>
                                ))}
                            </ul>
                        </div>
                        <div className="wedd-city-div">
                            <div onClick={toggleGrade} className="wedd-clickdiv">
                                <h6>{t('ourInfluencer_byGrade')}</h6>
                                <IoMdArrowDropdown />
                            </div>
                            <ul id="gradeList" class="wedd-grade-list">
                                {grade?.map((itm) => (
                                    <li className=" fw-bold fs-6" key={itm}>{itm.name}</li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className="wedd-rightsidediv col-sm-9">
                        <div className=" d-flex flex-wrap justify-content-center">
                            {influencer && influencer?.slice(0, showCount).map((item) => (
                                <div key={item._id} className="wedd-card-box">
                                    <Link to={`/influencer-detail/${item._id}/${item.firstname}`}>
                                        <div className='wedd-card-img-div1'>
                                            <img src={'https://www.influglue.com/public/frontend/images/big_social_icon/instagram.png?v=1688734414'} alt="img" />
                                            <p><Checkbox icon={<FavoriteBorder className="fs-2" />} checkedIcon={<Favorite className="fs-2" />} /></p>
                                        </div>
                                    </Link>
                                    <div className="wedd-card-content-div">
                                        <h6>{item.firstname} {item.lastname}</h6>
                                        <p>Delhi</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="weddbtn-div-bottom">
                            <Button className="" variant="contained" onClick={toggleImages}>
                                {showAll ? "Show Less" : "Show More"}
                            </Button>
                        </div>
                    </div>
                </div>
            </Box>

            <WeddingFooter />
        </>
    )
}

export default WeddingFilterSearch
