import IndModelMaleData from "../../../data/marxEvent/ourModelbank/indian/indianModelMaleData"
import WeddingFooter from "../../../common/marxWedding/FooterMarxWedding"
import MarxWeddingHeader from "../../../common/marxWedding/HeaderMarxWedding"
import WithBioWithoutRunningGallery from "../../../common/GalleryWitthBioAllMaxWithoutRunningGallery/WithBioWithoutRunningGallery"

const WeddingPreWeddingPackages = () => {
  return (
    <>
        <MarxWeddingHeader />
        <WithBioWithoutRunningGallery ModelData={IndModelMaleData}  heading={"Mar'x Pre-Wedding Packages"} detailpg={'wedd-preweddingpackages'}/>
        <WeddingFooter />

    </>
  )
}

export default WeddingPreWeddingPackages
