import Sidebar from "../../common/SideAbleMenu/SideBar"
import MarxGroupFooter from "../../common/marxGroup/MarxGroupFooter"
import MarxGroupHeader from "../../common/marxGroup/MarxGroupHeader"
import '../../styles/termConditionStyle.css'
const TermAndCondition = () => {
  return (
    <>
       <Sidebar />
      <MarxGroupHeader />
      <div className="term-text">
        <div className="div-term">
          <h2>Terms & Conditions</h2>
        </div>
        <div className="textt">
          <p>Welcome to Abaris (hereinafter referred to as the “website” or “site” or “we” or “us”). The website is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein (the "Terms"). Your use of our website constitutes your agreement to all such Terms. </p>
          <p>Our website is owned and operated by Softz Solutions & Co Pvt Ltd (name of the Company) with its office located at 796 Purbachal Main Road, Kolkata 700078 West Bengal India. (Office Address). </p>
          <p>By using the Site, you agree to comply with and be legally bound by the terms and conditions of these Terms of Service ("Terms"). These Terms govern your access to and use of the Site and Services and all Collective Content, and constitute a binding legal agreement between you and us. </p>
          <p>Please read carefully these Terms and our Privacy Policy which is incorporated by reference into these Terms. If you do not agree to these Terms, you have no right to obtain information from or otherwise continue using the Site. Failure to use the Site in accordance with these Terms may subject you to civil and criminal penalties.</p>
        </div>
        <div className="textt">
          <h4>Definitions And Interpretation</h4>
          <p>"Agreement" means the terms and conditions as detailed herein including all Exhibits, privacy policy, other policies mentioned on the website and will include the references to this agreement as amended, negated, supplemented, varied or replaced from time to time.</p>
          <p>Abaris means the online platform which provides various paid Campaign services listed by various Brands and influencers promotes those Campaigns.</p>
          <p>MLWI Pvt.Ltd. Agency will Charge 25%
            Commission + GST 18% on Total Billing as Service Fees, Min.
            Booking’s Price in India for any kind of Pre – Wedding Events
            (Chowki, Jagran, Sai Sandhya, Guru Ji Kirtan, Ladies Sangeet, Roka
            Ceremony, Sagan Ceremony, Mehndi Night) etc....! Starts from
            INR.5,Lakhs & Above with a Min. Gathering of at least 100 PPl.
            Which Includes Standard Type (Venue, Catering, Decor, Stage Set –
            up, Sound & Lights, DJ) Cost for Artists / Celebrities / Live Bands /
            Photography Excluded. Booking’s Price for Main Wedding Events /
            Reception / Cocktail Starts from INR.10,Lakhs & Above with a
            Min. Gathering of at least 200 PPl. Which Includes Standard Type
            (Venue, Catering, Decor, Stage Set – up, Sound & Lights, DJ) Cost for
            Artists / Celebrities / Live Bands / Photography Excluded. Wedding
            Booking’s for Premium & Luxury Packages Please Mail us for Price
            Quote with your Requirements. Min. Booking’s Cost for Destination
            Weddings Standard Type Starts from U.S $ 24,000/- & Above + GST
            18%. Enquires Related to Countries / States Located in Asia /
            Europe / USA / UAE / Etc
          </p>
        </div>
      </div>
      <MarxGroupFooter />
    </>
  )
}

export default TermAndCondition
