import { Box, Button } from "@mui/material"
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom"
import logo from '../../assest/images/marxWeddinglogo.jpeg'
import './headerMarxWedStyle.css'
import { GoHomeFill } from "react-icons/go";


const MarxWeddingHeader = () => {
    const { t } = useTranslation();
  return (
    <>
         <Box className=''>
         <div className="wedd-topdiv-bar">
          <div className="wedd-marxghome">
            <Link to='/marx-group' className=""> <Button size="small" className=" bg-white text-black p-1"><GoHomeFill className=' fs-5' /></Button></Link>
          </div>
        </div>
        <div className="wedd-navbar">
          <div className="wedd-nav-baar">
            <div className="logo">
              <Link to={'/marx-wedding'}>
                <img
                  className="weddinglogo"
                  src={logo}
                  alt="logo"
                />
              </Link>
            </div>

            <div className="weddingprofilename">
              <h1><span className="span1">MAR’X</span> <span className="span2">LUXURY </span> <span className="span3"> WEDDING INDIA </span> <span className="span4">PVT. LTD.</span></h1>
              <div className="stylistp">
                  <p>(The House of Royalty)</p>
                </div>
            </div>
          </div>
        </div>


      </Box>

    </>
  )
}

export default MarxWeddingHeader
