import weddDecorPhotography1 from '../../assest/images/weddDecorGallery/weddDecorPhotography1.jpeg'
import weddDecorPhotography2 from '../../assest/images/weddDecorGallery/weddDecorPhotography2.jpeg'
import weddDecorPhotography3 from '../../assest/images/weddDecorGallery/weddDecorPhotography3.jpeg'
import weddDecorPhotography4 from '../../assest/images/weddDecorGallery/weddDecorPhotography4.jpeg'
import weddDecorPhotography5 from '../../assest/images/weddDecorGallery/weddDecorPhotography5.jpeg'
import weddDecorPhotography6 from '../../assest/images/weddDecorGallery/weddDecorPhotography6.jpeg'
import weddDecorPhotography7 from '../../assest/images/weddDecorGallery/weddDecorPhotography7.jpeg'
import weddDecorPhotography8 from '../../assest/images/weddDecorGallery/weddDecorPhotography8.jpeg'
import weddDecorPhotography9 from '../../assest/images/weddDecorGallery/weddDecorPhotography9.jpeg'
import weddDecorPhotography10 from '../../assest/images/weddDecorGallery/weddDecorPhotography10.jpeg'
import weddDecorPhotography11 from '../../assest/images/weddDecorGallery/weddDecorPhotography11.jpeg'
import weddDecorPhotography12 from '../../assest/images/weddDecorGallery/weddDecorPhotography12.jpeg'
import weddDecorPhotography13 from '../../assest/images/weddDecorGallery/weddDecorPhotography13.jpeg'
import weddDecorPhotography14 from '../../assest/images/weddDecorGallery/weddDecorPhotography14.jpeg'
import weddDecorPhotography15 from '../../assest/images/weddDecorGallery/weddDecorPhotography15.jpeg'
import weddDecorPhotography16 from '../../assest/images/weddDecorGallery/weddDecorPhotography16.jpeg'
import weddDecorPhotography17 from '../../assest/images/weddDecorGallery/weddDecorPhotography17.jpeg'
import weddDecorPhotography18 from '../../assest/images/weddDecorGallery/weddDecorPhotography18.jpeg'
import weddDecorPhotography19 from '../../assest/images/weddDecorGallery/weddDecorPhotography19.jpeg'
import weddDecorPhotography20 from '../../assest/images/weddDecorGallery/weddDecorPhotography20.jpeg'
import weddDecorPhotography21 from '../../assest/images/weddDecorGallery/weddDecorPhotography21.jpeg'
import weddDecorPhotography22 from '../../assest/images/weddDecorGallery/weddDecorPhotography22.jpeg'
import weddDecorPhotography23 from '../../assest/images/weddDecorGallery/weddDecorPhotography23.jpeg'
import weddDecorPhotography24 from '../../assest/images/weddDecorGallery/weddDecorPhotography24.jpeg'
import weddDecorPhotography25 from '../../assest/images/weddDecorGallery/weddDecorPhotography25.jpeg'
import weddDecorPhotography26 from '../../assest/images/weddDecorGallery/weddDecorPhotography26.jpeg'
import weddDecorPhotography27 from '../../assest/images/weddDecorGallery/weddDecorPhotography27.jpeg'
import weddDecorPhotography28 from '../../assest/images/weddDecorGallery/weddDecorPhotography28.jpeg'
import weddDecorPhotography29 from '../../assest/images/weddDecorGallery/weddDecorPhotography29.jpeg'
import weddDecorPhotography30 from '../../assest/images/weddDecorGallery/weddDecorPhotography30.jpeg'
import weddDecorPhotography31 from '../../assest/images/weddDecorGallery/weddDecorPhotography31.jpeg'
import weddDecorPhotography32 from '../../assest/images/weddDecorGallery/weddDecorPhotography32.jpeg'



const TopMarxWeddSlideData = [
    {
        id: 1,
        imgSrc: weddDecorPhotography1,
    },
    {
        id: 2,
        imgSrc: weddDecorPhotography2,
    },
    {
        id: 3,
        imgSrc: weddDecorPhotography3,
    }, {
        id: 4,
        imgSrc: weddDecorPhotography4,
    }, {
        id: 5,
        imgSrc: weddDecorPhotography5,
    }, {
        id: 6,
        imgSrc: weddDecorPhotography6,
    }, {
        id: 7,
        imgSrc: weddDecorPhotography7,
    }, {
        id: 8,
        imgSrc: weddDecorPhotography8,
    }, {
        id: 9,
        imgSrc: weddDecorPhotography9,
    }, {
        id: 10,
        imgSrc: weddDecorPhotography10,
    }, {
        id: 11,
        imgSrc: weddDecorPhotography11,
    }, {
        id: 12,
        imgSrc: weddDecorPhotography12,
    }, {
        id: 13,
        imgSrc: weddDecorPhotography13,
    }, {
        id: 14,
        imgSrc: weddDecorPhotography14,
    }, {
        id: 15,
        imgSrc: weddDecorPhotography15,
    }, {
        id: 16,
        imgSrc: weddDecorPhotography16,
    }, {
        id: 17,
        imgSrc: weddDecorPhotography17,
    }, {
        id: 18,
        imgSrc: weddDecorPhotography18,
    }, {
        id: 19,
        imgSrc: weddDecorPhotography19,
    }
    , {
        id: 20,
        imgSrc: weddDecorPhotography20,
    }, {
        id: 21,
        imgSrc: weddDecorPhotography21,
    }, {
        id: 22,
        imgSrc: weddDecorPhotography22,
    }, {
        id: 23,
        imgSrc: weddDecorPhotography23,
    }, {
        id: 24,
        imgSrc: weddDecorPhotography24,
    }, {
        id: 25,
        imgSrc: weddDecorPhotography25,
    }, {
        id: 26,
        imgSrc: weddDecorPhotography26,
    }, {
        id: 27,
        imgSrc: weddDecorPhotography27,
    }, {
        id: 28,
        imgSrc: weddDecorPhotography28,
    }, {
        id: 29,
        imgSrc: weddDecorPhotography29,
    }, {
        id: 30,
        imgSrc: weddDecorPhotography30,
    }, {
        id: 31,
        imgSrc: weddDecorPhotography31,
    }, {
        id: 32,
        imgSrc: weddDecorPhotography32,
    }
];
export default TopMarxWeddSlideData;