
import { Box, Button, IconButton, InputAdornment, TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useNavigate } from 'react-router-dom';
import './marxGroupSignUpStyle.css'
import { useState } from 'react';
import axios from 'axios';

const MarxGroupSignUp = () => {
    const [SignUpDataa, SetSignUpDataa] = useState({
        firstname: '',
        lastname: '',
        email: '',
        mobile: '',
        password: '',
        confirmpassword: '',
    })
    const [errorFirstname, setErrorFirstname] = useState("");
    const [errorLastname, setErrorLastname] = useState("");
    const [errorEmail, setErrorEmail] = useState("");
    const [errorMobile, setErrorMobile] = useState("");
    const [errorPassword, setErrorPassword] = useState("");
    const [errorConfrimPassword, setErrorConfirmPassword] = useState("");
    const [bordrColor, setBordrColor] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const navigate = useNavigate()


    const onRegistrClick = async (e) => {
        e.preventDefault();

        try {
            // Reset errors and color
            setErrorFirstname("");
            setErrorLastname("");
            setErrorEmail("");
            setErrorMobile("");
            setErrorPassword("");
            setErrorConfirmPassword("");
            setBordrColor("");

            // Perform input validation here before making the API call
            if (
                SignUpDataa.firstname.length > 2 &&
                SignUpDataa.lastname.length > 1 &&
                SignUpDataa.email.includes("@") &&
                SignUpDataa.mobile.length === 10 &&
                SignUpDataa.password.length > 7 &&
                SignUpDataa.password === SignUpDataa.confirmpassword
            ) {
                setBordrColor('1px solid green');
                const sign_up = await axios.post(
                    'https://onlineparttimejobs.in/api/sellerList/add_Sellers',
                    { ...SignUpDataa }
                );
                SetSignUpDataa(sign_up.data);
                console.log(sign_up.data);
                // Provide user feedback or navigation here if needed
                navigate('/marx-group-login')
                console.log('Form data submitted successfully!');
            } else {
                if (SignUpDataa.firstname.length <= 2 || SignUpDataa.firstname === '') setErrorFirstname("enter first name");
                if (SignUpDataa.lastname.length <= 1 || SignUpDataa.lastname === '') setErrorLastname("enter last name");
                if (!SignUpDataa.email.includes("@") || SignUpDataa.email === '') setErrorEmail("enter valid email");
                if (SignUpDataa.mobile.length !== 10 || SignUpDataa.mobile === '') setErrorMobile("enter valid 10 digit number");
                if (SignUpDataa.password.length <= 8 || SignUpDataa.password === '') setErrorPassword("Password should be 8 digit long");
                if (SignUpDataa.password !== SignUpDataa.confirmpassword || SignUpDataa.confirmpassword === '') setErrorConfirmPassword("Password not matched")
                setErrorConfirmPassword("Passwords didn't match.");
                setBordrColor('1px solid red');
                // Provide user feedback for validation errors
                console.log('Form validation failed');
            }
        } catch (error) {
            // Provide user-friendly error message
            console.log('Error submitting form:', error.message);
        }
    };

    const onLoginClick = () => {
        navigate('/marx-group-login')
        
    }

    const onInputChnge = (e) => {
        const inpName = e.target.name;
        const inpVal = e.target.value;
        const cloned = { ...SignUpDataa }
        cloned[inpName] = inpVal;
        SetSignUpDataa(cloned);
    }
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const onCloseBtn = () => {
        navigate('/');
      
      };
    return (
        <>

            <Box className='bgimgstar' >
                <Box className='modelbox'>
                    <CloseIcon onClick={onCloseBtn} className='closeiconbtn' />

                    <Box className='loginbox'>
                        <h5 className=" text-center mb-2 fw-bold">Register</h5>
                        <div className="namedivv">
                            <TextField
                                name='firstname'
                                onChange={onInputChnge}
                                value={SignUpDataa.firstname}
                                style={{ border: bordrColor, borderRadius: '4px' }}
                                size="small" placeholder="First Name"
                            />
                            <TextField
                                name='lastname'
                                onChange={onInputChnge}
                                value={SignUpDataa.lastname}
                                style={{ border: bordrColor, borderRadius: '4px', marginLeft: '3px' }}
                                size="small" placeholder="Last Name"
                            />
                        </div>
                        <p className="error">{errorFirstname} {errorLastname}</p>
                        <div>
                            <TextField
                                name='email'
                                onChange={onInputChnge}
                                value={SignUpDataa.email}
                                style={{ border: bordrColor, borderRadius: '4px' }}
                                size="small" placeholder="Email" fullWidth
                            />
                            <p className="error">{errorEmail}</p>
                        </div>
                        <div className="mt-3">
                            <TextField
                                name='mobile'
                                onChange={onInputChnge}
                                value={SignUpDataa.mobile}
                                style={{ border: bordrColor, borderRadius: '4px' }}
                                size="small" placeholder="Mobile No" fullWidth
                            />
                            <p className="error">{errorMobile}</p>
                        </div>
                        <div className="mt-3">
                            <TextField
                                name='password'
                                className='passdinput'
                                onChange={onInputChnge}
                                value={SignUpDataa.password}
                                style={{ border: bordrColor, borderRadius: '4px' }}
                                size="small" placeholder="Create Password" fullWidth
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={togglePasswordVisibility}>
                                                {showPassword ? <VisibilityIcon className=' text-white'/> : <VisibilityOffIcon className=' text-white'/>}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <p className="error">{errorPassword}</p>
                        </div>
                        <div className="mt-3">
                            <TextField
                                type={showPassword ? 'text' : 'password'}
                                name='confirmpassword'
                                onChange={onInputChnge}
                                value={SignUpDataa.confirmpassword}
                                style={{ border: bordrColor, borderRadius: '4px' }}
                                size="small" placeholder="Re-enter Password" fullWidth
                            />
                            <p className="error m-0">{errorConfrimPassword}</p>
                        </div>

                        

                    </Box>

                    <div className='btnnbox'>
                        <Button onClick={onRegistrClick} size='small'>Sign UP</Button>
                    </div>
                    <div className='btnnbox'>
                        <p>OR</p>
                    </div>
                    <div className='btnnbox'>
                        <Button className='mx-5' onClick={onLoginClick} size='small' fullWidth>Login</Button>
                    </div>
                </Box>

            </Box>


        </>
    )
}

export default MarxGroupSignUp;
