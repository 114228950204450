import { Box, Button, Modal } from "@mui/material"
import './galleryWitthBioDetailpgAllMaxStyle.css'
import { useParams } from "react-router-dom"
// import IndModelMaleData from "../../data/marxEvent/ourModelbank/indian/indianModelMaleData"
import { useState } from "react"
import { LiaWindowCloseSolid } from "react-icons/lia";
import ImageOpenModel from "./ImageOpenModel";


const GalleryWitthBioDetailpgAllMax = ({allData}) => {
    // let { id } = useParams();
    // console.log(id)
    // const singleData = IndModelMaleData.find((item) => item.id == id)
    // console.log(singleData)
    // const { profileName, src, age, gender, height, workExperience } = singleData;
    // console.log(profileName, src)
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { profileName, src, age, gender, height, workExperience } = allData;
    console.log(profileName, src)

    const handleOpen = () => {
        setIsModalOpen(true);
    };

    const handleClose = () => {
        setIsModalOpen(false);
    };
    return (
        <>

            <div className="gallery-detail-main">
                <div className=" col-sm-4 gallery-rightdiv">
                    <div className="gallery-imgdivbox">
                        <img src={src} onClick={handleOpen} alt="img" />
                        <ImageOpenModel src={src} handleClose={handleClose} isModalOpen={isModalOpen}/>
                        <div className="btn">
                            <Button>Add to cart</Button>
                        </div>
                    </div>
                </div>

                <div className=" col-sm-8">
                    <div className="gallery-modelbio-div">
                        <h2>Bio</h2>
                        <div className="gallery-fdiv">
                            <div className="gallery-detail-box">
                                <ul>
                                    <li>
                                        <strong>Name</strong>
                                        <small>:</small>
                                        <span>{profileName}</span>
                                    </li>
                                    <li>
                                        <strong>Age</strong>
                                        <small>:</small>
                                        <span>{age}</span>
                                    </li>
                                    <li>
                                        <strong>Gender</strong>
                                        <small>:</small>
                                        <span>{gender}</span>
                                    </li>

                                    <li>
                                        <strong>Height</strong>
                                        <small>:</small>
                                        <span>{height}</span>
                                    </li>
                                    <li>
                                        <strong>Vidalstate</strong>
                                        <small>:</small>
                                        <span></span>
                                    </li>
                                </ul>
                            </div>
                            <div className="Workdiv-gallerywork">
                                <strong>Work Experience</strong>
                                <small>:</small>
                                <p>{workExperience}</p>
                            </div>
                            <div className="galllery-buttondiv">
                                <Button>Check Availability & Price</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal */}
            {/* <Modal
                open={isModalOpen}
                onClose={handleClose}
                className="modal"
            >
                <Box className="modal-content">
                    <LiaWindowCloseSolid className="close" onClick={handleClose} />
                    <img src={src} alt="big-img" />
                </Box>
            </Modal> */}


        </>
    )
}

export default GalleryWitthBioDetailpgAllMax
