
import WithBioWithoutRunningGallery from "../../../common/GalleryWitthBioAllMaxWithoutRunningGallery/WithBioWithoutRunningGallery"
import MarxEventFooter from "../../../common/Marx_Event/MarxEventFooter/MarxEventFooter"
import MarxEventHeader from "../../../common/Marx_Event/MarxEventHeader/MarxEventHeader"
import IndModelMaleData from "../../../data/marxEvent/ourModelbank/indian/indianModelMaleData"

const MarxMakeupArtist = () => {
  return (
    <>
        <MarxEventHeader />
        <WithBioWithoutRunningGallery ModelData={IndModelMaleData}  heading={"Mar'x Make-up Artist"} detailpg={'marxmakeup-artist'}/>
        <MarxEventFooter />

    </>
  )
}

export default MarxMakeupArtist
