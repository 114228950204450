import Footer from "../../common/MarxEntertainment/footer/Footer"
import Header from "../../common/MarxEntertainment/header/Header"
import HomeServiceBlock from "../../components/MarxEntertainment/homeServiceBlock/HomeServiceBlock"
import '../../styles/serviceStyle.css'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import br1 from '../../assest/images/cleb1.jpg'
import br2 from '../../assest/images/cleb2.jpg'
import br3 from '../../assest/images/abarisInfluencebr1.jpg'
import TopSliderAllMax from "../../common/TopSliderAllMarx/TopSliderAllMarx";
import TopMarxEntertainmentData from "../../data/MarxEntertainment/MarxCelebsGalleryData";


const Services = () => {
  return (
    <>
    
      <Header />
        {/* <div>
          <Swiper
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}

            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Pagination, Autoplay]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="item">
                <img
                  className="img-itm"
                  src={br1}
                  alt="imgimf"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>

              <div className="item">
                <img
                  className="img-itm"
                  src={br2}
                  alt="imgimf"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <img
                  className="img-itm"
                  src={br3}
                  alt="imgimf"
                />
              </div>
            </SwiperSlide>
          </Swiper>

        </div> */}
        <TopSliderAllMax title={"Celeb's Gallery"} images={TopMarxEntertainmentData}/>
      <div className="marx-entt-box">
        <HomeServiceBlock />
        <div className="our-services">
          <p>Mar'x Ent.ww. which was started with a small Artist Bank of  80 - 90 Artist in its kitty, now Manages Directly the largest Artists / Celebrity Bank in the entire History of Entertainment Industry, As we are looking after the Pipelines for  <b>Hollywood Talent, Bollywood Talent, Tollywood Talent, Pollywood Talent, Kollywood Talent, Marathi Talent, Gujarati Talent, Odiya Talent, Playback Singer's / Performer, Sports Personalities of India / Asia, Indian & International Rock - Bands, Indian & International D,J's, Show Host / Emcee / Anchors, Stand - up Comedians, Classical Dance Performers, Key Note Motivational Speakers,  etc..</b> which has been divided into more than <b>110 Plus Categories</b> of Artists under one Roof. Mar'x Ent.ww. is a team of hard Core working Professionals who believes in converting a small vision into a large Canvas where imagination has no limit.    </p>
          <p>It's been a Pleasure to inform you that we have Started Expanding our Operation Wing's in another <strong> 80 Countries</strong> all over Worldwide with the Grand Launch of our Company Mobile App. for online Celebrities Bookings Coming Soon in the following Countries  Australia, Russia, Brazil, Canada, North / South America, United Kingdom, France, Japan, Italy, Turkey, Dubai, Singapore, Malaysia, Thailand, Sri lanka, South Africa, Germany, UAE, Netherlands, New Zealand, Portugal, to name it a few. </p>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Services
