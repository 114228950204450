import { Link } from "react-router-dom"
import Sidebar from "../../../common/SideAbleMenu/SideBar"
import WeddingFooter from "../../../common/marxWedding/FooterMarxWedding"
import MarxWeddingHeader from "../../../common/marxWedding/HeaderMarxWedding"
import '../../../styles/destinationWeddingStyle.css'
import DestinationWeddCityData from "../../../data/MarxWedding/DestinationWeddData/DestinationWeddCityData"


const UAE = () => {
    return (
        <>
            <Sidebar />
            <MarxWeddingHeader />
            <div className="destinayion-weddshow-gallery">
                <h1>Destination Wedding Pics</h1>
                <div className="weddimagealbum">
                    {DestinationWeddCityData.map((url, index) => (
                        <Link className="text-decoration-none imgbox" to={`${url.name}-pics`} key={url.id}>
                            <img src={url.imgSrc} alt={`img${url.id}`} />
                            <p className=" fw-bold text-black text-uppercase">{url.name}</p>
                        </Link>
                    ))}
                </div>
            </div>
            <WeddingFooter />
        </>
    )
}

export default UAE
