
import { Link } from "react-router-dom"
import '../../../styles/weddRegistrationFormStyle.css'
import MarxWeddingHeader from "../../../common/marxWedding/HeaderMarxWedding"
import WeddingFooter from "../../../common/marxWedding/FooterMarxWedding"

const WeddingHotelAndVenueIndia = () => {
    return (
        <>
            <MarxWeddingHeader />
            <div className="wedd-registform">
                <h1>Hotel & Venue India</h1>
                <ul className="wedd-formss">
                    <li><Link to={'banquethall'}>Banquet Hall</Link></li>
                    <li><Link to={'resorts'}>Resorts</Link></li>
                    <li><Link to={'3-star-property'}>3 Star Property</Link></li>
                    <li><Link to={'5-star-property'}>5 Star Property</Link></li>
                    <li><Link to={'fort-places'}>Fort / Places</Link></li>
                    <li><Link to={'farmhouse'}>FarmHouse</Link></li>
                </ul>
            </div>
            <WeddingFooter />
        </>
    )
}

export default WeddingHotelAndVenueIndia
