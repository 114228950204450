import React, { useEffect } from "react";
import "./marxEventHeaderStyle.css";
import { useTranslation } from 'react-i18next';
import { Box, Button } from "@mui/material";
import { GoHomeFill } from "react-icons/go";
import marxEventLogo from '../../../assest/images/marxeventlogo.jpeg'
import marxeventaudiomp3 from '../../../assest/mp3/marxevent.mpeg'
import ReactAudioPlayer from "react-audio-player";
import { FiShoppingCart } from "react-icons/fi";
import { Link } from "react-router-dom";


const MarxEventHeader = () => {
    const { t } = useTranslation();
    return (
        <>
            <Box className=''>
                <div className="eventtop-div-bar">
                    <div className="eventhomebtnn-box">
                        <Link to='/marx-group' className=""> <Button size="small" className=" bg-white text-black p-1"><GoHomeFill className=' fs-5' /></Button></Link>
                    </div>

                    <div className="event-head">
                        <Box className='event-cartboxdiv'>  {/* onClick={onOpen} */}
                            <div className="event-cartbox">
                                <FiShoppingCart className="event-cart-trolley" />
                                <p className="event-cartnumber">10</p>   {/* {cartItems || 0} */}
                            </div>
                        </Box>

                        <div className="d-flex">
                            <ReactAudioPlayer
                                src={marxeventaudiomp3}
                                autoPlay
                                loop
                                controls
                                className="event-audioplayer"
                            />
                        </div>
                    </div>
                </div>

                <div className="">
                    <div className="eventnav">
                        <div className="eventnav-bar">
                            <div className="logo">
                                <Link to={'/marx-event-home'}>
                                    <img
                                        className="img-logo"
                                        src={marxEventLogo}
                                        alt="logo"
                                    />
                                </Link>
                            </div>
                            <div className="eventcompany-name">
                                <div>
                                    <h1><span className="span1">MAR’X</span> <span className="span2">EVENTS</span> <span className="span4">PVT. LTD.</span></h1>
                                </div>
                                <div className="stylist-p">
                                    <p>(The Fashion Consultants)</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </Box>
        </>
    )
}

export default MarxEventHeader
