import { useParams } from "react-router-dom"
import GalleryPhotos from "../../common/Gallery/Gallery"
import Sidebar from "../../common/SideAbleMenu/SideBar"
import WeddingFooter from "../../common/marxWedding/FooterMarxWedding"
import MarxWeddingHeader from "../../common/marxWedding/HeaderMarxWedding"
import SecondPicsData from "../../data/marxEvent/SecondPicsData"

const WeddingGallery = () => {
    return (
        <>
            <Sidebar />
            <MarxWeddingHeader />
            <GalleryPhotos GalleryData={SecondPicsData} title={'name'} />
            <WeddingFooter />
        </>
    )
}

export default WeddingGallery
