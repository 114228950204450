import internationalOurModelM1 from '../../../../assest/images/eventOurModelBank/international/Male/internationalOurModelM1.jpeg'
import internationalOurModelM2 from '../../../../assest/images/eventOurModelBank/international/Male/internationalOurModelM2.jpeg'
import internationalOurModelM3 from '../../../../assest/images/eventOurModelBank/international/Male/internationalOurModelM3.jpeg'
import internationalOurModelM4 from '../../../../assest/images/eventOurModelBank/international/Male/internationalOurModelM4.jpeg'
import internationalOurModelM5 from '../../../../assest/images/eventOurModelBank/international/Male/internationalOurModelM5.jpeg'
import internationalOurModelM6 from '../../../../assest/images/eventOurModelBank/international/Male/internationalOurModelM6.jpeg'
import internationalOurModelM7 from '../../../../assest/images/eventOurModelBank/international/Male/internationalOurModelM7.jpeg'
import internationalOurModelM8 from '../../../../assest/images/eventOurModelBank/international/Male/internationalOurModelM8.jpeg'
import internationalOurModelM9 from '../../../../assest/images/eventOurModelBank/international/Male/internationalOurModelM9.jpeg'
import internationalOurModelM10 from '../../../../assest/images/eventOurModelBank/international/Male/internationalOurModelM10.jpeg'
import internationalOurModelM11 from '../../../../assest/images/eventOurModelBank/international/Male/internationalOurModelM11.jpeg'
import internationalOurModelM12 from '../../../../assest/images/eventOurModelBank/international/Male/internationalOurModelM12.jpeg'
import internationalOurModelM13 from '../../../../assest/images/eventOurModelBank/international/Male/internationalOurModelM13.jpeg'
import internationalOurModelM14 from '../../../../assest/images/eventOurModelBank/international/Male/internationalOurModelM14.jpeg'
import internationalOurModelM15 from '../../../../assest/images/eventOurModelBank/international/Male/internationalOurModelM15.jpeg'
import internationalOurModelM16 from '../../../../assest/images/eventOurModelBank/international/Male/internationalOurModelM16.jpeg'
import internationalOurModelM17 from '../../../../assest/images/eventOurModelBank/international/Male/internationalOurModelM17.jpeg'
import internationalOurModelM18 from '../../../../assest/images/eventOurModelBank/international/Male/internationalOurModelM18.jpeg'
import internationalOurModelM19 from '../../../../assest/images/eventOurModelBank/international/Male/internationalOurModelM19.jpeg'
import internationalOurModelM20 from '../../../../assest/images/eventOurModelBank/international/Male/internationalOurModelM20.jpeg'
import internationalOurModelM21 from '../../../../assest/images/eventOurModelBank/international/Male/internationalOurModelM21.jpeg'
import internationalOurModelM22 from '../../../../assest/images/eventOurModelBank/international/Male/internationalOurModelM22.jpeg'
import internationalOurModelM23 from '../../../../assest/images/eventOurModelBank/international/Male/internationalOurModelM23.jpeg'
import internationalOurModelM24 from '../../../../assest/images/eventOurModelBank/international/Male/internationalOurModelM24.jpeg'
import internationalOurModelM25 from '../../../../assest/images/eventOurModelBank/international/Male/internationalOurModelM25.jpeg'
import internationalOurModelM26 from '../../../../assest/images/eventOurModelBank/international/Male/internationalOurModelM26.jpeg'
import internationalOurModelM27 from '../../../../assest/images/eventOurModelBank/international/Male/internationalOurModelM27.jpeg'




const InternModelMaleData = [
  {
    id:1,
    src: internationalOurModelM1,
     age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Maradona'
  },
  {
    id:2,
    src: internationalOurModelM2,
     age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Alex'
  },
  {
    id:3,
    src: internationalOurModelM3,
     age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Misha'
  },
  {
    id:4,
    src: internationalOurModelM4,
     age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Marco'
  },
  {
    id:5,
    src: internationalOurModelM5,
     age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Andreas'
  },
  {
    id:6,
    src: internationalOurModelM6,
     age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Marco'
  },
  {
    id:7,
    src: internationalOurModelM7,
     age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Arkady'
  },
  {
    id:8,
    src: internationalOurModelM8,
     age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Linus'
  },
  {
    id:9,
    src: internationalOurModelM9,
     age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Dovani'
  },
  {
    id:10,
    src: internationalOurModelM10,
     age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Milan'
  },{
    id:11,
    src: internationalOurModelM11,
     age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'John'
  },
  {
    id:12,
    src: internationalOurModelM12,
     age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Michal'
  },
  {
    id:13,
    src: internationalOurModelM13,
     age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Antony'
  },
  {
    id:14,
    src: internationalOurModelM14,
     age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Hamza'
  },
  {
    id:15,
    src: internationalOurModelM15,
     age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Marco'
  },{
    id:16,
    src: internationalOurModelM16,
     age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Alesandro'
  },
  {
    id:17,
    src: internationalOurModelM17,
     age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Bosko'
  },
  {
    id:18,
    src: internationalOurModelM18,
     age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Mike'
  },
  {
    id:19,
    src: internationalOurModelM19,
     age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Gleb'
  },
  {
    id:20,
    src: internationalOurModelM20,
     age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Adrien'
  },{
    id:21,
    src: internationalOurModelM21,
     age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Guii'
  },
  {
    id:22,
    src: internationalOurModelM22,
     age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Milan'
  },
  {
    id:23,
    src: internationalOurModelM23,
     age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Artem'
  },
  {
    id:24,
    src: internationalOurModelM24,
     age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Byrtek'
  },
  {
    id:25,
    src: internationalOurModelM25,
     age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Adam'
  },
  {
    id:26,
    src: internationalOurModelM26,
     age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Bozidar'
  },
  {
    id:27,
    src: internationalOurModelM27,
     age:25,
    gender:"male",
    height:'1.7 m',
    workExperience:"image source and profile name as state",
    profileName: 'Antonie'
  }
];

export default InternModelMaleData