
import GalleryPhotos from "../../../common/Gallery/Gallery"
import Sidebar from "../../../common/SideAbleMenu/SideBar"
import WeddingFooter from "../../../common/marxWedding/FooterMarxWedding"
import MarxWeddingHeader from "../../../common/marxWedding/HeaderMarxWedding"
import SecondPicsData from "../../../data/marxEvent/SecondPicsData"
import '../../../styles/destinationWeddingStyle.css'


const DelhiPics = () => {
    return (
        <>
            <Sidebar />
            <MarxWeddingHeader />
            <GalleryPhotos GalleryData={SecondPicsData} title={'Destination Wedding Pics'} />
            <WeddingFooter />
        </>
    )
}

export default DelhiPics
