
import IndModelMaleData from "../../../data/marxEvent/ourModelbank/indian/indianModelMaleData"
import WeddingFooter from "../../../common/marxWedding/FooterMarxWedding"
import MarxWeddingHeader from "../../../common/marxWedding/HeaderMarxWedding"
import WithBioWithoutRunningGallery from "../../../common/GalleryWitthBioAllMaxWithoutRunningGallery/WithBioWithoutRunningGallery"

const WeddingTravelPackages = () => {
  return (
    <>
        <MarxWeddingHeader />
        <WithBioWithoutRunningGallery ModelData={IndModelMaleData}  heading={"Mar'x Travel Packages"} detailpg={'wedd-travelpackages'}/>
        <WeddingFooter />

    </>
  )
}

export default WeddingTravelPackages
