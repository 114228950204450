import React, { useEffect } from 'react'
import Sidebar from '../../../common/SideAbleMenu/SideBar'
import Header from '../../../common/MarxEntertainment/header/Header'
import Footer from '../../../common/MarxEntertainment/footer/Footer'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import './purchaseHistoryStyle.css'
import PurchaseHistoryData from '../../../data/MarxEntertainment/PurchaseHistoryData'
import { GoDotFill } from "react-icons/go";
import axios from 'axios'

// const API_PACKGE = 'https://onlineparttimejobs.in/api/packagePaymentHistory/public'
const PurchaseHistory = () => {
  
  const purchasePackagehistory = async () => {
    try {
      const API_PACKAGE = 'https://onlineparttimejobs.in/api/packagePaymentHistory/public';
      const resultData = await axios.get(API_PACKAGE);
      console.log("resultData", resultData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    purchasePackagehistory();
  }, []);
  return (
    <>
      <Sidebar />
      <Header />
      <Box className='purchase-history'>
        <Typography className='h3comp fw-bold'>Purchase Package History</Typography>
        <div className='tablediv'>
          <TableContainer className='tablecontainr'>
            <Table>
              <TableHead className='tbleHead'>
                <TableRow>
                  <TableCell className="headingtable fw-bold">ID</TableCell>
                  <TableCell className="headingtable fw-bold">Products</TableCell>
                  <TableCell className="headingtable fw-bold">Customer</TableCell>
                  <TableCell className="headingtable fw-bold">Purchase Date</TableCell>
                  <TableCell className="headingtable fw-bold">Price</TableCell>
                  <TableCell className="headingtable fw-bold">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {PurchaseHistoryData.map((purchase, index) => (
                  <TableRow key={index}>
                    <TableCell>{purchase.id}</TableCell>
                    <TableCell className='width220'>
                      <div className='imgediv-history'>
                        <img className='productimg' src={purchase.productimage} alt="img" />
                        <p className=' fw-bold'>{purchase.productname}</p>
                      </div>
                    </TableCell>
                    <TableCell className='width220'>
                      <div className=' imgediv-history'>
                        <img className='customerimg' src={purchase.customerimage} alt="img" />
                        <p className=' fw-bold'>{purchase.customername}</p>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div>
                        <p>{purchase.purchaseDate}</p>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div>
                        <p>$ {purchase.price}</p>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div>
                        <p><GoDotFill /> {purchase.status}</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Box>
      <Footer />
    </>
  )
}

export default PurchaseHistory
