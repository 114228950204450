import { useState } from "react";
import './forgotPasswordStyle.css'
import { Button } from "@mui/material";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";


const ForgotPassword = () => {
    const [isOk, setIsOk] = useState(false);
  const navigate = useNavigate()


    const update = () => {
        setIsOk(!isOk);
    navigate('/forgot-password/otp')
    }
    return (
        <>

            <div className="forgotpassword">
                <div className="card d-margin">
                    <div>
                        <h1 className="ph-center">Forgot password</h1>
                    </div>
                    <hr></hr>

                    <form>
                        <div className="margin-everywhere">
                            <label className="margin-everywhere" htmlFor="exampleInputEmail1">
                                <b>Enter registered email</b>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Email"
                            ></input>
                            <Button fullWidth onClick={update} type="button" className="btnbtn">
                                Send Reset Code
                            </Button>
                        </div>

                        {(() => {
                            if (isOk) {
                                return (
                                    <p className="msgcenter">
                                        <IoMdCheckmarkCircleOutline className=" fs-4 me-2" />
                                        A link has sent to this email, use that link to change
                                        password
                                    </p>
                                );
                            }
                        })()}
                    </form>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword
