import MediaFooter from "../../common/MarxMedia/MarxMediaFooter/MarxMediaFooter"
import MarxMediaHeader from "../../common/MarxMedia/MarxMediaHeader/MarxMediaHeader"
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import '../../styles/marxMediaHomeStyle.css'
import RunningSlide from "../../common/RunningSlideAllMarx/RunningSlide";
import MediaExibhitionData from "../../data/MarxMedia/MediaExibhitionData";
import MediaOutDoorMarketingData from "../../data/MarxMedia/mediaOutdoorMarketingData";
import PrintMediaData from "../../data/MarxMedia/PrintMediaData";
import { useEffect } from "react";
import TopMarxMediaSlideData from "../../data/MarxMedia/TopMarxMediaSlideData";
import TopSliderAllMax from "../../common/TopSliderAllMarx/TopSliderAllMarx";
import { Dropdown } from "react-bootstrap";
import AllDemoData from "../../data/AllDemoData";




const MarxMediaHome = () => {
    useEffect(() => {
        document.title = "Mar'x Media Pvt.Ltd";
    }, []);

    return (
        <>
            <MarxMediaHeader />
            <TopSliderAllMax title={"Product Branding & Promotions"} images={TopMarxMediaSlideData} />

            <div className="">
                <div className="event-navbrlists">
                    <ul>
                        <li>
                            <Link to="/marx-media">Home</Link>
                        </li>
                        <li>
                            <Dropdown id="custom-dropdown">
                                <Dropdown.Toggle variant="" id="dropdown-basic">Services</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item as={Link} to="/mediaservice-branding">Branding</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/mediaservice-marketing">Marketing</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/mediaservice-designing">Designing</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/mediaservice-advertising">Advertising</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/mediaservice-promotions">Promotions</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/mediaservice-communication">Communication</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/mediaservice-publicrelations">Public Relations</Dropdown.Item>

                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                       
                        <li>
                            <Link to="/media-registrationform">Registration Form</Link>
                        </li>
                       
                        <li>
                            <Link to="/media-shows-gallery">Events / Shows Pics</Link>
                        </li>

                        
                        <li>
                            <Link to="/media-showvideoreel">Shows Video Reel</Link>
                        </li>
                       
                        <li>
                            <Link to="/media-filtersearch">Filter Search</Link>
                        </li>
                        <li className="">
                            <Dropdown id="custom-dropdown">
                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                    Advertising Campaigns
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item as={Link} to="/media-print">Print Media</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/media-electronic">Electronic Media</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                        <li>
                            <Link to="/media-subscriptionplans">Subscription Plans</Link>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="main-mediadiv">
                <Box className="mainmediadiv-box">
                    <div className="allmediatabs">
                        <h5 className=" text-center fw-bold mb-4"> INDEX </h5>
                        <p className='mange-mediatab'>
                            <Link to={''} >Digital Branding</Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Visual Branding </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Outdoor Branding </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Product Branding </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Indoor Branding </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >OOH Branding </Link>
                        </p>
                        <p className='mange-mediatab'>
                            <Link to={''} >Corporate Branding </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Retail Branding </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Offline Branding </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Cultural Branding </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Events Branding </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Social Branding </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Ethical Branding </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Disruptive Branding </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Content Marketing </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >SEO Marketing </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Influencer Marketing </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Digital Marketing </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Advertising Marketing </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Inbound Marketing </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Affiliate Marketing </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Buzz Marketing </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Acquisition Marketing </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Guerrilla Marketing </Link>
                        </p>
                        <p className='mange-mediatab'>
                            <Link to={''} >SEM Marketing </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Event Marketing </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Relationship Marketing </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Print Marketing </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >P.R Marketing </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Advertisement Designing </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Event Designing </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Package Designing </Link>
                        </p>
                        <p className='mange-mediatab'>
                            <Link to={''} >Brand Designing </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Exhibition Designing </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Wedding Designing </Link>
                        </p>
                        <p className='mange-mediatab'>
                            <Link to={''} >Concert Designing </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Corporate Shows Designing </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Public Shows Designing </Link>
                        </p>
                        <p className='mange-mediatab'>
                            <Link to={''} > Awards Show Designing </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Stage & Setup Designing </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Sound Designing </Link>
                        </p>
                        <p className='mange-mediatab'>
                            <Link to={''} > Media Relations</Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Community Relations </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >External Affairs Relations </Link>
                        </p>
                        <p className='mange-mediatab'>
                            <Link to={''} >Govt. Relations </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Investors Relations </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Community Relations </Link>
                        </p>
                        <p className='mange-mediatab'>
                            <Link to={''} >Internal Relations </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Customer Relations </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Crisis Communication </Link>
                        </p>
                        <p className='mange-mediatab'>
                            <Link to={''} >Social Media Communication </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Internet Communication </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Strategic Communication </Link>
                        </p>
                        <p className='mange-mediatab'>
                            <Link to={''} >Commercial Advertising </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Print Advertising </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Online Search Advertising </Link>
                        </p>
                        <p className='mange-mediatab'>
                            <Link to={''} >Social Media Advertising </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Direct Mail Advertising </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Broadcast Advertising </Link>
                        </p>
                        <p className='mange-mediatab'>
                            <Link to={''} >Video Advertising </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Outdoor Advertising </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Indoor Advertising </Link>
                        </p>
                        <p className='mange-mediatab'>
                            <Link to={''} >Mobile Sms Advertising </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} > Radio Advertising </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Films Advertising </Link>
                        </p>
                        <p className='mange-mediatab'>
                            <Link to={''} >Whtsapp Advertising </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Coupons Promotions </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Sales Promotions </Link>
                        </p>
                        <p className='mange-mediatab'>
                            <Link to={''} >Bogo Promotions </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Flash Sale Promotions </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Personal Selling Promotions </Link>
                        </p>
                        <p className='mange-mediatab'>
                            <Link to={''} >Direct Promotions </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Free Shipping Promotions </Link>
                        </p>
                        <p className='mange-mediatab'>
                            <Link to={''} >Freebies Promotions </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Loyalty Program Promotions </Link>
                        </p> <p className='mange-mediatab'>
                            <Link to={''} >Competition Promotions </Link>
                        </p>
                        <p className='mange-mediatab'>
                            <Link to={''} >Referral Promotions </Link>
                        </p>
                    </div>
                    <div className="mediatabpanels">
                        <div>
                            <div className="about-marx-media">
                                <h1>About Us</h1>
                                <p></p>
                            </div>

                            <div className="about-marx-media">
                                <h1>Who We Are</h1>
                                <div>
                                    <p></p>
                                </div>
                            </div>

                            <div className="about-marx-media">
                                <h1>Our Capabilities</h1>
                                <div>
                                    <p></p>
                                </div>
                            </div>
                            <div className="about-marx-media">
                                <h1> Our Perspective</h1>
                                <div>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </div>

            <RunningSlide title="Product Launch" images={AllDemoData} />
            <RunningSlide title="Exhibition & Stage set - up Designing" images={MediaExibhitionData} />
            <RunningSlide title="Print & TV commercial Advertisement" images={PrintMediaData} />
            <RunningSlide title="Outdoor Branding & marketing strategies" images={MediaOutDoorMarketingData} />
            <RunningSlide title="Our Work" images={AllDemoData} />

            <MediaFooter />
        </>
    )
}

export default MarxMediaHome
