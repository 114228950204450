import { useParams } from "react-router-dom"
import GalleryWitthBioDetailpgAllMax from "../../../common/GalleryWitthBioAllMax/GalleryWitthBioDetailpgAllMax"
import MarxEventFooter from "../../../common/Marx_Event/MarxEventFooter/MarxEventFooter"
import MarxEventHeader from "../../../common/Marx_Event/MarxEventHeader/MarxEventHeader"
import IndModelMaleData from "../../../data/marxEvent/ourModelbank/indian/indianModelMaleData"

const MarxFashionDesignerDetailPage = () => {

    let { id } = useParams();
    console.log(id)

    const singleData = IndModelMaleData.find((item) => item.id == id)
    console.log(singleData)
   


    return (
        <>
            <MarxEventHeader />
            <GalleryWitthBioDetailpgAllMax  allData={singleData} />
            <MarxEventFooter />

        </>
    )
}

export default MarxFashionDesignerDetailPage
