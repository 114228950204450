import GalleryPhotos from "../../common/Gallery/Gallery"
import Footer from "../../common/MarxEntertainment/footer/Footer"
import Header from "../../common/MarxEntertainment/header/Header"
import Sidebar from "../../common/SideAbleMenu/SideBar"
import SecondPicsData from "../../data/marxEvent/SecondPicsData"

const ServicesGallery = () => {
    return (
        <>
            <Sidebar />
            <Header />
            <GalleryPhotos GalleryData={SecondPicsData} title={'name'} />
            <Footer />
        </>
    )
}

export default ServicesGallery
