import videoo from '../../assest/video/web  intro.mp4'
import '../../styles/videoGalleryStyle.css'
import { Box } from '@mui/material';
import Sidebar from '../../common/SideAbleMenu/SideBar';
import MarxGroupHeader from '../../common/marxGroup/MarxGroupHeader';
import MarxGroupFooter from '../../common/marxGroup/MarxGroupFooter';


const VideoGallery = () => {
    return (
        <>
        <Sidebar/>
        <MarxGroupHeader/>
        <Box>
            <h2 className='vediotag'>Video Gallery</h2>
            <div className='bgdiv'>
                <div className="vidio-divbox">
                    <video
                        className="video-tagbtn"
                        playsInline
                        muted
                        autoPlay
                        controls
                        alt="All the devices"
                        src={videoo}
                       
                    />
                </div>
                <div className="vidio-divbox">
                    <video
                        className="video-tagbtn"
                        playsInline
                        muted
                        autoPlay
                        controls
                        alt="All the devices"
                        src={videoo}
                       
                    />
                </div>
                <div className="vidio-divbox">
                    <video
                        className="video-tagbtn"
                        playsInline
                        muted
                        autoPlay
                        controls
                        alt="All the devices"
                        src={videoo}
                       
                    />
                </div>
                <div className="vidio-divbox">
                    <video
                        className="video-tagbtn"
                        playsInline
                        muted
                        autoPlay
                        controls
                        alt="All the devices"
                        src={videoo}
                       
                    />
                </div>
                <div className="vidio-divbox">
                    <video
                        className="video-tagbtn"
                        playsInline
                        muted
                        autoPlay
                        controls
                        alt="All the devices"
                        src={videoo}
                       
                    />
                </div>
                <div className="vidio-divbox">
                    <video
                        className="video-tagbtn"
                        playsInline
                        muted
                        autoPlay
                        controls
                        alt="All the devices"
                        src={videoo}
                       
                    />
                </div>

                <div className="vidio-divbox">
                    <video
                        className="video-tagbtn"
                        playsInline
                        muted
                        autoPlay
                        controls
                        alt="All the devices"
                        src={videoo}
                       
                    />
                </div>
                <div className="vidio-divbox">
                    <video
                        className="video-tagbtn"
                        playsInline
                        muted
                        autoPlay
                        controls
                        alt="All the devices"
                        src={videoo}
                       
                    />
                </div>
                <div className="vidio-divbox">
                    <video
                        className="video-tagbtn"
                        playsInline
                        muted
                        autoPlay
                        controls
                        alt="All the devices"
                        src={videoo}
                       
                    />
                </div>
                <div className="vidio-divbox">
                    <video
                        className="video-tagbtn"
                        playsInline
                        muted
                        autoPlay
                        controls
                        alt="All the devices"
                        src={videoo}
                       
                    />
                </div>
                <div className="vidio-divbox">
                    <video
                        className="video-tagbtn"
                        playsInline
                        muted
                        autoPlay
                        controls
                        alt="All the devices"
                        src={videoo}
                       
                    />
                </div>
                <div className="vidio-divbox">
                    <video
                        className="video-tagbtn"
                        playsInline
                        muted
                        autoPlay
                        controls
                        alt="All the devices"
                        src={videoo}
                       
                    />
                </div>
                <div className="vidio-divbox">
                    <video
                        className="video-tagbtn"
                        playsInline
                        muted
                        autoPlay
                        controls
                        alt="All the devices"
                        src={videoo}
                       
                    />
                </div>
            </div>
            </Box>
            <MarxGroupFooter/>

        </>
    )
}

export default VideoGallery
