import { FaCheckCircle } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import IndModelFemaleData from "../../data/marxEvent/ourModelbank/indian/IndianModelFemaleData";
import './cartStyle.css'
import Sidebar from "../../common/SideAbleMenu/SideBar";
import MarxEventHeader from "../../common/Marx_Event/MarxEventHeader/MarxEventHeader";
import MarxEventFooter from "../../common/Marx_Event/MarxEventFooter/MarxEventFooter";
import { Box, Button } from "@mui/material";


const Cart = () => {

    let { id } = useParams();
    console.log(id)

    const singleData = IndModelFemaleData.find((item) => item.id == id)
    console.log(singleData)

    const { profileName, src, price } = singleData;
    console.log(profileName, src)

    return (
        <>
            <Sidebar />
            <MarxEventHeader />
            <Box className='mainboxcart'>
                <div className="cartt">
                    <div className="img-divb">
                        <img src={src} alt="img" />
                    </div>
                    <div>
                        <h5><FaCheckCircle className="icon" /> Added To Cart</h5>
                        <p className="ptag"> <strong>Name :</strong> {profileName}</p>
                        <p className="ptag"> <strong>Price :</strong> {price}</p>
                    </div>
                </div>
                <div className="cartt">
                    <div className="bookingdiv">
                        <p className="price">Cart subtotal : ₹{price}</p>
                        <div>
                            <Button className="btnnn1" fullWidth>Proceed to Confirm Booking</Button>
                        </div>
                        <div>
                            <Link to={'/cart'}>
                                <Button className="btnnn2" fullWidth>Go to Booking Cart</Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </Box>
            <MarxEventFooter />

        </>
    )
}

export default Cart
