import mediaExhibition1 from '../../assest/images/MediaExhibitionStageSetupDesigning/mediaexhibition1.jpeg'
import mediaExhibition2 from '../../assest/images/MediaExhibitionStageSetupDesigning/mediaexhibition2.jpeg'
import mediaExhibition3 from '../../assest/images/MediaExhibitionStageSetupDesigning/mediaexhibition3.jpeg'
import mediaExhibition4 from '../../assest/images/MediaExhibitionStageSetupDesigning/mediaexhibition4.jpeg'
import mediaExhibition5 from '../../assest/images/MediaExhibitionStageSetupDesigning/mediaexhibition5.jpeg'
import mediaExhibition6 from '../../assest/images/MediaExhibitionStageSetupDesigning/mediaexhibition6.jpeg'
import mediaExhibition7 from '../../assest/images/MediaExhibitionStageSetupDesigning/mediaexhibition7.jpeg'
import mediaExhibition8 from '../../assest/images/MediaExhibitionStageSetupDesigning/mediaexhibition8.jpeg'
import mediaExhibition9 from '../../assest/images/MediaExhibitionStageSetupDesigning/mediaexhibition9.jpeg'
import mediaExhibition10 from '../../assest/images/MediaExhibitionStageSetupDesigning/mediaexhibition10.jpeg'
import mediaExhibition11 from '../../assest/images/MediaExhibitionStageSetupDesigning/mediaexhibition11.jpeg'
import mediaExhibition12 from '../../assest/images/MediaExhibitionStageSetupDesigning/mediaexhibition12.jpeg'
import mediaExhibition13 from '../../assest/images/MediaExhibitionStageSetupDesigning/mediaexhibition13.jpeg'
import mediaExhibition14 from '../../assest/images/MediaExhibitionStageSetupDesigning/mediaexhibition14.jpeg'
import mediaExhibition15 from '../../assest/images/MediaExhibitionStageSetupDesigning/mediaexhibition15.jpeg'
import mediaExhibition16 from '../../assest/images/MediaExhibitionStageSetupDesigning/mediaexhibition16.jpeg'
import mediaExhibition17 from '../../assest/images/MediaExhibitionStageSetupDesigning/mediaexhibition17.jpeg'
import mediaExhibition18 from '../../assest/images/MediaExhibitionStageSetupDesigning/mediaexhibition18.jpeg'
import mediaExhibition19 from '../../assest/images/MediaExhibitionStageSetupDesigning/mediaexhibition19.jpeg'
import mediaExhibition20 from '../../assest/images/MediaExhibitionStageSetupDesigning/mediaexhibition20.jpeg'
import mediaExhibition21 from '../../assest/images/MediaExhibitionStageSetupDesigning/mediaexhibition21.jpeg'
import mediaExhibition22 from '../../assest/images/MediaExhibitionStageSetupDesigning/mediaexhibition22.jpeg'
import mediaExhibition23 from '../../assest/images/MediaExhibitionStageSetupDesigning/mediaexhibition23.jpeg'
import mediaExhibition24 from '../../assest/images/MediaExhibitionStageSetupDesigning/mediaexhibition24.jpeg'
import mediaExhibition25 from '../../assest/images/MediaExhibitionStageSetupDesigning/mediaexhibition25.jpeg'
import mediaExhibition26 from '../../assest/images/MediaExhibitionStageSetupDesigning/mediaexhibition26.jpeg'
import mediaExhibition27 from '../../assest/images/MediaExhibitionStageSetupDesigning/mediaexhibition27.jpeg'
import mediaExhibition28 from '../../assest/images/MediaExhibitionStageSetupDesigning/mediaexhibition28.jpeg'
import mediaExhibition29 from '../../assest/images/MediaExhibitionStageSetupDesigning/mediaexhibition29.jpeg'
import mediaExhibition30 from '../../assest/images/MediaExhibitionStageSetupDesigning/mediaexhibition30.jpeg'
import mediaExhibition31 from '../../assest/images/MediaExhibitionStageSetupDesigning/mediaexhibition31.jpeg'
const MediaExibhitionData = [
    {
        id:1,
        imgSrc: mediaExhibition1,
    },
    {
        id:2,
        imgSrc: mediaExhibition2,
    },
    {
        id:3,
        imgSrc: mediaExhibition3,
    }, {
        id:4,
        imgSrc: mediaExhibition4,
    }, {
        id:5,
        imgSrc: mediaExhibition5,
    }, {
        id:6,
        imgSrc: mediaExhibition6,
    }, {
        id:7,
        imgSrc: mediaExhibition7,
    }, {
        id:8,
        imgSrc: mediaExhibition8,
    }, {
        id:9,
        imgSrc: mediaExhibition9,
    }, {
        id:10,
        imgSrc: mediaExhibition10,
    },{
        id:11,
        imgSrc: mediaExhibition11,
    },{
        id:12,
        imgSrc: mediaExhibition12,
    },{
        id:13,
        imgSrc: mediaExhibition13,
    },{
        id:14,
        imgSrc: mediaExhibition14,
    },{
        id:15,
        imgSrc: mediaExhibition15,
    },{
        id:16,
        imgSrc: mediaExhibition16,
    },{
        id:17,
        imgSrc: mediaExhibition17,
    },{
        id:18,
        imgSrc: mediaExhibition18,
    },{
        id:19,
        imgSrc: mediaExhibition19,
    },{
        id:20,
        imgSrc: mediaExhibition20,
    },{
        id:21,
        imgSrc: mediaExhibition21,
    },{
        id:22,
        imgSrc: mediaExhibition22,
    },{
        id:23,
        imgSrc: mediaExhibition23,
    },{
        id:24,
        imgSrc: mediaExhibition24,
    },{
        id:25,
        imgSrc: mediaExhibition25,
    },{
        id:26,
        imgSrc: mediaExhibition26,
    },{
        id:27,
        imgSrc: mediaExhibition27,
    },{
        id:28,
        imgSrc: mediaExhibition28,
    },{
        id:29,
        imgSrc: mediaExhibition29,
    },
    {
        id:30,
        imgSrc: mediaExhibition30,
    },
    {
        id:31,
        imgSrc: mediaExhibition31,
    },
];
export default MediaExibhitionData