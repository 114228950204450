import MarxGroupHeader from "../../common/marxGroup/MarxGroupHeader"
import MarxGroupFooter from '../../common/marxGroup/MarxGroupFooter'
import { Link } from "react-router-dom"
import '../../styles/siteMapStyle.css'
import Sidebar from "../../common/SideAbleMenu/SideBar"


const SiteMap = () => {
    return (
        <>
        <Sidebar/>
            <MarxGroupHeader />
            <div className=" bg-black">
                <h3 className="fw-bold text-white pt-4 ps-5">MarxGroup</h3>
                <div className="sitemapdivbox">
                    <p><Link to={'/marx-group'}>Marx Group</Link></p>
                    <p><Link to={'/marx-event-home'}>Mar'x Events Pvt.Ltd.</Link></p>
                    <p><Link to={'/marx-entertainment'}>Mar'x Entertainment Worldwide Pvt.Ltd.</Link></p>
                    <p><Link to={'/marx-wedding'}> Mar'x luxury Wedding India Pvt.Ltd.</Link></p>
                    <p><Link to={'/ourservice'}>Mar'x Media Pvt.Ltd.</Link></p>
                    <p><Link to={'/ourservice'}> Mar'x Foundation(NGO)</Link></p>
                    <p><Link to={'/ourservice'}> Mar'x Real Estate & Developer Pvt.Ltd.</Link></p>
                    <p><Link to={'/ourservice'}> Mar'x Fashion Couture Pvt.Ltd.</Link></p>
                    <p><Link to={'/ourservice'}> Mar'x Motion Picture Alliance Pvt.Ltd.</Link></p>
                    <p><Link to={'/ourservice'}>Mar'x Academy Of Fashion & Pageants Pvt.Ltd.</Link></p>
                    <p><Link to={'/ourservice'}> Mar'x Agro Foods India Pvt.Ltd.</Link></p>
                    <p><Link to={'/our-service'}>Services</Link></p>
                    <p><Link to={'/ourservice'}>Our Clients</Link></p>
                    <p><Link to={'/ourservice'}>Testimonials</Link></p>
                    <p><Link to={'/contactus'}>Contact us</Link></p>
                    <p><Link to={'/ourservice'}>Jobs</Link></p>
                    <p><Link to={'/ourservice'}>FAQs</Link></p>
                    <p><Link to={'/ourservice'}>Blog</Link></p>
                    <p><Link to={'/site-map'}>SiteMap</Link></p>
                    <p><Link to={'/video-gallery'}>Video Gallery</Link></p>
                    <p><Link to={'/ourservice'}>our-team</Link></p>
                    <p><Link to={'/privacy-policy'}>Privacy Policy</Link></p>
                    <p><Link to={'/term-condition'}>Terms & Conditions</Link></p>
                </div>
                <h3 className="fw-bold text-white pt-4 ps-5">Mar'x Entertainment</h3>
                <div className="sitemapdivbox">
                    <p><Link to={'/marx-entertainment'}>Mar'x Entertainment Worldwide Pvt.Ltd.</Link></p>
                    <p><Link to={'/ourservice'}> Celebrities / Artists (we manage)</Link></p>
                    <p><Link to={'/ourservice'}>Services</Link></p>
                    <p><Link to={'/ourservice'}> Index</Link></p>
                </div>
            </div>

            <MarxGroupFooter />
        </>
    )
}

export default SiteMap
