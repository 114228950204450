
import { useEffect, useState } from "react"
import '../styles/ourTeamStyle.css'
import axios from "axios"
import Sidebar from "../common/SideAbleMenu/SideBar"
import MarxGroupHeader from "../common/marxGroup/MarxGroupHeader"
import Footer from "../common/marxGroup/MarxGroupFooter"


const OurTeam = () => {
    const [ourTeam, setOurTeam] = useState([])
    useEffect(() => {
        const getOurTeamData = async () => {
            const reqOurTeamData = await axios.get("https://onlineparttimejobs.in/api/adminMemberWidget", {
                withCredentials: true
            });
            console.log(reqOurTeamData.data)
            setOurTeam(reqOurTeamData.data)
        }
        getOurTeamData();
    }, []);

    return (
        <>
        <Sidebar/>
            <MarxGroupHeader />
            <div className="mainboxteam">
            {ourTeam?.map((item) => {
                return(
                <div className="team-membr">
                    <img src={item.photo.url} alt="img" />
                    <h6 className=" fw-bold fs-4 my-3">{item.name}</h6>
                    <p>{item.department}</p>
                    <p>{item.description}</p>
                </div>
            )})}
            </div>

            <Footer />
        </>
    )
}

export default OurTeam
