
import { useParams } from "react-router-dom"
import GalleryWitthBioDetailpgAllMax from "../../../../common/GalleryWitthBioAllMax/GalleryWitthBioDetailpgAllMax"
import Sidebar from "../../../../common/SideAbleMenu/SideBar"
import IndModelMaleData from "../../../../data/marxEvent/ourModelbank/indian/indianModelMaleData"
import MarxWeddingHeader from "../../../../common/marxWedding/HeaderMarxWedding"
import WeddingFooter from "../../../../common/marxWedding/FooterMarxWedding"

const IndiaResortsDetailpg = () => {

    let { id } = useParams();
    console.log(id)

    const singleData = IndModelMaleData.find((item) => item.id == id)
    console.log(singleData)
   


    return (
        <>
            <Sidebar />
            <MarxWeddingHeader />
            <GalleryWitthBioDetailpgAllMax  allData={singleData} />
            <WeddingFooter />

        </>
    )
}

export default IndiaResortsDetailpg
