import GalleryPhotos from "../../../common/Gallery/Gallery"
import MarxEventFooter from "../../../common/Marx_Event/MarxEventFooter/MarxEventFooter"
import MarxEventHeader from "../../../common/Marx_Event/MarxEventHeader/MarxEventHeader"
import SecondPicsData from "../../../data/marxEvent/SecondPicsData"

const AwardsAndAchievements = () => {
  return (
    <>
      <MarxEventHeader />
      <GalleryPhotos GalleryData={SecondPicsData} title={'Award & Achievements'} />
      <MarxEventFooter />

    </>
  )
}

export default AwardsAndAchievements
