import weddFood1 from '../../assest/images/weddFood/weddFood1.jpeg'
import weddFood2 from '../../assest/images/weddFood/weddFood2.jpeg'
import weddFood3 from '../../assest/images/weddFood/weddFood3.jpeg'
import weddFood4 from '../../assest/images/weddFood/weddFood4.jpeg'
import weddFood5 from '../../assest/images/weddFood/weddFood5.jpeg'
import weddFood6 from '../../assest/images/weddFood/weddFood6.jpeg'
import weddFood7 from '../../assest/images/weddFood/weddFood7.jpeg'
import weddFood8 from '../../assest/images/weddFood/weddFood8.jpeg'
import weddFood9 from '../../assest/images/weddFood/weddFood9.jpeg'
import weddFood10 from '../../assest/images/weddFood/weddFood10.jpeg'
import weddFood11 from '../../assest/images/weddFood/weddFood11.jpeg'
import weddFood12 from '../../assest/images/weddFood/weddFood12.jpeg'
import weddFood13 from '../../assest/images/weddFood/weddFood13.jpeg'






const WeddFoodData = [
    {
        id: 1,
        imgSrc: weddFood1,
    },
    {
        id: 2,
        imgSrc: weddFood2,
    },
    {
        id: 3,
        imgSrc: weddFood3,
    }, {
        id: 4,
        imgSrc: weddFood4,
    }, {
        id: 5,
        imgSrc: weddFood5,
    }, {
        id: 6,
        imgSrc:weddFood6,
    }, {
        id: 7,
        imgSrc:weddFood7,
    }, {
        id: 8,
        imgSrc:weddFood8,
    }, {
        id: 9,
        imgSrc:weddFood9,
    }, {
        id: 10,
        imgSrc:weddFood10,
    },{
        id: 11,
        imgSrc:weddFood11,
    },{
        id: 12,
        imgSrc:weddFood12,
    },{
        id: 13,
        imgSrc:weddFood13,
    }
];
export default WeddFoodData;