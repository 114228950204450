const LocationData = [
    {
        location_id: 1,
        location_name: "Coming Soon....,Kolkata-INDIA",
        location_lat: 22.5726,
        location_long: 88.3639
    },
    {
        location_id: 2,
        location_name: "Branch Office : B – 84 Kamdhenu Complex , Lokhandwala Market , Andheri West Mumbai - 400102 - INDIA.",
        location_lat: 19.0760,
        location_long: 72.8777
    },
    {
        location_id: 3,
        location_name: "Regd.Office : UGF 12 V complex M.G Road Gurgaon, Haryana – 122001-INDIA.",
        location_lat: 28.4595,
        location_long: 77.0266
    }, {
        location_id: 4,
        location_name: "Coming Soon....,UAE",
        location_lat: 23.4241,
        location_long: 53.8478
    }, {
        location_id: 5,
        location_name: "Coming Soon....,USA",
        location_lat: 39.833333,
        location_long: -98.585522
    }, {
        location_id: 6,
        location_name: "Coming Soon....,MALAYSIA",
        location_lat: 4.2105,
        location_long: 101.9758
    }, {
        location_id: 7,
        location_name: "Coming Soon....,MYANMAR",
        location_lat: 21.9162,
        location_long: 95.9560
    }, {
        location_id: 8,
        location_name: "Coming Soon....,Oman",
        location_lat: 21.4735,
        location_long: 55.9754
    }, {
        location_id: 9,
        location_name: "Coming Soon....,UK",
        location_lat: 51.5074,
        location_long: -0.1278
    }, {
        location_id: 10,
        location_name: "Coming Soon....,Canada",
        location_lat: 45.4215,
        location_long: -75.6919
    }, {
        location_id: 11,
        location_name: "Coming Soon....,Kuwait",
        location_lat: 29.3759,
        location_long: 47.9774
    }, {
        location_id: 12,
        location_name: "Coming Soon....,South Africa",
        location_lat: -30.5595,
        location_long: 22.9375
    }, {
        location_id: 13,
        location_name: "Coming Soon....,Nigeria",
        location_lat: 9.082,
        location_long: 8.6753
    }, {
        location_id: 14,
        location_name: "Coming Soon....,Mauritius",
        location_lat: -20.348404,
        location_long: 57.552152
    }, {
        location_id: 15,
        location_name: "Coming Soon....,Srilanka",
        location_lat: 7.8731,
        location_long: 80.7718
    }, {
        location_id: 16,
        location_name: "Coming Soon....,Qatar",
        location_lat: 25.3548,
        location_long: 51.1839
    }, {
        location_id: 17,
        location_name: "Coming Soon....,Australia",
        location_lat: -25.2744,
        location_long: 133.7751
    }, {
        location_id: 18,
        location_name: "Coming Soon....,Nepal",
        location_lat: 28.3949,
        location_long: 84.124
    }, {
        location_id: 19,
        location_name: "Coming Soon....,Trinidad & Tobago",
        location_lat: 10.6918,
        location_long: -61.2225
    }, {
        location_id: 20,
        location_name: "Coming Soon....,Singapore",
        location_lat: 1.3521,
        location_long: 103.8198
    }, {
        location_id: 21,
        location_name: "Coming Soon....,Bahrain",
        location_lat: 26.0667,
        location_long: 50.5577
    }, {
        location_id: 22,
        location_name: "Coming Soon....,Fiji",
        location_lat: -17.7134,
        location_long: 178.0650
    }, {
        location_id: 23,
        location_name: "Coming Soon....,Guyana",
        location_lat: 4.8604,
        location_long: -58.9302
    }, {
        location_id: 24,
        location_name: "Coming Soon....,France",
        location_lat: 46.603354,
        location_long: 1.888334
    }, {
        location_id: 25,
        location_name: "Coming Soon....,Germany",
        location_lat: 51.1657,
        location_long: 10.4515
    }, {
        location_id: 26,
        location_name: "Coming Soon....,New Zealand",
        location_lat: -40.9006,
        location_long: 174.886
    }, {
        location_id: 27,
        location_name: "Coming Soon....,Italy",
        location_lat: 41.8719,
        location_long: 12.5674
    }, {
        location_id: 28,
        location_name: "Coming Soon....,Bangladesh",
        location_lat: 23.685,
        location_long: 90.3563
    }, {
        location_id: 29,
        location_name: "Coming Soon....,Jamaica",
        location_lat: 18.1096,
        location_long: -77.2975
    }, {
        location_id: 30,
        location_name: "Coming Soon....,Kenya",
        location_lat: 0.0236,
        location_long: 37.9062
    }, {
        location_id: 31,
        location_name: "Coming Soon....,Tanzania",
        location_lat: -6.369028,
        location_long: 34.888822
    }, {
        location_id: 32,
        location_name: "Coming Soon....,Spain",
        location_lat: 40.4637,
        location_long: -3.7492
    }, {
        location_id: 33,
        location_name: "Coming Soon....,China",
        location_lat: 35.8617,
        location_long: 104.1954
    }, {
        location_id: 34,
        location_name: "Coming Soon....,Sweden",
        location_lat: 59.3293,
        location_long: 18.0686
    }, {
        location_id: 35,
        location_name: "Coming Soon....,Romania",
        location_lat: 45.9432,
        location_long: 24.9668
    }, {
        location_id: 36,
        location_name: "Coming Soon....,Burkina",
        location_lat: 12.2383,
        location_long: -1.5616
    }, {
        location_id: 37,
        location_name: "Coming Soon....,Mali",
        location_lat: 17.5707,
        location_long: -3.9962
    }, {
        location_id: 38,
        location_name: "Coming Soon....,Taiwan",
        location_lat: 23.6978,
        location_long: 120.9605
    }, {
        location_id: 39,
        location_name: "Coming Soon....,South Korea",
        location_lat: 35.9078,
        location_long: 127.7669
    }, {
        location_id: 40,
        location_name: "Coming Soon....,Cameroon",
        location_lat: 7.3697,
        location_long: 12.3547
    }, {
        location_id: 41,
        location_name: "Coming Soon....,Venezuela",
        location_lat: 6.4238,
        location_long: -66.5897
    }, {
        location_id: 42,
        location_name: "Coming Soon....,Madagascar",
        location_lat:  -18.7669,
        location_long: 46.8691
    }, {
        location_id: 43,
        location_name: "Coming Soon....,Mozambique",
        location_lat: -18.665695,
        location_long: 35.529562
    }, {
        location_id: 43,
        location_name: "Coming Soon....,Ghana",
        location_lat: 7.9465,
        location_long: -1.0232
    }, {
        location_id: 44,
        location_name: "Coming Soon....,Peru",
        location_lat: -9.189967,
        location_long: -75.015152
    }, {
        location_id: 45,
        location_name: "Coming Soon....,Morocco",
        location_lat: 31.7917,
        location_long: -7.0926
    }, {
        location_id: 46,
        location_name: "Coming Soon....,Switzerland",
        location_lat: 46.8182,
        location_long: 8.2275
    }, {
        location_id: 47,
        location_name: "Coming Soon....,Poland",
        location_lat: 51.9194,
        location_long: 19.1451
    }, {
        location_id: 48,
        location_name: "Coming Soon....,Argentina",
        location_lat: -38.4161,
        location_long: -63.6167
    }, {
        location_id: 49,
        location_name: "Coming Soon....,Algeria",
        location_lat: 28.0339,
        location_long: 1.6596
    }, {
        location_id: 50,
        location_name: "Coming Soon....,Sudan",
        location_lat: 12.8628,
        location_long: 30.2176
    }, {
        location_id: 51,
        location_name: "Coming Soon....,Uganda",
        location_lat: 1.3733,
        location_long: 32.2903
    }, {
        location_id: 52,
        location_name: "Coming Soon....,North Korea",
        location_lat: 40.3399,
        location_long: 127.5101
    }, {
        location_id: 53,
        location_name: "Coming Soon....,Columbia",
        location_lat: 4.5709,
        location_long: -74.2973
    }, {
        location_id: 54,
        location_name: "Coming Soon....,Turkey",
        location_lat: 38.9637,
        location_long: 35.2433
    }, {
        location_id: 55,
        location_name: "Coming Soon....,Vietnam",
        location_lat: 14.0583,
        location_long: 108.2772
    }, {
        location_id: 56,
        location_name: "Coming Soon....,Egypt",
        location_lat: 26.8206,
        location_long: 30.8025
    }, {
        location_id: 57,
        location_name: "Coming Soon....,Japan",
        location_lat: 36.2048,
        location_long: 138.2529
    }, {
        location_id: 58,
        location_name: "Coming Soon....,Ethiopia",
        location_lat: 9.145,
        location_long: 40.489673
    }, {
        location_id: 59,
        location_name: "Coming Soon....,Mexico",
        location_lat: 23.6345,
        location_long: -102.5528
    }, {
        location_id: 60,
        location_name: "Coming Soon....,Russia",
        location_lat: 61.524,
        location_long: 105.3188
    }, {
        location_id: 61,
        location_name: "Coming Soon....,Brazil",
        location_lat: -14.235,
        location_long: -51.9253
    }, {
        location_id: 62,
        location_name: "Coming Soon....,Indonesia",
        location_lat: -0.7893,
        location_long: 113.9213
    }, {
        location_id: 63,
        location_name: "Coming Soon....,Cuba",
        location_lat: 21.5218,
        location_long: -77.7812
    }, {
        location_id: 64,
        location_name: "Coming Soon....,Belgium",
        location_lat: 50.5039,
        location_long: 4.4699
    }, {
        location_id: 65,
        location_name: "Coming Soon....,Denmark",
        location_lat: 56.2639,
        location_long: 9.5018
    }, {
        location_id: 66,
        location_name: "Coming Soon....,Bolivia",
        location_lat: -16.2902,
        location_long: -63.5887
    }, {
        location_id: 67,
        location_name: "Coming Soon....,Zimbabwe",
        location_lat: -19.0154,
        location_long: 29.1549
    }, {
        location_id: 68,
        location_name: "Coming Soon....,Cambodia",
        location_lat: 12.5657,
        location_long: 104.991
    }, {
        location_id: 69,
        location_name: "Coming Soon....,Netherlands",
        location_lat: 52.1326,
        location_long: 5.2913
    }, {
        location_id: 70,
        location_name: "Coming Soon....,Senegal",
        location_lat: 14.4974,
        location_long: -14.4524
    }, {
        location_id: 71,
        location_name: "Coming Soon....,Guatemala",
        location_lat: 15.7835,
        location_long: -90.2308
    }, {
        location_id: 72,
        location_name: "Coming Soon....,Ecuador",
        location_lat: -1.8312,
        location_long: -78.1834
    }, {
        location_id: 73,
        location_name: "Coming Soon....,Hong kong",
        location_lat: 22.3193,
        location_long: 114.1694
    }, {
        location_id: 74,
        location_name: "Coming Soon....,Greece",
        location_lat: 39.0742,
        location_long: 21.8243
    }, {
        location_id: 75,
        location_name: "Coming Soon....,Portugal",
        location_lat: 39.3999,
        location_long: -8.2245
    }, {
        location_id: 76,
        location_name: "Coming Soon....,Austria",
        location_lat: 47.5162,
        location_long: 14.5501
    }, {
        location_id: 77,
        location_name: "Coming Soon....,Belarus",
        location_lat: 53.7098,
        location_long: 27.9534
    }, {
        location_id: 78,
        location_name: "Coming Soon....,Phillipines",
        location_lat: 12.8797,
        location_long: 121.774
    }, {
        location_id: 79,
        location_name: "Coming Soon....,Thailand",
        location_lat: 15.8700,
        location_long: 100.9925
    }

]
export default LocationData;