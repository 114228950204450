import enttCelebs1 from '../../assest/images/entt.CelebsGallery/enttCelebs1.jpeg'
import enttCelebs2 from '../../assest/images/entt.CelebsGallery/enttCelebs2.jpeg'
import enttCelebs3 from '../../assest/images/entt.CelebsGallery/enttCelebs3.jpeg'
import enttCelebs4 from '../../assest/images/entt.CelebsGallery/enttCelebs4.jpeg'
import enttCelebs5 from '../../assest/images/entt.CelebsGallery/enttCelebs5.jpeg'
import enttCelebs6 from '../../assest/images/entt.CelebsGallery/enttCelebs6.jpeg'
import enttCelebs7 from '../../assest/images/entt.CelebsGallery/enttCelebs7.jpeg'
import enttCelebs8 from '../../assest/images/entt.CelebsGallery/enttCelebs8.jpeg'
import enttCelebs9 from '../../assest/images/entt.CelebsGallery/enttCelebs9.jpeg'
import enttCelebs10 from '../../assest/images/entt.CelebsGallery/enttCelebs10.jpeg'
import enttCelebs11 from '../../assest/images/entt.CelebsGallery/enttCelebs11.jpeg'
import enttCelebs12 from '../../assest/images/entt.CelebsGallery/enttCelebs12.jpeg'
import enttCelebs13 from '../../assest/images/entt.CelebsGallery/enttCelebs13.jpeg'
import enttCelebs14 from '../../assest/images/entt.CelebsGallery/enttCelebs14.jpeg'
import enttCelebs15 from '../../assest/images/entt.CelebsGallery/enttCelebs15.jpeg'
import enttCelebs16 from '../../assest/images/entt.CelebsGallery/enttCelebs16.jpeg'
import enttCelebs17 from '../../assest/images/entt.CelebsGallery/enttCelebs17.jpeg'
import enttCelebs18 from '../../assest/images/entt.CelebsGallery/enttCelebs18.jpeg'
import enttCelebs19 from '../../assest/images/entt.CelebsGallery/enttCelebs19.jpeg'
import enttCelebs20 from '../../assest/images/entt.CelebsGallery/enttCelebs20.jpeg'
import enttCelebs21 from '../../assest/images/entt.CelebsGallery/enttCelebs21.jpeg'
import enttCelebs22 from '../../assest/images/entt.CelebsGallery/enttCelebs22.jpeg'





const TopMarxEntertainmentData = [
    {
        id: 1,
        imgSrc: enttCelebs1,
    },
    {
        id: 2,
        imgSrc: enttCelebs2,
    },
    {
        id: 3,
        imgSrc: enttCelebs3,
    }, {
        id: 4,
        imgSrc: enttCelebs4,
    }, {
        id: 5,
        imgSrc: enttCelebs5,
    }, {
        id: 6,
        imgSrc:enttCelebs6,
    }, {
        id: 7,
        imgSrc:enttCelebs7,
    }, {
        id: 8,
        imgSrc:enttCelebs8,
    }, {
        id: 9,
        imgSrc:enttCelebs9,
    }, {
        id: 10,
        imgSrc:enttCelebs10,
    },{
        id: 11,
        imgSrc:enttCelebs11,
    },{
        id: 12,
        imgSrc:enttCelebs12,
    },{
        id: 13,
        imgSrc:enttCelebs13,
    },{
        id: 14,
        imgSrc:enttCelebs14,
    },{
        id: 15,
        imgSrc:enttCelebs15,
    },{
        id: 16,
        imgSrc:enttCelebs16,
    },{
        id: 17,
        imgSrc:enttCelebs17,
    },{
        id: 18,
        imgSrc:enttCelebs18,
    },{
        id: 19,
        imgSrc:enttCelebs19,
    },{
        id: 20,
        imgSrc:enttCelebs20,
    },{
        id: 21,
        imgSrc:enttCelebs21,
    },{
        id: 22,
        imgSrc:enttCelebs22,
    }
];
export default TopMarxEntertainmentData;