import stageperformance7 from '../../assest/images/entt.StagePerformance/stageperformance7.jpeg'
import stageperformance8 from '../../assest/images/entt.StagePerformance/stageperformance8.jpeg'
import stageperformance9 from '../../assest/images/entt.StagePerformance/stageperformance9.jpeg'
import stageperformance10 from '../../assest/images/entt.StagePerformance/stageperformance10.jpeg'
import stageperformance11 from '../../assest/images/entt.StagePerformance/stageperformance11.jpeg'
import stageperformance12 from '../../assest/images/entt.StagePerformance/stageperformance12.jpeg'
import stageperformance13 from '../../assest/images/entt.StagePerformance/stageperformance13.jpeg'
import stageperformance14 from '../../assest/images/entt.StagePerformance/stageperformance14.jpeg'
import stageperformance15 from '../../assest/images/entt.StagePerformance/stageperformance15.jpeg'
import stageperformance16 from '../../assest/images/entt.StagePerformance/stageperformance16.jpeg'
import stageperformance17 from '../../assest/images/entt.StagePerformance/stageperformance17.jpeg'
import stageperformance18 from '../../assest/images/entt.StagePerformance/stageperformance18.jpeg'
import stageperformance19 from '../../assest/images/entt.StagePerformance/stageperformance19.jpeg'
import stageperformance20 from '../../assest/images/entt.StagePerformance/stageperformance20.jpeg'
import stageperformance21 from '../../assest/images/entt.StagePerformance/stageperformance21.jpeg'
import stageperformance22 from '../../assest/images/entt.StagePerformance/stageperformance22.jpeg'


const PurchaseHistoryData = [
    {
        id: 1,
        productname:' this isi s kxlcc kvvkvml kvmdm mdfsv',
        customername:'mohd bilal ahmad',
        productimage:stageperformance10,
        price:50,
        status:'paid',
        purchaseDate:'20 mar, 4:50pm',
        customerimage: stageperformance7,
    },
    {
        id: 2,
        productname:' this isi s kxlcc kvvkvml kvmdm mdfsv',
        customername:'mohd bilal ahmad',
        productimage:stageperformance10,
        price:50,
        status:'paid',
        purchaseDate:'20 mar, 4:50pm',
        customerimage: stageperformance8,
    },
    {
        id: 3,
        productname:' this isi s kxlcc kvvkvml kvmdm mdfsv',
        customername:'mohd bilal ahmad',
        productimage:stageperformance10,
        price:50,
        status:'paid',
        purchaseDate:'20 mar, 4:50pm',
        customerimage: stageperformance9,
    }, {
        id: 4,
        productname:' this isi s kxlcc kvvkvml kvmdm mdfsv',
        customername:'mohd bilal ahmad',
        productimage:stageperformance10,
        price:50,
        status:'paid',
        purchaseDate:'20 mar, 4:50pm',
        customerimage: stageperformance10,
    }, {
        id: 5,
        productname:' this isi s kxlcc kvvkvml kvmdm mdfsv',
        customername:'mohd bilal ahmad',
        productimage:stageperformance10,
        price:50,
        status:'paid',
        purchaseDate:'20 mar, 4:50pm',
        customerimage: stageperformance11,
    }, {
        id: 6,
        productname:' this isi s kxlcc kvvkvml kvmdm mdfsv',
        customername:'mohd bilal ahmad',
        productimage:stageperformance10,
        price:50,
        status:'paid',
        purchaseDate:'20 mar, 4:50pm',
        customerimage:stageperformance12,
    }, {
        id: 7,
        productname:' this isi s kxlcc kvvkvml kvmdm mdfsv',
        customername:'mohd bilal ahmad',
        productimage:stageperformance10,
        price:50,
        status:'paid',
        purchaseDate:'20 mar, 4:50pm',
        customerimage:stageperformance13,
    }, {
        id: 8,
        productname:' this isi s kxlcc kvvkvml kvmdm mdfsv',
        customername:'mohd bilal ahmad',
        productimage:stageperformance10,
        price:50,
        status:'paid',
        purchaseDate:'20 mar, 4:50pm',
        customerimage:stageperformance14,
    }, {
        id: 9,
        productname:' this isi s kxlcc kvvkvml kvmdm mdfsv',
        customername:'mohd bilal ahmad',
        productimage:stageperformance10,
        price:50,
        status:'paid',
        purchaseDate:'20 mar, 4:50pm',
        customerimage:stageperformance15,
    }, {
        id: 10,
        productname:' this isi s kxlcc kvvkvml kvmdm mdfsv',
        customername:'mohd bilal ahmad',
        productimage:stageperformance10,
        price:50,
        status:'paid',
        purchaseDate:'20 mar, 4:50pm',
        customerimage:stageperformance16,
    }, {
        id: 11,
        productname:' this isi s kxlcc kvvkvml kvmdm mdfsv',
        customername:'mohd bilal ahmad',
        productimage:stageperformance10,
        price:50,
        status:'paid',
        purchaseDate:'20 mar, 4:50pm',
        customerimage: stageperformance17,
    }, {
        id: 12,
        productname:' this isi s kxlcc kvvkvml kvmdm mdfsv',
        customername:'mohd bilal ahmad',
        productimage:stageperformance10,
        price:50,
        status:'paid',
        purchaseDate:'20 mar, 4:50pm',
        customerimage: stageperformance18,
    }, {
        id: 13,
        productname:' this isi s kxlcc kvvkvml kvmdm mdfsv',
        customername:'mohd bilal ahmad',
        productimage:stageperformance10,
        price:50,
        status:'paid',
        purchaseDate:'20 mar, 4:50pm',
        customerimage: stageperformance19,
    }, {
        id: 14,
        productname:' this isi s kxlcc kvvkvml kvmdm mdfsv',
        customername:'mohd bilal ahmad',
        productimage:stageperformance10,
        price:50,
        status:'paid',
        purchaseDate:'20 mar, 4:50pm',
        customerimage: stageperformance20,
    }, {
        id: 15,
        productname:' this isi s kxlcc kvvkvml kvmdm mdfsv',
        customername:'mohd bilal ahmad',
        productimage:stageperformance10,
        price:50,
        status:'paid',
        purchaseDate:'20 mar, 4:50pm',
        customerimage: stageperformance21,
    }, {
        id: 16,
        productname:' this isi s kxlcc kvvkvml kvmdm mdfsv',
        customername:'mohd bilal ahmad',
        productimage:stageperformance10,
        price:50,
        status:'paid',
        purchaseDate:'20 mar, 4:50pm',
        customerimage: stageperformance22,
    }
];
export default PurchaseHistoryData