import SliderHome from "../../components/sliderHome/SliderHome";
import TechBlock from "../../components/techBlock/TechBlock";
import InfluencerMarketing from "../../components/influncerMarkrtingBlog/InfluencerMarketing";
import HomeServiceBlock from "../../components/MarxEntertainment/homeServiceBlock/HomeServiceBlock";
import BrandBlock from "../../components/brandBlock/BrandBlock";
import HomeCtg from "../../components/homectg/HomeCtg";
import HomeCityBlock from "../../components/homeCItyBlock/HomeCityBlock";
import YouTuberBlock from "../../components/youtuberBlock/YouTuberBlock";
import InstagramBlock from "../../components/instagramBlock/InstagramBlock";
import MicroInfluencer from "../../components/microInfluencer/MicroInfluencer";
import HowItsWorkBlock from "../../components/howItsWorkBlock/HowItsWorkBlock";
import Header from "../../common/MarxEntertainment/header/Header";
import Footer from '../../common/MarxEntertainment/footer/Footer'
import FreqAskQues from "../../components/FreqAskQues";
import '../../styles/homeStyle.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Sidebar from "../../common/SideAbleMenu/SideBar";
import { Box } from "@mui/material";
import RunningSlide from "../../common/RunningSlideAllMarx/RunningSlide";
import PerformanceGalleryData from "../../data/MarxEntertainment/PerformanceGalleryData";
import FilmPromotionData from "../../data/MarxEntertainment/FilmPromotionData";
import ClebsFeatureData from "../../data/MarxEntertainment/ClebsFeatureData";
import { useEffect } from "react";
import TopSliderAllMax from "../../common/TopSliderAllMarx/TopSliderAllMarx";
import TopMarxEntertainmentData from "../../data/MarxEntertainment/MarxCelebsGalleryData";
import marxEntLogo from '../../assest/images/marxentLogo.jpeg'
import { MarxEntertainmentSidebarData } from "../../data/MarxEntertainment/MarxEntertainmentSidebarData";
import EnttOurWorkData from "../../data/MarxEntertainment/EnttOurWorkData";



function MarxEntertainmentHome() {
  useEffect(() => {
    document.title = "Mar’x Entertainment Worldwide Pvt.Ltd.";
  }, []);

  const { t } = useTranslation();


  return (
    <>
      <Sidebar SidebarData={MarxEntertainmentSidebarData} logo={marxEntLogo} />
      <Header />

      <TopSliderAllMax title={"Celeb's Gallery"} images={TopMarxEntertainmentData}/>

      <div className="nav-list-entrnmt">
        <ul>
        <li>
            <Link to="/marx-entertainment">Home </Link>
          </li>
          <li>
            <Link to="/service">{t('header_key1')}</Link>
          </li>
         
         
          {/* <li>
            <Link to="/index">Index</Link>
          </li> */}

          {/* <li className="price-li">
                {t('header_key3')}
                <span className="spnarrow">
                  <MdKeyboardArrowDown />
                </span>

                <div className="hide-price">
                  <ul>
                    <li><Link to={'/instagram-plan'}>Instagram Packages</Link></li>
                    <li><Link to={'/youtube-plan'}>Youtube Packages</Link></li>
                    <li><Link to={'/guest-posts'}>Guest Post Packages</Link></li>
                  </ul>
                </div>
              </li> */}

         

          <li>
            <Link to="/entertainment-showgallery">Shows Gallery</Link>
          </li>
         
          <li>
            <Link to="/entertainment-showsvideoreel">Shows Video Reel</Link>
          </li>
          <li>
            <Link to="/entertainment-filtersearch">Filter Search</Link>
          </li>
          <li>
            <Link to="/registration-from">Registration Forms</Link>
          </li>
          <li>
            <Link to="/entertainment-halloffame">Hall of Fame</Link>
          </li>
         
          <li>
            <Link to="/our-influencers">{t('header_key')}</Link>
          </li>
          <li>
            <Link to="/packages">Subscription Plans</Link>
          </li>

        </ul>
      </div>

      <div className="main-entdiv">
        <Box className="mainentdiv-box">
          <div className="allenttabs">
            <h5 className=" text-center fw-bold mb-4">INDEX </h5>
            <p className='mange-enttab'>
              <Link to={'/ent-debuttalent'} >MAR'X DEBUT TALENT </Link>
            </p>
            <p className='mange-enttab'>
              <Link to={'/ent-indiantalent'} >MAR'X INDIAN TALENT </Link>
            </p>
            <p className='mange-enttab'>
              <Link to={'/ent-odiyatalent'} >MAR'X ODIYA TALENT </Link>
            </p>
            <p className='mange-enttab'>
              <Link to={'/ent-sportstalent'} >MAR’X SPORTS TALENT </Link>
            </p>
            <p className='mange-enttab'>
              <Link to={'/ent-bengalitalent'} >MAR’X BENGALI TALENT </Link>
            </p>
            <p className='mange-enttab'>
              <Link to={'/ent-marathitalent'} >MAR'X MARATHI TALENT </Link>
            </p>
            <p className='mange-enttab'>
              <Link to={'/ent-gujratitalent'} >MAR'X GUJARATI TALENT </Link>
            </p>
            <p className='mange-enttab'>
              <Link to={'/ent-regionaltalent'} >MAR'X REGIONAL TALENT </Link>
            </p>
            <p className='mange-enttab'>
              <Link to={'/ent-exclusivetalent'} >MAR'X EXCLUSIVE TALENT </Link>
            </p>
            <p className='mange-enttab'>
              <Link to={'/ent-classicaltalent'} >MAR’X CLASSICAL TALENT</Link>
            </p>
            <p className='mange-enttab'>
              <Link to={'/ent-bollywoodtalent'} >MAR'X BOLLYWOOD TALENT </Link>
            </p>
            <p className='mange-enttab'>
              <Link to={'/ent-tollywoodtalent'} >MAR'X TOLLYWOOD TALENT</Link>
            </p>
            <p className='mange-enttab'>
              <Link to={'/ent-kollywoodtalent'} >MAR'X KOLLYWOOD TALENT</Link>
            </p>
            <p className='mange-enttab'>
              <Link to={'/ent-pollywoodtalent'} >MAR'X POLLYWOOD TALENT </Link>
            </p>
            <p className='mange-enttab'>
              <Link to={'/ent-hollywoodtalent'} >MAR'X HOLLYWOOD TALENT </Link>
            </p>
            <p className='mange-enttab'>
              <Link to={'/ent-performancetalent'} >MAR'X PERFORMING TALENT </Link>
            </p>
            <p className='mange-enttab'>
              <Link to={'/ent-internationaltalent'} >MAR'X INTERNATIONAL TALENT</Link>
            </p>
            <p className='mange-enttab'>
              <Link to={'/ent-concerttalent'} >MAR'X LIVE CONCERTS TALENT </Link>
            </p>
            <p className='mange-enttab'>
              <Link to={'/ent-nonexclusivetalent'} >MAR'X NON - EXCLUSIVE TALENT </Link>
            </p>
            <p className='mange-enttab'>
              <Link to={'/ent-televisionshoaptalent'} >MAR'X TELEVISION SOAP TALENT </Link>
            </p>
          </div>
          <div className="enttabpanels">
            <div>
              <div className="about-marx-ent">
                <h1>About Us</h1>
                <p>It feel's great Privilege to Introduce you  Mar'x Entertainment Worldwide. Pvt.Ltd.(The
                  Artist's Manager) as one of the Biggest Ventures of Mar'x Group of Companies.(M.g.c) in India, Mar'x Entertainment Worldwide Pvt.Ltd. is among the Largest Artist / Celebrity Alliance Company which is housing for over more than 14 years.with a Bust of More than 40,000/- Plus Artists & Celebrities from India & Globally Directly under its Wings, Mar'x Entertainment Worldwide which was established in Year 2010. with the aim of Providing Services in the field of  Entertainment Industry in India & Worldwide has already created a niche mark in the Indian Market to Deliver / Plan different Entertainment Subscriptions Packages to our clients for Corporate Events / Big - Fat  Indian Weddings / College Fest / Celebrity Brand Endorsements, CSR , Web - Series, Films / Movies Promotions, Music Video Albums, Live Concerts Tour, Private Birthday Parties, Gala Opening Ceremonies, Yacht Parties, Etc...!!
                </p>
              </div>

              <div className="about-marx-ent">
                <h1>Our Services</h1>
                <div className="servcedivptag">
                  <p>Mar'x Ent.ww. which was started with a small Artist Bank of  80 - 90 Artist in its kitty, now Manages Directly the largest Artists / Celebrity Bank in the entire History of Entertainment Industry, As we are looking after the Pipelines for  Hollywood Talent, Bollywood Talent, Tollywood Talent, Pollywood Talent, Kollywood Talent, Marathi Talent, Gujarati Talent, Odiya Talent, Playback Singer's / Performer, Sports Personalities of India / Asia, Indian & International Rock - Bands, Indian & International D,J's, Show Host / Emcee / Anchors, Stand - up Comedians, Classical Dance Performers, Key Note Motivational Speakers,  etc.. which has been divided into more than 107 Plus Categories of Artists under one Roof. Mar'x Ent.ww. is a team of hard Core working Professionals who believes in converting a small vision into a large Canvas where imagination has no limit.    </p>
                  <p>It's been a Pleasure to inform you that we have Started Expanding our Operation Wing's in another 30 Countries all over Worldwide with the Grand Launch of our Company Mobile App. for online Celebrities Bookings Coming Soon in the following Countries  Australia, Russia, Brazil, Canada, North / South America, United Kingdom, France, Japan, Italy, Turkey, Dubai, Singapore, Malaysia, Thailand, Sri lanka, South Africa, Germany, UAE, Netherlands, New Zealand, Portugal, to name it a few. </p>
                </div>
              </div>

              <div className="about-marx-ent">
                <h1>Who We Are</h1>
                <div>
                  <p>We At Mar'x Entertainment Worldwide. Pvt.Ltd. As The Biggest & Largest Entertainment Company In India & Worldwide Caters Its Services Into Hollywood Industry, Bollywood Industry, Tollywood Industry, Pollywood Industry, Kollywood Industry & Regional Film Industry. Our Specialized Hardworking Team Assure The Client With The Power Packed Entertainment Capsules For There Dream Wedding Event, Concerts, GALA Ceremonies, Corporate events, College fest, Birthday & anniversery parties etc. Throughout The Year. We Also Work On Tailer Made Concepts & Design As Per There Requirements For The Clients In India & Worldwide.</p>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </div>

      <RunningSlide title="PERFORMANCE Pics" images={PerformanceGalleryData}/>
      <RunningSlide title="FILMS PROMOTIONS & BRANDING" images={FilmPromotionData}/>
      <RunningSlide title="CELB'S FEATURED" images={ClebsFeatureData}/>
      <RunningSlide title="Our Work" images={EnttOurWorkData}/>

      {/* <SliderHome />
      <TechBlock />
      <InfluencerMarketing />
      <HomeServiceBlock />
      <BrandBlock />
      <HomeCtg />
      <HomeCityBlock />
      <YouTuberBlock />
      <InstagramBlock />
      <MicroInfluencer />
      <HowItsWorkBlock />
      <FreqAskQues /> */}
      <Footer />
    </>
  );
}
export default MarxEntertainmentHome;



