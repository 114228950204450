import { Box, Button, TextField } from "@mui/material"
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom"
import Logo from '../../assest/images/marxgrouplogo.jpeg'
import './marxGroupHeaderStyle.css'
import { useEffect, useState } from "react";
import audiomp3 from '../../assest/mp3/ULTRA TRANCE David Guetta Miami Ultra Music Festival 2014 (mp3cut.net).mp3'
import ReactAudioPlayer from "react-audio-player";
import { MdAccountCircle } from "react-icons/md";
import CloseIcon from '@mui/icons-material/Close';
import { CgProfile } from "react-icons/cg";
import { MdOutlineLogout } from "react-icons/md";
import GoogleLaguageTranslate from "../LaguageTranslate/GoogleLaguageTranslate"


const MarxGroupHeader = () => {
  const { t } = useTranslation();
  const [text, setText] = useState('');
  const [index, setIndex] = useState(0);
  const content = 'MAR’X Group Of Companies (M.G.C.)';
  const navigate = useNavigate()
  const [isProfileBoxOpen, setIsProfileBoxOpen] = useState(false);



  useEffect(() => {
    const intervalId = setInterval(() => {
      if (index < content.length) {
        setText((prevText) => prevText + content.charAt(index));
        setIndex((prevIndex) => prevIndex + 1);
      } else {
        clearInterval(intervalId);
      }
    }, 200); // Adjust the interval for the typing speed
    return () => clearInterval(intervalId);
  }, [index, content]);




  const toggleProfileBox = () => {
    console.log('Toggling profile box');
    setIsProfileBoxOpen(!isProfileBoxOpen);
  };

  const profileClose = () => {
    console.log('Closing profile box');
    setIsProfileBoxOpen(false);
  };

  return (
    <>
      <GoogleLaguageTranslate />

      <div className="marxgroup-topdivbar">
        <div className="marx-group-navlist">
          <ul>
            <li>
              <Link to={'/marx-group-login'}>
                <Button className="btnn1" size="small"> {t('header_key7')}</Button>
              </Link>
            </li>

            <li>
              <Button onClick={toggleProfileBox} className="btnn2" size="small"><MdAccountCircle className=" fs-2 text-primary bg-white rounded-5" /></Button>
              <Box className={isProfileBoxOpen ? 'boxvisible' : 'boxhidden'}>
                <Link className="myprofile" to={'/edit-profile'}><CgProfile className="me-1" />My Profile</Link>
                <Link className="logout-profile"><MdOutlineLogout className="me-1" />Logout</Link>
              </Box>
            </li>
          </ul>
        </div>
        <div className=" d-flex">
          <ReactAudioPlayer
            src={audiomp3}
            autoPlay
            loop
            controls
            className="marxgroup-audioplayer"
          />
        </div>
      </div>

      <Box className=''>
        <div className="marxgroup-navbar">
          <div className="marxgroup-nav-baar">
            <div className="logo">
              <Link to={'/marx-group'}>
                <img
                  className="imgglogo"
                  src={Logo}
                  alt="logo"
                />
              </Link>

            </div>
            <div className="companyprofilename">
              <h1>{text}</h1>
            </div>
          </div>
        </div>

      </Box>
    </>
  )
}

export default MarxGroupHeader
