import GalleryPhotos from "../../common/Gallery/Gallery"
import SecondPicsData from "../../data/marxEvent/SecondPicsData"
import MarxFoundationFooter from "../../common/MarxFoundation/FooterMarxFoundation"
import MarxFoundationHeader from "../../common/MarxFoundation/HeaderMarxFoundation"

const FoundationPressMediaCoverage = () => {
  return (
    <>
      <MarxFoundationHeader />
      <GalleryPhotos GalleryData={SecondPicsData} title={'Press & Media Coverage'} />
      <MarxFoundationFooter />

    </>
  )
}

export default FoundationPressMediaCoverage
