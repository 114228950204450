import QueryForm from "../../common/QueryForm/QueryForm"
import Sidebar from "../../common/SideAbleMenu/SideBar"
import WeddingFooter from "../../common/marxWedding/FooterMarxWedding"
import MarxWeddingHeader from "../../common/marxWedding/HeaderMarxWedding"

const WeddQuery = () => {
    return (
        <>
            <Sidebar />
            <MarxWeddingHeader />
            <QueryForm />
            <WeddingFooter />
        </>
    )
}

export default WeddQuery
