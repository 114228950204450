import { Button, TextField } from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoMdArrowDropdown } from "react-icons/io";
import { useGetAllDataQuery } from "../../Services/productApi";

const FilterSection = ({ searchTerm, setSearchTerm,filteredProducts }) => {
  const [filtrProduct, setFiltrProduct] = useState([]);

  const { t } = useTranslation();

  // Extract unique categories
  const uniqueCategories = useMemo(() => {
    const categoriesSet = new Set(filteredProducts?.map(item => item.category));
    return Array.from(categoriesSet);
  }, [filteredProducts]);

  console.log("filter by category", filteredProducts);

  const handleFilterByCategory = (category) => {
    const filteredByCategory = filteredProducts.filter(item => item.category === category);
    setFiltrProduct(filteredByCategory);
    console.log("filteredCategory------------", filteredByCategory);
  };

  return (
    <>
      <div className="">
        <div className="filterdiv">
          <h5>{t('ourInfluencer_filter')}</h5>
          <img src="https://www.influglue.com/public/frontend/images/filtericon.png" alt="img" />
        </div>

        <div className="city-div">
          <div className="clickdiv">
            <TextField
              size="small"
              placeholder="search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        <div className="city-div">
          <div className="clickdiv">
            <h6>{t('ourInfluencer_category')}</h6>
            <IoMdArrowDropdown />
          </div>
          <ul id="categoryList" class="category-list">
            {uniqueCategories && uniqueCategories?.map((category, index) => (
              <li
                key={index}
                style={{ cursor: 'pointer' }}
                onClick={() => handleFilterByCategory(category)}
              >
                {category}
              </li>
            ))}
          </ul>
        </div>

        <div className="city-div">
          <div className="clickdiv">
            <h6>{t('ourInfluencer_zone')}</h6>
            <IoMdArrowDropdown />
          </div>
          <ul id="zoneList" class="zone-list">

          </ul>
        </div>


        <div className="city-div">
          <div className="clickdiv">
            <h6>{t('ourInfluencer_price')}</h6>
            <IoMdArrowDropdown />
          </div>
          <ul id="priceList" class="price-list">

          </ul>
        </div>
        <div className="city-div">
          <div className="clickdiv">
            <h6>{t('ourInfluencer_byName')}</h6>
            <IoMdArrowDropdown />
          </div>
        </div>
        <div className="city-div">
          <div className="clickdiv">
            <h6>{t('ourInfluencer_byGender')}</h6>
            <IoMdArrowDropdown />
          </div>
          <ul id="genderList" class="gender-list">
            <li>Male</li>
            <li>Female</li>
          </ul>
        </div>
        <div className="city-div">
          <div className="clickdiv">
            <h6>{t('ourInfluencer_industry')} </h6>
            <IoMdArrowDropdown />
          </div>
          <ul id="indexList" class="index-list">

          </ul>
        </div>
        <div className="city-div">
          <div className="clickdiv">
            <h6>{t('ourInfluencer_country')}</h6>
            <IoMdArrowDropdown />
          </div>
          <ul id="countryList" class="country-list">

          </ul>
        </div>
        <div className="city-div">
          <div className="clickdiv">
            <h6>{t('ourInfluencer_byGrade')}</h6>
            <IoMdArrowDropdown />
          </div>
          <ul id="gradeList" class="grade-list">

          </ul>
        </div>
      </div>
    </>
  )
}

export default FilterSection
