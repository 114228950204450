import React, { useEffect } from 'react'
import Sidebar from '../../../common/SideAbleMenu/SideBar'
import Header from '../../../common/MarxEntertainment/header/Header'
import Footer from '../../../common/MarxEntertainment/footer/Footer'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import '../../../page/MarxEntertainment/PurchaseHistory/purchaseHistoryStyle.css'
import PurchaseHistoryData from '../../../data/MarxEntertainment/PurchaseHistoryData'
import { GoDotFill } from "react-icons/go";
import axios from 'axios'

const TransactionHistory = () => {

    const TransactionhistoryData = async () => {
        try {
          const API_TRANS = 'https://onlineparttimejobs.in/api/transactionPurchase/public';
          const responseData = await axios.get(API_TRANS);
          console.log("resultData", responseData);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
    
      useEffect(() => {
        TransactionhistoryData();
      }, []);
    return (
        <>
            <Sidebar />
            <Header />
            <Box className='purchase-history'>
                <Typography className='h3comp fw-bold'>Transaction History</Typography>
                <div className='tablediv'>
                    <TableContainer className='tablecontainr'>
                        <Table>
                            <TableHead className='tbleHead'>
                                <TableRow>
                                    <TableCell className="headingtable fw-bold">Merchant Id</TableCell>
                                    <TableCell className="headingtable fw-bold">Merchant Transaction Id</TableCell>
                                    <TableCell className="headingtable fw-bold">Trans Type</TableCell>
                                    <TableCell className="headingtable fw-bold">Date</TableCell>
                                    <TableCell className="headingtable fw-bold">Amount</TableCell>
                                    <TableCell className="headingtable fw-bold">Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {PurchaseHistoryData.map((purchase, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{purchase.id}</TableCell>
                                        <TableCell className='width220'>
                                            <div className='imgediv-history'>
                                                <p className=' fw-bold'>{purchase.productname}</p>
                                            </div>
                                        </TableCell>
                                        <TableCell className='width220'>
                                            <div className=' imgediv-history'>
                                                <p className=' fw-bold'>{purchase.customername}</p>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div>
                                                <p>{purchase.purchaseDate}</p>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div>
                                                <p>$ {purchase.price}</p>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div>
                                                <p><GoDotFill /> {purchase.status}</p>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Box>
            <Footer />
        </>
    )
}

export default TransactionHistory
