
import weddPhotoshootGallery1 from '../../assest/images/weddPhotograhyGallery/weddPhotoshootGallery1.jpeg'
import weddPhotoshootGallery2 from '../../assest/images/weddPhotograhyGallery/weddPhotoshootGallery2.jpeg'
import weddPhotoshootGallery3 from '../../assest/images/weddPhotograhyGallery/weddPhotoshootGallery3.jpeg'
import weddPhotoshootGallery4 from '../../assest/images/weddPhotograhyGallery/weddPhotoshootGallery4.jpeg'
import weddPhotoshootGallery5 from '../../assest/images/weddPhotograhyGallery/weddPhotoshootGallery5.jpeg'
import weddPhotoshootGallery6 from '../../assest/images/weddPhotograhyGallery/weddPhotoshootGallery6.jpeg'
import weddPhotoshootGallery7 from '../../assest/images/weddPhotograhyGallery/weddPhotoshootGallery7.jpeg'
import weddPhotoshootGallery8 from '../../assest/images/weddPhotograhyGallery/weddPhotoshootGallery8.jpeg'
import weddPhotoshootGallery9 from '../../assest/images/weddPhotograhyGallery/weddPhotoshootGallery9.jpeg'
import weddPhotoshootGallery10 from '../../assest/images/weddPhotograhyGallery/weddPhotoshootGallery10.jpeg'
import weddPhotoshootGallery11 from '../../assest/images/weddPhotograhyGallery/weddPhotoshootGallery11.jpeg'
import weddPhotoshootGallery12 from '../../assest/images/weddPhotograhyGallery/weddPhotoshootGallery12.jpeg'
import weddPhotoshootGallery13 from '../../assest/images/weddPhotograhyGallery/weddPhotoshootGallery13.jpeg'
import weddPhotoshootGallery14 from '../../assest/images/weddPhotograhyGallery/weddPhotoshootGallery14.jpeg'
import weddPhotoshootGallery15 from '../../assest/images/weddPhotograhyGallery/weddPhotoshootGallery15.jpeg'
import weddPhotoshootGallery16 from '../../assest/images/weddPhotograhyGallery/weddPhotoshootGallery16.jpeg'
import weddPhotoshootGallery17 from '../../assest/images/weddPhotograhyGallery/weddPhotoshootGallery17.jpeg'



const PhotoShootGalleryData = [
    {
        id: 1,
        imgSrc: weddPhotoshootGallery1,
    },
    {
        id: 2,
        imgSrc: weddPhotoshootGallery2,
    },
    {
        id: 3,
        imgSrc: weddPhotoshootGallery3,
    }, {
        id: 4,
        imgSrc: weddPhotoshootGallery4,
    }, {
        id: 5,
        imgSrc: weddPhotoshootGallery5,
    }, {
        id: 6,
        imgSrc: weddPhotoshootGallery6,
    }, {
        id: 7,
        imgSrc: weddPhotoshootGallery7,
    }, {
        id: 8,
        imgSrc: weddPhotoshootGallery8,
    }, {
        id: 9,
        imgSrc: weddPhotoshootGallery9,
    }, {
        id: 10,
        imgSrc: weddPhotoshootGallery10,
    }, {
        id: 11,
        imgSrc: weddPhotoshootGallery11,
    }, {
        id: 12,
        imgSrc: weddPhotoshootGallery12,
    }, {
        id: 13,
        imgSrc: weddPhotoshootGallery13,
    }, {
        id: 14,
        imgSrc: weddPhotoshootGallery14,
    }, {
        id: 15,
        imgSrc: weddPhotoshootGallery15,
    }, {
        id: 16,
        imgSrc: weddPhotoshootGallery16,
    }, {
        id: 17,
        imgSrc: weddPhotoshootGallery17,
    }
];
export default PhotoShootGalleryData;