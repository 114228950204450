import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { useState } from "react";
import "../../../styles/freqAskStyle.css";
import { useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Sidebar from "../../../common/SideAbleMenu/SideBar";
import MarxGroupFooter from "../../../common/marxGroup/MarxGroupFooter";
import MarxGroupHeader from "../../../common/marxGroup/MarxGroupHeader";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const FaqInfluenser = () => {
  const [expanded, setExpanded] = useState();
  const [data, setData] = useState();
  const params = useParams();
  console.log(params, params);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    const getfaqData = async () => {
      const reqData = await axios.get(
      `https://onlineparttimejobs.in/api/faqs/categ/${params.id}`,
        {
          withCredentials: true,
        }
      );
      console.log("abcd", reqData.data);
      setData(reqData.data);
    };
    getfaqData();
  }, []);

  return (
    <>
    <Sidebar/>
    <MarxGroupHeader/>
      <Box className="freAsk-box">
        <h1>Influencer FAQs</h1>
        <div className="accord-ques-div">
          {data?.map((faq) => (
            <Accordion
              key={faq._id}
              expanded={expanded === faq._id}
              onChange={handleChange(faq._id)}
              className="accordion-div"
            >
              <AccordionSummary
                aria-controls={`${faq._id}d-content`}
                id={`${faq._id}d-header`}
              >
                <Typography className="accord-ques">{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="accord-answr">{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </Box>
      <MarxGroupFooter/>

    </>
  );
};

export default FaqInfluenser;