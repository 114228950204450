import mrDevashish from '../../assest/images/mrDevashish.jpeg'
import mrDevashishfounder from '../../assest/images/mr-devashish founder.jpeg'
import mrAnand from '../../assest/images/mrAnand.jpeg'
import mrSanday from '../../assest/images/mrSanday.jpeg'
import { Box } from "@mui/material"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import marxacedmy from '../../assest/images/marxacedmy.jpeg'
import marxMedia from '../../assest/images/marxMediaLogo.jpeg'
import marxWedding from '../../assest/images/marxWeddinglogo.jpeg'
import marxEntLogo from '../../assest/images/marxentLogo.jpeg'
import marxFashionlogo from '../../assest/images/marxFashionCouturelogo.jpeg'
import marxFoundlogo from '../../assest/images/marxNgoLogo.jpeg'
import MarxGroupHeader from "../../common/marxGroup/MarxGroupHeader";
import { Link } from "react-router-dom";
import MarxGroupFooter from '../../common/marxGroup/MarxGroupFooter'
import '../../styles/marxGroupStyle.css'
import marxEventLogo from '../../assest/images/marxeventlogo.jpeg'
// import Sidebar from "../../common/SideAbleMenu/SideBar";
import realState from '../../assest/images/realStatelogo.jpeg'
import marxAgroFood from '../../assest/images/marxAgroFood.jpeg'
import marxMotionPic from '../../assest/images/marxMotionPic.jpeg'
import RunningSlide from '../../common/RunningSlideAllMarx/RunningSlide'
import { useEffect } from 'react'
import MarxGroupGalleryData from '../../data/MarxGroup/MarxGroupGalleryData'
// import DemoData from '../../data/DemoData'
// import imglogo from '../../assest/images/marxgrouplogo.jpeg'
// import { MarxGroupSidebarData } from '../../data/MarxGroup/MarxGroupSidebarData'
import MgOurWorkData from '../../data/MarxGroup/MgOurWorkData'




const MarxGroup = () => {
    useEffect(() => {
        document.title = "Mar’x Group Of Companies";
    }, []);

    // const slidesToShowNewsLaptop = 3;
    // const slidesToShowNewsMobile = 1;

    // // Set the initial slidesToShow based on the screen width
    // const SlidesToShowInItialise = window.innerWidth >= 768 ? slidesToShowNewsLaptop : slidesToShowNewsMobile;

    const latestNews = {
        dots: false,
        infinite: true,
        speed: 700,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        responsive: [
          {
            breakpoint: 450,
            settings: {
              slidesToShow: 1,
              centerMode: true,
              centerPadding: '1px',
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
              centerMode: true,
              centerPadding: '1px',
            },
          },
          {
            breakpoint: 9999, // A large value to ensure the last breakpoint always applies
            settings: {
              slidesToShow: 3,
            },
          }
        ],
      };

    const openNewTab = (url) => {
        window.open(url, '_blank');
    };

    return (
        <>
            {/* <Sidebar SidebarData={MarxGroupSidebarData} logo={imglogo} /> */}
            <MarxGroupHeader />
            <h4 className='m-0 bg-black text-white fw-bold text-center'> (Under Construction) </h4>
            <div className="main-div">
                <Box className="maindiv-box">
                    <div className="alltabs">
                        <p className='mange-tab' onClick={() => openNewTab('/marx-event-home')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxEventLogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>
                                Mar'x Events Pvt.Ltd. (<span data-text="The Fashion Consultants" className='running-text'> <span className=' text-black'>.</span></span>)
                            </Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/marx-media')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxMedia}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>Mar'x Media Pvt.Ltd.(<span data-text="The Leading pioneers" className='running-text'> <span className=' text-black'>.</span></span>)</Link>
                        </p>
                        <p className='mange-tab' onClick={() => openNewTab('/marx-foundation')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFoundlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'> Mar'x Foundation Charitable Trust (<span data-text="The N.G.O for Social Cause" className='running-text'> <span className=' text-black'>.</span></span>)</Link>
                        </p>
                        <p className='mange-tab' onClick={() => openNewTab('/marx-fashion-couture')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxFashionlogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'> Mar'x Fashion Couture Pvt.Ltd.(<span data-text="The Home for Fashionista" className='running-text'> <span className=' text-black'>.</span></span>)</Link>
                        </p>
                        <p className='mange-tab' onClick={() => openNewTab('/marx-agro-food')}>
                            <Link>
                                <img
                                    className=""
                                    src={marxAgroFood}
                                    alt="logo"
                                    style={{ width: '50px', height: '50px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'> Mar'x Agro Foods India Pvt.Ltd. (<span data-text="The Taste of Quality" className='running-text'> <span className=' text-black'>.</span></span>)</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/marx-wedding')}>
                            <Link>
                                <img
                                    className=""
                                    src={marxWedding}
                                    alt="logo"
                                    style={{ width: '50px', height: '50px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>Mar'x luxury Wedding India Pvt.Ltd. (<span data-text="The House of Royalty" className='running-text'> <span className=' text-black'>.</span></span>)</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/marx-real-estate')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={realState}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>Mar'x Real Estate & Developer Pvt.Ltd. (<span data-text="The Evolution of Luxury" className='running-text'> <span className=' text-black'>.</span></span>)</Link>
                        </p>

                        <p className='mange-tab' onClick={() => openNewTab('/marx-motion-picture')}>
                            <Link>
                                <img
                                    className=""
                                    src={marxMotionPic}
                                    alt="logo"
                                    style={{ width: '50px', height: '50px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'> Mar'x Motion & Pictures Alliance Pvt.Ltd. (<span data-text="The Pursuit of Excellence" className='running-text'> <span className=' text-black'>.</span></span>)</Link>
                        </p>
                        <p className='mange-tab' onClick={() => openNewTab('/marx-entertainment')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxEntLogo}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>Mar'x Entertainment Worldwide Pvt.Ltd.(<span data-text="The Artists Managers" className='running-text'> <span className=' text-black'>.</span></span>)</Link>
                        </p>
                        <p className='mange-tab' onClick={() => openNewTab('/marx-academy')}>
                            <Link>
                                <img
                                    className="me-2"
                                    src={marxacedmy}
                                    alt="logo"
                                    style={{ width: '40px', height: '40px', objectFit: 'contain' }}
                                />
                            </Link>
                            <Link className='atag'>Mar'x Academy Of Fashion & Pageants Pvt.Ltd. (<span data-text="The School of Fashion And Grooming" className='running-text'> <span className=' text-black'>.</span></span>)</Link>
                        </p>

                    </div>
                    <div className="tabpanels">
                        <div>
                            <div className="about-marx-entmnt">
                                <h1>Welcome</h1>
                                <p>The Name Of Company Mar'x Has Been Inspired From The Famous German Philospher, Political Economist, Historian, Political Theorist Sociologist, Communist & Revolutionary Karl Marx As He Ruled The Entire World In 18th Century With His Modified Ideas, Hard Work, Determination & Will Power To Achive Goals In Life. Which Inspires Me To Do Something In Life As An Inspiration.</p>
                            </div>
                            <div className="about-marx-entmnt">
                                <h1>About Us</h1>
                                <p>
                                    Mar'x Group of Companies (M.g.c) has Emerged As one of the Biggest & Powerful Conglomerate in India which has been diversified into different sectors such as : Mar'x Events.Pvt.Ltd.(The fashion Consultants) Mar'x Entertainment Worldwide.Pvt.Ltd.(The Artists Manager) Mar'x Luxury Wedding's India Pvt.Ltd.(The House of Royalty) Mar'x Media Pvt.Ltd.(The Leading Pioneer's) Mar'x Fashion Couture Pvt.Ltd.(Define your own Style Statement) Mar'x Academy of Fashion & Pageants Pvt.Ltd.(MAFP) Mar'x Agro Foods India Pvt.Ltd.(FMCG) Mar'x Foundation.Inc. (A Non - Profit Organization) Mar'x International Club Pvt.Ltd.(M.I.C) Mar'x Motion & Pictures Alliance Pvt.Ltd. with Few More Up - Coming Ventures on Pan - India & Worldwide Level Respectively.
                                </p>
                            </div>

                            <div className="about-marx-entmnt">
                                <h1>Why to Choose Us</h1>
                                <div>
                                    <p></p>
                                </div>
                            </div>

                            <div className="board-director" style={{ borderTop: '2px solid white' }}>
                                <h1>Board of Directors</h1>
                                <div className="each-director">
                                    <div className="">
                                        <img src={mrDevashish} alt="img" />
                                        <p className=" fw-bold fs-4">Mr. Devashish Bhandari</p>
                                        <p style={{ border: '1px solid white', padding: '10px' }}></p>
                                    </div>
                                    <div className="">
                                        <img src={mrAnand} alt="img" />
                                        <p className=" fw-bold fs-4">Mr. Anand Chaurdhary</p>
                                        <p style={{ border: '1px solid white', padding: '10px' }}></p>
                                    </div>
                                    <div className="">
                                        <img src={mrSanday} alt="img" />
                                        <p className=" fw-bold fs-4">Mr. Sanjay Dubey</p>
                                        <p style={{ border: '1px solid white', padding: '10px' }}></p>
                                    </div>
                                </div>
                            </div>
                            <div className="founder-chairmsg pt-4">
                                <h1>Founder / Chairperson</h1>
                                <p>(Message)</p>
                                <div className="foundermsg">
                                    <img src={mrDevashishfounder} alt="img" />
                                    <p className=" fw-bold fs-4">Mr. Devashish Bhandari</p>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </div>

            <RunningSlide title="GALLERY" images={MarxGroupGalleryData} />
            <RunningSlide title="Our work" images={MgOurWorkData} />
            {/* <RunningSlide title="Our work" images={DemoData} /> */}

            <div className="latestnews-sldddiv">
                <h1 className=' text-white fw-bold ms-3'>UP-COMING VENTURES</h1>
                <div className="divlatest">
                        <Slider className="slidrbox" {...latestNews}>
                            <div className="div-slide">
                                <img src={marxacedmy} alt="img" />
                            </div>
                            <div className="div-slide">
                                <img src={marxFashionlogo} alt="img" />
                            </div>
                            <div className="div-slide">
                                <img src={marxMotionPic} alt="img" />
                            </div>
                            <div className="div-slide">
                                <img src={marxAgroFood} alt="img" />
                            </div>
                           
                        </Slider>
                </div>
            </div>

            <MarxGroupFooter />
        </>
    )
}

export default MarxGroup
