import MGourwork1 from '../../assest/images/MGourwork/MGourwork1.jpeg'
import MGourwork2 from '../../assest/images/MGourwork/MGourwork2.jpeg'
import MGourwork3 from '../../assest/images/MGourwork/MGourwork3.jpeg'
import MGourwork4 from '../../assest/images/MGourwork/MGourwork4.jpeg'
import MGourwork5 from '../../assest/images/MGourwork/MGourwork5.jpeg'
import MGourwork6 from '../../assest/images/MGourwork/MGourwork6.jpeg'
import MGourwork7 from '../../assest/images/MGourwork/MGourwork7.jpeg'
import MGourwork8 from '../../assest/images/MGourwork/MGourwork8.jpeg'
import MGourwork9 from '../../assest/images/MGourwork/MGourwork9.jpeg'
import MGourwork10 from '../../assest/images/MGourwork/MGourwork10.jpeg'
import MGourwork11 from '../../assest/images/MGourwork/MGourwork11.jpeg'
import MGourwork12 from '../../assest/images/MGourwork/MGourwork12.jpeg'
import MGourwork13 from '../../assest/images/MGourwork/MGourwork13.jpeg'
import MGourwork14 from '../../assest/images/MGourwork/MGourwork14.jpeg'
import MGourwork15 from '../../assest/images/MGourwork/MGourwork15.jpeg'
import MGourwork16 from '../../assest/images/MGourwork/MGourwork16.jpeg'
import MGourwork17 from '../../assest/images/MGourwork/MGourwork17.jpeg'
import MGourwork18 from '../../assest/images/MGourwork/MGourwork18.jpeg'
import MGourwork19 from '../../assest/images/MGourwork/MGourwork19.jpeg'
import MGourwork20 from '../../assest/images/MGourwork/MGourwork20.jpeg'
import MGourwork21 from '../../assest/images/MGourwork/MGourwork21.jpeg'
import MGourwork22 from '../../assest/images/MGourwork/MGourwork22.jpeg'
import MGourwork23 from '../../assest/images/MGourwork/MGourwork23.jpeg'
import MGourwork24 from '../../assest/images/MGourwork/MGourwork24.jpeg'
import MGourwork25 from '../../assest/images/MGourwork/MGourwork25.jpeg'
import MGourwork26 from '../../assest/images/MGourwork/MGourwork26.jpeg'
import MGourwork27 from '../../assest/images/MGourwork/MGourwork27.jpeg'
import MGourwork28 from '../../assest/images/MGourwork/MGourwork28.jpeg'
import MGourwork29 from '../../assest/images/MGourwork/MGourwork29.jpeg'
import MGourwork30 from '../../assest/images/MGourwork/MGourwork30.jpeg'
import MGourwork31 from '../../assest/images/MGourwork/MGourwork31.jpeg'
import MGourwork32 from '../../assest/images/MGourwork/MGourwork32.jpeg'
import MGourwork33 from '../../assest/images/MGourwork/MGourwork33.jpeg'
import MGourwork34 from '../../assest/images/MGourwork/MGourwork34.jpeg'
import MGourwork35 from '../../assest/images/MGourwork/MGourwork35.jpeg'
import MGourwork36 from '../../assest/images/MGourwork/MGourwork36.jpeg'

const MgOurWorkData = [
    {
        id: 1,
        imgSrc: MGourwork1,
    },
    {
        id: 2,
        imgSrc: MGourwork2,
    },
    {
        id: 3,
        imgSrc: MGourwork3,
    }, {
        id: 4,
        imgSrc: MGourwork4,
    }, {
        id: 5,
        imgSrc: MGourwork5,
    }, {
        id: 6,
        imgSrc: MGourwork6,
    }, {
        id: 7,
        imgSrc: MGourwork7,
    }, {
        id: 8,
        imgSrc: MGourwork8,
    }, {
        id: 9,
        imgSrc: MGourwork9,
    }, {
        id: 10,
        imgSrc: MGourwork10,
    }, {
        id: 11,
        imgSrc: MGourwork11,
    }, {
        id: 12,
        imgSrc: MGourwork12,
    }, {
        id: 13,
        imgSrc: MGourwork13,
    }, {
        id: 14,
        imgSrc: MGourwork14,
    }, {
        id: 15,
        imgSrc: MGourwork15,
    }, {
        id: 16,
        imgSrc: MGourwork16,
    }, {
        id: 17,
        imgSrc: MGourwork17,
    }, {
        id: 18,
        imgSrc: MGourwork18,
    }, {
        id: 19,
        imgSrc: MGourwork19,
    }, {
        id: 20,
        imgSrc: MGourwork20,
    }, {
        id: 21,
        imgSrc: MGourwork21,
    }, {
        id: 22,
        imgSrc: MGourwork22,
    }, {
        id: 23,
        imgSrc: MGourwork23,
    }, {
        id: 24,
        imgSrc: MGourwork24,
    }, {
        id: 25,
        imgSrc: MGourwork25,
    }, {
        id: 26,
        imgSrc: MGourwork26,
    }, {
        id: 27,
        imgSrc: MGourwork27,
    }, {
        id: 28,
        imgSrc: MGourwork28,
    }, {
        id: 29,
        imgSrc: MGourwork29,
    }, {
        id: 30,
        imgSrc: MGourwork30,
    }, {
        id: 31,
        imgSrc: MGourwork31,
    }, {
        id: 32,
        imgSrc: MGourwork32,
    }, {
        id: 33,
        imgSrc: MGourwork33,
    }, {
        id: 34,
        imgSrc: MGourwork34,
    }, {
        id: 35,
        imgSrc: MGourwork35,
    }, {
        id: 36,
        imgSrc: MGourwork36,
    }
];
export default MgOurWorkData