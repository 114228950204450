import MarxMediaHeader from '../../common/MarxMedia/MarxMediaHeader/MarxMediaHeader'
import MediaFooter from '../../common/MarxMedia/MarxMediaFooter/MarxMediaFooter'
import GalleryPhotos from '../../common/Gallery/Gallery'
import SecondPicsData from '../../data/marxEvent/SecondPicsData'

const MediaShowsGallery = () => {
    return (
        <>
            <MarxMediaHeader />
            <GalleryPhotos  GalleryData={SecondPicsData} title={'Events Shows Gallery'}/>
            <MediaFooter />
        </>
    )
}

export default MediaShowsGallery
