import React, { useState } from "react";
import "./marxMediaFooterStyle.css";
import { Link } from "react-router-dom";
import { Box, Button, TextField } from "@mui/material";

function MediaFooter() {
  const [openSubscribe, setOpenSubscribe] = useState(false)
  const openNewTab = (url) => {
    window.open(url, '_blank');
  };

  const toggleSubscribeBox = () => {
    console.log('Toggling subscribe box');
    setOpenSubscribe(!openSubscribe);
  };

  return (
    <>

      <div className="footerdiv">
        <div className="topfooterblock">
          <div className="container mainwapdiv">
            <div className="aboutblock">
              <h2>About</h2>
              <ul>
                <li>
                  <Link to={'/our-service'} className="">
                    Services
                  </Link>
                </li>

                <li>
                  <Link className="" to="/our-client">
                    Our Clients
                  </Link>
                </li>
                <li onClick={() => openNewTab('/testimonials')}>
                  <Link className="">
                    Testimonials
                  </Link>
                </li>
                <li>
                  <Link className="" to="/contactus">
                    Contact us
                  </Link>
                </li>
                <li>
                  <Link className="" to="/jobs">
                    Jobs
                  </Link>
                </li>
                <li onClick={() => openNewTab('/faq')}>
                  <Link className="">
                    FAQs
                  </Link>
                </li>
                <li onClick={() => openNewTab('/blog')}>
                  <Link className="">
                    Blog
                  </Link>
                </li>
                <li>
                  <Link to='/site-map' className="">
                    SiteMap
                  </Link>
                </li>

                <li onClick={() => openNewTab('/our-team')}>
                  <Link className="">
                    Our Team
                  </Link>
                </li>

                <li>
                  <Link className="">
                    Advertise Here
                  </Link>
                </li>

                <li>
                  <Link onClick={toggleSubscribeBox} className="">
                    Subscribe letter
                  </Link>
                  <Box className={openSubscribe ? 'visiblesubscribe' : 'hiddensubscribe'}>
                    <div className="emailtoggle">
                      <TextField size="small" placeholder="email" />
                     <Button variant="contained">Submit</Button>
                    </div>
                  </Box>
                </li>

                <li>
                  <Link className="">
                    Buy Our Franchise
                  </Link>
                </li>
                <li>
                  <Link className="" to={'/media-query'}>
                    Query
                  </Link>
                </li>
              </ul>
            </div>

            <div className="secondblock">
              <h3>Contact Us</h3>
              <div className="block">
                <h2>WhatsApp Only (M)  : </h2>
                <a href="/"> +91 9968098241, 7210124700, 7210128900 </a>
              </div>
              <div className="block">
                <h2>Email : </h2>
                <a href="/">info@Marxgroupworldwide.com / Bookings@Marxgroupworldwide.com / Queries@Marxgroupworldwide.com</a>
              </div>
            </div>
          </div>
        </div>
        <div className="midfooterblock">
          <div className="block text-center">
            <h2 className="">Follow Us on</h2>
            <div className="innersocial">
              <Link className="" to="/">
                <img
                  className=""
                  src="https://www.influglue.com/public/frontend/images/facebook_btn.png?v=1688734414"
                  alt="facebook"
                  style={{ width: 32, height: 32 }}
                />
              </Link>
              <Link className="" to="/">
                <img
                  className=""
                  src="https://www.influglue.com/public/frontend/images/linkedin_btn.png?v=1688734414"
                  alt="linkedin"
                  style={{ width: 32, height: 32 }}
                />
              </Link>
              <Link className="" to="/">
                <img
                  className=""
                  src="https://www.influglue.com/public/frontend/images/instagram_btn.png?v=1688734414"
                  alt="instagram"
                  style={{ width: 32, height: 32 }}
                />
              </Link>
              <Link className="" to="/">
                <img
                  className=""
                  src="https://www.influglue.com/public/frontend/images/yahoo_btn.png?v=1688734414"
                  alt="youTube"
                  style={{ width: 32, height: 32 }}
                />
              </Link>
              <Link className="" to="/">
                <img
                  className=""
                  src="https://tse4.mm.bing.net/th?id=OIP.ztNyCL9PRiPmqTbS_ZcOeQHaEK&pid=Api&P=0&h=180"
                  alt="pintrest"
                  style={{ width: 32, height: 32, borderRadius: '50%', objectFit: 'cover' }}
                />
              </Link>
              <Link className="" to="/">
                <img
                  className=""
                  src="https://tse2.mm.bing.net/th?id=OIP.x3AF-5V66Exte3fMSeMm3gHaHZ&pid=Api&P=0&h=180"
                  alt="vk"
                  style={{ width: 32, height: 32, borderRadius: '50%', objectFit: 'cover' }}
                />
              </Link>
              <Link className="" to="/">
                <img
                  className=""
                  src="https://s.yimg.com/fz/api/res/1.2/KM9SnyJw8zrTQQC3gTcToQ--~C/YXBwaWQ9c3JjaGRkO2g9MTQ0O3E9ODA7dz0xNDQ-/https://s.yimg.com/zb/imgv1/efe0eda0-a368-3325-8975-d7ae7a783e4c/t_500x300"
                  alt="twitter"
                  style={{ width: 32, height: 32, borderRadius: '50%', objectFit: 'cover' }}
                />
              </Link>

            </div>
          </div>
        </div>
        <div className="fotrblack">
          <div className="copyrightblock">
            <div className="maincopyrightwapper">
              <div className="privacypolicy pt-3">
                <Link className="" to="/privacy-policy"> Privacy Policy </Link>
                <span>|</span>
                <Link to="/term-condition"> Terms & Conditions </Link>
              </div>
            </div>
            <div className="copy mt-2">
              Copyright © 2001 - 2024 Mar’x Group Of Companies . All Rights Reserved.
            </div>
            <div className="designdevelop d-flex justify-content-center mt-2">
              <p>Website Developed & Design By Abaris SoftTech Pvt.Ltd</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default MediaFooter;
