import Footer from "../../common/marxGroup/MarxGroupFooter"
import MarxGroupHeader from "../../common/marxGroup/MarxGroupHeader"
import franchiselogo from '../../assest/images/franchiselogo.jpeg'

const BuyOurFranchise = () => {
  return (
    <>
      <MarxGroupHeader/>
      <div className=" d-flex justify-content-center">
        <img style={{width:'200px'}} src={franchiselogo} alt="img" />
      </div>
      <Footer/>
      
    </>
  )
}

export default BuyOurFranchise
