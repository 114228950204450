import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './countriesSlideStyle.css'


const CountriesRunningSlide = ({ title, data }) => {
    // Define the number of slides to show for laptop and mobile
    const slidesToShowLaptop = 6;
    const slidesToShowMobile = 2;

    // Set the initial slidesToShow based on the screen width
    const initialSlidesToShow = window.innerWidth >= 768 ? slidesToShowLaptop : slidesToShowMobile;

    const settingGallery = {
        infinite: true,
        slidesToShow: initialSlidesToShow,
        swipeToSlide: true,
        arrows: true,
        autoplay: true,
        speed: 700,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: '600px', // adjust this breakpoint according to your design
                settings: {
                    slidesToShow: slidesToShowMobile,
                },
            },
            {
                breakpoint: '768px', // adjust this breakpoint according to your design
                settings: {
                    slidesToShow: slidesToShowLaptop,
                },
            }
            // Add more breakpoints as needed
        ],
    };

    return (
        <>
            <div className="contact-sldddiv">
                <h1 className='fw-bold ms-3'>{title}</h1>
                <div className="divvb-contact">
                        <Slider className="countryslider-box" {...settingGallery}>
                            {data?.map((item, index) => (
                                <div className="img-countrybox" key={index}>
                                    <img src={item.imgSrc} alt='img' />
                                    <p>{item.name}</p>
                                </div>
                            ))}
                        </Slider>
                </div>
            </div>
        </>
    )
}

export default CountriesRunningSlide
