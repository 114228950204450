
import IndModelMaleData from "../../../data/marxEvent/ourModelbank/indian/indianModelMaleData"
import WeddingFooter from "../../../common/marxWedding/FooterMarxWedding"
import MarxWeddingHeader from "../../../common/marxWedding/HeaderMarxWedding"
import WithBioWithoutRunningGallery from "../../../common/GalleryWitthBioAllMaxWithoutRunningGallery/WithBioWithoutRunningGallery"

const WeddingPackages = () => {
  return (
    <>
        <MarxWeddingHeader />
        <WithBioWithoutRunningGallery ModelData={IndModelMaleData}  heading={"Mar'x Wedding Packages"} detailpg={'wedd-packages'}/>
        <WeddingFooter />

    </>
  )
}

export default WeddingPackages
