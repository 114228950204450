import React, { useEffect, useState } from "react";
import "./HomeService.css";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";


function HomeServiceBlock() {
  const [industry, setImdustry] = useState([])
  const { t } = useTranslation();

  useEffect(() => {
    const getIndustryData = async () => {
      const reqData = await axios.get("https://onlineparttimejobs.in/api/industry/public", {
        withCredentials: true
      });
      console.log('industry', reqData.data)
      setImdustry(reqData.data)
    }
    getIndustryData();
  }, []);
  return (
    <>
      <div className="homeserviceBlock">
        <div className="mainHomeservice">
          <div className="serviceblock">
            <h2>Our Services</h2>
            <p>
            {t('service_paragraph')}
            </p>
            <div className="inner-service-block">
              {industry?.map((item) => {
                return (
                  <div className="block" key={item.level}>
                    <Link to={`${item._id}`}>
                      <h6>{item.name}</h6>
                    </Link>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
}
export default HomeServiceBlock;
