import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import '../../styles/marxRealEstateHomeStyle.css'
import MarxRealEstateHeader from "../../common/MarxRealEstate/HeaderMarxRealEstate";
import MarxRealEstateFooter from "../../common/MarxRealEstate/FooterMarxRealEstate";
import RunningSlide from "../../common/RunningSlideAllMarx/RunningSlide";
import { useEffect } from "react";
import TopSliderAllMax from "../../common/TopSliderAllMarx/TopSliderAllMarx";
import AllDemoData from "../../data/AllDemoData";
import { Dropdown } from "react-bootstrap";



const MarxRealEstateHome = () => {
    useEffect(() => {
        document.title ="Mar'x Real Estate & Developer Pvt.Ltd.";
      }, []);
  
    return (
        <>
            <MarxRealEstateHeader />
            <TopSliderAllMax title={"Gallery"} images={AllDemoData} />

            <div className="">
                <div className="event-navbrlists">
                    <ul>
                        <li>
                            <Link to="/marx-real-estate">Home</Link>
                        </li>
                       
                       
                        <li>
                            <Link to="/realestate-pricefiltersearch">Price Filter Search</Link>
                        </li>
                        <li>
                            <Link to="/realestate-gallerypics">Gallery Pics</Link>
                        </li>
                       
                        <li>
                            <Dropdown id="custom-dropdown">
                                <Dropdown.Toggle variant="" id="dropdown-basic">Services ( Sell / Purchase / Rent )</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item as={Link} to="/realestate-luxurypenthouse">Luxury Penthouse</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/realestate-luxuryvillas">Luxury Villas</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/realestate-luxuryresorts">Luxury Resorts</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/realestate-luxuryfarmhouse">Luxury Farmhouse</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/realestate-luxury3starhotel">Luxury 3 Star Hotel</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/realestate-luxury5starhotel">Luxury 5 Star Hotel</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/realestate-luxury7starhotel">Luxury 7 Star Hotel</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/realestate-luxuryapartments">Luxury Apartments</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/realestate-agriculturelands">Agriculture Lands</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/realestate-commerciallands">Commercial Lands</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/realestate-warehouse">Warehouse</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/realestate-industriallands">Industrial Lands</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/realestate-universitylands">University Lands</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/realestate-schoollands">School Lands</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/realestate-collegelands">College Lands</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/realestate-builderfloors">Builder Floors</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/realestate-privatesectorlands">Private Sector Lands</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/realestate-governmentsectorlands">Government Sector Lands</Dropdown.Item>

                                </Dropdown.Menu>
                            </Dropdown>
                        </li>

                        <li>
                            <Link to="/realestate-propertyvideoreel">Property Video Reel</Link>
                        </li>
                       
                        <li>
                            <Link to="/realestate-propertysearch">Property Search</Link>
                        </li>
                        <li>
                            <Dropdown id="custom-dropdown">
                                <Dropdown.Toggle variant="" id="dropdown-basic">Registration Form</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item as={Link} to="/realestate-clientform">Client Registration Form</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/realestate-venderform">Vender Registration Form</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/realestate-builderform">Builder Registration Form</Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/realestate-contractorform">Contractor Registration Form</Dropdown.Item>

                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                       
                    </ul>
                </div>
            </div>

            <div className="main-realestatediv">
                <Box className="mainrealestatediv-box">
                    <div className="allrealestatetabs">
                        <h5 className=" text-center fw-bold mb-4"> INDEX </h5>
                        <p className='mange-realestatetab'>
                            <Link to={'/'} >MARS </Link>
                        </p>
                    </div>
                    <div className="realestatetabpanels">
                        <div>
                            <div className="about-marx-realestate">
                                <h1>About Us</h1>
                                <p></p>
                            </div>

                            <div className="about-marx-realestate">
                                <h1>What We Do</h1>
                                <div>
                                    <p></p>
                                </div>
                            </div>

                            <div className="about-marx-realestate">
                                <h1>Our Vision</h1>
                                <div>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Box>
            </div>

            <RunningSlide title="Builder Tie-ups" images={AllDemoData}/>

            <MarxRealEstateFooter />
        </>
    )
}

export default MarxRealEstateHome
