import MarxEventFooter from "../../../common/Marx_Event/MarxEventFooter/MarxEventFooter"
import MarxEventHeader from "../../../common/Marx_Event/MarxEventHeader/MarxEventHeader"
import GalleryPhotos from "../../../common/Gallery/Gallery";
import SecondPicsData from "../../../data/marxEvent/SecondPicsData";


const Album2Pics = () => {

  return (
    <>
      <MarxEventHeader />
      <GalleryPhotos GalleryData={SecondPicsData} title={'Album2 Photo Gallery'} />
      <MarxEventFooter />
    </>
  )
}

export default Album2Pics
