import MarxEventFooter from "../../common/Marx_Event/MarxEventFooter/MarxEventFooter"
import MarxEventHeader from "../../common/Marx_Event/MarxEventHeader/MarxEventHeader"
import QueryForm from "../../common/QueryForm/QueryForm"

const EventsQuery = () => {
    return (
        <>
            <MarxEventHeader />
            <QueryForm />
            <MarxEventFooter />
        </>
    )
}

export default EventsQuery
