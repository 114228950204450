import React from "react";
import "./AbouUs.css";
import Header from "../../common/MarxEntertainment/header/Header";
import Footer from "../../common/MarxEntertainment/footer/Footer";
function AboutUs() {
  return (
    <>
      <Header />
      <div className="aboutus">
        <div className="breadcurmb">
          <div className="mainwapperabout">
            <a href="/">Home</a>
            <span>»</span>
            About Us
          </div>
        </div>
        <div className="termblock">
          <div className="mainwapperterm">
            <h1>Welcome</h1>
            <h5>
              THE NAME OF COMPANY MAR'X HAS BEEN INSPIRED FROM THE
              FAMOUS GERMAN PHILOSPHER, POLITICAL ECONOMIST, HISTORIAN, POLITICAL THEORIST SOCIOLOGIST, COMMUNIST & REVOLUTIONARY   KARL MARX AS HE RULED THE ENTIRE WORLD IN 18th CENTURY WITH HIS   MODIFIED IDEAS, HARD WORK, DETERMINATION & WILL POWER TO ACHIVE GOALS IN LIFE. WHICH INSPIRES ME TO DO SOMETHING IN LIFE AS AN
              INSPIRATION.
            </h5>
          </div>
        </div>
        <div className="termblock">
          <div className="mainwapperterm">
            <h1>About Us</h1>
            <p>
              It feel's great Privilege to Introduce you  <b>Mar'x Entertainment Worldwide. Pvt.Ltd.</b> (The
              Artist's Manager) as one of the Biggest Ventures of Mar'x Group of Companies.(M.g.c) in India, Mar'x Entertainment Worldwide Pvt.Ltd. is among the Largest Artist / Celebrity Alliance Company which is housing for over more than <b>12 years</b> .with a Bust of More than <b> 40,000/-</b> Plus Artists & Celebrities from India & Globally Directly under its Wings, Mar'x Entertainment Worldwide which was established in  <b>Year 2010</b>. with the aim of Providing Services in the field of  Entertainment Industry in India & Worldwide has already created a niche mark in the Indian Market to Deliver / Plan different Entertainment Packages to our clients for Corporate Events / Big - Fat  Indian Weddings / College Fest / Celebrity Brand Endorsements, CSR , Web - Series, Films / Movies Promotions, Music Video Albums, Live Concerts Tour, Private Birthday Parties, Gala Opening Ceremonies, Yacht Parties, Etc...!!
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default AboutUs;
