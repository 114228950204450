import { useEffect, useState } from "react"
import Footer from "../common/MarxEntertainment/footer/Footer"
import Header from "../common/MarxEntertainment/header/Header"
import CreatorCommunitiesData from "../data/CreaterCommunitiesData"
import '../styles/companyIndexStyle.css'
import axios from "axios"
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import slide1 from '../assest/images/slid1.jpg'
import slide2 from '../assest/images/sld2.jpg'
import slide3 from '../assest/images/sld3.jpg'
import { Link } from "react-router-dom"

const CompanyIndex = () => {
  const [index, setIndex] = useState([])

  useEffect(() => {
    const getIndexData = async () => {
        const reqIndexData = await axios.get("https://onlineparttimejobs.in/api/influenceIndexGroup/public", {
            withCredentials: true
        });
        console.log(reqIndexData.data)
        setIndex(reqIndexData.data)
    }
    getIndexData();
}, []);
 
  return (
    <>
      <Header />

      <div>
        <Swiper
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}

          autoplay={{
            delay: 2500, 
            disableOnInteraction: false,
          }}
          modules={[Pagination, Autoplay]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div className="imgdiv">
              <img
                className="img-itm"
                src={slide1}
                alt="imgimf"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>

            <div className="imgdiv">
              <img
                className="img-itm"
                src={slide2}
                alt="imgimf"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="imgdiv">
              <img
                className="img-itm"
                src={slide3}
                alt="imgimf"
              />
            </div>
          </SwiperSlide>
        </Swiper>

      </div>

      <div className="indexBlock">
        <div className="mainindex">
          <div className="mainindexblock">
            <h2>Our Index</h2>
            <div className="inner-index-block">
              {index?.map((item) => {
                return (
                  <div className="block">
                    <Link href="/">
                      <h6>{item.name}</h6>
                    </Link>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default CompanyIndex
