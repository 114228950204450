
import React from 'react'
import MarxMediaHeader from '../../common/MarxMedia/MarxMediaHeader/MarxMediaHeader'
import MediaFooter from '../../common/MarxMedia/MarxMediaFooter/MarxMediaFooter'
import GalleryPhotos from '../../common/Gallery/Gallery'
import SecondPicsData from '../../data/marxEvent/SecondPicsData'

const MediaPrint = () => {
    return (
        <>
            <MarxMediaHeader />
            <GalleryPhotos GalleryData={SecondPicsData} title={'Print Media'} />
            <MediaFooter />
        </>
    )
}

export default MediaPrint
