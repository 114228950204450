import GalleryPhotos from "../../common/Gallery/Gallery"
import Footer from "../../common/MarxEntertainment/footer/Footer"
import Header from "../../common/MarxEntertainment/header/Header"
import Sidebar from "../../common/SideAbleMenu/SideBar"
import EnttHallOfFameData from "../../data/MarxEntertainment/EnttHallOfFameData"
import SecondPicsData from "../../data/marxEvent/SecondPicsData"

const EntertainmentHallofFame = () => {
    return (
        <>
            <Sidebar />
            <Header />
            <GalleryPhotos GalleryData={EnttHallOfFameData} title={'Hall Of Fame'} />
            <Footer />
        </>
    )
}

export default EntertainmentHallofFame
