import WithBioWithoutRunningGallery from "../../../common/GalleryWitthBioAllMaxWithoutRunningGallery/WithBioWithoutRunningGallery"
import MarxEventFooter from "../../../common/Marx_Event/MarxEventFooter/MarxEventFooter"
import MarxEventHeader from "../../../common/Marx_Event/MarxEventHeader/MarxEventHeader"
import IndModelMaleData from "../../../data/marxEvent/ourModelbank/indian/indianModelMaleData"

const MarxLifeCoachExpert = () => {
  return (
    <>
        <MarxEventHeader />
        <WithBioWithoutRunningGallery ModelData={IndModelMaleData}  heading={"Mar'x Life Coach Expert"} detailpg={'marxlifecoach-expert'}/>
        <MarxEventFooter />

    </>
  )
}

export default MarxLifeCoachExpert
