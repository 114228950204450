import { useParams } from "react-router-dom"
import IndModelMaleData from "../../../data/marxEvent/ourModelbank/indian/indianModelMaleData"
import WeddingFooter from "../../../common/marxWedding/FooterMarxWedding"
import MarxWeddingHeader from "../../../common/marxWedding/HeaderMarxWedding"
import WithBioWithoutRunningGalleryDetailpg from "../../../common/GalleryWitthBioAllMaxWithoutRunningGallery/WithBioWithoutRunningGalleryDetailpg"

const WeddingPreWeddingPackagesDetailpg = () => {

    let { id } = useParams();
    console.log(id)

    const singleData = IndModelMaleData.find((item) => item.id == id)
    console.log(singleData)
   


    return (
        <>
            <MarxWeddingHeader />
            <WithBioWithoutRunningGalleryDetailpg  allData={singleData} />
            <WeddingFooter />

        </>
    )
}

export default WeddingPreWeddingPackagesDetailpg
