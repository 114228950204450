import stageperformance7 from '../../assest/images/entt.StagePerformance/stageperformance7.jpeg'
import stageperformance8 from '../../assest/images/entt.StagePerformance/stageperformance8.jpeg'
import stageperformance9 from '../../assest/images/entt.StagePerformance/stageperformance9.jpeg'
import stageperformance10 from '../../assest/images/entt.StagePerformance/stageperformance10.jpeg'
import stageperformance11 from '../../assest/images/entt.StagePerformance/stageperformance11.jpeg'
import stageperformance12 from '../../assest/images/entt.StagePerformance/stageperformance12.jpeg'
import stageperformance13 from '../../assest/images/entt.StagePerformance/stageperformance13.jpeg'
import stageperformance14 from '../../assest/images/entt.StagePerformance/stageperformance14.jpeg'
import stageperformance15 from '../../assest/images/entt.StagePerformance/stageperformance15.jpeg'
import stageperformance16 from '../../assest/images/entt.StagePerformance/stageperformance16.jpeg'
import stageperformance17 from '../../assest/images/entt.StagePerformance/stageperformance17.jpeg'
import stageperformance18 from '../../assest/images/entt.StagePerformance/stageperformance18.jpeg'
import stageperformance19 from '../../assest/images/entt.StagePerformance/stageperformance19.jpeg'
import stageperformance20 from '../../assest/images/entt.StagePerformance/stageperformance20.jpeg'
import stageperformance21 from '../../assest/images/entt.StagePerformance/stageperformance21.jpeg'
import stageperformance22 from '../../assest/images/entt.StagePerformance/stageperformance22.jpeg'


const PerformanceGalleryData = [
    {
        id: 1,
        imgSrc: stageperformance7,
    },
    {
        id: 2,
        imgSrc: stageperformance8,
    },
    {
        id: 3,
        imgSrc: stageperformance9,
    }, {
        id: 4,
        imgSrc: stageperformance10,
    }, {
        id: 5,
        imgSrc: stageperformance11,
    }, {
        id: 6,
        imgSrc:stageperformance12,
    }, {
        id: 7,
        imgSrc:stageperformance13,
    }, {
        id: 8,
        imgSrc:stageperformance14,
    }, {
        id: 9,
        imgSrc:stageperformance15,
    }, {
        id: 10,
        imgSrc:stageperformance16,
    }, {
        id: 11,
        imgSrc: stageperformance17,
    }, {
        id: 12,
        imgSrc: stageperformance18,
    }, {
        id: 13,
        imgSrc: stageperformance19,
    }, {
        id: 14,
        imgSrc: stageperformance20,
    }, {
        id: 15,
        imgSrc: stageperformance21,
    }, {
        id: 16,
        imgSrc: stageperformance22,
    }
];
export default PerformanceGalleryData