import { Box, Button, Checkbox, FormControlLabel, TextField } from "@mui/material"
import Footer from "../common/MarxEntertainment/footer/Footer"
import Header from "../common/MarxEntertainment/header/Header"
import '../styles/clientRegistFormStyle.css'
import { Link } from "react-router-dom"

const ClientRegistForm = () => {
    return (
        <>
            <Header />
            <Box className='client-detail-box'>
                <div>
                    <div className="client-detail-text">
                        <div className="company-name">
                            <h1><span className="span1">MAR’X</span> <span className="span2">ENTERTAINMENT</span> <span className="span3"> WORLDWIDE</span> <span className="span4">PVT. LTD.</span></h1>
                        </div>
                        <h4>CLIENT  REGISTRATION FORM. </h4>
                    </div>
                    <div className="client-input-area-div">
                        <div>
                            <TextField
                                name="firstname"
                                placeholder="First Name " fullWidth />
                        </div>
                        <div>
                            <TextField
                                name="lastname"
                                placeholder="Last Name " fullWidth />
                        </div>
                        <div>
                            <TextField
                                name="CompanyName"
                                placeholder="Company / Organization Name" fullWidth />
                        </div>
                        <div>
                            <TextField
                                name="OfficeAddress"
                                placeholder="Office Address" fullWidth />
                        </div>

                        <div>
                            <Box className=" d-flex ms-2">
                                <FormControlLabel control={<Checkbox />} label="Public Co. " />
                                <FormControlLabel control={<Checkbox />} label="Private Co." />
                            </Box>
                        </div>
                        <div>
                            <Box className=" d-flex ms-2">
                                <FormControlLabel control={<Checkbox />} label="Corporate Co. " />
                                <FormControlLabel control={<Checkbox />} label="Non – Corporate Co." />
                            </Box>
                        </div>

                        <div>
                            <TextField
                                name="NatureofBusiness"
                                placeholder="Nature  of Business" fullWidth />
                        </div>
                        <div>
                            <TextField
                                name="Employeesworking"
                                placeholder="No. of Employees working" fullWidth />
                        </div>
                        <div>
                            <TextField
                                name="Designation"
                                placeholder="Designation" fullWidth />
                        </div>
                        <div>
                            <TextField
                                name="CompanyGSTNo"
                                placeholder="Company G.S.T No.(Mandatory)" fullWidth />
                        </div>
                        <div>
                            <TextField
                                name="IndividualPanNo"
                                placeholder="Individual Pan – No.(Mandatory)" fullWidth />
                        </div>
                        <div>
                            <TextField
                                name="ProofDocumentName"
                                placeholder="Attached Company Id. Proof Document Name" fullWidth />
                        </div>
                        <div>
                            <TextField
                                name="City"
                                placeholder="City" fullWidth />
                        </div>
                        <div>
                            <TextField
                                name="State"
                                placeholder="State" fullWidth />
                        </div>
                        <div>
                            <TextField
                                name="PinCode"
                                placeholder="Pin Code" fullWidth />
                        </div>
                        <div>
                            <TextField
                                name="mobile"
                                placeholder="Mobile No." fullWidth
                            />
                        </div>
                        <div>
                            <TextField
                                name="WhatsappNo"
                                placeholder="Whatsapp No." fullWidth />
                        </div>
                        <div>
                            <TextField
                                name="OfficeLandlineNo"
                                placeholder="Office Landline No." fullWidth />
                        </div>
                        <div>
                            <TextField
                                name="OfficialEmailId"
                                placeholder="Official Email Id" fullWidth />
                        </div>
                        <div>
                            <TextField
                                name="Officialwebsite"
                                placeholder="Official website" fullWidth />
                        </div>


                        <div className="client-btn-div">
                            <Button type="button">Submit</Button>
                        </div>
                    </div>
                </div>
            </Box>
            <Box>
                <div className="notes-div">
                    <h4>Note :</h4>
                    <ul>
                        <li>All Above Required Information Should be given by true Consent by the Client for our Data Records. (Mandatory).</li>
                        <li>All Clients Registrations for Silver Category Membership are valid for <b>3 Months</b> only from the issue date of Meww Registration ID.</li>
                        <li>All Clients are Requested to Mention there Meww. Id. for future Artist Booking’s / Queries / Fee Price / Availability etc..! .in India / worldwide.</li>
                        <li>All <b>Corporate Clients</b> are Requested to submit there Company Name Registration Copy / GST Registration No. / PAN No. / TIN no. / LLC No. / TAN No. / VAT No. / DSC No. / DIN No. / INC Copy as Company ID. Proof Document. (Any 1)</li>
                        <li>All <b>Non – Corporate Clients</b> are Requested to submit there Company Name Registration Copy / GST Registration No. / PAN No. / TIN no. / LLC No. / TAN No. / VAT No. / DSC No. / DIN No. / INC Copy as Company ID. Proof Document. (Any 1)</li>
                        <li>All Clients are Requested to send there Registration forms by Scan Copy at Meww. Official email Id. Only. Within 7 working Days. <Link>Marxentertainmentworldwide@gmail.com </Link> / <Link>Info@Marxgroupofcompanies.in</Link></li>
                        <li>All Clients are Requested to submit there Company Id. Proof Document Scan Copy with there signatures & official Co.Stamp.</li>
                        <li>All <b>Corporate Clients</b> Registration fee Price is INR. : <b>Rs.5,000/-</b> in India & U.S : <b>$ 77/-</b> in worldwide Countries / Continents. Including ( U.S, Canada, Dubai, Europe, Australia, Bangkok, Thailand, Singapore, Malaysia,  U,K, South Africa ) etc....! to be deposited at  Mar'x Ent. ww  Official Bank A/c  by only  Online Bank Transfers / RTGS / Global Money Transfers. </li>
                        <li>All <b>Non – Corporate Clients</b> Registration fee Price is INR. <b>Rs.3,000/-</b> in India & U.S: <b>$ 46/-</b> in worldwide Countries / Continents. Including ( U.S, Canada, Dubai, Europe, Australia, Bangkok, Thailand, Singapore, Malaysia,  U,K, South Africa ) etc....! to be deposited at Mar'x Ent. ww  Official Bank A/c  by only  Online Bank Transfers / RTGS / Global Money Transfers.</li>
                        <li>All Clients are Requested to send us the scan copy of the Bank Payment / Transfers at our official email id :  <Link>Marxentertainmentworldwide@gmail.com </Link> / <Link>Info@Marxgroupofcompanies.in</Link>. </li>
                        <li>It is understood that w.e.f 1st Dec. 2016. Mar’x Ent.ww. Management has made this Client Registration as a Mandatory Conduct for further Professional dealings in India / worldwide.</li>
                        <li>All Clients are allowed for Max. <b>24</b> different Artist / Celebrity Names fee Price / Dates Availability / Travel & Hospitality Query details in <b>3 Months @ 8 Per Month / 4 Queries</b> Within 15 Days as a limit in <b>Silver Membership</b> Category. </li>
                        <li>All Clients are Requested to Apply <b>N/A</b> for Blank fields leftover in the form. </li>
                        <li>Min. Artist Fee / Booking Starts with a price from INR. Rs,1.5,Lakhs for Corporate Shows & Events & Rs.3,Lakhs to Rs.50,Lakhs & Above for Pre - Wedding & Weddings / Destination Weddings in India / Worldwide for all the Mentioned Artists w.e.f 1st Dec. 2016.</li>
                        <li>Queries Related to Artist or Celebrity Booking's / Availability / Fee Price / etc...!. will be Replied within 72 Hours or 3 official working Days. (Sunday & National Holidays not Included)</li>
                        <li><b>Categories for Corporate Clients Includes :</b> Companies Related to ( Events, Corporate / Production House, Entertainment, BPO , MNC ,Wedding Planners, Hospitality, Tour & Travel, Public / Private Banks, Infrastructure, Real Estate, FMCG / Consumer Brands, etc....!</li>
                        <li><b>Categories for Non - Corporate Clients Includes :</b> Outlets Related to ( Clothing Stores,       Food & Beverages, Designer Showrooms, Automobile Showrooms, Bar & Lounge, Jewellery Showrooms, Consumer Product Showrooms, Schools & College,  etc...!</li>
                        <li>The Above Mentioned Client Registration fees can be Revised again without any prior Notice by the Mar'x Ent. ww Management.</li>
                        <li>The Client Meww. Registration fee Price for Silver Category Membership is <b>Refundable</b> after the Deal gets Matured on Receiving the full Amount of Artist within 7 official working Days.</li>
                        <li>All Worldwide Clients Registration Fee Price will be charged in American $ only as a common Currency. </li>
                        <li>If any Dispute Arises on Account of the Management the same shall be subjected to the Jurisdiction of the Court at Haryana.- India.</li>
                        <li>Meww Management will Charge 10% of the final deal Amount of the Artist / Celebrity from both the Parties Individually as there Show Management Service fee for show Bookings in India.</li>
                        <li>Meww Management will Require a Purchase order of the Total Amount from the Client in which the deal has been finalized Including GST & other Taxes.</li>
                        <li>All Payments for the Artists / Celebrities are to be made 50% Advance on Bookings in Favour of Mar’x Group of companies. Official Bank Account & Rest 50% on Artists / Celebrities Arrival on Airport or 1 day before the event date through RTGS Bank Transfers / Draft / Global Money Transfers.(For Events in any other Countries Procedure will be the same) <b>Cheque Payments will not be Accepted.</b></li>
                        <li>Meww Management will Sign a Legal M.O.U in Court between both the Parties with Term’s & Conditions in which the deal has been finalized as a mandatory Conduct for a better Transparent deal.</li>
                    </ul>
                    <div>
                        <p>Date : </p>
                        <p>(Signature with Official Co. Stamp)  :  </p>
                    </div>
                </div>
                <div className="notes-div">
                    <p>NOTE : This Client Registration form is protected under Copyright Act and cannot be Re - produced anywhere in any form / Manner in print or website, without the Consent & written permission from Mar’x Entertainment Worldwide. / Mar’x Group of Companies. Management, as they are strictly against law . We have showcase them to you because they are part of our work.</p>
                    <p>For Any Work Related queries / Booking's in India Please Mail us at : Info@Marxgroupofcompanies.in/ Marxentertainmentworldwide@gmail.com</p>
                    <p>For queries in Asia / Europe / Middle - East, Canada, U.S / Worldwide Countries Mail us at  : Worldwide.Meww@Marxgroupofcompanies.in </p>
                    <p>Please Contact us at Mentioned Official / Whatsapp No.s. on our website between 11:00 am to 7:00 pm. IST / Mon to Sat. (National Holidays & Sundays Closed. </p>
                </div>
                <div className="notes-div">
                    <p>Regd. office : No.12, UGF. V.Complex Building M.G Road - Gurgaon Haryana – 122001. Email.id::Marxentertainmentworldwide@gmail.com/.Info@Marxgroupofcompanies.in./ .Worldwide.Meww@Marxgroupofcompanies.in.Off.(M):9968098241(Whatsapp),7210124700, 7210128900. Website : www.Marxgroupofcompanies.in</p>
                </div>
            </Box>
            <Footer />
        </>
    )
}

export default ClientRegistForm
