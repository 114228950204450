import Gallery from "react-photo-gallery";
import FadeIn from "react-fade-in";
import Carousel, { Modal, ModalGateway } from "react-images";
import { useCallback, useState } from "react";
import './galleryWithNameStyle.css'


const GalleryPhotosWithName = ({ GalleryInfo,title }) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    // Custom render for each photo to include profileName
    const renderGalleryPhoto = ({ photo, index }) => {
        return (
            <div className="galleryphoto-container" key={photo.id} onClick={(event) => openLightbox(event, { photo, index })}>
                <img src={photo.src} alt={photo.name} className="galleryphoto" />
                <div className="galleryphoto-caption">{photo.name}</div>
            </div>
        );
    };

    return (
        <>

            <FadeIn>
                <div className="gallery-Container">
                    <h1 className="gallery-title">{title}</h1>
                    <Gallery className='photosimg' photos={GalleryInfo} renderImage={renderGalleryPhoto} onClick={openLightbox} />
                    <ModalGateway className='dddddd'>
                        {viewerIsOpen ? (
                            <Modal onClose={closeLightbox} className='sdsfghjfdasfgh'>
                                <Carousel className='scdfghggg'
                                    currentIndex={currentImage}
                                    views={GalleryInfo.map((photo) => ({
                                        ...photo,
                                        srcset: photo.srcSet,
                                        caption: photo.name,
                                    }))}
                                />
                            </Modal>
                        ) : null}
                    </ModalGateway>
                </div>
            </FadeIn>
        </>
    )
}

export default GalleryPhotosWithName
