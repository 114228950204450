import MarxEventFooter from "../../common/Marx_Event/MarxEventFooter/MarxEventFooter"
import MarxEventHeader from "../../common/Marx_Event/MarxEventHeader/MarxEventHeader"
import '../../styles/eventServiceStyle.css'

const EventServices = () => {
    return (
        <>
            <MarxEventHeader />
            <div className="service-marx-event">
                <h1>Our Services</h1>
                <div className="servcediv-p">
                    <p>SPECIALIZES IN LASER SHOWS</p>
                    <p>SPECIALIZES IN PYROTECHNICS</p>
                    <p>SPECIALIZES IN SHOWS LIGHTING</p>
                    <p>SPECIALIZES IN STAGE DESIGNING</p>
                    <p>SPECIALIZES IN LCD / LED SCREEN</p>
                    <p>SPECIALIZES IN SET-UP DESIGNING</p>
                    <p>SPECIALIZES IN AERIAL FIREWORKS</p>
                    <p>SPECIALIZES IN CONSOLE DESIGNING</p>
                    <p>SPECIALIZES IN BACKGROUND EFFECTS</p>
                    <p>SPECIALIZES IN PLANNED EVENTS EXECUTION</p>
                    <p>SPECIALIZES IN AUDIO - VIDEO VISUALS SHOWS</p>
                    <p>SPECIALIZES IN LATERAL & INTEGRAL SHOW EXECUTION</p>
                    <p>SPECIALIZES IN TECHNICAL DESIGNING & INFRASTRUCTURE</p>
                    <p>SPECIALIZES IN EVENTS CONCEPTUALIZE DESIGNING & DEVELOPMENT</p>
                    <p>SPECIALIZES IN ENTERTAINMENT SHOWS & ARTIFICIAL INTELLIGENCE (AI)</p>
                </div>
            </div>

            <MarxEventFooter />
        </>
    )
}

export default EventServices
