import "./Contact.css";
import "bootstrap/dist/css/bootstrap.css";
import img from "../../assest/images/contactbg.jpg";
// import Form from "react-bootstrap/Form";
// import FloatingLabel from "react-bootstrap/FloatingLabel";
// import Button from "react-bootstrap/Button";
// import { CiMail } from "react-icons/ci";
// import { FaLocationDot } from "react-icons/fa6";
import Header from "../../common/MarxEntertainment/header/Header";
import Footer from "../../common/MarxEntertainment/footer/Footer";
import { Link } from "react-router-dom";

function Contact() {
  return (
    <>
      <Header />


      <section className="banner-section">
        <div>
          <img src={img} className="img-fluid" alt=" " />
        </div>

        <div className="container headerContainer">
          <div className="row">
            <div className="col-md-12" id="headerCol">
              <div className="header-heading">
                <h2>CONTACT US</h2>
                <p>LOOKING FORWARD FOR HEALTHY RELATIONS.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div>
        <div className="company-name">
         <h1><span className="span1">MAR’X</span> <span className="span2">ENTERTAINMENT</span> <span className="span3"> WORLDWIDE</span> <span className="span4">PVT. LTD.</span> (The Artist Manager’s)</h1>
       </div>

        <div className=" row">
          <div className=" col-sm-7">

            <div className="address-div">
              <h3>Mr. DEVASHISH BHANDARI. (Founder / Chairperson)</h3>
              <h5>Mobile  Office : + 91 – 9968098241. (Whatsapp only)</h5>
              <h5>Email Id : Info@Marxgroupofcompanies.in  </h5>
            </div>

            <div className="address-div">
              <h3>Artist  Manager Operations Head ( Pan - India) </h3>
              <h5>Mr. Sanjay Dubey </h5>
              <h5>Office (M) : 7210124700, 7669199800, 7210128900 (Whatsapp only) </h5>
              <h5>Email Id : Sanjay@Marxgroupofcompanies.in  </h5>
              <h5>Website : www.Marxgroupofcompanies.in </h5>
            </div>

            <div className="address-div">
              <h3>For Worldwide Shows Queries Mail us at :  </h3>
              <h5>Email Id : Worldwide@Marxgroupofcompanies.in </h5>
              <h5>Website : www.Marxgroupofcompanies.in </h5>
            </div>

            <div className="address-div">
              <h5>Regd.Office :  UGF  12  V complex  M.G Road Gurgaon,   Haryana – 122001.  </h5>
              <h5>Branch Office :   B – 84 Kamdhenu Complex , Lokhandwala Market , Andheri West Mumbai  - 400102 -  INDIA. </h5>
              <h5>Email.id:  Queries@Marxgroupofcompanies.in  </h5>
              <h5>Website : www.Marxgroupofcompanies.in </h5>
            </div>
          </div>
          <div className=" col-sm-5">
            <h2 className=" fw-bold text-center mt-5">Follow us on : </h2>
            <div className="socialmedia-div">
              <div className="social-link">
                <div>
                  <img src="https://s.yimg.com/fz/api/res/1.2/UfSQHihwpKu_0dPTFVK1ag--~C/YXBwaWQ9c3JjaGRkO2ZpPWZpdDtoPTEyMDtxPTgwO3c9MTIw/https://s.yimg.com/zb/imgv1/ff6c2336-05c2-34bf-8d42-c8eb48fbacf1/t_500x300" alt="img" />
                </div>
                <div>
                  <Link> MAR'X Entertainment Worldwide pvt ltd | Facebook</Link>
                  <Link> TeamMarxentertainmentworldwidepvtltd_Official II | Facebook </Link>
                  <Link> MAR'X GROUP of COMPANIES.Ltd | Facebook </Link>
                </div>
              </div>
              <div className="social-link">
                <div>
                  <img src="https://tse2.mm.bing.net/th?id=OIP.kA13Itmxev0ytEqrJf_f1gHaHa&pid=Api&P=0&h=180" alt="img" />
                </div>
                <div>
                  <Link>https://www.youtube.com/results?search_query=MAR%27X+ENTERTAINMEN </Link>
                </div>
              </div>
              <div className="social-link">
                <div>
                  <img src="https://s.yimg.com/fz/api/res/1.2/_5dmtTdXQzStB8e.53m2EQ--~C/YXBwaWQ9c3JjaGRkO2g9NzI7cT04MDt3PTcy/https://s.yimg.com/zb/imgv1/e9d6381f-6a16-39d9-b6f9-1467838c022c/t_500x300" alt="img" />
                </div>
                <div>
                  <Link>https://teammarx_mgc.official </Link>
                </div>
              </div>
              <div className="social-link">
                <div>
                  <img src="https://tse1.mm.bing.net/th?id=OIP.u8-VygkUnwaGso9q7SORcwHaHa&pid=Api&P=0&h=180" alt="img" />
                </div>
                <div>
                  <Link>https://www.linkedin.com/in/mar-x-ent-ww-pvt-ltd-the-artist-manager-s1a91828b?trk=hp-identity-name </Link>
                </div>
              </div>
              <div className="social-link">
                <div>
                  <img src="https://tse4.mm.bing.net/th?id=OIP.ztNyCL9PRiPmqTbS_ZcOeQHaEK&pid=Api&P=0&h=180" alt="img" />
                </div>
                <div>
                  <Link>https://Pin.it/66EiAnJ</Link>
                </div>
              </div>
              <div className="social-link">
                <div>
                  <img src="https://tse2.mm.bing.net/th?id=OIP.x3AF-5V66Exte3fMSeMm3gHaHZ&pid=Api&P=0&h=180" alt="img" />
                </div>
                <div>
                  <Link>https://Vk.com/Mewwmgc </Link>
                </div>
              </div>
              <div className="social-link">
                <div>
                  <img src="https://tse2.mm.bing.net/th?id=OIP.SRhk7PRQuPqlEhadkTJ6FQHaEM&pid=Api&P=0&h=180" alt="img" />
                </div>
                <div>
                  <Link>https://Vero.co/Mewwofficial_  </Link>
                </div>
              </div>
              <div className="social-link">
                <div>
                  <img src="https://tse3.mm.bing.net/th?id=OIP.LcLyxGAhDda28UkzzokL9QHaEK&pid=Api&P=0&h=180" alt="img" />
                </div>
                <div>
                  <Link>https://Marxentertainmentworldwidepvtltdofficial </Link>
                </div>
              </div>
              <div className="social-link">
                <div>
                  <img src="https://s.yimg.com/fz/api/res/1.2/KM9SnyJw8zrTQQC3gTcToQ--~C/YXBwaWQ9c3JjaGRkO2g9MTQ0O3E9ODA7dz0xNDQ-/https://s.yimg.com/zb/imgv1/efe0eda0-a368-3325-8975-d7ae7a783e4c/t_500x300" alt="img" />
                </div>
                <div>
                  <Link>https://twitter.com/Marxentertww </Link>
                </div>
              </div>
              <div className="social-link">
                <div>
                  <img src="https://s.yimg.com/fz/api/res/1.2/eSVihTWG52CYhbg9mLvn5g--~C/YXBwaWQ9c3JjaGRkO2ZpPWZpdDtoPTI0MDtxPTgwO3c9MjQw/https://s.yimg.com/zb/imgv1/fa44effb-c9ae-312b-992b-cc714e04dfdb/t_500x300" alt="img" />
                </div>
                <div>
                  <Link>https://Team-Mewwpvtltd.tumblr.com  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="note-div">
          <h4>
            *NOTE* : This e-mail and any files transmitted with it are for the sole use of the intended Recipient(s) and may contain confidential and privileged information. If you are not the intended Recipient, please contact the sender by reply e-mail to unsubscribe and destroy all copies and the original message. Any unauthorized Review, use, Disclosure, Dissemination, Forwarding, Printing, Editing or Copying of this email or Attach PDF Files or any action taken in faith, confidence or trust on this e-mail is strictly prohibited and may be unlawful. Before opening any attachments please check them for viruses and defects & all the photographs & P.D.F  files here in this email are protected under copyright act and Cannot be
            Re-Produced anywhere in any  form / Manner in Print, website, Social Media Platforms, Emails, without the consent & written Permission from Mar'x Group of Companies.(M.G.C) / Mar’x Entertainment Worldwide Management , as they are strictly against law . We have emailed them to you because they are part of our Daily work & you can see them and consider us for your new projects / Shows / Events for work & Bookings etc....!!
          </h4>
        </div>

      </div>

      {/* <section id="form-section" style={{ backgroundColor: "#f1f1f1" }}>
        <div className="form">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 formHeading" style={{ padding: "0px" }}>
                <h2>Fill this form and get in touch quickly!</h2>
              </div>
            </div>
            <div className="row inner-formHeading">
              <div className="col-md-12 inputsForms">
                <Form.Select size="lg" aria-label="Default select example">
                  <option>Choose topic</option>
                  <option value="1">Payment Withdrawal or Transfer</option>
                  <option value="2">Sign up and Login issue </option>
                  <option value="3">Other</option>
                </Form.Select>
              </div>
              <div className="col-md-12 inputsForms">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Name*"
                  className="mb-3"
                >
                  <Form.Control type="text" placeholder="name@example.com" />
                </FloatingLabel>
              </div>
              <div className="col-md-12 inputsForms">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Email*"
                  className="mb-3"
                >
                  <Form.Control type="email" placeholder="name@example.com" />
                </FloatingLabel>
              </div>
              <div className="col-md-12 inputsForms">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Mobile*"
                  className="mb-3"
                >
                  <Form.Control type="number" placeholder="name@example.com" />
                </FloatingLabel>
              </div>
              <div className="col-md-12 inputsForms">
                <Form.Select size="lg" aria-label="Default select example">
                  <option>Choose city</option>
                  <option value="1">Delhi</option>
                  <option value="2">Mumbai</option>
                  <option value="3">Agra</option>
                </Form.Select>
              </div>
              <div className="col-md-12 inputsForms">
                <FloatingLabel controlId="floatingTextarea2" label="Message">
                  <Form.Control
                    as="textarea"
                    placeholder="Leave a message here"
                    style={{ height: "150px" }}
                  />
                </FloatingLabel>
              </div>
              <div className="col-md-12 inputsForms" id="Submit_btn">
                <Button variant="primary" size="lg">
                  SUBMIT
                </Button>
              </div>
            </div>
          </div>
        </div>

        
       
      </section> */}

      <Footer />
    </>
  );
}
export default Contact;
