import { Button } from "@mui/material"
import Footer from "../common/MarxEntertainment/footer/Footer"
import Header from "../common/MarxEntertainment/header/Header"
import '../styles/signUpStyle.css'
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next';


const SignUp = () => {
    const {t}=useTranslation();
    return (
        <>
            <Header />
            <div className="signup-top-div">
                <div>
                    <h1>{t('signup_banner_h')}</h1>
                    <h5>{t('signup_banner_p')}</h5>
                </div>
            </div>

            <div className="signup-maindiv">
                <div className="brand-influencer">
                    <div>
                        <img src="https://www.influglue.com/public/frontend/images/influencericon.png?v=1688734414" alt="img" />
                    </div>
                    <h2>{t('signup_infu_h')}</h2>
                    <p>{t('signup_infu_p')}</p>
                    <Link to={'/influencer-signup'}>
                        <Button variant="contained">{t('signup_infu_button')}</Button>
                    </Link>
                </div>
                <div className="brand-influencer">
                    <div>
                        <img src="https://www.influglue.com/public/frontend/images/advertiser-icon.png?v=1688734414" alt="img" />
                    </div>
                    <h2>{t('signup_brand_h')}</h2>
                    <p>{t('signup_brand_p')}</p>
                    <Link to={'/advertiser-signup'}>
                    <Button variant="contained">{t('signup_brand_button')}</Button>
                    </Link>
                </div>
            </div>
            <div className="already-signin">
                <Link to={'/sign-in'}>
                   <p>{t('signup_login')}</p>
                </Link>
            </div>
            <Footer />
        </>
    )
}

export default SignUp
