import MarxEventFooter from "../../common/Marx_Event/MarxEventFooter/MarxEventFooter"
import MarxEventHeader from "../../common/Marx_Event/MarxEventHeader/MarxEventHeader"
import '../../styles/ourProjectEventStyle.css'
const OurProjects = () => {
    return (
        <>
            <MarxEventHeader />
            <div className="project-marx-event">
                <h1>Some Of Our Projects</h1>
                <p className="ptag">Have Conceptualize Various Shows as Grooming Expert / Fashion Director for Fashion Shows & Beauty Pageants on Pan – India Level</p>
                <div className="projectdiv">
                    <p>*FASHION SHOW AT DELHI FUN & FOOD VILLAGE IN COLLABORATION FOR AIRTEL.</p>
                    <p>*FASHION SHOW AT NOIDA TULIP FARMS FOR TELE ATLAS ( M.N.C )</p>
                    <p>*FASHION SHOW AT LUCKHNOW HOTEL TAJ PALACE FOR ANKIT GOLD TEA (DEALERS MEET)</p>
                    <p>*FASHION SHOW AT DELHI HOTEL CROWN PLAZA FOR ESYS LAPTOP PRODUCT LAUNCH ( SERCON )</p>
                    <p>*FASHION SHOW AT MEERUT FOR DESIGNER ASHISH SHARMA(Magna Communications)</p>
                    <p>*FASHION SHOW AT DELHI PHD CHAMBER OF COMMERCE FOR DESIGNER RADHIKA JAIN.</p>
                    <p>*FASHION SHOW FOR DESIGNER VEENU KHANNA.</p>
                    <p>*FASHION SHOW FOR DESIGNER TEK CHAND SAREES (chandni chowk)</p>
                    <p>*FASHION SHOW AT DELHI FOR DESIGNER ROOP VATIKA.</p>
                    <p>*FASHION SHOW FOR DESIGNER GAUTAM & ASHA GUPTA (Atture)</p>
                    <p>*FASHION SHOW AT JAIPUR CHOKI DANI RESORTS FOR DESIGNER TRIVENI SAREES & LEHNGA.</p>
                    <p>*FASHION SHOW AT DELHI FOR GOEL JEWELLERS (KAROL BAGH)</p>
                    <p>*FASHION SHOW AT DELHI FOR DESIGNER RATNA JAIN (TANTRA)</p>
                    <p>*FASHION SHOW AT DELHI FOR BHARTI COLLEGE MISS BHARTI ( D.U )</p>
                    <p>*FASHION SHOW AT DELHI BHARATI COLLEGE (D.U) BASED ON “GLOBAL WARMING”.</p>
                    <p>*FASHION SHOW AT DELHI FOR DESIGNER MEGHA & MANOJ SETHI.’10</p>
                    <p>*FASHION SHOW AT TGIP NOIDA FOR BRAND ‘BIG BAZAR’ BB STYLE ICON OF THE YR 11-12.</p>
                    <p>*FASHION SHOW AT DELHI FOR DUBAI BASED INTERNATIONAL BRAND ‘MUSHKY’ WITH IRFAN PATHAN (INDIAN CRICKTER )’12.</p>
                    <p>*FASHION SHOW AT BIHAR FOR BEAUTY PAGEANT Mr. & Miss. PATNA 2014. WITH Ms. ASIA PACIFIC WORLD (Himangini Singh Yadu)</p>
                    <p>*ORGANIZER’S FOR BEAUTY PAGEANT Miss. BIHAR INTERNATIONAL 2014. WITH A SOCIAL CAUSE ( Let’s Join Our Hands for Women Empowerment in India)</p>
                    <p>*FASHION SHOW AT BANGALORE FOR DESIGNER PRIYANKA ’17 .... Etc..!</p>
                    <p>*INVITED AS GUEST OF HONOUR FOR VARIOUS INDIAN FASHION WEEKS FROM 2001 To 2022.</p>
                    <p>*ASSOCIATED WITH FASHION DESIGNING COUNCIL OF INDIA (FDCI) & INDIAN FEDERATION OF FASHION DEVELOPMENT (IFFD) THE BIGGEST FASHION HOUSE IN INDIA.</p>
                    <p>*OUR FASHION SHOWS HAVE BEEN FEATURED IN VARIOUS TOP PUBLISHING NEWSPAPERS & NEWS CHANNELS LIKE ‘TIMES OF INDIA’ / DELHI TIMES / HINDUSTAN TIMES / HT CITY / NAVBHARAT TIMES / DAINIK JAGRAN / ZEE NEWS / STAR NEWS / AAJ TAK / ABP NEWS / etc…!!</p>
                    
                </div>
            </div>
            <MarxEventFooter />
        </>
    )
}

export default OurProjects
