import { Box, Button, TextField } from "@mui/material"
import Footer from "../common/MarxEntertainment/footer/Footer"
import Header from "../common/MarxEntertainment/header/Header"
import '../styles/loginStep1Style.css'
import { RiAccountBoxFill } from "react-icons/ri";
import { GiSuitcase } from "react-icons/gi";
import { MdEmail } from "react-icons/md";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LoginStep1 = () => {
    const {t}=useTranslation();
    return (
        <>
            <Header />
            <Box className='bg-img-box'>
                <div className="bg-box1">
                    <h2>{t('login_left_h')}</h2>
                    <p>{t('login_left_p')}</p>
                    <div className="btn-div-box">
                        <RiAccountBoxFill className="icon" />
                        <Link to={'/influencer-signup'}>
                            <Button>{t('login_left_button1')}</Button>
                        </Link>
                    </div>
                    <div className="btn-div-box">
                        <GiSuitcase className="icon" />
                        <Link to={'/advertiser-signup'}>
                            <Button>{t('login_left_button2')}</Button>
                        </Link>
                    </div>
                </div>
                <div className="bg-box2">
                    <h3>{t('login_right_h')}</h3>
                    <div className=" d-flex">
                        <MdEmail className="icon3" />
                        <TextField fullWidth placeholder=" Email Address..." />
                    </div>
                    <div>
                        <Button fullWidth>{t('login_right_button')}</Button>
                    </div>
                </div>
            </Box>
            <Footer />
        </>
    )
}

export default LoginStep1
