import { useGetAllDataQuery } from '../Services/productApi'
import { useGetAllPostQuery } from '../Services/Post';

// import './App.css';

//get all data

function EmptyPage() {
  const responseInfo = useGetAllPostQuery();
  console.log("response information :", responseInfo);
  console.log('information', responseInfo.data);
  // console.log(responseInfo.isSuccess);
  // console.log(responseInfo.requestId);

  const getData = useGetAllDataQuery();
  console.log("getData", getData.data)
  // const allData=getData.data;
  // const foundItem = allData.find(item => item.id === 2);
  // console.log(foundItem);
  const [ category, company, image, name, price ] = getData.data;
  console.log(category.category, company.company, image.image, name.name, price.price)
  return (
    <>
      <h1>redux toolkit -RTK query (getAllData)</h1>
      <div className=' d-flex justify-content-around align-items-center flex-wrap'>
        {responseInfo.data && responseInfo.data.map((val, index) => {
          return (
            <div key={index} style={{ width: '350px', border: '2px solid lightgrey', textAlign: 'center', margin: '10px' }} >
              <p>{val.id}</p>
              <p>{val.body}</p>
              <p>{val.title}</p>
              <p>{val.userId}</p>
            </div>
          )
        })}
      </div>

      <div className=' d-flex justify-content-around align-items-center flex-wrap'>
            <div style={{ width: '350px', border: '2px solid lightgrey', textAlign: 'center', margin: '10px' }} >
              <p>{category.category}</p>
              <p>{company.company}</p>
              <img style={{width:'200px'}} src={image.image} alt="img" />
              <p>{ name.name}</p>
              <p>{ price.price}</p>
            </div>
      </div>
    </>
  );
}

//get single data using id

// function EmptyPage() {
//   const responseInfo=useGetSinglePostByIdQuery(55);
//   // console.log("response information :",responseInfo);
//   console.log(responseInfo.data);
//   // console.log(responseInfo.isSuccess);
//   // console.log(responseInfo.requestId);
//   return (
//     <>
//     <h1>redux toolkit -RTK query (get single Data)</h1>

//       <div>
//         <h2>{responseInfo.data && responseInfo.data.id} { responseInfo.data && responseInfo.data.title}</h2>
//         <p>{ responseInfo.data && responseInfo.data.body}</p>
//         <hr />
//       </div>
//     </>
//   );
// }


//get limited data

// function EmptyPage() {
//   const responseInfo=useGetLimitedDataQuery(5);
//   // console.log("response information :",responseInfo);
//   console.log(responseInfo.data);
//   // console.log(responseInfo.isSuccess);
//   // console.log(responseInfo.requestId);
//   return (
//     <>
//     <h1>redux toolkit -RTK query (get limited Data)</h1>
//     {
//       responseInfo.data && responseInfo.data.map((val,index)=>
//         <div key={index}>
//           <h2>{val.id} {val.title}</h2>
//           <p>{val.body}</p>
//           <hr />
//         </div>
//       )
//     }
//     </>
//   );
// }

export default EmptyPage;