import MarxGroupFooter from '../../common/marxGroup/MarxGroupFooter'
import '../../styles/ContactusStyle.css'
import { Box, Button } from "@mui/material"
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom"
import Logo from '../../assest/images/marxNgoLogo.jpeg'
import { useEffect, useState } from "react";
import audiomp3 from '../../assest/mp3/ULTRA TRANCE David Guetta Miami Ultra Music Festival 2014 (mp3cut.net).mp3'
import ReactAudioPlayer from "react-audio-player";
import { MdAccountCircle } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { MdOutlineLogout } from "react-icons/md";
import { TileLayer, Marker, Popup, MapContainer } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import CountriesRunningSlide from "../../common/RunningSlideAllMarx/CountriesRunningSlide";
import CountriesData from "../../data/MarxGroup/CountriesData";
import LocationData from '../../data/MarxGroup/ContactusMapData';

 // Custom night view map style
 const nightViewStyle = `
 body.leaflet-container {
     background-color: #111;
 }
 .leaflet-tile-container {
     filter: brightness(120%) contrast(110%) grayscale(1);
 }
 .leaflet-container .leaflet-marker-icon, .leaflet-container .leaflet-marker-shadow {
    filter: invert(99%) sepia(100%) saturate(10000%) hue-rotate(360deg);
    color: red;
 }
`;


const customMarker = new L.Icon({
    iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
    iconSize: [25, 41],
    iconAnchor: [10, 41],
    popupAnchor: [2, -40],
    className: 'blink-icon'
});

const FoundationContactUs = () => {
    const { t } = useTranslation();
    const [isProfileBoxOpen, setIsProfileBoxOpen] = useState(false);

    useEffect(() => {
        const googleTranslateElementInit = () => {
            new window.google.translate.TranslateElement(
                {
                    pageLanguage: "en",
                    autoDisplay: false,
                    attribution: null // to null to remove "Powered by Google"
                },
                "google_translate_element"
            );
        };

        // Check if the script has already been added to the document
        if (!window.google || !window.google.translate) {
            const addScript = document.createElement("script");
            addScript.id = "google-translate-script";
            addScript.src =
                "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
            addScript.async = true;
            addScript.defer = true;

            // Set up a callback function for when the script is loaded
            addScript.onload = googleTranslateElementInit;

            document.body.appendChild(addScript);
        } else {
            // If Google Translate is already defined, initialize it immediately
            googleTranslateElementInit();
        }

        // Clean up function to remove the script when the component is unmounted
        return () => {
            const script = document.getElementById("google-translate-script");
            if (script) {
                document.body.removeChild(script);
            }
        };
    }, []);

    const toggleProfileBox = () => {
        console.log('Toggling profile box');
        setIsProfileBoxOpen(!isProfileBoxOpen);
    };


    useEffect(() => {
        // Add custom styles to the map
        const nightViewCSS = L.DomUtil.create('style');
        nightViewCSS.innerHTML = nightViewStyle;
        document.getElementsByTagName('head')[0].appendChild(nightViewCSS);

        // Cleanup function to remove added style when the component unmounts
        return () => {
            document.getElementsByTagName('head')[0].removeChild(nightViewCSS);
        };
    }, []); // Empty dependency array ensures this effect runs only once on mount




    return (
        <>
            <div className="marxgroup-topdivbar">
                <div className="marx-group-navlist">
                    <ul>
                        <li>
                            <Link>
                                <Button className="btnn1" size="small"> {t('header_key7')}</Button>
                            </Link>
                        </li>
                        <li>
                            <Button onClick={toggleProfileBox} className="btnn2" size="small"><MdAccountCircle className=" fs-2 text-primary bg-white rounded-5" /></Button>
                            <Box className={isProfileBoxOpen ? 'boxvisible' : 'boxhidden'}>
                                <Link className="myprofile" to={'/edit-profile'}><CgProfile className="me-1" />My Profile</Link>
                                <Link className="logout-profile"><MdOutlineLogout className="me-1" />Logout</Link>
                            </Box>
                        </li>
                    </ul>
                </div>
                <div className=" d-flex">
                    <ReactAudioPlayer
                        src={audiomp3}
                        // autoPlay
                        // loop
                        controls
                        className="marxgroup-audioplayer"
                    />
                </div>
            </div>

            <Box className=''>
                <div className="marxgroup-navbar">
                    <div className="marxgroup-nav-baar">
                        <div className="logo">
                            <Link to={'/marx-group'}>
                                <img
                                    className="imgglogo"
                                    src={Logo}
                                    alt="logo"
                                />
                            </Link>

                        </div>
                        <div className="company-profile-name">
                            <h1 className="h1tag">WorldWide Work Operations  In 80 Countries</h1>
                        </div>
                    </div>
                </div>

            </Box>

            <div className='bgblackbox'>
                <div className=" d-flex justify-content-center">
                    <MapContainer style={{ height: "580px", width: "90%", borderRadius: '4px' }}
                        zoom={4}
                        center={[22.3511, 78.6677]}
                        minZoom={4}
                        maxZoom={4}
                    >
                        <TileLayer
                            url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
                        />
                        {LocationData.map((location, index) => (
                            <Marker
                                icon={customMarker}
                                key={`marker-${index}`}
                                position={[location.location_lat, location.location_long]}
                            >
                                <Popup>{location.location_name}</Popup>
                            </Marker>
                        ))}
                    </MapContainer>
                </div>

                <CountriesRunningSlide title={'Countries'} data={CountriesData} />

                <div className="contactusmain">
                    <h1>Contact Us</h1>
                    <div className="emailphone">
                        <div>
                            <p>Mobile Office : + 91 – 9968098241. (Whatsapp only)</p>
                            <p>Office (M) : + 91 – 7210124700 (Whatsapp only)</p>
                            <p>Office (M) : + 91 – 7210128900. (Whatsapp only)</p>
                            {/* <p>Office (M) : + 91 – 7669199800. (Whatsapp only)</p> */}
                        </div>
                        <div className='emaildiv'>
                            <p>Email Id : info@Marxgroupworldwide.com</p>
                            <p>Email Id : Bookings@Marxgroupworldwide.com</p>
                            <p>Email Id : Queries@Marxgroupworldwide.com</p>
                            <p>Email Id : Worldwide@Marxgroupworldwide.com</p>
                            <p className='webadd'>Website Address : www.MarxGroupWorldwide.com</p>
                        </div>
                    </div>
                    <h4 className='add m-0 text-center py-3'>Regd. Office Address :- MGC. 318, 3rd floor, V-Complex, MG Road Gurugram Haryana - 122002 - India. </h4>
                </div>
            </div>

            <MarxGroupFooter />
        </>
    )
}

export default FoundationContactUs
