import ind from '../../../assest/images/countriesFlag/flag_ind.jpg'


const DestinationWeddCountryData = [
    {
        id: 1,
        name:'UAE',
        imgSrc: ind,
    },
    {
        id: 2,
        name:'Turkey',
        imgSrc: ind,
    },
    {
        id: 3,
        name:'Switzerland',
        imgSrc: ind,
    }, {
        id: 4,
        name:'USA',
        imgSrc: ind,
    }, {
        id: 5,
        name:'Canada',
        imgSrc: ind,
    }, {
        id: 6,
        name:'Malaysia',
        imgSrc:ind,
    }, {
        id: 7,
        name:'Singapore',
        imgSrc:ind,
    }
];
export default DestinationWeddCountryData;