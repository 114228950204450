import enttHallOfFame1 from '../../assest/images/entt.HallOfFame/enttHallOfFame1.jpeg'
import enttHallOfFame2 from '../../assest/images/entt.HallOfFame/enttHallOfFame2.jpeg'
import enttHallOfFame3 from '../../assest/images/entt.HallOfFame/enttHallOfFame3.jpeg'
import enttHallOfFame4 from '../../assest/images/entt.HallOfFame/enttHallOfFame4.jpeg'
import enttHallOfFame5 from '../../assest/images/entt.HallOfFame/enttHallOfFame5.jpeg'
import enttHallOfFame6 from '../../assest/images/entt.HallOfFame/enttHallOfFame6.jpeg'
import enttHallOfFame7 from '../../assest/images/entt.HallOfFame/enttHallOfFame7.jpeg'
import enttHallOfFame8 from '../../assest/images/entt.HallOfFame/enttHallOfFame8.jpeg'
import enttHallOfFame9 from '../../assest/images/entt.HallOfFame/enttHallOfFame9.jpeg'
import enttHallOfFame10 from '../../assest/images/entt.HallOfFame/enttHallOfFame10.jpeg'
import enttHallOfFame11 from '../../assest/images/entt.HallOfFame/enttHallOfFame11.jpeg'
import enttHallOfFame12 from '../../assest/images/entt.HallOfFame/enttHallOfFame12.jpeg'
import enttHallOfFame13 from '../../assest/images/entt.HallOfFame/enttHallOfFame13.jpeg'
import enttHallOfFame14 from '../../assest/images/entt.HallOfFame/enttHallOfFame14.jpeg'
import enttHallOfFame15 from '../../assest/images/entt.HallOfFame/enttHallOfFame15.jpeg'
import enttHallOfFame16 from '../../assest/images/entt.HallOfFame/enttHallOfFame16.jpeg'
import enttHallOfFame17 from '../../assest/images/entt.HallOfFame/enttHallOfFame17.jpeg'
import enttHallOfFame18 from '../../assest/images/entt.HallOfFame/enttHallOfFame18.jpeg'
import enttHallOfFame19 from '../../assest/images/entt.HallOfFame/enttHallOfFame19.jpeg'
import enttHallOfFame20 from '../../assest/images/entt.HallOfFame/enttHallOfFame20.jpeg'
import enttHallOfFame21 from '../../assest/images/entt.HallOfFame/enttHallOfFame21.jpeg'
import enttHallOfFame22 from '../../assest/images/entt.HallOfFame/enttHallOfFame22.jpeg'
import enttHallOfFame23 from '../../assest/images/entt.HallOfFame/enttHallOfFame23.jpeg'
import enttHallOfFame24 from '../../assest/images/entt.HallOfFame/enttHallOfFame24.jpeg'
import enttHallOfFame25 from '../../assest/images/entt.HallOfFame/enttHallOfFame25.jpeg'




const EnttHallOfFameData = [
    {
        id: 1,
        src: enttHallOfFame1,
    },
    {
        id: 2,
        src: enttHallOfFame2,
    },
    {
        id: 3,
        src: enttHallOfFame3,
    }, {
        id: 4,
        src: enttHallOfFame4,
    }, {
        id: 5,
        src: enttHallOfFame5,
    }, {
        id: 6,
        src:enttHallOfFame6,
    }, {
        id: 7,
        src:enttHallOfFame7,
    }, {
        id: 8,
        src:enttHallOfFame8,
    }, {
        id: 9,
        src:enttHallOfFame9,
    }, {
        id: 10,
        src:enttHallOfFame10,
    },{
        id: 11,
        src:enttHallOfFame11,
    },{
        id: 12,
        src:enttHallOfFame12,
    },{
        id: 13,
        src:enttHallOfFame13,
    },{
        id: 14,
        src:enttHallOfFame14,
    },{
        id: 15,
        src:enttHallOfFame15,
    },{
        id: 16,
        src:enttHallOfFame16,
    },{
        id: 17,
        src:enttHallOfFame17,
    },{
        id: 18,
        src:enttHallOfFame18,
    },{
        id: 19,
        src:enttHallOfFame19,
    },{
        id: 20,
        src:enttHallOfFame20,
    },{
        id: 21,
        src:enttHallOfFame21,
    },{
        id: 22,
        src:enttHallOfFame22,
    },{
        id: 23,
        src:enttHallOfFame23,
    },{
        id: 24,
        src:enttHallOfFame24,
    },{
        id: 25,
        src:enttHallOfFame25,
    }
];
export default EnttHallOfFameData;