import GalleryPhotos from '../../common/Gallery/Gallery'
import SecondPicsData from '../../data/marxEvent/SecondPicsData'
import MarxFoundationHeader from '../../common/MarxFoundation/HeaderMarxFoundation'
import MarxFoundationFooter from '../../common/MarxFoundation/FooterMarxFoundation'

const FoundationShowsGallery = () => {
    return (
        <>
            <MarxFoundationHeader />
            <GalleryPhotos  GalleryData={SecondPicsData} title={'Events Shows Gallery'}/>
            <MarxFoundationFooter />
        </>
    )
}

export default FoundationShowsGallery
