import { Box, Button } from "@mui/material"
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom"
import logo from '../../../assest/images/marxMediaLogo.jpeg'
import './marxMediaHeaderStyle.css'
import { GoHomeFill } from "react-icons/go";


const MarxMediaHeader = () => {
    const { t } = useTranslation();

    return (
        <>
            <Box className=''>
                <div className="media-topdiv-bar">
                    <div className="media-marxghome">
                        <Link to='/marx-group' className=""> <Button size="small" className=" bg-white text-black p-1"><GoHomeFill className=' fs-5' /></Button></Link>
                    </div>
                </div>
                <div className="navbarmedia">
                    <div className="nav-mediabaar">
                        <div className="logo">
                            <Link to={'/marx-media'}>
                                <img
                                    className="medialogo"
                                    src={logo}
                                    alt="logo"
                                />
                            </Link>
                        </div>

                        <div className="mediaprofilename">
                            <h1><span className="span1">MAR’X</span> <span className="span2">MEDIA</span> <span className="span4">PVT.LTD.</span></h1>
                            <div className="stylistp">
                                <p>(The Leading Pioneer's)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Box>
        </>
    )
}

export default MarxMediaHeader
