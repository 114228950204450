import mediaprint1 from '../../assest/images/mediaPrintTVcommercialAdvertisement/mediaprint1.jpeg'
import mediaprint2 from '../../assest/images/mediaPrintTVcommercialAdvertisement/mediaprint2.jpeg'
import mediaprint3 from '../../assest/images/mediaPrintTVcommercialAdvertisement/mediaprint3.jpeg'
import mediaprint4 from '../../assest/images/mediaPrintTVcommercialAdvertisement/mediaprint4.jpeg'
import mediaprint5 from '../../assest/images/mediaPrintTVcommercialAdvertisement/mediaprint5.jpeg'
import mediaprint6 from '../../assest/images/mediaPrintTVcommercialAdvertisement/mediaprint6.jpeg'


const PrintMediaData = [
    {
        id:1,
        imgSrc: mediaprint1,
    },
    {
        id:2,
        imgSrc: mediaprint2,
    },
    {
        id:3,
        imgSrc: mediaprint3,
    }, {
        id:4,
        imgSrc: mediaprint4,
    }, {
        id:5,
        imgSrc: mediaprint5,
    }, {
        id:6,
        imgSrc: mediaprint6,
    }
];
export default PrintMediaData;