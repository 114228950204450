import '../GalleryWitthBioAllMax/galleryWithBioAllMaxStyle.css'
import { Link } from "react-router-dom";

const WithBioWithoutRunningGallery = ({heading,detailpg,ModelData}) => {
  
  return (
    <>
        <div className="gallerymodel-Container">
           <h1 className="gallerymodel-title">{heading}</h1>  
          <div className="galleryflexbox">
            {ModelData.map((item) => (
              <Link className="gallery-anchrtag" to={`/${detailpg}/${item.id}`}>
                <div key={item.id} className='galleryphoto-container'>
                  <img className="gallery-photoimg" src={item.src} alt={item.profileName} />
                  <p className="gallery-photocaption">{item.profileName}</p>
                </div>
              </Link>
            ))}
          </div>
        </div>
    </>
  )
}

export default WithBioWithoutRunningGallery
