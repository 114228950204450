import { Box, Button } from "@mui/material"
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom"
import logo from '../../assest/images/marxMotionPic.jpeg'
import './headerMarxMotionPictureStyle.css'
import { GoHomeFill } from "react-icons/go";


const MarxMotionPictureHeader = () => {
  const { t } = useTranslation();
  return (
    <>
      <Box className=''>
        <div className="motionpic-topdiv-barbox">
          <div className="motionpic-marxghome">
            <Link to='/marx-group' className=""> <Button size="small" className=" bg-white text-black p-1"><GoHomeFill className=' fs-5' /></Button></Link>
          </div>

        </div>

        <div className="motionpic-navbar">
          <div className="motionpic-nav-baar">
            <div className="logo">
              <Link to={'/marx-motion-picture'}>
                <img
                  className="motionpic-logo"
                  src={logo}
                  alt="logo"
                />
              </Link>
            </div>

            <div className="motionpic-profilename">
              <h1><span className="span1">MAR’X</span> <span className="span2">MOTION PICTURE </span> <span className="span3"> ALLIANCE </span> <span className="span4">  PVT. LTD. </span></h1>
              <div className="stylistp">
                <p>(The Pursuit of Excellence)</p>
              </div>
            </div>
          </div>
        </div>


      </Box>

    </>
  )
}

export default MarxMotionPictureHeader
